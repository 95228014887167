import React, { useEffect, useState }  from "react";
import { observer } from "mobx-react";
import {  Form } from 'antd';
import useStore from "../../../../store";
import { useHistory, useParams } from "react-router";
import { Button, Row, Col, Layout, Upload  } from "antd";
import { FormBox, InputBox } from "../../../../components/AntdAddons";
import BreadcrumbComponent from "../../../../components/BreadcrumbComponent/BreadcrumbComponent";
import { NewsBreadcrumb } from "../../../../config/BreadcrumbConfig";
import { NewsRequest } from "../../../../requests/NewsRequest";
import ReactQuill from "react-quill";
import '../../../../../node_modules/react-quill/dist/quill.snow.css';
import { useTranslation } from "react-i18next";
import { uploadedFileOnPreview } from "../../../../config/Global";

const EditNewsComponent: React.FC = observer(() => {
	const modules = {
		toolbar: [
		[{ header: [1, 2, false] }],
		["bold", "italic", "underline", "strike", "blockquote"],
		[{ list: "ordered" }, { list: "bullet" }],
		["link", "image"]
	]
};
	const formats = [
	"header",
	"bold",
	"italic",
	"underline",
	"strike",
	"blockquote",
	"list",
	"bullet",
	"indent",
	"link",
	"image"
	];
	const { NEWS, ROOT } = useStore();
	const {
		update,
		details,

	} = NEWS;

	const [news, setnews] = useState<any>(null)
	const { id }: any = useParams();
	const { AssignErrorToInput } = ROOT;
	const[image, setNewsImage] = useState(null);

	const onFileChange = (info:any) =>{
		setNewsImage(info);
		return false;
	}

	const [form] = Form.useForm();
	const [saving, setSaving] = useState<boolean>(false);
	const [description, setDescription] = useState<string>("");
	const [Shortdescription, setShortdescription] = useState<string>("");
	const history = useHistory();
	const { t } = useTranslation();
	const listRoute = () =>{
		history.push('/news-management');
	}

	// Handle submit and call function to save new record
	const handleSubmit = (data: any) => {
		setSaving(true);
		const formData = new FormData();
		image && formData.append('news_image',image);
		data.title && formData.append('title', data.title);
		formData.append('description', description)
		formData.append('short_description', Shortdescription);
		update(news.id, formData)
			.then(() => {
				setSaving(false);
				form.resetFields();
				listRoute();
			})
			.catch((e:any) => {
				setSaving(false);
				const errors = e?.data?.errors || e?.errors
				AssignErrorToInput(form, errors);
			})
	};

	useEffect(()=>{
		details(id).then((data:any) => {
			form.resetFields();
			form.setFieldsValue({
				title:data?.title,
			});
			setnews(data);
			setDescription(data?.description);
			setShortdescription(data?.short_description);
		});
	},[details]);

	return (
		news && <FormBox
		id="addNewsForm"
		form={form}
		encType="multipart/formdata"
		onFinish={handleSubmit} key="formbasic">
			<div className="Breadcrumb-bar Breadcrumb-bar-export">
				<BreadcrumbComponent items={NewsBreadcrumb(t).path} />
			</div>
			<Layout style={{ padding: '0 24px' }}>
				<Row gutter={6} wrap={false}>
					<Col span={8} className="form__input__textbox">
						<InputBox.Text
							size="large"
							placeholder={t('TITLE')}
							name="title"
							label={t('TITLE')}
							rules={NewsRequest(t).title} />
					</Col>
					<Col span={16} className="form__input__textbox">
						<Upload
							name="image"
							accept="image/png, image/jpeg"
							listType="picture"
							defaultFileList={[
								{
									uid: '1',
									name: news.title,
									status: 'done',
									response: 'Server Error 500', // custom error message to show
									url: news.news_image,
									thumbUrl: news.news_image,
								}]}
							maxCount={1}
							beforeUpload={onFileChange}
							onPreview={uploadedFileOnPreview}
						>
							<Button className="mb-20">{t("CLICK_TO_UPLOAD_IMAGE")}</Button>
						</Upload>
					</Col>

				</Row>
				<Row gutter={6} wrap={false}>
					<Col span={16} className="form__input__textbox form__input__selectBox">
						<p>{t("SHORT_DESCRIPTION")}</p>
						<ReactQuill
							placeholder={t("SHORT_DESCRIPTION")} theme="snow"
							modules={modules}
							formats={formats}
							onChange={(e:any) => {
								setShortdescription(e);
							}}
							value={Shortdescription}
						/>
					</Col>
				</Row>
				<Row gutter={6} wrap={false}>
					<Col span={16} className="form__input__textbox form__input__selectBox">
						<p>{t("DESCRIPTION")}</p>
						<ReactQuill
							placeholder={t("DESCRIPTION")} theme="snow"
							modules={modules}
							formats={formats}
							onChange={(e:any) => {
								setDescription(e);
							}}
							value={description}
						/>
					</Col>
				</Row>
				<Col span={24} className="permission__button ant-row-center ant-row mt-50">
					<Button type="primary"
						htmlType="submit"
						loading={saving}
						key="submit1">{t('SAVE')}</Button>
					<Button type="primary" key="resetCancel" danger onClick={listRoute}>{t('CANCEL')}</Button>
				</Col>
			</Layout>
		</FormBox>
	);
});
export default EditNewsComponent;
