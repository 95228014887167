import { GridOptions, GridReadyEvent, IServerSideGetRowsParams } from "ag-grid-community";
import axios from "axios";
import { makeAutoObservable } from "mobx";
import API_URL from "../../config/ApiUrl";
import { NewsListDataProps } from "./NewsIntrface";
import Config from "../../config/Config";
import RootStore from "../RootStore/RootStore";

export default class NewsStore {
	public listData?:NewsListDataProps[];
	public grid?: any;
	private rootStore: RootStore;
	public deleteNewsPopup?: boolean;
	public deleteNewsID?: number;
	public perPage?:any = 100;

	constructor() {
		this.rootStore = new RootStore();
		makeAutoObservable(this);
	}

	// Setter Functions
	private setListData = (value?: NewsListDataProps[]): void => {
		this.listData = value;
	};

	private setGrid = (value: any) => {
		this.grid = value;
	};

	public openDeleteNewsPopup = (id: number): void => {
		this.deleteNewsPopup = true;
		this.setDeleteNewsID(id);
	};

	public closeDeleteNewsPopup = (): void => {
		this.deleteNewsPopup = false;
		this.setDeleteNewsID();
	};

	private setDeleteNewsID = (id?: number): void => {
		this.deleteNewsID = id;
	};

	public setupGrid = (params: GridReadyEvent) => {
		this.setGrid(params);
		this.setPageSize()
		const datasource = this.createDatasource(Config.grid.server.gridOptions);
		params.api.setServerSideDatasource(datasource);
	};

	public setPageSize = (page = this.perPage) => {
		this.perPage = page;
		if (this.grid) {
			this.grid.api.paginationSetPageSize(parseInt(page));
		}
	};

	private createDatasource = (gridOptions?: GridOptions) => {
		return {
			gridOptions,
			getRows: (params: IServerSideGetRowsParams) => {
				const payload = this.rootStore.getServerListPayload(params);
				this.fetchList(payload).then((data) => {
					params.successCallback(data?.rows, data?.count);
					if (data.count <= 0) {
						params.api.showNoRowsOverlay();
					} else {
						params.api.hideOverlay();
					}
				});
			},
		};
	};

	// API Functions
	public fetchList =  (payLoad:any): Promise<any> => {
		return axios.post(API_URL.NEWS.LIST,payLoad).then(({ data }) => {
			this.setListData(data.data.rows);
			return data.data;
		});
	};

	public create = (payload: any): Promise<any> => {
		return axios
			.post(API_URL.NEWS.CREATE, payload)
			.then(({ data }) => {
				this.setListData(data.data.rows);
				return data.data;
			})
			.catch(({ data }) => {
				return Promise.reject(data);
			});
	};

	public update = (id:number,payload: FormData): Promise<any> => {
		return axios.post(API_URL.NEWS.UPDATE(id), payload).then(({ data }) => {
			return data.data;
		});
	};	
	
	public details = (id: number): Promise<any> => {
		return axios.get(API_URL.NEWS.DETAILS(id)).then(({ data }) => {
			return data.data;
		});
	};

	public remove = (id: number): Promise<any> => {
		return axios.delete(API_URL.NEWS.DELETE(id)).then(({ data }) => {
			this.setupGrid(this.grid);
			return data.data;
		});
	};

	public changeStatus = (payload:any): Promise<any> => {
		return axios.post(API_URL.NEWS.CHANGE_STATUS(payload.id),payload)
		.then(({ data }) => {
			return data.data;
		});
	};


}