import React, { useEffect, useState }  from "react";
import { Button, Row, Col, Layout  } from "antd";
import useStore from "../../../../store";
import { observer } from "mobx-react-lite";
import { FormBox, InputBox } from "../../../../components/AntdAddons";
import BreadcrumbComponent from "../../../../components/BreadcrumbComponent/BreadcrumbComponent";
import { SettingsBreadcrumb } from "../../../../config/BreadcrumbConfig";
import { SmtpRequest } from "../../../../requests/SettingsRequest";
import {  Form } from 'antd';
import { useHistory } from "react-router";
import { useTranslation } from "react-i18next";


const SmtpComponent: React.FC = observer(() => {
	const { SETTINGS, ROOT } = useStore();
	const { getSettingDetails, saveSettingDetails, } = SETTINGS;
	const [settings, setSettings] = useState<any>()
	const { AssignErrorToInput } = ROOT;
	const key = "smtp";
	const listRoute = () =>{
		history.push('/settings');
	}

	useEffect(() => {
		if (!settings) {
			getSettingDetails(key).then((data:any) => {
				const settingData = JSON.parse(data?.setting_value);
				form.resetFields();
				form.setFieldsValue({
					mail_mailer:settingData?.mail_mailer,
					mail_host:settingData?.mail_host,
					mail_port:settingData?.mail_port,
					mail_username:settingData?.mail_username,
					mail_password:settingData?.mail_password,
					mail_encryption:settingData?.mail_encryption,
					mail_from_address:settingData?.mail_from_address,
					mail_from_name:settingData?.mail_from_name,
				});
				setSettings(settingData);
			});
		}
	}, [getSettingDetails, settings, setSettings])
	const [form] = Form.useForm();
	const [saving, setSaving] = useState<boolean>(false);
	const history = useHistory();
	const { t } = useTranslation();

	// Handle submit and call function to save new record
	const handleSubmit = (data: any) => {
		setSaving(true);
		const formData = new FormData();
		data.mail_mailer && formData.append('mail_mailer', data.mail_mailer);
		data.mail_host && formData.append('mail_host', data.mail_host);
		data.mail_port && formData.append('mail_port', data.mail_port);
		data.mail_username && formData.append('mail_username', data.mail_username);
		data.mail_password && formData.append('mail_password', data.mail_password);
		data.mail_encryption && formData.append('mail_encryption', data.mail_encryption);
		data.mail_from_address && formData.append('mail_from_address', data.mail_from_address);
		data.mail_from_name && formData.append('mail_from_name', data.mail_from_name);
		saveSettingDetails(key, formData)
			.then(() => {
				setSaving(false);
				form.resetFields();
				listRoute();
			})
			.catch((e:any) => {
				setSaving(false);
				const errors = e?.data?.errors || e?.errors
				AssignErrorToInput(form, errors);
			})
	};
	return (
	<>
	<div className="Breadcrumb-bar Breadcrumb-bar-export">
		<BreadcrumbComponent items={SettingsBreadcrumb(t).path} />
	</div>
	<FormBox
		id="siteManagementForm"
		form={form}
		encType="multipart/formdata"
		onFinish={handleSubmit} key="formbasic">
			<Layout style={{ padding: '0 24px' }}>
				<Row gutter={6} wrap={false}>
					<Col span={8} className="form__input__textbox">
						<InputBox.Text
							size="large"
							placeholder={t("MAILER")}
							name="mail_mailer"
							label={t("MAILER")}
							rules={SmtpRequest(t).mail_mailer}
						/>
					</Col>
					<Col span={8} className="form__input__textbox">
						<InputBox.Text
							size="large"
							placeholder={t("HOST")}
							name="mail_host"
							label={t("HOST")}
							rules={SmtpRequest(t).mail_host}
						/>
					</Col>
				</Row>
				<Row gutter={6} wrap={false}>
					<Col span={8} className="form__input__textbox">
						<InputBox.Text
							size="large"
							placeholder={t("PORT")}
							name="mail_port"
							label={t("PORT")}
							rules={SmtpRequest(t).mail_port}
						/>
					</Col>
					<Col span={8} className="form__input__textbox form__input__selectBox">
						<InputBox.Text
							size="large"
							placeholder={`${t("EMAIL")} / ${t("USER")}${t("NAME")}`}
							name="mail_username"
							label={`${t("EMAIL")} / ${t("USER")}${t("NAME")}`}
							rules={SmtpRequest(t).mail_username}  />
					</Col>
				</Row>
				<Row gutter={6} wrap={false}>
					<Col span={8} className="form__input__textbox form__input__selectBox">
						<InputBox.Password
							size="large"
							placeholder={t("PASSWORD")}
							name="mail_password"
							label={t("PASSWORD")}
							rules={SmtpRequest(t).mail_password}  />
					</Col>
					<Col span={8} className="form__input__textbox">
						<InputBox.Text
							size="large"
							placeholder={t("ENCRYPTION")}
							name="mail_encryption"
							label={t("ENCRYPTION")}
							rules={SmtpRequest(t).mail_encryption}
						/>
					</Col>
				</Row>
				<Row gutter={6} wrap={false}>
					<Col span={8} className="form__input__textbox">
						<InputBox.Text
							size="large"
							placeholder={t("MAIL_FROM_ADDRESS")}
							name="mail_from_address"
							label={t("MAIL_FROM_ADDRESS")}
							rules={SmtpRequest(t).mail_from_address}
						/>
					</Col>
					<Col span={8} className="form__input__textbox">
						<InputBox.Text
							size="large"
							placeholder={t("MAIL_FROM_NAME")}
							name="mail_from_name"
							label={t("MAIL_FROM_NAME")}
							rules={SmtpRequest(t).mail_from_name}
						/>
					</Col>
				</Row>
				<Col span={24} className="permission__button ant-row-center ant-row mt-50">
					<Button type="primary"
						htmlType="submit"
						loading={saving}
						key="submit1">{t('SAVE')}</Button>
					<Button type="primary" key="resetCancel" danger onClick={listRoute}>{t('CANCEL')}</Button>
				</Col>
			</Layout>
		</FormBox>
		</>
	);
});

export default SmtpComponent;
