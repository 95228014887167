import { observer } from "mobx-react";
import React, { useEffect, useState } from "react";
import { useHistory } from "react-router";
import BreadcrumbComponent from "../../../../components/BreadcrumbComponent/BreadcrumbComponent";
import ContentBox from "../../../../components/ContentBox/ContentBox";
import { LoanRequestBreadcrumb } from "../../../../config/BreadcrumbConfig";
import useStore from "../../../../store";
import HeaderButtons from "./components/HeaderButtons";
import ListComponent from "./components/ListComponent";
import RecordPerPage from "../../../../utils/RecordPerPage";
import { useTranslation } from "react-i18next";
import { Button } from "antd";
import { GrDocumentExcel } from "react-icons/gr";
import ExportPopupComponent from "../../../../utils/ExportPopupComponent";

const LoanRequestView: React.FC = observer(() => {
	const { t } = useTranslation();
	const { LOAN, AUTH, COMMON} = useStore();
	const { setupGrid, setPageSize, perPage, exportList } = LOAN;
	const { permissions } = AUTH;

	const [exportSaving, setExportSaving] = useState<boolean>(false);
	const [exportPopup, showExportPopup] = useState<boolean>(false);

	const pathHistory = useHistory();
	const {allPermissionList} = COMMON

	useEffect(()=>{
		//
	}, [allPermissionList]);

	const addLoanRequest= () => {
		pathHistory.push('loan-request-management/create');
	}

	const editLoanRequest= (id:number) => {
		pathHistory.push('loan-request-management/'+id+'/edit');
	}

	const loanRequestDetails = (id:number) => {
		pathHistory.push('loan-request-management/'+id+'/details');
	}

	const exportListData = (type: string) => {
		setExportSaving(true);
		exportList(type).finally(() => {
			setExportSaving(false);
			showExportPopup(false);
		});
	}

	return (
		<>
			<div className="Breadcrumb-bar">
				<BreadcrumbComponent items={LoanRequestBreadcrumb(t).path} />
				<div className="header_all_btn">
					<Button type="primary" className="export-btn" icon={<GrDocumentExcel />} onClick={()=> showExportPopup(true)}>
						{t("GENERATE_REPORT")}
					</Button>
							
					<ExportPopupComponent
						callbackExportFunction={exportListData}
						setVisible={showExportPopup}
						visible={exportPopup}
						saving={exportSaving}
					/>

					<HeaderButtons
						key={1}
						addRedirect={addLoanRequest}
						addPermission={permissions && allPermissionList && permissions.indexOf(allPermissionList.ADD_USER_LOAN_REQUEST) >= 0}
					/>
				</div>
			</div>

			<ContentBox>
			<	div className="RecordPerPage__right">
					<RecordPerPage
						style={{ width: "150px" }}
						defaultValue={perPage + " per page"}
						onChange={setPageSize}
					/>
				</div>
				<ListComponent
					setupGrid={setupGrid}
					editRedirect={editLoanRequest}
					editPermission={permissions && allPermissionList && permissions.indexOf(allPermissionList.EDIT_USER_LOAN_REQUEST) >=0}
					detailPermission={permissions && allPermissionList && permissions.indexOf(allPermissionList.USER_LOAN_REQUEST_DETAILS) >=0}
					detailsRedirect={loanRequestDetails}
				/>
			</ContentBox>
		</>
	);
});

export default LoanRequestView;
