import axios from "axios";
import { makeAutoObservable } from "mobx";
import API_URL from "../../config/ApiUrl";
import { RoleListDataProps, PermissionListDataProps } from "./RoleInterface";

export default class ProfessionStore {
	public listData?: RoleListDataProps[];
	public role?: RoleListDataProps;
	public addRolePopup?: boolean;
	public editRolePopup?: boolean;
	public editRoleID?: number;
	public deleteRolePopup?: boolean;
	public deleteRoleID?: number;
	public permissionList?:PermissionListDataProps[];
	public roleType?:any[];
	public showPermissionModel?:boolean

	constructor() {
		makeAutoObservable(this);
	}

	public openPermissionModel = () => {
		this.showPermissionModel = true;
	}

	public closePermissionModel = () => {
		this.showPermissionModel = false;
	}
	// Setter Functions
	public openAddRolePopup = (): void => {
		this.addRolePopup = true;
	};
	public closeAddRolePopup = (): void => {
		this.addRolePopup = false;
	};
	public openEditRolePopup = (id: number): void => {
		this.editRolePopup = true;
		this.setEditRoleID(id);
	};
	public closeEditRolePopup = (): void => {
		this.editRolePopup = false;
		this.setEditRoleID();
	};
	public openDeleteRolePopup = (id: number): void => {
		this.deleteRolePopup = true;
		this.setDeleteRoleID(id);
	};
	public closeDeleteRolePopup = (): void => {
		this.deleteRolePopup = false;
		this.setDeleteRoleID();
	};

	private setEditRoleID = (id?: number): void => {
		this.editRoleID = id;
	};

	private setDeleteRoleID = (id?: number): void => {
		this.deleteRoleID = id;
	};

	private setListData = (value?: RoleListDataProps[]): void => {
		this.listData = value;
	};

	private setPermissionList = (data:any): void => {
		this.permissionList = data;
	}

	public setRoleType = () => {
		this.roleType = [{id:1, value:"Admin"}, {id:2, value:"User"}];
	}
	public setRole = (value?: RoleListDataProps): void => {
		this.role = value;
	};

	// API Functions
	public fetchList = (): Promise<any> => {
		return axios.post(API_URL.ROLE.LIST).then(({ data }) => {
			this.setListData(data.data.rows);
			return data;
		});
	};

	public create = (payload: any): Promise<any> => {
		return axios
			.post(API_URL.ROLE.CREATE, payload)
			.then(({ data }) => {
				this.fetchList();
				return data;
			})
			.catch(({ data }) => {
				return Promise.reject(data);
			});
	};

	public update = (payload: any): Promise<any> => {
		return axios
			.post(API_URL.ROLE.UPDATE(payload.id), payload)
			.then(({ data }) => {
				this.fetchList();
				return data;
			});
	};

	public details = (id: number): Promise<any> => {
		return axios.get(API_URL.ROLE.DETAILS(id)).then(({ data }) => {
			this.setRole(data.data);
			return data;
		});
	};

	public remove = (id: number): Promise<any> => {
		return axios.delete(API_URL.ROLE.DELETE(id)).then(({ data }) => {
			this.fetchList();
			return data;
		});
	};

	public getPermissionList = (): Promise<any> => {
		return axios.get(API_URL.ROLE.PERMISSION_LIST).then(({ data }) => {
			this.setPermissionList(data.data);
			return data;
		});
	};

	public changeStatus = (payload:any): Promise<any> => {
		return axios.post(API_URL.ROLE.CHANGE_STATUS(payload.id),payload)
		.then(({ data }) => {
			this.fetchList();
			return data;
		});
	};
}
