import { Button, Checkbox, Modal } from "antd";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

interface PermissionProps {
	permissionList: any[];
	selectedPermission: any[];
	onCheckChange: (data: any) => void;
	visible?: boolean;
	title: string;
	closePermissionModel: () => void;
	disabled?: boolean;
	roleType?: number;
}

const PermissionComponent: React.FC<PermissionProps> = ({
	permissionList,
	selectedPermission,
	onCheckChange,
	visible,
	title,
	closePermissionModel,
	disabled,
	roleType,
}) => {
	const { t } = useTranslation();
	const [newPermissionList, setNewPermissionList] = useState<any>([]);

	const close = () => {
		closePermissionModel();
	};

	useEffect(() => {
		if (permissionList) {
			const permissionListData = permissionList.reduce((acc: any[], item: any) => {
				const permissionSubList = item.permissions.reduce((accSub: any[], itemSub: any) => {
					if (itemSub && roleType == 1 && itemSub.for_admin == 1) {
						accSub.push(itemSub);
					}
					if (itemSub && roleType == 2 && itemSub.for_user == 1) {
						accSub.push(itemSub);
					}
					return accSub;
				}, []) ;
				if (permissionSubList.length > 0) {
					acc.push({...item, permissions: permissionSubList});
				}
				return acc;
			}, []);

			setNewPermissionList(permissionListData);
		}
	}, [permissionList, roleType]);
	
	useEffect(()=>{
		//
	}, [newPermissionList])
	
	return (
		<Modal
			centered
			title={title}
			visible={visible}
			destroyOnClose={true}
			onCancel={close}
			width={1000}
			footer={[
				<Button key="okbutton1" type="primary" onClick={close}>
					{t("OK")}
				</Button>,
			]}
		>
			<div className="permission__table">
				<table key="table">
					<thead key="thead">
						<tr key="headtr">
							<th key="modules">{t("MODULES")}</th>
							<th colSpan={5} key="permissions">
								{t("PERMISSIONS")}
							</th>
						</tr>
					</thead>
					<tbody key={"module"}>
						{newPermissionList?.map((item: any, index: number) => {
							return (
								<tr key={"tr" + item.id}>
									<td key={"td" + item.id + item.name}>
										<b>{item.name}</b>
									</td>
									<td key={"td" + index}>
										{item.permissions.map((permission: any, index: number) => {
											return (
												<>
													<div className="ant-col-12">
														<Checkbox
															onChange={onCheckChange}
															name={"permission"}
															disabled={disabled}
															value={permission.id}
															key={"checkbvalue" + permission.id + index}
															defaultChecked={
																selectedPermission.indexOf(permission.id) >= 0
																	? true
																	: false
															}
														/>{" "}
														{permission.permission_name}{" "}
														{selectedPermission.indexOf(permission.id) >= 0}{" "}
														&nbsp;
													</div>
												</>
											);
										})}
									</td>
								</tr>
							);
						})}
					</tbody>
				</table>
			</div>
		</Modal>
	);
};

export default PermissionComponent;
