import axios from "axios";
import { makeAutoObservable } from "mobx";
import API_URL from "../../config/ApiUrl";
import { SliderListDataProps } from "./SliderInterface";

export default class SliderStore {
	
	public listData?: SliderListDataProps[];
	public Slider?: SliderListDataProps;
	public deleteSliderPopup?: boolean;
	public deleteSliderID?: number;
	public sliderType = [{id:1,value:"Home"},{id:2,value:"Donation"}];
	constructor() {
		makeAutoObservable(this);
	}

	// Setter Functions
	public openDeleteSliderPopup = (id: number): void => {
		this.deleteSliderPopup = true;
		this.setDeleteSliderID(id);
	};

	public closeDeleteSliderPopup = (): void => {
		this.deleteSliderPopup = false;
		this.setDeleteSliderID();
	};
	private setDeleteSliderID = (id?: number): void => {
		this.deleteSliderID = id;
	};

	private setListData = (value?: SliderListDataProps[]): void => {
		this.listData = value;
	};

	public setSlider = (value?: SliderListDataProps): void => {
		this.Slider = value;
	};

	// API Functions
	public fetchList = (): Promise<any> => {
		return axios.post(API_URL.SLIDER.LIST).then(({ data }) => {
			this.setListData(data.data.rows);
			return data.data;
		});
	};

	public create = (payload: any): Promise<any> => {
		return axios .post(API_URL.SLIDER.CREATE, payload).then(({ data }) => {
			this.fetchList();
			return data;
		})
	};
	
	public update = (id: number, payload:any): Promise<any> => {
		return axios.post(API_URL.SLIDER.UPDATE(id), payload).then(({ data }) => {
			return data.data;
		});
	};

	public details = (id: number): Promise<any> => {
		return axios.get(API_URL.SLIDER.DETAILS(id)).then(({ data }) => {
			return data.data;
		});
	};

	public remove = (id: number): Promise<any> => {
		return axios.delete(API_URL.SLIDER.DELETE(id)).then(({ data }) => {
			this.fetchList();
			return data.data;
		});
	};

	public changeStatus = (payload:any): Promise<any> => {
		return axios.post(API_URL.SLIDER.CHANGE_STATUS(payload.id),payload)
		.then(({ data }) => {
			this.fetchList();
			return data.data;
		});
	};

}
