import React, { useEffect } from "react";
import { AgGridColumn } from "ag-grid-react";
import { SliderListDataProps } from "../../../../store/SliderStore/SliderInterface";
import AgGridWrapper from "../../../../components/AgGridWrapper/AgGridWrapper";
import { Button, Switch } from "antd";
import { EditOutlined, DeleteOutlined } from "@ant-design/icons";
import moment from "moment";
import Config from "../../../../config/Config";
import DateComparator from "../../../../utils/DateComparator";
import { useTranslation } from "react-i18next";
import { displayFormats } from "../../../../config/Global";
interface ListProps {
	rowData: SliderListDataProps[];
	getData: () => Promise<any>;
	InactiveData:(data: any) => Promise<any>;
	ChangeStatus:(params: any) => Promise<any>;
	editSliderRedirect:(id:number) => void;
	openDeleteSliderPopup: (id: number) => void | any;
}
const ListComponent: React.FC<ListProps> = ({
	rowData,
	getData,
	ChangeStatus,
	editSliderRedirect,
	openDeleteSliderPopup
}) => {
	const { t } = useTranslation();
	useEffect(() => {
		getData();
	}, [getData]);
	const changeStatus = (params:any) => {
		ChangeStatus(params)
			.then(() => {
				getData();
				return;
			})
			.finally(() => {
				return;
			});
	}
	const ActionRenderer = (params: any) => {
		return (
			<div className="action-column">
				<Button
					type="text"
					title={t("EDIT")}
					icon={<EditOutlined />}
					onClick={() =>editSliderRedirect(params.data?.id)}
				></Button>
				<Button
					type="text"
					title={t("DELETE")}
					icon={<DeleteOutlined />}
					onClick={() => openDeleteSliderPopup(params.data?.id)}
				></Button>
			</div>
		);

		};
		const SwitchRenderer = (params: any) => {
			return (
				<div>
					<Switch
						defaultChecked={params.data?.status}
						onChange= {() => changeStatus({id:params.data?.id,status:params.data?.status ==1 ? 0 : 1})}
					/>
				</div>
			);
		};
    return (
        <>
					<AgGridWrapper rowData={rowData} frameworkComponents={{ ActionRenderer, SwitchRenderer}} overlayLoadingTemplate={t("PLEASE_WAIT_WHILE_DATA_IS_LOADING")} overlayNoRowsTemplate={t("NO_RECORD_FOUND")}>
						<AgGridColumn field="id" headerName={"# " + t('ID')} filter="agNumberColumnFilter" minWidth={50}
							pinned={"left"} />
						<AgGridColumn field="title" headerName={t('TITLE')}  minWidth={250} />
						<AgGridColumn field="sequence" headerName={t("SEQUENCE")}  filter="agNumberColumnFilter" minWidth={100} />
						<AgGridColumn field="description" headerName={t('DESCRIPTION')}  minWidth={300} />
						<AgGridColumn field="type_of_slider" headerName={t("SLIDER_TYPE")}  minWidth={300} />
						<AgGridColumn
							field="created_at"
							headerName={t('CREATED_AT')}
							valueGetter={({ data }) =>
								data.created_at &&
								moment(data.created_at).format(Config.dateTimeFormat)
							}
							filter={"agDateColumnFilter"}
							valueFormatter={(data:any) =>
								data?.data?.created_at && moment.utc(data?.data?.created_at).local().format(displayFormats.DATE_TIME_FORMAT)
							}
							filterParams={{
								comparator: DateComparator,
							}}
							width={220}
						/>
						<AgGridColumn
							field="status"
							headerName={t('STATUS')}
							cellRenderer="SwitchRenderer"
							pinned={"right"}
							width={120}
							sortable={false}
							filter={false}
						/>
						<AgGridColumn
							headerName={t('ACTION')}
							type="actionColumn"
							sortable={false}
							filter={false}
							pinned={"right"}
							width={120}
						/>
					</AgGridWrapper>
        </>
	);
};

export default ListComponent;
