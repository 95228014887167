import CryptoJS from "crypto-js";

export const transactionFor = (t: any) => [
	{
		id:1,
		transaction_for:t("GROUP")
	},
	{
		id:2,
		transaction_for:t("USER")
	},
	{
		id:3,
		transaction_for:t("ADMIN")
	},
]

export const paymentType = (t: any) => [
	{
		id:1,
		payment_type:t("CREDIT")
	},
	{
		id:2,
		payment_type:t("DEBIT")
	}
]

export const paymentStatus = (t: any) => [
	{
		id:0,
		payment_status:t("PENDING")
	},
	{
		id:1,
		payment_status:t("SUCCESS")
	},
	{
		id:2,
		payment_status:t("FAILED")
	},
	{
		id:3,
		payment_status:t("REFUND")
	},
]

export const displayFormats = {
	DATE_FORMAT: 'MM-DD-YYYY',
	TIME_FORMAT: 'HH:mm',
	DATE_TIME_FORMAT: 'MM-DD-YYYY HH:mm',
};

export const genderList = (t: any) => [
	{ id: 1, value: t("MALE") },
	{ id: 2, value: t("FEMALE") },
	{ id: 3, value: t("OTHERS") },
];

export const userType = (t: any) => [
	{ id: 1, value: t("ADMIN") },
	{ id: 2, value: t("USER") },
];

export const uploadedFileOnPreview = async (file: any) => {
	let src = file.url;
	if (!src) {
		src = await new Promise((resolve) => {
			const reader = new FileReader();
			reader.readAsDataURL(file.originFileObj);
			reader.onload = () => resolve(reader.result);
		});
	}
	if (src.includes(".sheet") || src.includes("..ms-excel")) {
		const url = file.url ? file.url : URL.createObjectURL(file?.originFileObj)
		window.open(url);
	}else{
		const image = new Image();
		image.src = src;
		const imgWindow: any = window.open(src);
		imgWindow.document.write(image.outerHTML);
	}
};


export const aesEncryptionPassword =  async (password: any) => {
	const passwordInput = password;
	const iv = CryptoJS.enc.Utf8.parse(process.env.REACT_APP_CRYPTO_JS_IV ?? "");
	const key = CryptoJS.enc.Utf8.parse(process.env.REACT_APP_CRYPTO_JS_KEY ?? "");
	
	// Encrypt the password
	const options = {
		iv: iv,
		mode: CryptoJS.mode.CBC, // Specify the mode (CBC)
		padding: CryptoJS.pad.Pkcs7 // Specify padding
	};
	const encryptedCP = CryptoJS.AES.encrypt(passwordInput, key, options);
	const encryptedBase64 = encryptedCP.toString();
	const timestamp = Date.now().toString().substring(0, 10);
	const newPassword = encryptedBase64 + '#' + timestamp;
	return newPassword;
};