import React, { useEffect } from "react";
import { AgGridColumn } from "ag-grid-react";
import { BannerListDataProps } from "../../../../store/BannerStore/BannerInterface";
import AgGridWrapper from "../../../../components/AgGridWrapper/AgGridWrapper";
import { Button, Switch } from "antd";
import { EditOutlined,} from "@ant-design/icons";
import moment from "moment";
import Config from "../../../../config/Config";
import DateComparator from "../../../../utils/DateComparator";
import { useTranslation } from "react-i18next";
import { displayFormats } from "../../../../config/Global";
interface ListProps {
	rowData: BannerListDataProps[];
	getData: () => Promise<any>;
	InactiveData: (data: any) => Promise<any>;
	ChangeStatus: (params: any) => Promise<any>;
	editBannerRedirect: (id: number) => void;
	openDeleteBannerPopup: (id: number) => void | any;
}
const ListComponent: React.FC<ListProps> = ({
	rowData,
	getData,
	ChangeStatus,
	editBannerRedirect,
}) => {
	const { t } = useTranslation();
	useEffect(() => {
		getData();
	}, [getData]);
	const changeStatus = (params: any) => {
		ChangeStatus(params)
			.then(() => {
				return;
			})
			.finally(() => {
				getData();
				return;
			});
	}
	const ActionRenderer = (params: any) => {
		return (
			<div className="action-column">
				<Button
					type="text"
					title={t("EDIT")}
					icon={<EditOutlined />}
					onClick={() => editBannerRedirect(params.data?.id)}
				></Button>
			</div>
		);

	};
	const SwitchRenderer = (params: any) => {
		return (
			<div>
				<Switch
					defaultChecked={params.data?.status}
					onChange={() => changeStatus({ id: params.data?.id, status: params.data?.status == 1 ? 0 : 1 })}
				/>
			</div>
		);
	};
	return (
		<>
			<AgGridWrapper rowData={rowData} frameworkComponents={{ ActionRenderer, SwitchRenderer }} overlayLoadingTemplate={t("PLEASE_WAIT_WHILE_DATA_IS_LOADING")} overlayNoRowsTemplate={t("NO_RECORD_FOUND")}>
				<AgGridColumn field="id" headerName={"# " + t('ID')} filter="agNumberColumnFilter" pinned={"left"} width={100} />
				<AgGridColumn field="title" headerName={t('TITLE')} minWidth={200} />
				<AgGridColumn field="description" headerName={t('DESCRIPTION')} minWidth={290} />
				<AgGridColumn
					field="created_at"
					headerName={t('CREATED_AT')}
					valueGetter={({ data }) =>
						data.created_at &&
						moment(data.created_at).format(Config.dateTimeFormat)
					}
					filter={"agDateColumnFilter"}
					valueFormatter={(data:any) =>
						data?.data?.created_at && moment.utc(data?.data?.created_at).local().format(displayFormats.DATE_TIME_FORMAT)
					}
					filterParams={{
						comparator: DateComparator,
					}}
					width={220}
				/>
				<AgGridColumn
					field="status"
					headerName={t("STATUS")}
					cellRenderer="SwitchRenderer"
					width={120}
					sortable={false}
					filter={false}
				/>
				<AgGridColumn
					headerName={t("ACTION")}
					type="actionColumn"
					sortable={false}
					filter={false}
					width={120}
				/>
			</AgGridWrapper>
		</>
	);
};

export default ListComponent;
