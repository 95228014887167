import React, { useState, useEffect } from "react";
import { Button, Col, Form, Layout, Row } from "antd";
import useStore from "../../../../store";
import { useHistory, useParams } from "react-router";
import { observer } from "mobx-react";
import { FormBox, InputBox } from "../../../../components/AntdAddons";
import '../../../../../node_modules/react-quill/dist/quill.snow.css';
import BreadcrumbComponent from "../../../../components/BreadcrumbComponent/BreadcrumbComponent";
import { TemplatesBreadcrumb } from "../../../../config/BreadcrumbConfig";
import { TemplateRequest } from "../../../../requests/TemplateRequtest";
import { useTranslation } from "react-i18next";

const EditNotificationTemplatesComponent: React.FC = observer(() => {
	const { ROOT, TEMPLATE, COMMON } = useStore();
	const {
		update,
		details,
	} = TEMPLATE;

	const [templates, setTemplates] = useState<any>(null)
	const [templatesContent, setTemplatesContent] = useState<any>(null)
	const { id }: any = useParams();
	const { t } = useTranslation();

	const {
		languageList,
		fetchLanguage,
	} = COMMON;
	const { AssignErrorToInput } = ROOT;
	const [form] = Form.useForm();
	const [saving, setSaving] = useState<boolean>(false);

	const history = useHistory();
	useEffect(()=>{
		if(!languageList) {
			fetchLanguage()
		}
		if(!templates) {
			details(id).then((data:any) => {
				setTemplatesContent(data.data?.templateLanguage)
				setTemplates(data?.data);
				form.setFieldsValue({
					title:data.data.title,
					description:data.data.description
				});
			})
		}
	},[fetchLanguage, languageList, details])

	const listRoute = () =>{
		history.push('/templates-management');
	};

	// Handle submit and call function to save new record
	const handleSubmit = () => {
		const formData = new FormData();
		formData.append('type','3');
		formData.append("description", form.getFieldValue("description"));
		templatesContent.map((data:any, index:number) => {
			formData.append("language["+index+"][notification_title]", form.getFieldValue("language["+index+"][notification_title]"));
			formData.append("language["+index+"][notification_description]", form.getFieldValue("language["+index+"][notification_description]"));
			formData.append("language["+index+"][language_id]", form.getFieldValue("language["+index+"][language_id]"));
		});

		update(id, formData)
			.then(() => {
				setSaving(false);
				form.resetFields();
				listRoute();
			})
			.catch((e:any) => {
				setSaving(false);
				AssignErrorToInput(form, e?.data?.errors);
			})
	};
	return (
		<>
			<div className="Breadcrumb-bar">
					<BreadcrumbComponent items={TemplatesBreadcrumb(t).path} />
			</div>
			<FormBox
				id={"EDITSMSTEMPLATE"}
				form={form}
				onFinish={handleSubmit} key="formbasic"
			>

				<Layout style={{ padding: '0 24px' }}>
					<InputBox.Text
						name="title"
						placeholder={t('TITLE')}
						maxLength={5000}
						disabled={true}
						label={t('TITLE')}
						required
					/>
					<InputBox.TextArea
						name="description"
						placeholder={t("TEMPLATE_DESCRIPTION")}
						maxLength={5000}
						label={t("TEMPLATE_DESCRIPTION")}
						rules={TemplateRequest(t).description}
					/>
					<p style={{color:'red'}}>{"* " + t('TEMPLATE_WARNING_MESSAGE')}</p>
					{templatesContent && templatesContent.map((data:any, index:number) => {
						form.setFieldsValue({["language["+index+"][notification_title]"]:data.notification_title});
						form.setFieldsValue({["language["+index+"][notification_description]"]:data.notification_description});
						form.setFieldsValue({["language["+index+"][language_id]"]:data.language_id});
							return (
								<>
									<div key={"language"+index}>
										<p>{data.language.name} {t("TITLE")}</p>
										<InputBox.Text
											name={"language["+index+"][notification_title]"}
											placeholder={t('TITLE')}
											maxLength={5000}
											required
										/>
										<p>{data.language.name} {t("DESCRIPTION")}</p>
										<InputBox.TextArea
											name={"language["+index+"][notification_description]"}
											placeholder={t("DESCRIPTION")}
											maxLength={5000}
											required
										/>
									</div>
								</>
							);
						})}
					<Row justify="center">
						<Col span={24} className="permission__button ant-row-center ant-row mt-50">
							<Button type="primary"
								htmlType="submit"
								loading={saving}
								key="submit1">{t('SAVE')}</Button>
							<Button type="primary" key="resetCancel" danger onClick={listRoute}>{t('CANCEL')}</Button>
						</Col>
					</Row>
				</Layout>
			</FormBox>
		</>
	);
});
export default EditNotificationTemplatesComponent;
