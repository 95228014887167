import React, { useEffect, useState }  from "react";
import { observer } from "mobx-react";
import {  Form } from 'antd';
import useStore from "../../../../store";
import { useHistory, useParams } from "react-router";
import { Button, Row, Col, Layout, Upload  } from "antd";
import { FormBox, InputBox } from "../../../../components/AntdAddons";
import { SliderRequest } from "../../../../requests/SliderRequest";
import BreadcrumbComponent from "../../../../components/BreadcrumbComponent/BreadcrumbComponent";
import { SliderBreadcrumb } from "../../../../config/BreadcrumbConfig";
import { useTranslation } from "react-i18next";
import { uploadedFileOnPreview } from "../../../../config/Global";
const EditsliderComponent: React.FC = observer(() => {
	const { SLIDER, ROOT } = useStore();
	const {
		update,
		details,
		sliderType
	} = SLIDER;

	const [slider, setslider] = useState<any>(null)
	const { id }: any = useParams();
	const { AssignErrorToInput } = ROOT;

	const[image, setBanneerImage] = useState(null)
	const onFileChange = (info:any) =>{
		setBanneerImage(info);
		return false;
	}
	useEffect(()=>{
		details(id).then((data:any) => {
			setslider(data);
			form.resetFields();
			form.setFieldsValue({
				title:data?.title,
				description:data?.description,
				sequence:data?.sequence,
				slider_type:data?.slider_type,
				link:data?.link,
				link_title:data?.link_title,
			});
	});
	},[details])
	const [form] = Form.useForm();
	const [saving, setSaving] = useState<boolean>(false);
	const history = useHistory();
	const { t } = useTranslation();
	const listRoute = () =>{
		history.push('/slider');
  }

	// Handle submit and call function to save new record
	const handleSubmit = (data: any) => {
		setSaving(true);
		const formData = new FormData();
		image && formData.append('image',image);
		data.title && formData.append("title",data.title);
		data.description && formData.append("description",data.description);
		data.sequence && formData.append("sequence",data.sequence);
		data.slider_type && formData.append("slider_type",data.slider_type);
		data.link && formData.append("link",data.link);
		data.link_title && formData.append("link_title",data.link_title);
		update(slider.id, formData)
			.then(() => {
				setSaving(false);
				form.resetFields();
				listRoute();
			})
			.catch((e:any) => {
				setSaving(false);
				AssignErrorToInput(form, e?.data?.errors);
			})
	};

	return (
		slider && <>
		<div className="Breadcrumb-bar">
			<BreadcrumbComponent items={SliderBreadcrumb(t).path} />
		</div>
		<FormBox
			id="addSliderForm"
			form={form}
			encType="multipart/formdata"
			onFinish={handleSubmit} key="formbasic">
			<Layout style={{ padding: '0 24px' }}>
				<Row gutter={6} wrap={false}>
					<Col span={8} className="form__input__textbox">
						<InputBox.Text
							size="large"
							placeholder={t('TITLE')}
							label={t('TITLE')}
							name="title"
							rules={SliderRequest(t).title}
						/>
					</Col>
					<Col span={8} className="form__input__textbox">
							<InputBox.TextArea
								label={t("DESCRIPTION")}
								name="description"
								placeholder={t("DESCRIPTION")}
								rules={SliderRequest(t).description}
								maxLength={200}
							/>
					</Col>
				</Row>
				<Row gutter={6} wrap={false}>
					<Col span={16} className="form__input__textbox">
						<Upload
							name="image"
							accept="image/png, image/jpeg"
							listType="picture"
							defaultFileList={[
								{
									uid: '1',
									name: slider.title,
									status: 'done',
									response: 'Server Error 500', // custom error message to show
									url: slider.image,
									thumbUrl: slider.image,
								}]}
							maxCount={1}
							beforeUpload={onFileChange}
							onPreview={uploadedFileOnPreview}
						>
							<Button className="mb-20">{t("CLICK_TO_UPLOAD_IMAGE")}</Button>
						</Upload>
						<em>{t("WE_SUGGEST_TO_SET_THE_IMAGE_WIDTH_PX_WIDTH_X_HEIGHT_PX_HEIGHT",{widthPX:"1900px", heightPX: "785px"})}</em>
					</Col>
				</Row>
				<Row gutter={6} wrap={false}>
					<Col span={8} className="form__input__textbox">
						<InputBox.Number
							size="large"
							label={t("SEQUENCE")}
							name="sequence"
							placeholder={t("SEQUENCE")}
							rules={SliderRequest(t).sequence}
							maxLength={100}
						/>
					</Col>
					<Col span={8} className="form__input__textbox form__input__selectBox">
							<label>{t("SLIDER_TYPE")}</label>
							<InputBox.Radio
								options={{
									list:sliderType,
									valueKey:"id",
									textKey:"value"
								}}
								name="slider_type"
								rules={SliderRequest(t).slider_type}
							/>
					</Col>
				</Row>
				<Row gutter={6} wrap={false}>
					<Col span={8} className="form__input__textbox">
						<InputBox.Text
							label={t("LINK")}
							name="link"
							placeholder={t("LINK")}
							rules={SliderRequest(t).link}
							maxLength={100}
						/>
					</Col>
					<Col span={8} className="form__input__textbox">
						<InputBox.Text
							label={t("LINK_TITLE")}
							name="link_title"
							placeholder={t("LINK_TITLE")}
							rules={SliderRequest(t).link_title}
							maxLength={100}
						/>
					</Col>
				</Row>
				<Col span={24} className="permission__button ant-row-center ant-row mt-20">
					<Button type="primary"
						htmlType="submit"
						loading={saving}
						key="submit1">{t('SAVE')}</Button>
					<Button type="primary" key="resetCancel" danger onClick={listRoute}>{t('CANCEL')}</Button>
				</Col>
			</Layout>
		</FormBox>
		</>
	);
});
export default EditsliderComponent;
