import {
	GridOptions,
	GridReadyEvent,
	IServerSideGetRowsParams,
} from "ag-grid-community";
import axios from "axios";
import { makeAutoObservable } from "mobx";
import API_URL from "../../config/ApiUrl";
import Config from "../../config/Config";
import RootStore from "../RootStore/RootStore";
import { DonationCampaignListDataProps } from "./DonationCampaignIntrface";

export default class DonationCampaignStore {
	public listData?: DonationCampaignListDataProps[];
	public grid?: any;
	public perPage?: any = 100;
	private rootStore: RootStore;
	public DonationCampaign?: DonationCampaignListDataProps;
	public addDonationCampaignPopup?: boolean;
	public editDonationCampaignPopup?: boolean;
	public editDonationCampaignID?: number;
	public deleteDonationCampaignPopup?: boolean;
	public deleteDonationCampaignID?: number;
	public donationList = [
		{ id: 1, value: "VSLA" },
		{ id: 2, value: "GROUP" },
	];

	constructor() {
		this.rootStore = new RootStore();
		makeAutoObservable(this);
	}

	// Setter Functions
	public openDeleteDonationCampaignPopup = (id: number): void => {
		this.deleteDonationCampaignPopup = true;
		this.setDeleteDonationCampaignID(id);
	};

	public closeDeleteDonationCampaignPopup = (): void => {
		this.deleteDonationCampaignPopup = false;
		this.setDeleteDonationCampaignID();
	};

	private setDeleteDonationCampaignID = (id?: number): void => {
		this.deleteDonationCampaignID = id;
	};

	private setListData = (value?: DonationCampaignListDataProps[]): void => {
		this.listData = value;
	};

	public setDonationCampaignType = (
		value?: DonationCampaignListDataProps
	): void => {
		this.DonationCampaign = value;
	};

	private setGrid = (value: any) => {
		this.grid = value;
	};

	public setupGrid = (params: GridReadyEvent) => {
		this.setGrid(params);
		this.setPageSize();
		const datasource = this.createDatasource(Config.grid.server.gridOptions);
		params.api.setServerSideDatasource(datasource);
	};

	public setPageSize = (page = this.perPage) => {
		this.perPage = page;
		if (this.grid) {
			this.grid.api.paginationSetPageSize(parseInt(page));
		}
	};

	private createDatasource = (gridOptions?: GridOptions) => {
		return {
			gridOptions,
			getRows: (params: IServerSideGetRowsParams) => {
				const payload = this.rootStore.getServerListPayload(params);
				this.fetchList(payload).then((data) => {
					params.successCallback(data?.rows, data?.count);
					if (data.count <= 0) {
						params.api.showNoRowsOverlay();
					} else {
						params.api.hideOverlay();
					}
				});
			},
		};
	};

	// API Functions
	public fetchList = (payLoad: any): Promise<any> => {
		return axios
			.post(API_URL.DONATION_CAMPAIGN.LIST, payLoad)
			.then(({ data }) => {
				this.setListData(data.data.rows);
				return data.data;
			});
	};

	public create = (payload: any): Promise<any> => {
		return axios
			.post(API_URL.DONATION_CAMPAIGN.CREATE, payload)
			.then(({ data }) => {
				this.fetchList({});
				return data;
			})
			.catch(({ data }) => {
				return Promise.reject(data);
			});
	};

	public update = (id: number, payload: any): Promise<any> => {
		return axios
			.post(API_URL.DONATION_CAMPAIGN.UPDATE(id), payload)
			.then(({ data }) => {
				return data;
			});
	};

	public details = (id: number): Promise<any> => {
		return axios.get(API_URL.DONATION_CAMPAIGN.DETAILS(id)).then(({ data }) => {
			return data.data;
		});
	};

	public remove = (id: number): Promise<any> => {
		return axios
			.delete(API_URL.DONATION_CAMPAIGN.DELETE(id))
			.then(({ data }) => {
				this.fetchList({});
				return data;
			});
	};

	public changeStatus = (payload: any): Promise<any> => {
		return axios
			.post(API_URL.DONATION_CAMPAIGN.CHANGE_STATUS(payload.id), payload)
			.then(({ data }) => {
				return data;
			});
	};
}
