import { GridOptions, GridReadyEvent, IServerSideGetRowsParams } from "ag-grid-community";
import axios from "axios";
import { makeAutoObservable } from "mobx";
import API_URL from "../../config/ApiUrl";
import Config from "../../config/Config";
import RootStore from "../RootStore/RootStore";
import { DonationSubjectListDataProps } from "./DonationSubjectIntrface";

export default class DonationSubjectStore {
	public listData?: DonationSubjectListDataProps[];
	public grid?: any;
	public perPage?: any = 100;
	private rootStore: RootStore;
	public DonationSubject?: DonationSubjectListDataProps;
	public addDonationSubjectPopup?: boolean;
	public editDonationSubjectPopup?: boolean;
	public editDonationSubjectID?: number;
	public deleteDonationSubjectPopup?: boolean;
	public deleteDonationSubjectID?: number;

	constructor() {
		this.rootStore = new RootStore();
		makeAutoObservable(this);
	}

	// Setter Functions
	public openAddDonationSubjectPopup = (): void => {
		this.addDonationSubjectPopup = true;
	};
	public closeAddDonationSubjectPopup = (): void => {
		this.addDonationSubjectPopup = false;
	};
	public openEditDonationSubjectPopup = (id: number): void => {
		this.editDonationSubjectPopup = true;
		this.setEditDonationSubjectID(id);
	};
	public closeEditDonationSubjectPopup = (): void => {
		this.editDonationSubjectPopup = false;
		this.setEditDonationSubjectID();
	};
	public openDeleteDonationSubjectPopup = (id: number): void => {
		this.deleteDonationSubjectPopup = true;
		this.setDeleteDonationSubjectID(id);
	};

	public closeDeleteDonationSubjectPopup = (): void => {
		this.deleteDonationSubjectPopup = false;
		this.setDeleteDonationSubjectID();
	};

	private setEditDonationSubjectID = (id?: number): void => {
		this.editDonationSubjectID = id;
	};
	private setDeleteDonationSubjectID = (id?: number): void => {
		this.deleteDonationSubjectID = id;
	};

	private setListData = (value?: DonationSubjectListDataProps[]): void => {
		this.listData = value;
	};

	public setDonationSubjectType = (
		value?: DonationSubjectListDataProps
	): void => {
		this.DonationSubject = value;
	};

	private setGrid = (value: any) => {
		this.grid = value;
	};

	public setupGrid = (params: GridReadyEvent) => {
		this.setGrid(params);
		this.setPageSize();
		const datasource = this.createDatasource(Config.grid.server.gridOptions);
		params.api.setServerSideDatasource(datasource);
	};

	public setPageSize = (page = this.perPage) => {
		this.perPage = page;
		if (this.grid) {
			this.grid.api.paginationSetPageSize(parseInt(page));
		}
	};

	private createDatasource = (gridOptions?: GridOptions) => {
		return {
			gridOptions,
			getRows: (params: IServerSideGetRowsParams) => {
				const payload = this.rootStore.getServerListPayload(params);
				this.fetchList(payload).then((data) => {
					params.successCallback(data?.rows, data?.count);
					if (data.count <= 0) {
						params.api.showNoRowsOverlay();
					} else {
						params.api.hideOverlay();
					}
				});
			},
		};
	};

	// API Functions
	public fetchList = (payLoad: any): Promise<any> => {
		return axios.post(API_URL.DONATIONSUBJECT.LIST, payLoad).then(({ data }) => {
			this.setListData(data.data.rows);
			return data.data;
		});
	};

	public create = (payload: any): Promise<any> => {
		return axios
			.post(API_URL.DONATIONSUBJECT.CREATE, payload)
			.then(({ data }) => {
				this.fetchList({});
				return data;
			})
			.catch(({ data }) => {
				return Promise.reject(data);
			});
	};

	public update = (payload: any): Promise<any> => {
		return axios
			.post(API_URL.DONATIONSUBJECT.UPDATE(payload.id), payload)
			.then(({ data }) => {
				this.fetchList({});
				return data;
			});
	};

	public details = (id: number): Promise<any> => {
		return axios.get(API_URL.DONATIONSUBJECT.DETAILS(id)).then(({ data }) => {
			return data;
		});
	};

	public remove = (id: number): Promise<any> => {
		return axios.delete(API_URL.DONATIONSUBJECT.DELETE(id)).then(({ data }) => {
			this.fetchList({});
			return data;
		});
	};

	public changeStatus = (payload: any): Promise<any> => {
		return axios
			.post(API_URL.DONATIONSUBJECT.CHANGE_STATUS(payload.id), payload)
			.then(({ data }) => {
				this.fetchList({});
				return data;
			});
	};
}
