import React, { useEffect, useState } from "react";
import BreadcrumbComponent from "../../../components/BreadcrumbComponent/BreadcrumbComponent";
import { SettingsBreadcrumb } from "../../../config/BreadcrumbConfig";
import ContentBox from "../../../components/ContentBox/ContentBox";
import { observer } from "mobx-react-lite";
import { Button, Layout, Row, Col, Radio } from "antd";
import { RightOutlined } from '@ant-design/icons';
import websettingsImage from "../../../assets/images/web_settings.svg";
import weblanguagesImage from "../../../assets/images/languages-icon.svg";
import { useHistory } from "react-router";
import useStore from "../../../store";
import serverSettingsImage from "../../../assets/images/server_settings-icon.svg";
import { useTranslation } from "react-i18next";

const SettingsView: React.FC = observer(() => {
	const { t } = useTranslation();
	const pathHistory = useHistory();
	const {
		SETTINGS:{setDefaultLanguage},
		COMMON: {fetchLanguage, languageList}
	} =  useStore();

	const openSiteSettings = () => {
		pathHistory.push("/settings/site-settings");
	};

	const openSetHomePageMessageView = (key: any) => {
		pathHistory.push("/settings/" + key);
	};

	const openRedirectLinkView = () => {
		pathHistory.push("/settings/redirect-link");
	};

	const openHomePageContentLinkView = () => {
		pathHistory.push("/settings/home-page-content");
	};

	const openSmtpSettings = () => {
		pathHistory.push("/settings/smtp-settings");
	}

	const openSmsView = () => {
		pathHistory.push("/settings/sms-settings");
	}

	const openAlIzzaPaymentGatewayView = () => {
		pathHistory.push("/settings/al-izza-payment-gateway-settings");
	}

	const openMTNInterpayAfricaGatewayView = () => {
		pathHistory.push("/settings/mtn-interpay-africa-payment-gateway-settings");
	}

	const [defaultLanguageId, setDefaultLanguageId] = useState<any>();
	useEffect(() => {
		!languageList && fetchLanguage();
		if(languageList) {
			const defaultLanguage = languageList.filter((element:any) => {
				return element.status == 1;
			});
			(defaultLanguage) ? setDefaultLanguageId(defaultLanguage[0]?.id) : setDefaultLanguageId(0);
		}
	}, [fetchLanguage, languageList, defaultLanguageId, setDefaultLanguageId])
	return (
			<>
			<div className="Breadcrumb-bar">
				<BreadcrumbComponent items={SettingsBreadcrumb(t).path} />
			</div>
			<ContentBox>
				<Layout className="settings__layout">
					<Row gutter={6} wrap={true} >
							<Col span={24} className="form__input__textbox form__input__selectBox">
								<label className="settings__layout__label"><img src={weblanguagesImage} /> {t("LANGUAGES")} </label>
							</Col>
					</Row>
					<Row gutter={6} wrap={true} >
						{defaultLanguageId && <Radio.Group defaultValue={defaultLanguageId} buttonStyle="solid" size="large" onChange={(e) => {setDefaultLanguage(e.target.value).then(()=>{fetchLanguage()})}}>
								{languageList && languageList.map((element:any, index:any) => {
									return <Radio.Button  key ={"radiolang"+index} value={element?.id}>{element?.name}</Radio.Button>
								})}
						</Radio.Group>}
					</Row>
					<Row gutter={6} wrap={true} className="mt-25">
							<Col span={24} className="form__input__textbox form__input__selectBox">
								<label className="settings__layout__label"><img src={serverSettingsImage} /> {t("SERVER_SETTINGS")}</label>
							</Col>
					</Row>
					<Row gutter={6} wrap={true} >
						<Col className="form__input__textbox form__input__selectBox">
							<Button
								className="settings__layout_bg_label"
								type="primary"
								onClick={openSmtpSettings}
							>
								{t("SMTP_SETTINGS")}
							<RightOutlined />
							</Button>
						</Col>
						<Col className="form__input__textbox form__input__selectBox">
							<Button
								className="settings__layout_bg_label"
								type="primary"
								onClick={openSmsView}
							>
								{t("SMS_API")}
							<RightOutlined />
							</Button>
						</Col>
						<Col className="form__input__textbox form__input__selectBox">
							<Button
								className="settings__layout_bg_label"
								type="primary"
								onClick={openAlIzzaPaymentGatewayView}
							>
								{t("AL_IZZA_PAYMENT_GATEWAY")}
							<RightOutlined />
							</Button>
						</Col>
						<Col className="form__input__textbox form__input__selectBox">
							<Button
								className="settings__layout_bg_label"
								type="primary"
								onClick={openMTNInterpayAfricaGatewayView}
							>
								{t("INTERPAYAFRICA")}
							<RightOutlined />
							</Button>
						</Col>
					</Row>
					<Row gutter={6} wrap={true} className="mt-25">
							<Col span={24} className="form__input__textbox form__input__selectBox">
								<label className="settings__layout__label"><img src={websettingsImage} />
								{t("WEB_SETTINGS")}</label>
							</Col>
					</Row>
					<Row gutter={6} wrap={true} >
						<Col className="form__input__textbox form__input__selectBox">
							<Button
								className="settings__layout_bg_label"
								type="primary"
								onClick={openSiteSettings}
							>
								{t("SITE_SETTINGS")}
							<RightOutlined />
							</Button>
						</Col>
						<Col className="form__input__textbox form__input__selectBox">
							<Button
								className="settings__layout_bg_label"
								type="primary"
								onClick={openRedirectLinkView}
							>
								{t("REDIRECT_LINK")}
							<RightOutlined />
							</Button>
						</Col>
						<Col className="form__input__textbox form__input__selectBox">
							<Button
								className="settings__layout_bg_label"
								type="primary"
								onClick={openHomePageContentLinkView}
							>
								{t("HOME_PAGE_CONTENT")}
							<RightOutlined />
							</Button>
						</Col>
					</Row>
					<Row gutter={6} wrap={true}  className="mt-25">
						{/* <Col className="form__input__textbox form__input__selectBox">
							<Button
								className="settings__layout_bg_label"
								type="primary"
								onClick={()=>openSetHomePageMessageView('welcome-message')}
							>
								{t("SET_WELCOME_MESSAGE")}
							<RightOutlined />
							</Button>
						</Col> */}
						<Col className="form__input__textbox form__input__selectBox">
							<Button
								className="settings__layout_bg_label"
								type="primary"
								onClick={()=>openSetHomePageMessageView('footer-message')}
							>
								{t("SET_FOOTER_MESSAGE")}
							<RightOutlined />
							</Button>
						</Col>
						<Col className="form__input__textbox form__input__selectBox">
							<Button
								className="settings__layout_bg_label"
								type="primary"
								onClick={()=>openSetHomePageMessageView('passbook-message')}
							>
								{t("SET_PASSBOOK_MESSAGE")}
							<RightOutlined />
							</Button>
						</Col>
						<Col className="form__input__textbox form__input__selectBox">
							<Button
								className="settings__layout_bg_label"
								type="primary"
								onClick={()=>openSetHomePageMessageView('election-message')}
							>
								{t("SET_ELECTION_MESSAGE")}
							<RightOutlined />
							</Button>
						</Col>
						<Col className="form__input__textbox form__input__selectBox">
							<Button
								className="settings__layout_bg_label"
								type="primary"
								onClick={()=>openSetHomePageMessageView('meetings-message')}
							>
								{t("SET_MEETINGS_MESSAGE")}
							<RightOutlined />
							</Button>
						</Col>
					</Row>
					<Row gutter={6} wrap={true}  className="mt-25">
					</Row>
				</Layout>
			</ContentBox>
		</>
	);
});

export default SettingsView;
