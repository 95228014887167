import { isPossiblePhoneNumber, isValidPhoneNumber } from "react-phone-number-input";
import { RequestProps } from "../config/InterfacesAndTypes";
import Message from "../config/Message";

export const UserRequest = (t: any): RequestProps => ({
	firstname: [{ required: true, message: Message(t).required(t("FIRST_NAME")) }],
	lastname: [{ required: true, message: Message(t).required(t("LAST_NAME")) }],
	email: [
		{ required: true, message: Message(t).required(t("EMAIL")) },
		{ type: "email", message: Message(t).type.email(t("EMAIL")) },
	],
	phone_number: [
		{ required: true, message: Message(t).required(t("PHONE_NUMBER")) },
		({ getFieldValue }) => ({
			validator() {
				const phone_number = getFieldValue("phone_number");

				if (
					phone_number &&
					(!isPossiblePhoneNumber(phone_number) || !isValidPhoneNumber(phone_number))
				) {
					return Promise.reject(t("PLEASE_ENTER_VALID_FIELD_NAME", { fieldName: t("PHONE_NUMBER") }));
				}
				return Promise.resolve();
			},
		}),
	],
	address: [{ required: true, message: Message(t).required(t("ADDRESS")) }],
	user_type: [{ required: true, message: Message(t).required(t("USER_TYPE")) }],
	gender: [{ required: true, message: Message(t).required(t("GENDER")) }],
	date_of_birth: [{ required: true, message: Message(t).required(t("DATE_OF_BIRTH")) }],
	language_id: [{ required: true, message: Message(t).required(t("LANGUAGE")) }],
	currency_id: [{ required: true, message: Message(t).required(t("CURRENCY")) }],
	role_id: [
		({ getFieldValue }) => ({
			validator() {
				const user_type = getFieldValue("user_type");
				const role_id = getFieldValue("role_id");
				if (user_type && user_type != "" && (role_id && role_id == "")) {
					return Promise.reject(Message(t).required(t("ROLE")));
				}
				return Promise.resolve();
			},
		})
	],
});
