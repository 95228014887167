import React, { Dispatch, SetStateAction, useEffect, useState } from "react";
import { Layout, Dropdown, Button, Menu, Space } from "antd";
import { AiOutlineMenuFold, AiOutlineMenuUnfold } from "react-icons/ai";
import { DownOutlined } from "@ant-design/icons";//, MenuFoldOutlined, MenuUnfoldOutlined, UserOutlined
import UserImage from "../../../assets/images/top_profile_user_img.svg";
import useStore from "../../../store";
import { Link } from "react-router-dom";
import { FaPlayCircle } from "react-icons/fa";
import { useTranslation } from "react-i18next";
import i18n from "../../../i18n";
import { observer } from "mobx-react";

interface HeaderViewProps {
	visible?: boolean;
	collapsed: boolean;
	SetCollapsed: Dispatch<SetStateAction<boolean>>;

}

const AppHeaderView: React.FC<HeaderViewProps> = observer(({
	visible,
	collapsed,
	SetCollapsed,
}) => {
	const { t } = useTranslation();
	const {AUTH, COMMON, USER} = useStore();
	const {user, logOut} = AUTH;
	const {updateMyLanguage} = USER;
	const {languageList, fetchLanguage, setSelectedLanguage, selectedLanguage} = COMMON
	const [getLanguage, setGetLanguage] = useState<boolean>(false);
	const userMenu = (
		<Menu className="header__user__menu">
			<Menu.Item key="editProfile"><Link to="/edit-profile">{t("EDIT_PROFILE")}</Link></Menu.Item>
			<Menu.Item key="logout" onClick={logOut}>{t("LOG_OUT")}</Menu.Item>
		</Menu>
	)

	const onLanguageChange = (event: any) => {
		const selectedLanguage = languageList?.filter((element: any) => element.id == event.target.id)
		setLanguage(selectedLanguage[0]);
		updateMyLanguage({language_id: event.target.id}).then(() => {
			history.go(0);
		});
	};

	const setLanguage = (selectedLanguage:any) => {
		setSelectedLanguage(selectedLanguage);
		i18n.changeLanguage(selectedLanguage?.local_key);
		setGetLanguage(selectedLanguage?.name);
	}

	useEffect(() => {
		const localStorageLanguage = window.localStorage.i18nextLng;
		!languageList && fetchLanguage()

		let userLanguage: any = (languageList && localStorageLanguage) ? languageList.find((element: any) => { return element.local_key == localStorageLanguage }) : languageList && languageList.find((element: any) => { return element.status == 1 });


		if(user && !localStorageLanguage) {
			userLanguage = languageList && languageList.find((element: any) => { return element.id == user.language_id })
		}

		(userLanguage) && selectedLanguage?.local_key != userLanguage?.local_key && setLanguage(userLanguage);

		userLanguage && setGetLanguage(userLanguage.name);

	}, [languageList]);

	// useEffect(() => {
	// }, [languageList]);
	return !visible ? null : (
		<Layout.Header className="main__page__appheader">
			<div className="container-fluid">
				<div className="appheader__left">
					{collapsed ? (
						<AiOutlineMenuUnfold
							className="sidebar__trigger"
							size={30}
							onClick={() => SetCollapsed(!collapsed)}
						/>
					) : (
						<AiOutlineMenuFold
							className="sidebar__trigger"
							size={30}
							onClick={() => SetCollapsed(!collapsed)}
						/>
					)}
				</div>
				<div className="appheader__right">
				<Space>
				</Space>
				<Dropdown overlay={
							<Menu>
								{languageList &&
									languageList.map((item: any, index: number) => {
										return (
											<Menu.Item key={"headerView" + index}>
												<a href="#" id={item.id} onClick={(e) => onLanguageChange(e)}>
													{item.name}
													<FaPlayCircle />
												</a>
											</Menu.Item>
										);
									})}
							</Menu>
						}
							trigger={["click"]}
						>
							<div className="country-dropdown">
								{languageList && (
									<a
										className="ant-dropdown-link"
										onClick={(e) => e.preventDefault()}
									>
										{getLanguage} <DownOutlined />
									</a>
								)}
							</div>
						</Dropdown>
					<Dropdown overlay={userMenu} >
						<Button>
							{/* <UserOutlined /> */}
							<img alt="images" src={UserImage}/>
							{user?.firstname + " " +user?.lastname}
							<DownOutlined />
						</Button>
					</Dropdown>
				</div>
			</div>
		</Layout.Header>
	);
});

export default AppHeaderView;
