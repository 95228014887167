import React, { useEffect, useState } from "react";
import { observer } from "mobx-react";
import { Form } from "antd";
import useStore from "../../../../../store";
import { useHistory } from "react-router";
import FormComponent from "./FormComponent";
import moment from "moment";

const AddMeetingComponent: React.FC = observer(() => {
	const { MEETING, COMMON, ROOT } = useStore();
	const { create } = MEETING;

	const { groupList, fetchGroup } = COMMON;

	const { AssignErrorToInput } = ROOT;
	useEffect(() => {
		fetchGroup();
	}, [fetchGroup]);

	const [form] = Form.useForm();
	const [saving, setSaving] = useState<boolean>(false);
	const history = useHistory();
	const meeting = {};

	const listRoute = () => {
		history.push("/meeting-management");
	};

	// Handle submit and call function to save new record
	const handleSubmit = (data: any) => {
		setSaving(true);
		const formData = new FormData();
		data.group_id && formData.append("group_id", data.group_id);
		data.type && formData.append("type", data.type);
		data.title && formData.append("title", data.title);
		data.location && formData.append("location", data.location);
		data.description && data.description?.trim() && formData.append("description", data.description);
		data.meeting_start_date_time && formData.append("meeting_start_date_time", moment(data.meeting_start_date_time).utc().format("YYYY-MM-DD HH:mm"));
		data.meeting_end_date_time && formData.append("meeting_end_date_time", moment(data.meeting_end_date_time).utc().format("YYYY-MM-DD HH:mm"));

		create(formData)
			.then(() => {
				setSaving(false);
				form.resetFields();
				listRoute();
			})
			.catch((e: any) => {
				setSaving(false);
				AssignErrorToInput(form, e?.data?.errors);
			});
	};

	return (
		<FormComponent
			formId="addLoanForm"
			form={form}
			handleSubmit={handleSubmit}
			saving={saving}
			listRoute={listRoute}
			isEdit={false}
			groupList={groupList}
			meetingDetails={meeting}
		/>
	);
});
export default AddMeetingComponent;
