import React, { useEffect, useState }  from "react";
import { observer } from "mobx-react";
import { Form } from 'antd';
import useStore from "../../../../../store";
import { useHistory } from "react-router";
import FormComponent from "./FormComponent";
import moment from "moment";

const AddGroupComponent: React.FC = observer(() => {
	const { GROUP, COMMON,ROOT } = useStore();
	const {
		create,
	} = GROUP;

	const {
		userList,
		currencyList,
		fetchUser,
		confederationList,
		fetchCurrency,
		fetchConfederationList,
	} = COMMON;
	const { AssignErrorToInput } = ROOT;
	const [form] = Form.useForm();
	const [saving, setSaving] = useState<boolean>(false);
	const[groupImage, setGroupImage] = useState(null);
	const [fileList,setFileList]=useState<any>([])

	const history = useHistory();

	

	const onFileChange = (info:any) => {
		setGroupImage(info);
		return false;
	}
	useEffect(() => {
		fetchUser(0,1, 1000);
		fetchCurrency();
		fetchConfederationList();
	}, [fetchUser, fetchCurrency, fetchConfederationList])


	const listRoute = () =>{
		history.push('/group-management');
	}

	// Handle submit and call function to save new record
	const handleSubmit = (data: any) => {
		setSaving(true);
		const formData = new FormData();
		groupImage && formData.append('group_image', groupImage);
		data.name && formData.append('name', data.name);
		data.group_description && formData.append('group_description', data.group_description);
		data.numbers_of_member && formData.append('numbers_of_member', data.numbers_of_member);
		data.share_price && formData.append('share_price', data.share_price);
		data.max_share_purchase_limit && formData.append('max_share_purchase_limit', data.max_share_purchase_limit);
		data.president_id && formData.append('president_id', data.president_id);
		data.currency_id && formData.append('currency_id', data.currency_id);
		data.group_welfare_rate && formData.append('group_welfare_rate', data.group_welfare_rate);
		data.borrowing_limit && formData.append('borrowing_limit', data.borrowing_limit);
		(data.interest_rate>=0) && formData.append('interest_rate', data.interest_rate);
		data.max_borrowing_time_line && formData.append('max_borrowing_time_line', data.max_borrowing_time_line);
		data.operating_cycle_date && formData.append('operating_cycle_date', moment(data.operating_cycle_date).utc().format('YYYY-MM-DD'));
		data.confederation_id && formData.append('confederation_id', data.confederation_id);
		create(formData)
			.then(() => {
				setSaving(false);
				form.resetFields();
				listRoute();
			})
			.catch((e:any) => {
				setSaving(false);
				AssignErrorToInput(form, e?.data?.errors);
			})
	};

	return (
		<FormComponent
			formId="addGroupForm"
			form={form}
			handleSubmit={handleSubmit}
			saving={saving}
			listRoute={listRoute}
			isEdit={false}
			onFileChange={onFileChange}
			userList={userList}
			currencyList={currencyList}
			confederationList={confederationList}
			group={[]}
			fileList={fileList}
			setFileList={setFileList}
		/>
	);
});
export default AddGroupComponent;
