import { CalendarOutlined, EnvironmentOutlined, MailOutlined, UserOutlined } from "@ant-design/icons";
import { Button, Row, Col, Layout, Form } from "antd";
import { observer } from "mobx-react";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { FormBox, InputBox } from "../../../../../components/AntdAddons";
import BreadcrumbComponent from "../../../../../components/BreadcrumbComponent/BreadcrumbComponent";
import { UserBreadcrumb } from "../../../../../config/BreadcrumbConfig";
import RoleList from "../../../../../config/RoleList";
import { UserRequest } from "../../../../../requests/UserRequest";
import gendericon from "../../../../../assets/images/gender.svg";
import { useTranslation } from "react-i18next";
import { displayFormats, genderList, userType } from "../../../../../config/Global";
import PhoneInput from "react-phone-number-input";
import 'react-phone-number-input/style.css';
import useStore from "../../../../../store";

interface FormDataProps {
	formId: string;
	form: any;
	handleSubmit: (data: any) => void | any;
	saving: boolean;
	listRoute: () => void;
	isEdit: boolean;
	professionList: any[];
	roleList: any[];
	phoneNo?: any;
	setPhone?: any;
}


const FormComponent: React.FC<FormDataProps> = observer(({
	formId,
	form,
	handleSubmit,
	saving,
	listRoute,
	isEdit,
	professionList,
	roleList,
	phoneNo,
	setPhone
}) => {
	const { COMMON } = useStore();
	const {
		currencyList,
		fetchCurrency,
	} = COMMON;
	const [roleSelect, setRoleToSelect] = useState<any>([]);
	const { t } = useTranslation();
	const disableDate = (current: any) => {
		return current && (current > moment().endOf('day'));
	}

	const setRoleType = (userType: any) => {
		form.setFieldsValue({ role_id: undefined });
		if (userType == 1 || form.getFieldValue('user_type') == 1) {
			setRoleToSelect(roleList.filter(element => element.role_type == userType));
		} else {
			setRoleToSelect(roleList.filter((role: any) => (role.name == RoleList.VILLAGE_AGENT || role.name == RoleList.ELECTION_REFREE)));
		}
		roleSelect;
	}


	useEffect(() => {
		//setRoleToSelect(form.getFieldValue('user_type'));

	}, [setRoleToSelect, isEdit, form])

	useEffect(() => {
		fetchCurrency()
	}, [])
	return (
		<FormBox
			id={formId}
			form={form}
			encType="multipart/formdata"
			onFinish={handleSubmit} key="formbasic">
			<div className="Breadcrumb-bar Breadcrumb-bar-export">
				<BreadcrumbComponent items={UserBreadcrumb(t).path} />
			</div>
			<Layout style={{ padding: '0 24px' }}>
				<Row gutter={6} wrap={false}>
					<Col span={8} className="form__input__textbox">
						<InputBox.Text
							size="large"
							placeholder={t("FIRST_NAME")}
							label={t("FIRST_NAME")}
							name="firstname"
							prefix={<UserOutlined />}
							rules={UserRequest(t).firstname} />
					</Col>
					<Col span={8} className="form__input__textbox">
						<InputBox.Text
							size="large"
							placeholder={t("LAST_NAME")}
							label={t("LAST_NAME")}
							name="lastname"
							prefix={<UserOutlined />}
							rules={UserRequest(t).lastname} />
					</Col>
				</Row>
				<Row gutter={12} wrap={false}>
					<Col span={16} className="form__input__textbox form__input__selectBox">
						<InputBox.TextArea
							prefix={<EnvironmentOutlined />}
							name="address"
							placeholder={t("ADDRESS")}
							label={t("ADDRESS")}
							rules={UserRequest(t).address}
						/>
					</Col>
				</Row>
				<Row gutter={6} wrap={false}>
					<Col span={8} className="form__input__textbox form__input__selectBox">
						<InputBox.DatePicker
							name="date_of_birth"
							placeholder={t("DATE_OF_BIRTH")}
							label={t("DATE_OF_BIRTH")}
							rules={UserRequest(t).date_of_birth}
							disabledDate={disableDate}
							suffixIcon={<CalendarOutlined />}
							format={displayFormats.DATE_FORMAT}
						/>
					</Col>
					<Col span={8} className="form__input__textbox">
						<InputBox.Text
							size="large"
							placeholder={t("EMAIL")}
							label={t("EMAIL")}
							name="email"
							prefix={<MailOutlined />}
							rules={UserRequest(t).email} />
					</Col>
				</Row>
				<Row gutter={6} wrap={false}>
					<Col span={8} className="form__input__textbox form__input__selectBox">
						<InputBox.Select
							options={{
								list: professionList,
								valueKey: "id",
								textKey: "name"
							}}
							name="profession_id"
							placeholder={t("SELECT_PROFESSION")}
							label={t("SELECT_PROFESSION")}
						/>
					</Col>
					<Col span={8} className="form__input__textbox">
						{/* <InputBox.Text
								size="large"
								placeholder={t("PHONE_NUMBER")}
								label={t("PHONE_NUMBER")}
								name="phone_number"
								prefix={<img src={callingicon}/>}
								rules={UserRequest(t).phone_number} /> */}
						<Form.Item
							name="phone_number"
							rules={UserRequest(t).phone_number}
							label={t("PHONE_NUMBER")}
							className="phoneInputContent"
						>
							<PhoneInput
								disabled={isEdit}
								countrySelectProps={{ unicodeFlags: true }}
								placeholder={t('PHONE_NUMBER')}
								name="phone_number"
								value={phoneNo}
								international
								defaultCountry="IN"
								countryCallingCodeEditable={false}
								onChange={setPhone}
							/>
						</Form.Item>
					</Col>
				</Row>
				<Row gutter={6} wrap={false}>
					<Col span={8} className="form__input__textbox form__input__selectBox">
						<InputBox.Select
							options={{
								list: userType(t),
								valueKey: "id",
								textKey: "value"
							}}
							onChange={setRoleType}
							name="user_type"
							placeholder={t("USER_TYPE")}
							label={t("USER_TYPE")}
							disabled={isEdit}
							rules={UserRequest(t).user_type}
						/>
					</Col>
					<Col span={8} className="form__input__textbox form__input__selectBox">
						<InputBox.Select
							options={{
								list: roleList.filter(role => role.role_type == form.getFieldValue('user_type')),
								valueKey: "id",
								textKey: "name"
							}}
							mode={form.getFieldValue('user_type') && form.getFieldValue('user_type') == 1 ? "multiple" : "combobox"}
							name="role_id"
							placeholder={t("SELECT_ROLE")}
							label={t("SELECT_ROLE")}
							disabled={isEdit && form.getFieldValue('user_type') == 2}
							rules={UserRequest(t).role_id}
						/>
					</Col>
				</Row>
				<Row gutter={6} wrap={false}>
					<Col span={8} className="form__input__textbox form__input__selectBox">
						<label >{<img src={gendericon} />} {t("GENDER")} </label>
						<InputBox.Radio
							options={{
								list: genderList(t),
								valueKey: "id",
								textKey: "value"
							}}
							name="gender"
							rules={UserRequest(t).gender}
						/>
					</Col>
					<Col span={8} className="form__input__textbox form__input__selectBox">
						<InputBox.Select
							options={{
								list: currencyList,
								valueKey: "id",
								textKey: "name"
							}}
							label={t("CURRENCY")}
							name="currency_id"
							placeholder={t("CURRENCY")}
							rules={UserRequest(t).currency_id}
							disabled={isEdit}
						/>
					</Col>
				</Row>
				<Col span={24} className="permission__button ant-row-center ant-row mt-50">
					<Button type="primary"
						htmlType="submit"
						loading={saving}
						key="submit1">{t('SAVE')}</Button>
					<Button type="primary" key="resetCancel" danger onClick={listRoute}>{t('CANCEL')}</Button>
				</Col>
			</Layout>
		</FormBox>
	);
});
export default FormComponent;
