import axios from "axios";
import { makeAutoObservable } from "mobx";
import API_URL from "../../config/ApiUrl";
import RootStore from "../RootStore/RootStore";
import { HomePageContentListDataProps } from "./HomePageContentInterface";

export default class HomePageContentStore {
	public listData?: HomePageContentListDataProps[];
	public grid?: any;
	public homePageContentList: [] | any;
	public homePageContent?: HomePageContentListDataProps;
	private rootStore: RootStore;
	public editHomePageContentPopup?: boolean;
	public perPage?:any = 100;
	public editHomePageContentID?: number;
	constructor() {
		this.rootStore = new RootStore();
		makeAutoObservable(this);
	}

	public openEditHomePageContentPopup = (id: number): void => {
		this.editHomePageContentPopup = true;
		this.setEditHomePageContentID(id);
	};
	public closeEditHomePageContentPopup = (): void => {
		this.editHomePageContentPopup = false;
		this.setEditHomePageContentID();
	};
	public setHomePageContent = (value?: HomePageContentListDataProps): void => {
		this.homePageContentList = value;
	};

	// Setter Functions
	private setListData = (value?: HomePageContentListDataProps[]): void => {
		this.listData = value;
	};

	private setEditHomePageContentID = (id?: number): void => {
		this.editHomePageContentID = id;
	};

	// API Functions
	public fetchList = (payLoad:any): Promise<any> => {
		return axios.post(API_URL.HOME_PAGE_CONTENT.LIST,payLoad).then(({ data }) => {
			this.setListData(data.data.rows);
			return data.data;
		});
	};

	public create = (payload: any): Promise<any> => {
		return axios
			.post(API_URL.HOME_PAGE_CONTENT.CREATE, payload)
			.then(({ data }) => {
				this.setListData(data.data.rows);
				return data.data;
			})
			.catch(({ data }) => {
				return Promise.reject(data);
			});
	};

	public details = (id: number): Promise<any> => {
		return axios.get(API_URL.HOME_PAGE_CONTENT.DETAILS(id)).then(({ data }) => {
			return data;
		});
	};

	public update = (id:number,payload:FormData): Promise<any> => {
		return axios
			.post(API_URL.HOME_PAGE_CONTENT.UPDATE(id), payload, {headers: { "Content-Type": "multipart/form-data" }})
			.then(({ data }) => {
				return data;
			});
	};

	public changeStatus = (payload:any): Promise<any> => {
		return axios.post(API_URL.HOME_PAGE_CONTENT.CHANGE_STATUS(payload.id),payload)
		.then(({ data }) => {
			return data;
		});
	};

}

