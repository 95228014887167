import React, { useEffect } from "react";
import { AgGridColumn } from "ag-grid-react";
import AgGridWrapper from "../../../../components/AgGridWrapper/AgGridWrapper";
import { Button, message, Switch } from "antd";
import { EditOutlined, DeleteOutlined } from "@ant-design/icons";
import DateComparator from "../../../../utils/DateComparator";
import moment from "moment";
import Config from "../../../../config/Config";
import { useTranslation } from "react-i18next";
import { displayFormats } from "../../../../config/Global";
import useStore from "../../../../store";

interface ListProps {
	setupGrid: () => Promise<any>;
	editRedirect: (id: number) => void;
	openDeletePopup: (id: number) => void | any;
	ChangeStatus: (params: any) => Promise<any>;
}
const ListComponent: React.FC<ListProps> = ({
	setupGrid,
	editRedirect,
	openDeletePopup,
	ChangeStatus,
}) => {
	const { t } = useTranslation();
	const { AUTH, COMMON } = useStore();
	const { permissions } = AUTH;
	const {allPermissionList} = COMMON

	useEffect(()=>{
		//
	}, [allPermissionList]);

	const changeStatus = (params: any) => {
		ChangeStatus(params)
			.then(() => {
				return;
			})
			.finally(() => {
				return;
			});
	};

	function copyTextToClipboard(textToCopy: any) {
		if (navigator.clipboard && window.isSecureContext) {
			// navigator clipboard api method'
			return navigator.clipboard.writeText(textToCopy);
		} else {
			// text area method
			const textArea = document.createElement("textarea");
			textArea.value = textToCopy;
			// make the textarea out of viewport
			textArea.style.position = "fixed";
			textArea.style.left = "-999999px";
			textArea.style.top = "-999999px";
			document.body.appendChild(textArea);
			textArea.focus();
			textArea.select();
			return new Promise<void>((res, rej) => {
				// here the magic happens
				document.execCommand("copy") ? res() : rej();
				textArea.remove();
			});
		}
	}

	const ActionRenderer = (params: any) => {
		return (
			<div className="action-column">
				{params.data?.canEdit == 1 && <Button
					type="text"
					title={t("EDIT")}
					icon={<EditOutlined />}
					onClick={() => editRedirect(params.data?.id)}
					hidden={
						permissions && allPermissionList &&
						permissions.indexOf(allPermissionList.EDIT_DONATION_CAMPAIGN) < 0 &&
						permissions.indexOf(allPermissionList.DONATION_CAMPAIGN_DETAILS) < 0
					}
				></Button>}
				{params.data?.canDelete == 1 && <Button
					type="text"
					title={t("DELETE")}
					icon={<DeleteOutlined />}
					onClick={() => openDeletePopup(params.data?.id)}
					hidden={
						permissions && allPermissionList && 
						permissions.indexOf(allPermissionList.DELETE_DONATION_CAMPAIGN) < 0
					}
				></Button>}

				{params.data?.link != "" && (
					<Button
						title={t("COPY")}
						type="default"
						style={{ color: "green" }}
						onClick={() =>
							copyTextToClipboard(params.data?.link).then(() =>
								message.success(t("LINK_COPIED"))
							)
						}
					>
						<span>{t("COPY")}</span>
					</Button>
				)}
			</div>
		);
	};
	const SwitchRenderer = (params: any) => {
		return (
			<div>
				<Switch
					defaultChecked={params.data?.status}
					onChange={() =>
						changeStatus({
							id: params.data?.id,
							status: params.data?.status == 1 ? 0 : 1,
						})
					}
					disabled={moment().endOf("day") > moment(params.data?.end_date_time)}
				/>
			</div>
		);
	};

	return (
		<>
			<AgGridWrapper
				type="serverSide"
				onGridReady={setupGrid}
				frameworkComponents={{ ActionRenderer, SwitchRenderer }}
				overlayLoadingTemplate={t("PLEASE_WAIT_WHILE_DATA_IS_LOADING")}
				overlayNoRowsTemplate={t("NO_RECORD_FOUND")}
			>
				<AgGridColumn
					field="id"
					headerName={"# " + t("ID")}
					width={100}
					pinned={"left"}
					filter="agNumberColumnFilter"
				/>
				<AgGridColumn field="group_name" headerName={t("GROUP_NAME")} />
				<AgGridColumn field="name" headerName={t("CAMPAIGN_NAME")} />
				<AgGridColumn
					field="donation_subject"
					headerName={t("DONATION_SUBJECT")}
				/>
				<AgGridColumn
					field="start_date_time"
					headerName={t("START_DATE_TIME")}
					valueGetter={({ data }) =>
						data.start_date_time &&
						moment(data.start_date_time).format(Config.dateTimeFormat)
					}
					filter={"agDateColumnFilter"}
					valueFormatter={(data: any) =>
						data?.data?.start_date_time &&
						moment
							.utc(data?.data?.start_date_time)
							.local()
							.format(displayFormats.DATE_TIME_FORMAT)
					}
					filterParams={{
						comparator: DateComparator,
					}}
				/>
				<AgGridColumn
					field="end_date_time"
					headerName={t("END_DATE_TIME")}
					valueGetter={({ data }) =>
						data.end_date_time &&
						moment(data.end_date_time).format(Config.dateTimeFormat)
					}
					filter={"agDateColumnFilter"}
					valueFormatter={(data: any) =>
						data?.data?.end_date_time &&
						moment
							.utc(data?.data?.end_date_time)
							.local()
							.format(displayFormats.DATE_TIME_FORMAT)
					}
					filterParams={{
						comparator: DateComparator,
					}}
				/>
				<AgGridColumn field="amount" headerName={t("AMOUNT_RECEIVED")} />
				<AgGridColumn
					field="status"
					headerName={t("STATUS")}
					cellRenderer="SwitchRenderer"
					width={120}
					pinned="right"
					sortable={false}
					filter={false}
				/>
				<AgGridColumn
					headerName={t("ACTION")}
					type="actionColumn"
					sortable={false}
					filter={false}
					width={100}
					pinned={"right"}
				/>
			</AgGridWrapper>
		</>
	);
};

export default ListComponent;
