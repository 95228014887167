import i18n from "i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import backend from "i18next-http-backend";
import { initReactI18next } from "react-i18next";
import CommonStore from "./store/CommonStore/CommonStore";

const backendOptions = {
	loadPath: "{{lng}}|{{ns}}",
	request: (options: any, url: any, payload: any, callback: any) => {
		try {
			const [lng] = url.split("|");
			const commonStore = new CommonStore();
			!commonStore.translationData &&
				commonStore.loadTranslationData(lng).then((response: any) => {
					callback(null, {
						data: response,
						status: 200,
					});
				});
		} catch (e) {
			console.error(e);
			callback(null, {
				status: 500,
			});
		}
	},
};

i18n
	.use(LanguageDetector)
	.use(backend)
	.use(initReactI18next)
	.init({
		backend: backendOptions,
		// preload: ["en", "fr", "ha", "za"],
		supportedLngs: ["en", "fr", "ha", "za"],
		load: "languageOnly",
		ns: ["translations"],
		defaultNS: "translations",
		react: {
			// wait: true,
			useSuspense: true,
			bindI18n: "languageChanged loaded",
			bindI18nStore: "added",
		},
		// fallbackLng: "fr",
		lng: window.localStorage.i18nextLng ?? "en",
		debug: true,
		// have a common namespace used around the full app

		keySeparator: false, // we use content as keys

		interpolation: {
			escapeValue: false, // not needed for react!!
			formatSeparator: ",",
		},
		initImmediate: false,
	});

export default i18n;

// export default (callback: any) => {
//   const instance1 = i18n
//       .use(LanguageDetector)
//       .use(backend)
//       .use(initReactI18next)
//       .init({
//           backend: backendOptions,

//           debug: true,
//           // whitelist: ["fr", "en", "ha", "za"],
//           interpolation: {
//               escapeValue: false,
//           },
//           lng: window.localStorage.i18nextLng ?? "en",
//           react: {
//               // wait: true,
//               bindI18n: 'languageChanged loaded',
//       bindI18nStore: 'added'
//           },
//       },() => callback());
//   };
