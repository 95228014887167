import React, { useEffect } from "react";
import { observer } from "mobx-react";
import BreadcrumbComponent from "../../../../components/BreadcrumbComponent/BreadcrumbComponent";
import ContentBox from "../../../../components/ContentBox/ContentBox";
import { ProfessionBreadcrumb } from "../../../../config/BreadcrumbConfig";
import useStore from "../../../../store";
import HeaderButtons from "./components/HeaderButtons";
import ListComponent from "./components/ListComponent";
import AddComponent from "./components/AddComponent";
import EditComponent from "./components/EditComponent";
import DeleteComponent from "./components/DeleteComponent";
import { useTranslation } from "react-i18next";

const ProfessionView: React.FC = observer(() => {
	const { t } = useTranslation();
	const { PROFESSION, AUTH, COMMON } = useStore();
	const {
		listData,
		create,
		update,
		details,
		remove,
		changeStatus,
		fetchList,
		addProfessionPopup,
		openAddProfessionPopup,
		closeAddProfessionPopup,
		editProfessionID,
		editProfessionPopup,
		openEditprofessionPopup,
		closeEditPofessionPopup,
		deleteProfessionID,
		deleteProfessionPopup,
		openDeleteprofessionPopup,
		closeDeleteprofessionPopup,
	} = PROFESSION;

	const {permissions} = AUTH
	const {allPermissionList} = COMMON

	useEffect(()=>{
		//
	}, [allPermissionList]);

	return (
		<>
			<div className="Breadcrumb-bar">
				<BreadcrumbComponent items={ProfessionBreadcrumb(t).path} />
				<HeaderButtons
					key={1}
					openAddProfessionPopup={openAddProfessionPopup}
					addPermission={permissions && allPermissionList && permissions.indexOf(allPermissionList.ADD_PROFESSION) >=0}
				/>
			</div>

			<ContentBox>
				<ListComponent
					rowData={listData}
					getData={fetchList}
					openEditprofessionPopup={openEditprofessionPopup}
					openDeleteprofessionPopup={openDeleteprofessionPopup}
					ChangeStatus={changeStatus}
					editPermission={permissions && allPermissionList && permissions.indexOf(allPermissionList.EDIT_ROLE) >=0}
					deletePermission={permissions && allPermissionList && permissions.indexOf(allPermissionList.DELETE_ROLE) >=0}
				/>
				<AddComponent
					createData={create}
					visible={addProfessionPopup}
					closeAddProfessionPopup={closeAddProfessionPopup}
				/>
				<EditComponent
					EditID={editProfessionID}
					EditData={update}
					ReadData={details}
					visible={editProfessionPopup}
					closeEditPofessionPopup={closeEditPofessionPopup}
				/>
				<DeleteComponent
					DeleteID={deleteProfessionID}
					DeleteData={remove}
					visible={deleteProfessionPopup}
					closeDeleteprofessionPopup={closeDeleteprofessionPopup}
				/>
			</ContentBox>
		</>
	);
});

export default ProfessionView;
