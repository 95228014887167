import React from "react";
import { AgGridColumn } from "ag-grid-react";
import AgGridWrapper from "../../../../components/AgGridWrapper/AgGridWrapper";
import moment from "moment";
import DateComparator from "../../../../utils/DateComparator";
import { Button, Switch } from "antd";
import { EditOutlined, DeleteOutlined } from "@ant-design/icons";
import Config from "../../../../config/Config";
import { GridReadyEvent } from "ag-grid-community";
import { useTranslation } from "react-i18next";
import { displayFormats } from "../../../../config/Global";
interface ListProps {
	setupGrid: (params: GridReadyEvent) => void;
	openEditFinePopup: (id: number) => void | any;
	openDeleteFinePopup: (id: number) => void | any;
	ChangeStatus: (data: any) => Promise<any>;
}
const ListComponent: React.FC<ListProps> = ({
	setupGrid,
	openEditFinePopup,
	openDeleteFinePopup,
	ChangeStatus,
}) => {
	const { t } = useTranslation();
	const changeStatus = (data: any) => {
		ChangeStatus(data)
			.then(() => {
				return;
			})
			.finally(() => {
				return;
			});
	};
	const ActionRenderer = (params: any) => {
		return (
			(params.data?.paid_status == 0) ? <div className="action-column">
				<Button
					type="text"
					title={t("EDIT")}
					icon={<EditOutlined />}
					onClick={() => openEditFinePopup(params.data?.id)}
				></Button>
				<Button
					type="text"
					title={t("DELETE")}
					icon={<DeleteOutlined />}
					onClick={() => openDeleteFinePopup(params.data?.id)}
				></Button>
			</div> : <></>
		);
	};
	const SwitchRenderer = (params: any) => {
		return (
			<div>
				<Switch
					defaultChecked={params.data?.status}
					onChange={() =>
						changeStatus({
							id: params.data?.id,
							status: params.data?.status == 1 ? 0 : 1,
						})
					}
				/>
			</div>
		);
	};
	return (
		<>
			<AgGridWrapper
				type="serverSide"
				onGridReady={setupGrid}
				rowSelection={"multiple"}
				frameworkComponents={{ ActionRenderer, SwitchRenderer }}
				overlayLoadingTemplate={t("PLEASE_WAIT_WHILE_DATA_IS_LOADING")} overlayNoRowsTemplate={t("NO_RECORD_FOUND")}
			>
				<AgGridColumn
					field="id"
					filter={"agNumberColumnFilter"}
					headerName={"# " + t('ID')}
					width={100}
					pinned={"left"}
				/>
				<AgGridColumn
					field="created_at"
					headerName={t("DATE")}
					valueGetter={({ data }) =>
						data.created_at &&
						moment(data.created_at).format(Config.dateTimeFormat)
					}
					filter={"agDateColumnFilter"}
					valueFormatter={(data: any) =>
						data?.data?.created_at && moment.utc(data?.data?.created_at).local().format(displayFormats.DATE_TIME_FORMAT)
					}
					filterParams={{
						comparator: DateComparator,
					}}
					width={220}
				/>
				<AgGridColumn field="group_name" headerName={t('GROUP_NAME')} />
				<AgGridColumn field="user_name" headerName={t("MEMBER_NAME")} />
				<AgGridColumn field="fine_type_name" headerName={t("FINE_TYPE")} />
				<AgGridColumn field="fine_amount" headerName={t("AMOUNT")} />
				<AgGridColumn field="fine_payment_mode" headerName={t("PAYMENT_MODE")} />
				<AgGridColumn field="fine_due_date" headerName={t("DUE_DATE")}
					valueGetter={({ data }) =>
						data.fine_due_date &&
						moment(data.fine_due_date).format(Config.dateFormat)
					}
					filter={"agDateColumnFilter"}
					valueFormatter={(data: any) =>
						data?.data?.fine_due_date && moment.utc(data?.data?.fine_due_date).local().format(displayFormats.DATE_FORMAT)
					}
					filterParams={{
						comparator: DateComparator,
					}}
					width={220} />
				<AgGridColumn
					field="fine_paid_status"
					headerName={t("PAID_STATUS")}
					width={220}
				/>
				{/* <AgGridColumn
					field="status"
					headerName={t('STATUS')}
					cellRenderer="SwitchRenderer"
					width={120}
					pinned={"right"}
					sortable={false}
					filter={false}
				/> */}

				<AgGridColumn
					headerName={t('ACTION')}
					type="actionColumn"
					sortable={false}
					filter={false}
					width={100}
					pinned={"right"}
				/>
			</AgGridWrapper>
		</>
	);
};

export default ListComponent;
