import React from "react";
import { AgGridColumn } from "ag-grid-react";
import AgGridWrapper from "../../../../components/AgGridWrapper/AgGridWrapper";
import { Button } from "antd";
import { EditOutlined } from "@ant-design/icons"; // RedoOutlined
import Config from "../../../../config/Config";
import DateComparator from "../../../../utils/DateComparator";
import moment from "moment";
import { GridReadyEvent } from "ag-grid-community";
import { useTranslation } from "react-i18next";
import { displayFormats } from "../../../../config/Global";
interface ListProps {
	setupGrid: (params: GridReadyEvent) => void;
	editDonationRedirect:(id:number) => void;
}
const ListComponent: React.FC<ListProps> = ({
	setupGrid,
	editDonationRedirect,
}) => {
	const { t } = useTranslation();
	const ActionRenderer = (params: any) => {
		return (
			<div className="action-column">
				<Button
					hidden={params.data.paid_status==1}
					type="text"
					title={t("EDIT_DONATION")}
					icon={<EditOutlined />}
					onClick={() => editDonationRedirect(params.data?.id)}
				></Button>
				{/* <Button
					type="text"
					icon={<RedoOutlined />}
					hidden={params.data.paid_status!=1}
				></Button> */}
			</div>
		);
	};
    return (
        <>
					<AgGridWrapper
						type="serverSide"
						onGridReady={setupGrid}
						rowSelection={"multiple"}
						overlayLoadingTemplate={t("PLEASE_WAIT_WHILE_DATA_IS_LOADING")}
						overlayNoRowsTemplate={t("NO_RECORD_FOUND")}
						frameworkComponents={{ ActionRenderer}}>
						<AgGridColumn
							field="id"
							headerName={"# " + t('ID')}
							width={100}
							pinned={"left"}
							filter="agNumberColumnFilter"
						/>
						<AgGridColumn field="name" headerName={t("NAME")} />
						<AgGridColumn field="amount" headerName={t("AMOUNT")} filter="agNumberColumnFilter"/>
						<AgGridColumn
							field="created_at"
							headerName={t('CREATED_AT')}
							valueGetter={({ data }) =>
								data.created_at &&
								moment(data.created_at).format(Config.dateTimeFormat)
							}
							filter={"agDateColumnFilter"}
							valueFormatter={(data:any) =>
								data?.data?.created_at && moment.utc(data?.data?.created_at).local().format(displayFormats.DATE_TIME_FORMAT)
							}
							filterParams={{
								comparator: DateComparator,
							}}
							width={220}
						/>
						<AgGridColumn field="phone_number" headerName={t("PHONE_NUMBER")} />
						<AgGridColumn field="email" headerName={t("EMAIL_ID")} />
						<AgGridColumn field="address" headerName={t("ADDRESS")} />
						<AgGridColumn field="organisation" headerName={t("ORGANIZATION")} />
						<AgGridColumn field="donation_subject" headerName={t("DONATION_SUBJECT")} />
						<AgGridColumn field="group_name" headerName={t("DONATING_TO")} />
						<AgGridColumn field="donation_paid_status" headerName={t('STATUS')} />
						<AgGridColumn
							headerName={t('ACTION')}
							type="actionColumn"
							sortable={false}
							filter={false}
							width={100}
							pinned={"right"}
						/>
					</AgGridWrapper>
        </>
	);
};

export default ListComponent;
