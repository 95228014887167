import React from "react";
import { observer } from "mobx-react";
import BreadcrumbComponent from "../../../components/BreadcrumbComponent/BreadcrumbComponent";
import { FinestypeBreadcrumb } from "../../../config/BreadcrumbConfig";
import HeaderButtons from "./components/HeaderButtons";
import ContentBox from "../../../components/ContentBox/ContentBox";
import ListComponent from "./components/ListComponent";
import useStore from "../../../store";
import AddComponent from "./components/AddComponent";
import EditComponent from "./components/EditComponent";
import DeleteComponent from "./components/DeleteComponent";
import { Link } from "react-router-dom";
import { Row,} from 'antd';
import { useTranslation } from "react-i18next";
const FinesTypeView: React.FC = observer(() => {
	const { FINETYPE } = useStore();
	const { t } = useTranslation();
	const {
		listData,
		create,
		update,
		details,
		remove,
		changeStatus,
		fetchList,
		addFinestypePopup,
		openAddFinestypePopup,
		closeAddFinestypePopup,
		editFinestypeID,
		editFinestypePopup,
		openEditFinestypePopup,
		closeEditFinestypePopup,
		deleteFinestypeID,
		deleteFinestypePopup,
		openDeleteFinestypePopup,
		closeDeleteFinestypePopup,
	} = FINETYPE;
    return (
		<>
            <div className="Breadcrumb-bar">
			<BreadcrumbComponent items={FinestypeBreadcrumb(t).path} />
			<HeaderButtons key={1} openAddFinestypePopup={openAddFinestypePopup}/>
			</div>
			<Row justify="end">
				<Link to="fine-management" className="btn btn-primary btn-link ant-row-end">{t("BACK_TO_FINES")}</Link>
			</Row>
            <ContentBox>
				<ListComponent
					rowData={listData}
					getData={fetchList}
					openEditFinestypePopup={openEditFinestypePopup}
					openDeleteFinestypePopup={openDeleteFinestypePopup}
					InactiveData={changeStatus}
				/>
				<AddComponent
					createData={create}
					visible={addFinestypePopup}
					closeAddFinestypePopup={closeAddFinestypePopup}
				/>
				<EditComponent
					EditID={editFinestypeID}
					EditData={update}
					ReadData={details}
					visible={editFinestypePopup}
					closeEditFinestypePopup={closeEditFinestypePopup}
				/>
				<DeleteComponent
					DeleteID={deleteFinestypeID}
					DeleteData={remove}
					visible={deleteFinestypePopup}
					closeDeleteFinestypePopup={closeDeleteFinestypePopup}
				/>
            </ContentBox>
        </>

);
});

export default FinesTypeView;