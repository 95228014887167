import React, { useEffect } from "react";
import { AgGridColumn } from "ag-grid-react";
import AgGridWrapper from "../../../../components/AgGridWrapper/AgGridWrapper";
import { Button, Switch } from "antd";
import { EditOutlined, DeleteOutlined } from "@ant-design/icons";
import { RedirectLinkListDataProps } from "../../../../store/RedirectLinkStore/RedirectLinkIntrface";
import DateComparator from "../../../../utils/DateComparator";
import moment from "moment";
import Config from "../../../../config/Config";
import { useTranslation } from "react-i18next";
import { displayFormats } from "../../../../config/Global";
interface ListProps {
	rowData: RedirectLinkListDataProps[];
	getData: () => Promise<any>;
	editRedirect:(id:number) => void;
	openDeletePopup: (id: number) => void | any;
	ChangeStatus:(params: any) => Promise<any>;
}
const ListComponent: React.FC<ListProps> = ({
	rowData,
	getData,
	editRedirect,
	openDeletePopup,
	ChangeStatus,
}) => {
	const { t } = useTranslation();
	useEffect(() => {
		getData();
	}, [getData]);

	const changeStatus = (params:any) => {
		ChangeStatus(params)
			.then(() => {
				getData();
				return;
			})
			.finally(() => {
				return;
			});
	}

	const ActionRenderer = (params: any) => {
		return (
			<div className="action-column">
				<Button
					type="text"
					title={t("EDIT")}
					icon={<EditOutlined />}
					onClick={() => editRedirect(params.data?.id)}
					//hidden={moment().endOf('day') > moment(params.data?.end_date_time)}
				></Button>
				<Button
					type="text"
					title={t("DELETE")}
					icon={<DeleteOutlined />}
					onClick={() => openDeletePopup(params.data?.id)}
				></Button>
			</div>
		);
	};
	const SwitchRenderer = (params: any) => {
		return (
			<div>
				<Switch
					defaultChecked={params.data?.status}
					onChange= {() => changeStatus({id:params.data?.id,status:params.data?.status ==1 ? 0 : 1})}
				/>
			</div>
		);
	};

	return (
			<>
				<AgGridWrapper rowData={rowData} frameworkComponents={{ ActionRenderer, SwitchRenderer}} overlayLoadingTemplate={t("PLEASE_WAIT_WHILE_DATA_IS_LOADING")} overlayNoRowsTemplate={t("NO_RECORD_FOUND")}>
					<AgGridColumn
						field="id"
						headerName={"# " + t('ID')}
						width={100}
						pinned={"left"}
						filter="agNumberColumnFilter"
					/>
					<AgGridColumn field="link_caption" headerName={t("LINK_CAPTION")} />
					<AgGridColumn field="link_url" headerName={t("LINK_URL")} />
					<AgGridColumn
						field="created_at"
						headerName={t('CREATED_AT')}
						valueGetter={({ data }) =>
							data.created_at &&
							moment(data.created_at).format(Config.dateTimeFormat)
						}
						filter={"agDateColumnFilter"}
						valueFormatter={(data:any) =>
							data?.data?.created_at && moment.utc(data?.data?.created_at).local().format(displayFormats.DATE_TIME_FORMAT)
						}
						filterParams={{
							comparator: DateComparator,
						}}
						width={220}
					/>
					<AgGridColumn
						field="updated_at"
						headerName={t("UPDATED_AT")}
						valueGetter={({ data }) =>
							data.created_at &&
							moment(data.created_at).format(Config.dateTimeFormat)
						}
						filter={"agDateColumnFilter"}
						valueFormatter={(data:any) =>
							data?.data?.updated_at && moment.utc(data?.data?.updated_at).local().format(displayFormats.DATE_TIME_FORMAT)
						}
						filterParams={{
							comparator: DateComparator,
						}}
						width={220}
					/>
					<AgGridColumn
						field="status"
						headerName={t('STATUS')}
						cellRenderer="SwitchRenderer"
						width={120}
						pinned="right"
						sortable={false}
						filter={false}
					/>
					<AgGridColumn
						headerName={t('ACTION')}
						type="actionColumn"
						sortable={false}
						filter={false}
						width={100}
						pinned={"right"}
					/>
				</AgGridWrapper>
			</>
	);
};

export default ListComponent;
