import React, { useEffect, useState }  from "react";
import { observer } from "mobx-react";
import {  Form, message } from 'antd';
import useStore from "../../../../../store";
import { useHistory } from "react-router";
import FormComponent from "./FormComponent";
import { useTranslation } from "react-i18next";

const AddRoleComponent: React.FC = observer(() => {
	const { t } = useTranslation();
	const { ROLE } = useStore();
	const {
		permissionList,
		getPermissionList,
		create,
		roleType,
		setRoleType,
		showPermissionModel,
		openPermissionModel,
		closePermissionModel
	} = ROLE;
	const { ROOT } = useStore();
	const { AssignErrorToInput } = ROOT;

	useEffect(()=>{
		getPermissionList()
		setRoleType()
	},[getPermissionList, setRoleType])
	const [form] = Form.useForm();
	const [saving, setSaving] = useState<boolean>(false);
	const [selectedPermission, setPermission] = useState<any>([]);
	const history = useHistory();
	const listRoute = () =>{
		history.push('/role-management');
  }

	const onCheckChange = (e:any)  => {
		const options = selectedPermission;
    let index

    if (e.target.checked) {
      options.push(+e.target.value)
    } else {
      index = options.indexOf(+e.target.value)
      options.splice(index, 1)
    }
		setPermission(options);
	}
	// Handle submit and call function to save new record
	const handleSubmit = (data: any) => {
		if (!selectedPermission.length) {
			message.error(t("PLEASE_SELECT_AT_LEAST_ONE_PERMISSION_FOR_ROLE"));
			return;
		}

		data.permission = selectedPermission;
		setSaving(true);

		create(data)
			.then(() => {
				setSaving(false);
				setPermission([]);
				form.resetFields();
				listRoute();
			})
			.catch((e:any) => {
				setSaving(false);
				AssignErrorToInput(form, e?.data?.errors);
			})
	};
	
	useEffect(() => {
		form.setFieldsValue({role_type: 1})
	}, [form])

	return (
		<FormComponent
			formId="addRoleForm"
			form={form}
			handleSubmit={handleSubmit}
			roleType={roleType}
			permissionList={permissionList}
			selectedPermission={selectedPermission}
			onCheckChange={onCheckChange}
			showPermissionModel={showPermissionModel}
			closePermissionModel={closePermissionModel}
			openPermissionModel={openPermissionModel}
			saving={saving}
			listRoute={listRoute}
			isEdit={false}
		/>
	);
});
export default AddRoleComponent;
