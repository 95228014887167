import moment from "moment";
import { displayFormats } from "../config/Global";
import { RequestProps } from "../config/InterfacesAndTypes";
import Message from "../config/Message";

export const GroupRequest = (t: any): RequestProps => ({
	name: [{ required: true, message: Message(t).required(t('GROUP_NAME')) }],
	description: [{ required: true, message: Message(t).required(t('DESCRIPTION')) }],
	share_price: [
		{ required: true, message: Message(t).required(t("SHARE_PRICE")) },
		{ pattern: /^([0-9]+)$/, message:	Message(t).type.pattern(t("SHARE_PRICE"))},
		({ getFieldValue }) => ({
			validator() {
				if (getFieldValue("share_price") < 1) {
					return Promise.reject(Message(t).minIntFloat(1, t('SHARE_PRICE')));
				}
				return Promise.resolve();
			},
		}),
	],
	numbers_of_member: [
		{ required: true, message: Message(t).required(t('NUMBER_OF_MEMBERS')) },
		{ pattern: /^([0-9]+)$/, message: t("PLEASE_ENTER_DIGITS_ONLY") + "!"},
		({ getFieldValue }) => ({
			validator() {
				if (getFieldValue("numbers_of_member") < 5) {
					return Promise.reject(Message(t).minIntFloat(5, t('NUMBER_OF_MEMBERS')));
				}
				return Promise.resolve();
			},
		}),
	],
	max_share_purchase_limit: [
		{ required: true, message: Message(t).required(t("MAX_SHARE_PURCHASE_LIMIT")) },
		{ pattern: /^([0-9]+)$/, message:	Message(t).type.pattern(t("MAX_SHARE_PURCHASE_LIMIT"))},
		({ getFieldValue }) => ({
			validator() {
				const maxSharePurchaseLimit = getFieldValue("max_share_purchase_limit");
				if (maxSharePurchaseLimit < 1) {
					return Promise.reject(Message(t).minIntFloat(1, t('MAXIMUM_SHARES')));
				}
				if (maxSharePurchaseLimit > 5) {
					return Promise.reject(Message(t).maxIntFloat(5, t('MAXIMUM_SHARES')));
				}
				return Promise.resolve();
			},
		}),
	],
	president_id: [{ required: true, message: Message(t).required(t("PRESIDENT")) }],
	currency_id: [{ required: true, message: Message(t).required(t("CURRENCY")) }],
	group_welfare_rate: [
		{ required: true, message: Message(t).required(t("GROUP_WELFARE_RATE"))},
		{ pattern: /^([0-9]+)$/, message:	Message(t).type.pattern(t("GROUP_WELFARE_RATE"))},
		({ getFieldValue }) => ({
			validator() {
				if (getFieldValue("group_welfare_rate") < 0) {
					return Promise.reject(Message(t).minIntFloat(0, t('GROUP_WELFARE_RATE')));
				}
				return Promise.resolve();
			},
		}),
	],
	interest_rate: [
		{ required: true, message: Message(t).required(t("INTEREST_RATE") + " (%)")},
		{ pattern: /^([0-9]+)$/, message:	Message(t).type.pattern(t("INTEREST_RATE") + " (%)")},
		({ getFieldValue }) => ({
			validator() {
				const interestRate = getFieldValue("interest_rate");
				if (interestRate < 0 || interestRate > 100) {
					return Promise.reject(Message(t).rangeBetween(t('INTEREST_RATE'), 0, 100));
				}
				return Promise.resolve();
			},
		}),
	],
	borrowing_limit: [
		{ required: true, message: Message(t).required(t("BORROWING_LIMIT"))},
		{ pattern: /^([0-9]+)$/, message:	Message(t).type.pattern(t("BORROWING_LIMIT"))},
	],
	max_borrowing_time_line: [
		{ required: true, message: Message(t).required(t("MAX_BORROWING_TIME_LINE"))},
		{ pattern: /^([0-9]+)$/, message:	Message(t).type.pattern(t("MAX_BORROWING_TIME_LINE"))},
		({ getFieldValue }) => ({
			validator() {
				if (!getFieldValue("operating_cycle_date")) {
					return Promise.reject(Message(t).required(t('OPERATING_CYCLE_DATE')));
				}
				const maxBorrowingTimeLine = getFieldValue("max_borrowing_time_line");

				const operatingCycleDate = moment(getFieldValue("operating_cycle_date"));
				const currentDate = moment();
				const monthsDifference = (operatingCycleDate.diff(currentDate, 'months') - 1);
				if (maxBorrowingTimeLine < 1) {
					return Promise.reject(Message(t).minIntFloat(1, t('MAX_BORROWING_TIME_LINE_IN_MONTHS')));
				}
				if (maxBorrowingTimeLine > monthsDifference) {
					return Promise.reject(Message(t).maxIntFloat(monthsDifference, t('MAX_BORROWING_TIME_LINE_IN_MONTHS')));
				}
				return Promise.resolve();
			},
		}),
	],
	operating_cycle_date: [
		{ required: true, message: Message(t).required("Operating cycle date") },
		({ getFieldValue }) => ({
			validator() {
				const operatingCycleDate = moment(getFieldValue("operating_cycle_date"));
				const currentDate = moment();
				const monthsDifference = operatingCycleDate.diff(currentDate, 'months');
				if (operatingCycleDate && monthsDifference < 6) {
					return Promise.reject(Message(t).afterDateTime(t('OPERATING_CYCLE_DATE'), moment().add(6, "months").endOf('day').format(displayFormats.DATE_FORMAT)));
				}
				return Promise.resolve();
			}
		}),
	],
	confederation_id:[{ required: true, message: Message(t).required(t("CONFEDERATION")) }],
});
