import React, { useState } from "react";
import { Button, Modal, Form } from "antd";
import useStore from "../../../../store";
import FormComponent from "./FormComponent";
import { useTranslation } from "react-i18next";
interface AddProps {
	createData: (data: any) => Promise<any>;
	closeAddDonationSubjectePopup: () => void;
	visible?: boolean;
}

const AddComponent: React.FC<AddProps> = ({
	createData,
	closeAddDonationSubjectePopup,
	visible,
}) => {
	const [form] = Form.useForm();
	const [saving, setSaving] = useState<boolean>(false);
	const { ROOT } = useStore();
	const { AssignErrorToInput } = ROOT;
	const { t } = useTranslation();

	// Handle submit and call function to save new record
	const handleSubmit = (data: any) => {
		setSaving(true);
		createData(data)
			.then(() => {
				form.resetFields();
				close();
			})
			.catch((e) => {				
				const errors = e?.errors || e?.data?.errors;
				errors && AssignErrorToInput(form, errors);
				setSaving(false);
			})
			.finally(() => setSaving(false));
	};

	const close = () => {
		form.resetFields();
		closeAddDonationSubjectePopup();
	};
	return (
		<Modal
			centered
			title={t("NEW_DONATION_SUBJECT")}
			visible={visible}
			destroyOnClose={true}
			onCancel={close}
			footer={[
				<Button
					key="1"
					loading={saving}
					form="addDonationSubjecteForm"
					type="primary"
					htmlType="submit"
				>
					{t('SAVE')}
				</Button>,
				<Button key="2" onClick={close}>
					{t('CANCEL')}
				</Button>,
			]}
		>
			<FormComponent
				id="addDonationSubjecteForm"
				form={form}
				onFinish={handleSubmit}
			/>
		</Modal>
	);
};

export default AddComponent;
