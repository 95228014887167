import { Button, Col, Layout, Modal, Form, Row } from 'antd';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { FormBox, InputBox } from '../../../../components/AntdAddons';
import useStore from '../../../../store';

interface RemoveVillageAgentInterface  {
	setIsRemoveGroupModelVisiable: (visiable:boolean) => void;
	handleCancel: () => void;
	isRemoveGroupModelVisiable: boolean;
	id: number;
}

const RemoveVillageAgent : React.FC<RemoveVillageAgentInterface> = ({
	handleCancel,
	isRemoveGroupModelVisiable,
	id
}) => {
	const { t } = useTranslation();
	const {
		VILLAGE_AGENT : {removeVillageAgentFromGroup, details}
	} = useStore();

	const [villageAgent, setVillageAgent] = useState<any>(null);
	const [groupList, setGroupList] = useState<any>([]);
	const [saving, setSaving] = useState<boolean>(false);
	const [selectedGroups, setSelectedGroups] = useState<any>([]);

	const handleRemove = (data:any) => {
		data.user_id = id;
		data.group_id = selectedGroups.filter((element:any)=> {
				return (data.groups.indexOf(element) < 0)
			}
		)

		setSaving(true);
		removeVillageAgentFromGroup(data).then(() => {
			handleCancel();
		}).catch(() => {
			//AssignErrorToInput(data, e?.data);
		})
		.finally(() => setSaving(false));
	}

	const [form] = Form.useForm();

	useEffect(() => {
		details(id).then((data: any) => {
			const assignedGroups = data.roles?.map((element:any) => {
				if(element.name== "Village Agent")  return  {id:element.group.id.toString(), name:element.group?.name};
			});
			setGroupList(assignedGroups);
			const selected = assignedGroups.map((element:any) => {return element.id.toString()});
			form.setFieldsValue({groups:selected});
			setSelectedGroups(selected);
			setVillageAgent(data)
		});
	}, [details, form])

	return (
		<Modal
				centered
				visible={isRemoveGroupModelVisiable}
				destroyOnClose={true}
				onCancel={handleCancel}
				width={600}
				footer={[
					<Button
						key="1"
						form="removeVillageAgentForm"
						type="primary"
						loading={saving}
						htmlType="submit"
					>

						{t('SAVE')}
					</Button>,
					<Button key="cancleREmove" onClick={handleCancel}>
						{t('CANCEL')}
					</Button>,
				]}
			>
				<FormBox
					id="removeVillageAgentForm"
					encType="multipart/formdata"
					key="formbasic"
					form={form}
					onFinish={handleRemove}>
					<Layout style={{ padding: '0 24px' }}>
						<Row wrap={false}>
							<Col span={24} className="form__input__textbox" >
							{villageAgent &&<InputBox.Select
									placeholder={t("SEARCH_GROUP")}
									label={t("SEARCH_GROUP")}
									name="groups"
									options={{
										list:groupList,
										valueKey:"id",
										textKey:"name"
									}}
									mode="multiple"
									dropdownMatchSelectWidth={500}
									dropdownClassName="remove__groupList"
								/>}
								<br/>
								<br/>
							</Col>
						</Row>
					</Layout>
				</FormBox>
			</Modal>
	)
}

export default RemoveVillageAgent;

