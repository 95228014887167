import { RequestProps } from "../config/InterfacesAndTypes";
import Message from "../config/Message";

export const ProfessionRequest = (t: any): RequestProps => ({
	name: [{ required: true, message: Message(t).required(t("NAME")) },
	({ getFieldValue }) => ({
		validator() {
			const name = getFieldValue("name");
			if (name && !name.trim()) {
				return Promise.reject(Message(t).required(t("NAME")));
			}
			return Promise.resolve();
		},
	}),
	],

});
