import { UserOutlined } from "@ant-design/icons";
import { Button, Row, Col, Layout, } from "antd";
import { observer } from "mobx-react";
import React from "react";
import { useTranslation } from "react-i18next";
import { FormBox, InputBox } from "../../../../components/AntdAddons";
import BreadcrumbComponent from "../../../../components/BreadcrumbComponent/BreadcrumbComponent";
import { RedirectLinkBreadcrumb } from "../../../../config/BreadcrumbConfig";
import { RedirectLinkRequest } from "../../../../requests/RedirectLinkRequest";

interface FormDataProps {
	formId:string;
	form:any;
	handleSubmit:(data: any) => void |any;
	saving:boolean;
	isEdit:boolean;
	listRoute:() => void;

}
const FormComponent: React.FC<FormDataProps> = observer(({
		formId,
		form,
		handleSubmit,
		saving,
		listRoute,
	}) => {
	const { t } = useTranslation();
	// useEffect(() => {
	// }, [isEdit,]);

	return (
		<FormBox
			id={formId}
			form={form}
			encType="multipart/formdata"
			onFinish={handleSubmit} key="formbasic">
			<div className="Breadcrumb-bar Breadcrumb-bar-export">
				<BreadcrumbComponent items={RedirectLinkBreadcrumb(t).path} />
			</div>
			<Layout style={{ padding: '0 24px' }}>
				<Row gutter={6} wrap={false}>
					<Col span={8} className="form__input__textbox">
						<InputBox.Text
							size="large"
							placeholder={t("LINK_CAPTION")}
							label={t("LINK_CAPTION")}
							name="link_caption"
							prefix={<UserOutlined />}
							rules={RedirectLinkRequest(t).link_caption} />
					</Col>
					<Col span={8} className="form__input__textbox">
						<InputBox.Text
							size="large"
							placeholder={t("LINK_URL")}
							label={t("LINK_URL")}
							name="link_url"
							prefix={<UserOutlined />}
							rules={RedirectLinkRequest(t).link_url} />
					</Col>
				</Row>
				<Col span={24} className="permission__button ant-row-center ant-row mt-50">
					<Button type="primary"
						htmlType="submit"
						loading={saving}
						key="submit1">{t('SAVE')}</Button>
					<Button type="primary" key="resetCancel" danger onClick={listRoute}>{t('CANCEL')}</Button>
				</Col>
			</Layout>
		</FormBox>
	);
});
export default FormComponent;
