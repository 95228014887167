import { UserOutlined } from "@ant-design/icons";
import { Button, Row, Col, Layout } from "antd";
import { observer } from "mobx-react";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { FormBox, InputBox } from "../../../../../components/AntdAddons";
import BreadcrumbComponent from "../../../../../components/BreadcrumbComponent/BreadcrumbComponent";
import { MeetingBreadcrumb } from "../../../../../config/BreadcrumbConfig";
import { displayFormats } from "../../../../../config/Global";
import { MeetingRequest } from "../../../../../requests/MeetingRequest";

interface FormDataProps {
	formId: string;
	form: any;
	handleSubmit: (data: any) => void | any;
	saving: boolean;
	listRoute: () => void;
	isEdit: boolean;
	groupList: any[];
	meetingDetails: any;
}

const FormComponent: React.FC<FormDataProps> = observer(
	({
		formId,
		form,
		handleSubmit,
		saving,
		listRoute,
		groupList,
		isEdit,
		meetingDetails,
	}) => {
		const [meetingType, setMeetingType] = useState<any>(null);
		const { t } = useTranslation();

		const disableStartDate = (current:any) =>{
			return current && current <= moment(moment().add(24, 'hours').format("YYYY-MM-DD HH:mm"));
		}
	
		const disableEndDate = (current:any) =>{
			return (form.getFieldValue('meeting_start_date_time') ? current && current <  moment(form.getFieldValue('meeting_start_date_time')) : current && current < moment(moment().add(24, 'hours').format("YYYY-MM-DD HH:mm")));
		}

		useEffect(() => {
			meetingDetails && meetingDetails?.type && setMeetingType(meetingDetails.type);
		}, [form, meetingDetails]);

		return (
			<FormBox
				id={formId}
				form={form}
				encType="multipart/formdata"
				onFinish={handleSubmit}
				key="formbasic"
			>
				<div className="Breadcrumb-bar Breadcrumb-bar-export">
					<BreadcrumbComponent items={MeetingBreadcrumb(t).path} />
				</div>
				<Layout style={{ padding: "0 24px" }}>
					<Row gutter={6} wrap={false}>
						<Col span={8} className="form__input__textbox">
							{groupList && (
								<InputBox.Select
									options={{
										list: groupList,
										valueKey: "id",
										textKey: "name",
									}}
									label={t("GROUP")}
									placeholder={t("GROUP")}
									name="group_id"
									rules={MeetingRequest(t).group_id}
									disabled={isEdit}
								/>
							)}
						</Col>
						<Col span={8} className="form__input__textbox">
							<InputBox.Text
								size="large"
								placeholder={t("TITLE")}
								label={t("TITLE")}
								name="title"
								prefix={<UserOutlined />}
								rules={MeetingRequest(t).title}
							/>
						</Col>
					</Row>

					<Row gutter={6} wrap={false}>
						<Col span={8} className="form__input__textbox">
							<InputBox.Select
								options={{
									list: [
										{ id: 1, name: "Online" },
										{ id: 2, name: "Live" },
									],
									valueKey: "id",
									textKey: "name",
								}}
								label={t("MEETING_TYPE")}
								placeholder={t("MEETING_TYPE")}
								name="type"
								rules={MeetingRequest(t).type}
								disabled={isEdit}
								onChange={(id: any) => {
									setMeetingType(id);
								}}
							/>
						</Col>
						{meetingType == 2 && (
							<Col span={8} className="form__input__textbox">
								<InputBox.TextArea
									size="large"
									name="location"
									placeholder={t("LOCATION")}
									prefix={<UserOutlined />}
									label={t("LOCATION")}
									rules={MeetingRequest(t).location}
								/>
							</Col>
						)}
					</Row>
					<Row gutter={6} wrap={false}>
						<Col
							span={8}
							className="form__input__textbox form__input__selectBox"
						>
							<InputBox.TextArea
								label={t("DESCRIPTION")}
								placeholder={t("DESCRIPTION")}
								name="description"
								rules={MeetingRequest(t).description}
							/>
						</Col>
					</Row>
					<Row gutter={6} wrap={false}>
						<Col
							span={8}
							className="form__input__textbox form__input__selectBox"
						>
							<InputBox.DateTimePicker
								label={t("START_DATE_TIME")}
								placeholder={t("START_DATE_TIME")}
								name="meeting_start_date_time"
								rules={MeetingRequest(t).meeting_start_date_time}
								disabledDate={disableStartDate}
								onChange={(e: any) => {form.setFieldsValue({'meeting_start_date_time': moment(e),'meeting_end_date_time': moment(e.add(10, 'minute'))})}}
								format={displayFormats.DATE_TIME_FORMAT}
							/>
						</Col>
						<Col span={8} className="form__input__textbox">
							<InputBox.DateTimePicker
								label={t("END_DATE_TIME")}
								placeholder={t("END_DATE_TIME")}
								name="meeting_end_date_time"
								rules={MeetingRequest(t).meeting_end_date_time}
								disabledDate={disableEndDate}
								format={displayFormats.DATE_TIME_FORMAT}
							/>
						</Col>
					</Row>
					<Col
						span={24}
						className="permission__button ant-row-center ant-row mt-50"
					>
						<Button
							type="primary"
							htmlType="submit"
							loading={saving}
							key="submit1"
						>
							{t("SAVE")}
						</Button>
						<Button type="primary" key="resetCancel" danger onClick={listRoute}>
							{t("CANCEL")}
						</Button>
					</Col>
				</Layout>
			</FormBox>
		);
	}
);
export default FormComponent;
