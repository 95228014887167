import React, { useEffect, useState }  from "react";
import { Button, Row, Col, Layout  } from "antd";
import useStore from "../../../../store";
import { observer } from "mobx-react-lite";
import { FormBox, InputBox } from "../../../../components/AntdAddons";
import BreadcrumbComponent from "../../../../components/BreadcrumbComponent/BreadcrumbComponent";
import { SettingsBreadcrumb } from "../../../../config/BreadcrumbConfig";
import { SettingsFooterRequest } from "../../../../requests/SettingsRequest";
import {  Form } from 'antd';
import { useHistory } from "react-router";
import { useTranslation } from "react-i18next";
// import { uploadedFileOnPreview } from "../../../../config/Global";


const SettingsFooterView: React.FC = observer(() => {
	const { SETTINGS, ROOT } = useStore();
	const { getFooterSection, saveFooterSection, } = SETTINGS;
	const [settings, setSettings] = useState<any>(null)
	const { AssignErrorToInput } = ROOT;
	// const[logoImage, setLogoImage] = useState(null)
	const listRoute = () =>{
		history.push('/settings');
	}
	// const onFileChange = (info:any) =>{
	// 	setLogoImage(info);
	// 	return false;
	// }
	useEffect(()=>{
		getFooterSection().then((data:any) => {
			data ? setSettings(data) : setSettings([]);
			form.resetFields();
			form.setFieldsValue({
				project_name:data?.project_name,
				email:data?.email,
				phone_number:data?.phone_number,
				address:data?.address,
				facebook_url:data?.facebook_url,
				instagram_url:data?.instagram_url,
				twitter_url:data?.twitter_url,
				linkedin_url:data?.linkedin_url,
				google_url:data?.google_url,
				whatsapp_url:data?.whatsapp_url,
			});
	});
	},[getFooterSection,setSettings])
	const [form] = Form.useForm();
	const [saving, setSaving] = useState<boolean>(false);
	const history = useHistory();
	const { t } = useTranslation();

	// Handle submit and call function to save new record
	const handleSubmit = (data: any) => {
		setSaving(true);
		const formData = new FormData();
		// logoImage && formData.append('logo',logoImage);
		data.project_name && formData.append('project_name', data.project_name);
		data.email && formData.append('email', data.email);
		data.phone_number && formData.append('phone_number', data.phone_number);
		data.address && formData.append('address', data.address);
		data.facebook_url && formData.append('facebook_url', data.facebook_url);
		data.instagram_url && formData.append('instagram_url', data.instagram_url);
		data.twitter_url && formData.append('twitter_url', data.twitter_url);
		data.linkedin_url && formData.append('linkedin_url', data.linkedin_url);
		data.google_url && formData.append('google_url', data.google_url);
		data.whatsapp_url && formData.append('whatsapp_url', data.whatsapp_url);
		saveFooterSection(formData)
			.then(() => {
				setSaving(false);
				form.resetFields();
				listRoute();
			})
			.catch((e:any) => {
				setSaving(false);
				const errors = e?.data?.errors || e?.errors
				AssignErrorToInput(form, errors);
			})
	};
	return (
	<>
	<div className="Breadcrumb-bar Breadcrumb-bar-export">
		<BreadcrumbComponent items={SettingsBreadcrumb(t).path} />
	</div>
	{settings && <FormBox
		id="siteManagementForm"
		form={form}
		encType="multipart/formdata"
		onFinish={handleSubmit} key="formbasic">
			<Layout style={{ padding: '0 24px' }}>
				<Row gutter={6} wrap={false}>
					<Col span={16} className="form__input__textbox">
						<InputBox.Text
							size="large"
							placeholder={t("PROJECT_NAME")}
							name="project_name"
							label={t("PROJECT_NAME")}
							rules={SettingsFooterRequest(t).project_name}
						/>
					</Col>
				</Row>
				<Row gutter={6} wrap={false}>
					<Col span={8} className="form__input__textbox">
						<InputBox.Text
							size="large"
							placeholder={t("PHONE_NUMBER")}
							name="phone_number"
							label={t("PHONE_NUMBER")}
							rules={SettingsFooterRequest(t).phone_number}
						/>
					</Col>
					<Col span={8} className="form__input__textbox">
						<InputBox.Text
							size="large"
							placeholder={t("EMAIL")}
							name="email"
							label={t("EMAIL")}
							rules={SettingsFooterRequest(t).email}
						/>
					</Col>
				</Row>
				<Row gutter={6} wrap={false}>
					<Col span={16} className="form__input__textbox form__input__selectBox">
						<InputBox.TextArea
							size="large"
							placeholder={t("ADDRESS")}
							name="address"
							label={t("ADDRESS")}
							rules={SettingsFooterRequest(t).address}  />
					</Col>
				</Row>
				<Row gutter={6} wrap={false}>
					<Col span={8} className="form__input__textbox">
						<InputBox.Text
							size="large"
							placeholder={t("FACEBOOK_URL")}
							name="facebook_url"
							label={t("FACEBOOK_URL")} />
					</Col>
					<Col span={8} className="form__input__textbox">
						<InputBox.Text
							size="large"
							placeholder={t("TWITTER_URL")}
							name="twitter_url"
							label={t("TWITTER_URL")} />
					</Col>
				</Row>
				<Row gutter={6} wrap={false}>
					<Col span={8} className="form__input__textbox">
						<InputBox.Text
							size="large"
							placeholder={t("LINKEDIN_URL")}
							name="linkedin_url"
							label={t("LINKEDIN_URL")} />
					</Col>
					<Col span={8} className="form__input__textbox">
						<InputBox.Text
							size="large"
							placeholder={t("INSTAGRAM_URL")}
							name="instagram_url"
							label={t("INSTAGRAM_URL")} />
					</Col>
				</Row>
				<Row gutter={6} wrap={false}>
					<Col span={8} className="form__input__textbox">
						<InputBox.Text
							size="large"
							placeholder={t("GOOGLE_URL")}
							name="google_url"
							label={t("GOOGLE_URL")} />
					</Col>
					<Col span={8} className="form__input__textbox">
						<InputBox.Text
							size="large"
							placeholder={t("WHATSAPP_URL")}
							name="whatsapp_url"
							label={t("WHATSAPP_URL")} />
					</Col>
				</Row>
				{/* <Row gutter={6} wrap={false}>
					<Col span={16} className="form__input__textbox form__input__textbox__logo">
						<Upload
							name="logo"
							accept="image/png, image/jpeg"
							listType="picture"
							defaultFileList={[
								{
									uid: '1daf',
									name: settings?.project_name,
									status: 'done',
									response: 'Server Error 500', // custom error message to show
									url: settings?.logo,
									thumbUrl: settings?.logo,
								}
							]}
							maxCount={1}
							beforeUpload={onFileChange}
							onPreview={uploadedFileOnPreview}
						>
							<Button className="mb-20">{t("CLICK_TO_UPLOAD_IMAGE")}</Button>
						</Upload>
					</Col>
				</Row> */}
				<Col span={24} className="permission__button ant-row-center ant-row mt-50">
					<Button type="primary"
						htmlType="submit"
						loading={saving}
						key="submit1">{t('SAVE')}</Button>
					<Button type="primary" key="resetCancel" danger onClick={listRoute}>{t('CANCEL')}</Button>
				</Col>
			</Layout>
		</FormBox>}
		</>
	);
});

export default SettingsFooterView;
