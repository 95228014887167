import React, { useEffect, useState } from "react";
import { observer } from "mobx-react";
import { Form } from 'antd';
import useStore from "../../../../store";
import { useHistory, useParams } from "react-router";
import FormComponent from "./FormComponent";

const EditRedirectLinkComponent: React.FC = observer(() => {
	const { REDIRECT_LINK, ROOT } = useStore();
	const {
		update,
		details,
	} = REDIRECT_LINK;

	const [RedirectLink, setRedirectLink] = useState<any>([])
	const { id }: any = useParams();
	const { AssignErrorToInput } = ROOT;
	useEffect(() => {
		details(id).then((data: any) => {
			setRedirectLink(data);
			form.resetFields();
			form.setFieldsValue({
				link_caption: data?.link_caption,
				link_url: data?.link_url,
			});
		});
	}, [details])
	const [form] = Form.useForm();
	const [saving, setSaving] = useState<boolean>(false);
	const history = useHistory();
	const listRoute = () => {
		history.push('/settings/redirect-link');
	}

	// Handle submit and call function to save new record
	const handleSubmit = (data: any) => {
		setSaving(true);
		const formData = new FormData();
		data.link_caption && formData.append('link_caption', data.link_caption);
		data.link_url && formData.append('link_url', data.link_url);
		formData.append('link_type', '1');
		update(RedirectLink.id, formData)
			.then(() => {
				setSaving(false);
				form.resetFields();
				listRoute();
			})
			.catch((e: any) => {
				setSaving(false);
				AssignErrorToInput(form, e?.data?.errors);
			})
	};
	return (
		<FormComponent
			formId="editRedirectLinkForm"
			form={form}
			handleSubmit={handleSubmit}
			saving={saving}
			isEdit={true}
			listRoute={listRoute}

		/>
	);
});
export default EditRedirectLinkComponent;
