import React from "react";
import { UserAddOutlined } from "@ant-design/icons";
import { Button, Space } from "antd";
import { useTranslation } from "react-i18next";

interface HeaderButtonsProps {
	addUserRedirect: () => void;
	addPermission : boolean;
}
const HeaderButtons: React.FC<HeaderButtonsProps> = ({addUserRedirect, addPermission}) => {
	const { t } = useTranslation();
	return (
		<Space hidden={!addPermission}>
			<Button type="primary" style={{ background: "orange" }} icon={<UserAddOutlined />} onClick={addUserRedirect}>
				{t("ADD_NEW_USER")}
			</Button>
		</Space>
	);
};

export default HeaderButtons;
