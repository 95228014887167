import React from "react";
import { AgGridColumn } from "ag-grid-react";
import moment from "moment";
import AgGridWrapper from "../../../../../components/AgGridWrapper/AgGridWrapper";
import Config from "../../../../../config/Config";
import DateComparator from "../../../../../utils/DateComparator";
import { GridReadyEvent } from "ag-grid-community";
import { EyeOutlined, EditOutlined } from "@ant-design/icons";
import { Button} from "antd";
import { useTranslation } from "react-i18next";
import { displayFormats } from "../../../../../config/Global";

interface ListProps {
	setupGrid: (params: GridReadyEvent) => void;
	editRedirect:(id:number) => void;
	editPermission:boolean;
	detailPermission:boolean;
	detailsRedirect:(id:number) => void;
}

const ListComponent: React.FC<ListProps> = ({
	setupGrid,
	editRedirect,
	editPermission,
	detailPermission,
	detailsRedirect,
}) => {
	const { t } = useTranslation();

	const ActionRenderer = (params: any) => {
		return (
			<div className="action-column">
				<Button
					type="text"
					title={t("EDIT")}
					icon={<EditOutlined />}
					onClick={() => editRedirect(params.data?.id)}
					hidden={!editPermission || (params.data.accountant_approve_status==1 || params.data.president_approve_status==1 || params.data.secretary_approve_status==1)}
				></Button>
				<Button
					type="text"
					title={t("DETAILS")}
					icon={<EyeOutlined />}
					onClick={() => detailsRedirect(params.data?.id)}
					hidden={!detailPermission}
				></Button>
			</div>
		);
	};

	return (
		<AgGridWrapper
			type="serverSide"
			onGridReady={setupGrid}
			rowSelection={"multiple"}
			frameworkComponents={{ ActionRenderer}}
			overlayLoadingTemplate={t("PLEASE_WAIT_WHILE_DATA_IS_LOADING")} overlayNoRowsTemplate={t("NO_RECORD_FOUND")}
		>
			<AgGridColumn
			field="id"
				headerName={"# " + t('ID')} width={150}
				pinned={"left"}
				sortable={false}
				filter= {'agNumberColumnFilter'}
			/>
			<AgGridColumn
				field="group_name"
				headerName={t('GROUP_NAME')}
			/>
			<AgGridColumn
				field="user_name"
				headerName={t("MEMBER_NAME")}
			/>
			<AgGridColumn
				field="loan_payment_mode"
				headerName={t("PAYMENT_MODE")}
				filter= {'agTextColumnFilter'}
			/>
			<AgGridColumn
				field="created_at"
				headerName={t("DATE")}
				valueGetter={({ data }) =>
					data.created_at &&
					moment(data.created_at).format(Config.dateTimeFormat)
				}
				filter={"agDateColumnFilter"}
				valueFormatter={(data:any) =>
					data?.data?.created_at && moment.utc(data?.data?.created_at).local().format(displayFormats.DATE_TIME_FORMAT)
				}
				filterParams={{
					comparator: DateComparator,
				}}
			/>
			<AgGridColumn
				field="number_of_installments"
				headerName={t("DURATION") + " (" + t("IN_MONTHS")}
				filter= {'agNumberColumnFilter'}
			/>

			<AgGridColumn
				field="interest_rate"
				headerName={t("INTEREST_RATE") + "(%)"}
				filter= {'agNumberColumnFilter'}
			/>
			<AgGridColumn
				field="loan_amount"
				headerName={t("LOAN_AMOUNT")}
				filter= {'agNumberColumnFilter'}
			/>
			<AgGridColumn
				field="repay_amount"
				headerName={t("REPAY_AMOUNT")}
				filter= {'agNumberColumnFilter'}
			/>
			<AgGridColumn
				field="display_approved_status"
				headerName={t("STATUS")}
			/>
			<AgGridColumn
				field="updated_at"
				headerName={t("UPDATED_AT")}
				valueGetter={({ data }) =>
					data.updated_at &&
					moment(data.updated_at).format(Config.dateTimeFormat)
				}
				filter={"agDateColumnFilter"}
				valueFormatter={(data:any) =>
					data?.data?.updated_at && moment.utc(data?.data?.updated_at).local().format(displayFormats.DATE_TIME_FORMAT)
				}
				filterParams={{
					comparator: DateComparator,
				}}
			/>
			<AgGridColumn
				headerName={t('ACTION')}
				type="actionColumn"
				sortable={false}
				filter={false}
				width={120}
				pinned={"right"}
			/>
		</AgGridWrapper>
	);
};

export default ListComponent;
