import moment from "moment";
import { RequestProps } from "../config/InterfacesAndTypes";
import Message from "../config/Message";

export const DonationCampaignRequest = (t: any): RequestProps => ({
	name: [
		{ required: true, message: Message(t).required(t("CAMPAIGN_NAME")) },
		{ min: 3, message: Message(t).min(3, t('CAMPAIGN_NAME')) },
		{ max: 50, message: Message(t).max(50, t('CAMPAIGN_NAME')) },
		({ getFieldValue }) => ({
			validator() {
				const name = getFieldValue("name");
				if (name && !name.trim()) {
					return Promise.reject(Message(t).required(t('CAMPAIGN_NAME')));
				}
				return Promise.resolve();
			},
		}),
	],
	group_id: [{ required: true, message: Message(t).required(t("GROUP_NAME")) }],
	donation_subject: [{ required: true, message: Message(t).required(t("DONATION_SUBJECT")) }],
	start_date_time: [{ required: true, message: Message(t).required(t("START_DATE_TIME")) }],
	end_date_time: [
		{ required: true, message: Message(t).required(t("END_DATE_TIME")) },
		({ getFieldValue }) => ({
			validator() {
				if (moment(getFieldValue("start_date_time")) >= moment(getFieldValue("end_date_time"))) {
					return Promise.reject(t("END_DATE_TIME_SHOULD_GRATER_THAN_START_DATE_TIME"));
				}
				return Promise.resolve();
			}
		}),
	],
});
