import { Tabs } from "antd";
import { observer } from "mobx-react";
import React, { useEffect, useState } from "react";
import { useHistory } from "react-router";
import BreadcrumbComponent from "../../../../components/BreadcrumbComponent/BreadcrumbComponent";
import ContentBox from "../../../../components/ContentBox/ContentBox";
import { MeetingBreadcrumb } from "../../../../config/BreadcrumbConfig";
import useStore from "../../../../store";
import DeleteMeetingComponent from "./components/DeleteComponent";
import HeaderButtons from "./components/HeaderButtons";
import ListComponent from "./components/ListComponent";
import RecordPerPage from "../../../../utils/RecordPerPage";
import { useTranslation } from "react-i18next";

const MeetingView: React.FC = observer(() => {
	const { t } = useTranslation();
	const { MEETING, AUTH, COMMON } = useStore();
	const { setupGrid, remove, openDeleteMeetingPopup, closeDeleteMeetingPopup, deleteMeetingPopup, deleteMeetingID, meetingType, changeMeetinType,setPageSize, perPage} = MEETING;
	const { permissions } = AUTH;
	const pathHistory = useHistory();
	const { TabPane } = Tabs;
	const [isMeeting, setIsMeeting] = useState<boolean>(false);
	const {allPermissionList} = COMMON

	useEffect(()=>{
		//
	}, [allPermissionList]);

	const addRedirect= () => {
		pathHistory.push('meeting-management/create');
	}

	const editRedirect= (id:number) => {
		pathHistory.push('meeting-management/'+id+'/edit');
	}

	const meetingDetails = (id:number) => {
		pathHistory.push('meeting-management/'+id+'/details');
	}

	const setMeetingType = (key:any) => {
		changeMeetinType(key);
	}

	useEffect(() => {
		if(!isMeeting) {
			setMeetingType(1);
			setIsMeeting(true);
		}
	}, [isMeeting, setIsMeeting, setMeetingType]);

  return (
		<>
			<div className="Breadcrumb-bar">

				<BreadcrumbComponent items={MeetingBreadcrumb(t).path} />
				<HeaderButtons
					key={1}
					addRedirect={addRedirect}
					addPermission={permissions && allPermissionList && permissions.indexOf(allPermissionList.ADD_MEETING) >= 0}
				/>
			</div>

			<ContentBox>
				<DeleteMeetingComponent
					DeleteID={deleteMeetingID}
					DeleteData={remove}
					visible={deleteMeetingPopup}
					closeDeletePopup={closeDeleteMeetingPopup}
				/>
				<Tabs  defaultActiveKey={"1"} onChange={setMeetingType} centered >
					<TabPane tab={t("UPCOMING_MEETING")} key="1" active={meetingType ==1} >
						<div className="RecordPerPage__right">
							<RecordPerPage
								style={{ width: "150px" }}
								defaultValue={perPage + " per page"}
								onChange={setPageSize}
							/>
						</div>
						<ListComponent
							setupGrid={setupGrid}
							editRedirect={editRedirect}
							editPermission={permissions && allPermissionList && permissions.indexOf(allPermissionList.EDIT_MEETING) >=0}
							detailPermission={permissions && allPermissionList && permissions.indexOf(allPermissionList.DELETE_MEETING) >=0}
							detailsRedirect={meetingDetails}
							openDeletePopup={openDeleteMeetingPopup}
							deletePermission={permissions && allPermissionList && permissions.indexOf(allPermissionList.DELETE_MEETING)}
						/>
					</TabPane>
					<TabPane tab={t("PAST_MEETING")} key="2" active={meetingType ==2}>
						<div className="RecordPerPage__right">
							<RecordPerPage
								style={{ width: "150px" }}
								defaultValue={perPage + " per page"}
								onChange={setPageSize}
							/>
						</div>
						<ListComponent
							setupGrid={setupGrid}
							editRedirect={editRedirect}
							editPermission={permissions && allPermissionList && permissions.indexOf(allPermissionList.EDIT_MEETING) >=0}
							detailPermission={permissions && allPermissionList && permissions.indexOf(allPermissionList.DELETE_MEETING) >=0}
							detailsRedirect={meetingDetails}
							openDeletePopup={openDeleteMeetingPopup}
							deletePermission={permissions && allPermissionList && permissions.indexOf(allPermissionList.DELETE_MEETING)}
						/>
					</TabPane>
				</Tabs>


			</ContentBox>
		</>
	);
});

export default MeetingView;
