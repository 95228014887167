const API_URL = {
	ADMIN_LOGIN: "admin/login",
	FORGET_PASSWORD: "admin/forget-password",
	RESET_PASSWORD_OTP: (otp: string): string =>
	"admin/" + otp +"/reset-password",
	REGISTER_WITH_EMAIL: "register",
	ME: "admin/me",
	LOGOUT: "admin/logout",
	VERIFY_ACCOUNT: (verificationCode: string): string =>
		"verify-email/" + verificationCode,
	RESEND_VERIFICATION_LINK: (email: string): string =>
		"resend-verification-code/" + btoa(email),
	ADMIN_FORGOT_PASSWORD: "admin/forget-password",
	FORGOT_PASSWORD: "forget-password",
	SEND_RESET_PASSWORD_LINK: (id: number): string =>  "admin/" + (id) + "/send-reset-password-link",
	CHANGE_PASSWORD: "admin/change-password",
	FAQ_CATEGORY_LIST: "faq-category-list",
	PROFESSION_LIST: "profession-list",
	LANGUAGE_LIST: "language-list",
	CURRENCY_LIST: "currency-list",
	ROLE_LIST: "role-list",
	USER_LIST: "user-list",
	GROUP_LIST: "group-list",
	FINE_TYPE_LIST: "fine-type-list",
	DONATION_SUBJECT_LIST:"donation-subject-list",
	GROUP_USER_LIST: (id: number): string =>  (id) + "/group-user-list",
	CONFEDERATION_LIST: "confederation-list",
	ELECTION_REFEREE_LIST:"admin/election-referee/list",
	DASHBOARD:"admin/dashboard",
	SYNC: "sync",
	LANGUAGE_DATA: (local_key: string): string => "language/" + local_key,
	DONATION_FORM_DATA:"donation/form-data",
	USER: {
		LIST: "admin/user",
		CREATE: "admin/user/create",
		DETAILS: (id: number): string => "admin/user/" + id + "/details",
		UPDATE: (id: number): string => "admin/user/" + id + "/update",
		DELETE: (id: number): string => "admin/user/" + id + "/delete",
		CHANGE_STATUS: (id: number): string =>
			"admin/user/" + id + "/change-status",
		UPDATE_MY_LANGUAGE: "admin/user/update-my-language",
		REPORT_LIST: "admin/report/list",
	},

	TRANSACTION: {
		LIST: "admin/transaction",
		DETAILS: (id: string): string => "admin/transaction/" + id + "/details",
	},

	PROFESSION: {
		LIST: "admin/profession",
		CREATE: "admin/profession/create",
		DETAILS: (id: number): string => "admin/profession/" + id + "/details",
		UPDATE: (id: number): string => "admin/profession/" + id + "/update",
		DELETE: (id: number): string => "admin/profession/" + id + "/delete",
		CHANGE_STATUS: (id: number): string =>
			"admin/profession/" + id + "/change-status",
	},

	ROLE: {
		LIST: "admin/role",
		CREATE: "admin/role/create",
		PERMISSION_LIST: "admin/role/permissionlist",
		DETAILS: (id: number): string => "admin/role/" + id + "/details",
		UPDATE: (id: number): string => "admin/role/" + id + "/update",
		DELETE: (id: number): string => "admin/role/" + id + "/delete",
		CHANGE_STATUS: (id: number): string =>
			"admin/role/" + id + "/change-status",
	},

	FAQ_CATEGORY: {
		LIST: "admin/faq-category",
		CREATE: "admin/faq-category/create",
		DETAILS: (id: number): string => "admin/faq-category/" + id + "/details",
		UPDATE: (id: number): string => "admin/faq-category/" + id + "/update",
		DELETE: (id: number): string => "admin/faq-category/" + id + "/destroy",
		CHANGE_STATUS: (id: number): string =>
			"admin/faq-category/" + id + "/change-status",
	},

	FAQ: {
		LIST: "admin/faq",
		CREATE: "admin/faq/create",
		DETAILS: (id: number): string => "admin/faq/" + id + "/details",
		UPDATE: (id: number): string => "admin/faq/" + id + "/update",
		DELETE: (id: number): string => "admin/faq/" + id + "/delete",
		CHANGE_STATUS: (id: number): string => "admin/faq/" + id + "/change-status",
	},

	GROUP: {
		LIST: "admin/group",
		CREATE: "admin/group/create",
		DETAILS: (id: number): string => "admin/group/" + id + "/details",
		UPDATE: (id: number): string => "admin/group/" + id + "/update",
		DELETE: (id: number): string => "admin/group/" + id + "/delete",
		CHANGE_STATUS: (id: number): string => "admin/group/" + id + "/change-publish-status",
		GROUP_FINANCE_MANAGEMENT:"admin/group-finance-management",
	},

	FINE: {
		LIST: "admin/fine",
		CREATE: "admin/fine/create",
		DETAILS: (id: number): string => "admin/fine/" + id + "/details",
		UPDATE: (id: number): string => "admin/fine/" + id + "/update",
		DELETE: (id: number): string => "admin/fine/" + id + "/delete",
		CHANGE_STATUS: (id: number): string => "admin/fine/" + id + "/change-status",
	},

	FINE_TYPE: {
		LIST: "admin/fine-type",
		CREATE: "admin/fine-type/create",
		DETAILS: (id: number): string => "admin/fine-type/" + id + "/details",
		UPDATE: (id: number): string => "admin/fine-type/" + id + "/update",
		DELETE: (id: number): string => "admin/fine-type/" + id + "/delete",
		CHANGE_STATUS: (id: number): string => "admin/fine-type/" + id + "/change-status",
	},

	CMS: {
		LIST: "admin/cms",
		DETAILS: (id: number): string => "admin/cms/" + id + "/details",
		UPDATE: (id: number): string => "admin/cms/" + id + "/update",
		CHANGE_STATUS: (id: number): string => "admin/cms/" + id + "/change-status",
	},

	TEMPLATE: {
		LIST: "admin/template",
		DETAILS: (id: number): string => "admin/template/" + id + "/details",
		UPDATE: (id: number): string => "admin/template/" + id + "/update",
		CHANGE_STATUS: (id: number): string => "admin/template/" + id + "/change-status",
	},

	DONATIONSUBJECT: {
		LIST:"admin/donation-subject",
		CREATE:"admin/donation-subject/create",
		DETAILS:(id: number): string => "admin/donation-subject/" + id + "/details",
		UPDATE:(id: number): string => "admin/donation-subject/" + id + "/update",
		DELETE:(id: number): string =>"admin/donation-subject/" + id + "/delete",
		CHANGE_STATUS:(id: number): string => "admin/donation-subject/" + id + "/change-status",
	},
	DONATION: {
		LIST:"admin/donation",
		CREATE:"admin/donation/create",
		DETAILS:(id: number): string => "admin/donation/" + id + "/details",
		UPDATE:(id: number): string => "admin/donation/" + id + "/update",
		DELETE:(id: number): string =>"admin/donation/" + id + "/delete",
		CHANGE_STATUS:(id: number): string => "admin/donation/" + id + "/change-status",
	},
	DONATION_CAMPAIGN: {
		LIST:"admin/donation-campaign",
		CREATE:"admin/donation-campaign/create",
		DETAILS:(id: number): string => "admin/donation-campaign/" + id + "/details",
		UPDATE:(id: number): string => "admin/donation-campaign/" + id + "/update",
		DELETE:(id: number): string =>"admin/donation-campaign/" + id + "/delete",
		CHANGE_STATUS:(id: number): string => "admin/donation-campaign/" + id + "/change-status",
	},

	WALLET: {
		USER:"admin/wallet/user",
		GROUP:"admin/wallet/group",
		DETAILS:(id: number): string =>"admin/wallet/" + id + "/details",
	},

	LOAN: {
		LIST:"admin/user-loan",
		CREATE:"admin/user-loan/create",
		DETAILS:(id: number): string =>"admin/user-loan/" + id + "/details",
		UPDATE:(id: number): string =>"admin/user-loan/" + id + "/update",
		DELETE:(id: number): string =>"admin/user-loan/" + id + "/delete",
	},

	MEETING: {
		LIST:"admin/meeting",
		CREATE:"admin/meeting/create",
		DETAILS:(id: number): string =>"admin/meeting/" + id + "/details",
		UPDATE:(id: number): string =>"admin/meeting/" + id + "/update",
		DELETE:(id: number): string =>"admin/meeting/" + id + "/delete",
	},

	LOAN_DISBURSEMENT: {
		LIST:"admin/loan-disbursement/list",
		DETAILS:(id: number): string =>"admin/loan-disbursement/" + id + "/details"
	},

	GROUP_EXPENSE: {
		LIST: "admin/group-expense",
		DETAILS: (id: string): string => "admin/group-expense/" + id + "/details",
	},

	BANNER: {
		LIST:"admin/banner",
		DETAILS:(id: number): string =>"admin/banner/" + id + "/details",
		UPDATE:(id: number): string =>"admin/banner/" + id + "/update",
		DELETE:(id: number): string =>"admin/banner/" + id + "/delete",
		CHANGE_STATUS:(id: number): string => "admin/banner/" + id + "/change-status",
	},

	SLIDER: {
		LIST:"admin/slider",
		CREATE:"admin/slider/create",
		DETAILS:(id: number): string =>"admin/slider/" + id + "/details",
		UPDATE:(id: number): string =>"admin/slider/" + id + "/update",
		DELETE:(id: number): string =>"admin/slider/" + id + "/delete",
		CHANGE_STATUS:(id: number): string => "admin/slider/" + id + "/change-status",
	},

	NEWS: {
		LIST:"admin/news",
		CREATE:"admin/news/create",
		DETAILS:(id: number): string =>"admin/news/" + id + "/details",
		UPDATE:(id: number): string =>"admin/news/" + id + "/update",
		DELETE:(id: number): string =>"admin/news/" + id + "/delete",
		CHANGE_STATUS:(id: number): string => "admin/news/" + id + "/change-status",
	},

	VILLAGE_AGENT: {
		LIST:"admin/village-agent/list",
		DETAILS:(id: string): string =>"admin/village-agent/" + id + "/details",
		ASSIGN_VILLAGE_AGENT_TO_GROUP:"admin/group/assign-village-agent",
		REMOVE_VILLAGE_AGENT_FROM_GROUP:"admin/group/remove-village-agent"
	},

	FOOTER_SECTION: {
		DETAILS:"admin/footer-section",
		SAVE:"admin/footer-section/save",
	},

	REDIRECT_LINK: {
		LIST:"admin/redirect-link",
		CREATE:"admin/redirect-link/create",
		DETAILS:(id: number): string =>"admin/redirect-link/" + id + "/details",
		UPDATE:(id: number): string =>"admin/redirect-link/" + id + "/update",
		DELETE:(id: number): string =>"admin/redirect-link/" + id + "/delete",
		CHANGE_STATUS:(id: number): string => "admin/redirect-link/" + id + "/change-status",
	},

	ELECTION_REFEREE: {
		LIST:"admin/election-referee/list",
		DETAILS:(id: string): string =>"admin/election-referee/" + id + "/details"
	},

	ELECTION: {
		LIST:"admin/election",
		DETAILS:(id: string): string =>"admin/election/" + id + "/details"
	},
	NEW_ELECTION: {
		LIST:"admin/election/new-election-list",
	},
	ASSIGN_ELECTION_REFREE:{
		ASSIGN_REFREE:"admin/election/assign-to-election-refree"
	},
	SETTING: {
		DETAILS:(key: string): string =>"admin/setting/" + key + "/details",
		SAVE:(key: string): string =>"admin/setting/" + key + "/save",
		CHANGE_DEFAULT_LANGUAGE:"admin/setting/change-default-language",
	},
	CONFEDERATION: {
		LIST: "admin/confederation",
		CREATE: "admin/confederation/create",
		DETAILS: (id: number): string => "admin/confederation/" + id + "/details",
		UPDATE: (id: number): string => "admin/confederation/" + id + "/update",
		DELETE: (id: number): string => "admin/confederation/" + id + "/delete",
		CHANGE_STATUS: (id: number): string =>
			"admin/confederation/" + id + "/change-status",
	},
	TRASLATION: {
		LIST:"admin/translation",
		CREATE:"admin/translation/create",
		DETAILS:(id: number): string =>"admin/translation/" + id + "/details",
		UPDATE:(id: number): string =>"admin/translation/" + id + "/update",
	},

	HOME_PAGE_CONTENT: {
		LIST: "admin/home-page-content",
		CREATE: "admin/home-page-content/create",
		DETAILS: (id: number): string => "admin/home-page-content/" + id + "/details",
		UPDATE: (id: number): string => "admin/home-page-content/" + id + "/update",
		CHANGE_STATUS: (id: number): string => "admin/home-page-content/" + id + "/change-status",
	},
	IMPORT_DATA:{
		CREATE:"/admin/import"
	}
};

export default API_URL;
