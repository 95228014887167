import { RequestProps } from "../config/InterfacesAndTypes";
import Message from "../config/Message";

export const SettingsFooterRequest = (t: any): RequestProps => ({
	project_name: [{ required: true, message: Message(t).required(t("PROJECT_NAME")) }],
	phone_number: [{ required: true, message: Message(t).required(t("PHONE_NUMBER")) }],
	email: [{ required: true, message: Message(t).type.email(t("EMAIL")) }],
	address: [{ required: true, message: Message(t).required(t("ADDRESS")) }],
	footer_description: [{ required: true, message: Message(t).required(t("FOOTER_DESCRIPTION")) }],
});

export const SmtpRequest = (t: any): RequestProps => ({
	mail_mailer: [{ required: true, message: Message(t).required(t("MAILER")) }],
	mail_host: [{ required: true, message: Message(t).required(t("HOST")) }],
	mail_port: [{ required: true, message: Message(t).type.email(t("PORT")) }],
	mail_username: [
		{ required: true, message: Message(t).required(t("EMAIL")) },
	],
	mail_password: [{ required: true, message: Message(t).required(t("PASSWORD")) }],
	mail_encryption: [{ required: true, message: Message(t).required(t("ENCRYPTION")) }],
	mail_from_address: [
		{ required: true, message: Message(t).required(t("MAIL_FROM_ADDRESS")) },
		{ type: "email", message: Message(t).type.email(t("MAIL_FROM_ADDRESS")) },
	],
	mail_from_name: [{ required: true, message: Message(t).required(t("MAIL_FROM_NAME")) }],
});

export const SmsRequest = (t: any): RequestProps => ({
	sid: [{ required: true, message: Message(t).required(t("SID")) }],
	auth_token: [{ required: true, message: Message(t).required(t("AUTH_TOKEN")) }],
	phone_number: [{ required: true, message: Message(t).required(t("PHONE_NUMBER")) },],
	message_services_id: [{ required: true, message: Message(t).required(t("MESSAGE_SERVICES_ID")) },],
});

export const HomePageSettingRequest = (t: any): RequestProps => ({
	title: [{ required: true, message: Message(t).required(t("TITLE")) }],
});

export const AlIzzaPaymentGatewayRequest = (t: any): RequestProps => ({
	api_base_url: [{ required: true, message: Message(t).required(t("API_BASE_URL")) }],
	phone_number: [{ required: true, message: Message(t).required(t("PHONE_NUMBER")) }],
	account_pin: [{ required: true, message: Message(t).required(t("ACCOUNT_PIN")) }],
	transaction_pin: [{ required: true, message: Message(t).required(t("TRANSACTION_PIN")) },],
});

export const MTNInterpayAfricaPaymentGatewayRequest = (t: any): RequestProps => ({
	api_base_url: [{ required: true, message: Message(t).required(t("API_BASE_URL")) }],
	merchant_mobile: [{ required: true, message: Message(t).required(t("MERCHANT_MOBILE")) }],
	merchant_id: [{ required: true, message: Message(t).required(t("MERCHANT_ID")) }],
	app_id: [{ required: true, message: Message(t).required(t("APP_ID")) }],
	app_key: [{ required: true, message: Message(t).required(t("APP_KEY")) },],
});
