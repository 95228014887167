import React, { useEffect, useState }  from "react";
import { observer } from "mobx-react";
import { Form } from 'antd';
import useStore from "../../../../store";
import { useHistory } from "react-router";
import FormComponent from "./FormComponent";

const AddDonationComponent: React.FC = observer(() => {
	const { DONATION, COMMON } = useStore();
	const {
		create,
		donationList
	} = DONATION;

	const {
		donationFormData, 
		getDonationFormData, 
		selectedLanguage
	} = COMMON;

	const { ROOT } = useStore();
	const { AssignErrorToInput } = ROOT;
	const [form] = Form.useForm();
	const [saving, setSaving] = useState<boolean>(false);
	const [showGroupList, setShowGroupList] = useState<boolean>(false);
	const [donationSubjectList, setDonationSubjectList] =  useState<boolean>();
	const [countryList, setCountryList] =  useState<boolean>();
	const [groupList, setGroupList] =  useState<boolean>();
	const history = useHistory();

	useEffect(()=>{
		selectedLanguage && !donationFormData && getDonationFormData();
		selectedLanguage && donationFormData && setDonationSubjectList(donationFormData.donationSubjectList);
		selectedLanguage && donationFormData && setCountryList(donationFormData.countryList);
		selectedLanguage && donationFormData && setGroupList(donationFormData.groupList);
		form.setFieldsValue({donation_type: 1});
	},[selectedLanguage, donationFormData]);

	const listRoute = () =>{
		history.push('/donation');
  }
	// Handle submit and call function to save new record
	const handleSubmit = (data: any) => {
		setSaving(true);
		const formData = new FormData();
		data.name && formData.append('name', data.name);
		data.address && formData.append('address', data.address);
		data.amount && formData.append('amount', data.amount);
		data.organisation && formData.append('organisation', data.organisation);
		data.donation_type && formData.append('donation_type', data.donation_type);
		data.group_id && formData.append('group_id', data.group_id);
		data.phone_number && formData.append('phone_number', data.phone_number);
		data.donation_subject && formData.append('donation_subject', data.donation_subject);
		data.email && formData.append('email', data.email);
		data.zipcode && formData.append('zipcode', data.zipcode);
		data.country_id && formData.append('country_id', data.country_id);
		create(formData)
			.then(() => {
				setSaving(false);
				form.resetFields();
				listRoute();
			})
			.catch((e:any) => {
				setSaving(false);
				const errors = e?.data?.errors || e?.errors
				AssignErrorToInput(form, errors);
			})
	};

	return (
	<FormComponent
		formId="addDonationForm"
		form={form}
		handleSubmit={handleSubmit}
		saving={saving}
		groupList={groupList}
		donationSubjectList={donationSubjectList}
		isEdit={false}
		listRoute={listRoute}
		donationList={donationList}
		countryList={countryList}
		showGroupList={showGroupList}
		setShowGroupList={setShowGroupList}
	/>
	);
});
export default AddDonationComponent;
