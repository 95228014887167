import React, { useEffect, useState }  from "react";
import { observer } from "mobx-react";
import { Form } from 'antd';
import useStore from "../../../../store";
import { useHistory } from "react-router";
import FormComponent from "./FormComponent";
import moment from "moment";

const AddDonationCampaignComponent: React.FC = observer(() => {
	const { DONATION_CAMPAIGN, COMMON } = useStore();
	const {
		create,
		donationList
	} = DONATION_CAMPAIGN;

	const {
		donationFormData, getDonationFormData,  selectedLanguage
	} = COMMON;

	const { ROOT } = useStore();
	const { AssignErrorToInput } = ROOT;
	const [form] = Form.useForm();
	const [saving, setSaving] = useState<boolean>(false);
	const [showGroupList, setShowGroupList] =  useState<boolean>(false);
    const [donationSubjectList, setDonationSubjectList] =  useState<boolean>();
    const [groupList, setGroupList] =  useState<boolean>();
	const history = useHistory();

	useEffect(()=>{
        selectedLanguage && !donationFormData && getDonationFormData();
		selectedLanguage && donationFormData && setDonationSubjectList(donationFormData.donationSubjectList);
        selectedLanguage && donationFormData && setGroupList(donationFormData.groupList);
		form.setFieldsValue({donation_type: 1});
	},[selectedLanguage, donationFormData]);

	const listRoute = () =>{
		history.push('/donation-campaign');
  }
	// Handle submit and call function to save new record
	const handleSubmit = (data: any) => {
		setSaving(true);
		const formData = new FormData();
		data.name && formData.append('name', data.name);
		data.group_id && formData.append('group_id', data.group_id);
		data.donation_subject && formData.append('donation_subject', data.donation_subject);
		data.donation_type && formData.append('donation_type', data.donation_type);		
		data.start_date_time && formData.append('start_date_time', moment(data.start_date_time).utc().format('YYYY-MM-DD HH:mm:ss'));		
		data.end_date_time && formData.append('end_date_time', moment(data.end_date_time).utc().format('YYYY-MM-DD HH:mm:ss'));		
		
		create(formData)
			.then(() => {
				setSaving(false);
				form.resetFields();
				listRoute();
			})
			.catch((e:any) => {
				setSaving(false);
				AssignErrorToInput(form, e?.data?.errors);
			})
	};

	return (
	<FormComponent
		formId="addDonationCampaignForm"
		form={form}
		handleSubmit={handleSubmit}
		saving={saving}			
		listRoute={listRoute}
		donationList={donationList}
		donationType={false}
		donationSubjectList={donationSubjectList}
		groupList={groupList}
		showGroupList={showGroupList}
		setShowGroupList={setShowGroupList}
		
	/>
	);
});
export default AddDonationCampaignComponent;
