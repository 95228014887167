import React, { useEffect } from "react";
import { observer } from "mobx-react";
import BreadcrumbComponent from "../../../../components/BreadcrumbComponent/BreadcrumbComponent";
import ContentBox from "../../../../components/ContentBox/ContentBox";
import { FaqBreadcrumb } from "../../../../config/BreadcrumbConfig";
import useStore from "../../../../store";
import HeaderButtons from "./components/HeaderButtons";
import ListComponent from "./components/ListComponent";
import AddComponent from "./components/AddComponent";
import EditComponent from "./components/EditComponent";
import DeleteComponent from "./components/DeleteComponent";
import { useTranslation } from "react-i18next";

const FaqView: React.FC = observer(() => {
	const { t } = useTranslation();
	const { FAQ, COMMON, AUTH } = useStore();
	const {
		listData,
		create,
		update,
		details,
		remove,
		fetchList,
		changeStatus,
		addFaqPopup,
		openAddFaqPopup,
		closeAddFaqPopup,
		editFaqID,
		editFaqPopup,
		openEditFaqPopup,
		closeEditFaqPopup,
		deleteFaqID,
		deleteFaqPopup,
		openDeleteFaqPopup,
		closeDeleteFaqPopup,
	} = FAQ;

	const {permissions} = AUTH
	const {faqCategoryList, fetchFaqCategory} = COMMON
	const {allPermissionList} = COMMON

	useEffect(()=>{
		//
	}, [allPermissionList]);
	return (
		<>
		<div className="Breadcrumb-bar">
			<BreadcrumbComponent items={FaqBreadcrumb(t).path} />
			<HeaderButtons
						key={1}
						openAddFaqPopup={openAddFaqPopup}
						addPermission={permissions && allPermissionList && permissions.indexOf(allPermissionList.ADD_FAQ) >=0}
					/>
			</div>

			<ContentBox>
				<ListComponent
					rowData={listData}
					getData={fetchList}
					openEditFaqPopup={openEditFaqPopup}
					openDeleteFaqPopup={openDeleteFaqPopup}
					ChangeStatus={changeStatus}
					editPermission={permissions && allPermissionList && permissions.indexOf(allPermissionList.EDIT_FAQ) >=0}
					deletePermission={permissions && allPermissionList && permissions.indexOf(allPermissionList.DELETE_FAQ) >=0}
				/>
				<AddComponent
					fetchFaqCategoryList={fetchFaqCategory}
					faqCategoryList={faqCategoryList}
					createData={create}
					visible={addFaqPopup}
					closeAddFaqPopup={closeAddFaqPopup}
				/>
				<EditComponent
					EditID={editFaqID}
					EditData={update}
					ReadData={details}
					visible={editFaqPopup}
					closeEditFaqPopup={closeEditFaqPopup}
					fetchFaqCategoryList={fetchFaqCategory}
					faqCategoryList={faqCategoryList}
				/>
				<DeleteComponent
					DeleteID={deleteFaqID}
					DeleteData={remove}
					visible={deleteFaqPopup}
					closeDeleteFaqPopup={closeDeleteFaqPopup}
				/>
			</ContentBox>
		</>
	);
});

export default FaqView;
