import React, { useState, useEffect } from "react";
import { Button, Modal, Layout, Row, Col } from "antd";
import moment from "moment";
import { useTranslation } from "react-i18next";
import { displayFormats } from "../../../../config/Global";

interface DetailProps {
	GroupExpenseID: number;
	closePopup: () => void;
	visible?: boolean;
	ReadData: (id: number) => Promise<any>;
}

const GroupExpenseDetails: React.FC<DetailProps> = ({
	GroupExpenseID,
	visible,
	closePopup,
	ReadData,
}) => {
	const [groupExpense, setGroupExpense] = useState<any>();
	const { t } = useTranslation();
	useEffect(() => {
		if (GroupExpenseID) {			
			ReadData(GroupExpenseID).then(({ data }) => {
				setGroupExpense(data);
			});
		}
	}, [GroupExpenseID, ReadData]);

	const close = () => {
		setGroupExpense(null);
		closePopup();
	};

	return (
		<Modal
			centered
			title={t("GROUP_EXPENSE_DETAILS")}
			visible={visible}
			destroyOnClose={true}
			onCancel={close}
			width={'auto'}
			style={{minWidth:"900px"}}
			footer={[
				<Button key="2" onClick={close}>
					{t("CLOSE")}
				</Button>,
			]}
		>
			<Layout className="details_table" style={{ padding: '0 24px' }}>
				<Row gutter={6} wrap={false} >
					<Col span={12} className="form__input__textbox form__input__selectBox">
						<label>{t("USER_NAME")} : </label>
						<span>{groupExpense && groupExpense.user?.firstname + " " + groupExpense.user?.lastname}</span>
					</Col>
					<Col span={12} className="form__input__textbox">
						<label>{t("GROUP")} : </label>
						<span>{groupExpense && groupExpense.group?.name}</span>
					</Col>
				</Row>
				<Row gutter={6} wrap={false}>
					<Col span={12} className="form__input__textbox">
						<label>{t("PHONE_NUMBER")} : </label>
						<span>{groupExpense && groupExpense?.user?.phone_number}</span>
					</Col>
					<Col span={12} className="form__input__textbox">
						<label>{t("EMAIL")} : </label>
						<span>{groupExpense && groupExpense?.user?.email}</span>
					</Col>
				</Row>
				<Row gutter={6} wrap={false}>
					<Col span={12} className="form__input__textbox">
						<label>{t("AMOUNT")} : </label>
						<span>{groupExpense && groupExpense?.amount}</span>
					</Col>
					<Col span={12} className="form__input__textbox">
						<label>{t("STATUS")} : </label>
						<span>{groupExpense && groupExpense?.display_approved_status}</span>
					</Col>
				</Row>
				<Row gutter={6} wrap={false}>
					<Col span={24} className="form__input__textbox">
						<label>{t("DESCRIPTION")} : </label>
						<span>{groupExpense && groupExpense?.description}</span>
					</Col>
				</Row>
				<Row gutter={6} wrap={false}>
					<Col span={24} className="form__input__textbox">
						<label>{t("PAYMENT_MODE")} : </label>
						<span>{groupExpense && groupExpense?.group_expense_payment_mode}</span>
					</Col>
				</Row>
				<Row gutter={6} wrap={false}>
					<Col span={12} className="form__input__textbox">
						<label>{t("CREATED_AT")}: </label>
						<span>{moment.utc(groupExpense?.created_at).local().format(displayFormats.DATE_TIME_FORMAT)}</span>
					</Col>
					<Col span={12} className="form__input__textbox">
						<label>{t("UPDATED_AT")}: </label>
						<span>{moment.utc(groupExpense?.updated_at).local().format(displayFormats.DATE_TIME_FORMAT)}</span>
					</Col>
				</Row>
				<Row gutter={6} wrap={false}>
					<Col span={12} className="form__input__textbox">
						<label>{t("CREATED_BY")}: </label>
						<span>{groupExpense?.createdBy ? groupExpense?.createdBy?.firstname + " "+groupExpense?.createdBy?.lastname+" ("+groupExpense?.createdBy?.email+")" : " - "}</span>
					</Col>
					<Col span={12} className="form__input__textbox">
						<label>{t("UPDATED_BY")}: </label>
						<span>{groupExpense?.updatedBy ? groupExpense?.updatedBy?.firstname + " "+groupExpense?.updatedBy?.lastname+" ("+groupExpense?.updatedBy?.email+")" : " - "}</span>
					</Col>
				</Row>
			</Layout>
		</Modal>
	);
};

export default GroupExpenseDetails;
