import React, { useEffect, useState } from "react";
import { observer } from "mobx-react";
import { Form } from 'antd';
import useStore from "../../../../../store";
import { useHistory, useParams } from "react-router";
import FormComponent from "./FormComponent";
import moment from "moment";

const EditGroupComponent: React.FC = observer(() => {
	const { ROOT, GROUP, COMMON } = useStore();
	const {
		update,
		details,
	} = GROUP;
	const [groupImage, setGroupImage] = useState(null)
	const [fileList,setFileList]=useState<any>([])

	const onFileChange = (info: any) => {
		setGroupImage(info);
		return false;
	}
	const [group, setGroup] = useState<any>([])
	const { id }: any = useParams();
	const [form] = Form.useForm();
	const [saving, setSaving] = useState<boolean>(false);
	const history = useHistory();

	const {
		userList,
		currencyList,
		confederationList,
		fetchUser,
		fetchCurrency,
		fetchConfederationList,
	} = COMMON;

	const { AssignErrorToInput } = ROOT;
	useEffect(() => {
		fetchUser(0, 1, 1000);

		details(id).then((data: any) => {
			setGroup(data);
			form.resetFields();
			form.setFieldsValue({
				group_image: [data?.group_image]?.map(
					(image: { id: any; document_name: any }) => {
						return {
							uid: "-1",
							name:data?.group_image_name,
							status: "done",
							url: image,
							thumbUrl: image,
						};
					}
				),
				name: data.name,
				group_description: data.group_description,
				numbers_of_member: data.numbers_of_member,
				share_price: data.share_price,
				max_share_purchase_limit: data.max_share_purchase_limit,
				president_id: data.president?.id,
				confederation_id: data.confederation_id,
				currency_id: data.currency_id,
				group_welfare_rate: data.group_welfare_rate,
				borrowing_limit: data.borrowing_limit,
				interest_rate: data.interest_rate,
				max_borrowing_time_line: data.max_borrowing_time_line,
				operating_cycle_date: moment(data.operating_cycle_date),
			});
			let list:any=[]
			list=[data?.group_image]?.map(
				(image: any) => {
					return {
						uid: "-1",
						name:data?.group_image_name,
						status: "done",
						url: image,
						thumbUrl: image,
					};
				}
			),
			setFileList(list)
			fetchCurrency();
			fetchConfederationList();
		});
	}, [fetchUser, fetchCurrency, details])

	const listRoute = () => {
		history.push('/group-management');
	}

	// Handle submit and call function to save new record
	const handleSubmit = (data: any) => {
		setSaving(true);
		const formData = new FormData();
		groupImage && formData.append('group_image', groupImage);
		data.name && formData.append('name', data.name);
		data.group_description && formData.append('group_description', data.group_description);
		data.currency_id && formData.append('currency_id', data.currency_id);
		data.borrowing_limit && formData.append('borrowing_limit', data.borrowing_limit);
		data.max_borrowing_time_line && formData.append('max_borrowing_time_line', data.max_borrowing_time_line);
		data.confederation_id && formData.append('confederation_id', data.confederation_id);
		update(group.id, formData)
			.then(() => {
				setSaving(false);
				form.resetFields();
				listRoute();
			})
			.catch((e: any) => {
				setSaving(false);
				AssignErrorToInput(form, e?.data?.errors);
			})
	};

	return (
		<FormComponent
			formId="editUserForm"
			form={form}
			handleSubmit={handleSubmit}
			saving={saving}
			listRoute={listRoute}
			isEdit={true}
			onFileChange={onFileChange}
			userList={userList}
			confederationList={confederationList}
			currencyList={currencyList}
			group={group}
			fileList={fileList}
			setFileList={setFileList}
		/>
	);
});
export default EditGroupComponent;
