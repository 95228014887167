import React from "react";
import { AgGridColumn } from "ag-grid-react";
import AgGridWrapper from "../../../../../components/AgGridWrapper/AgGridWrapper";
import moment from "moment";
import DateComparator from "../../../../../utils/DateComparator";
import Config from "../../../../../config/Config";
import useStore from "../../../../../store";
import { Row } from "antd";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { displayFormats } from "../../../../../config/Global";
const ListLoanComponent: React.FC = () => {
	const { t } = useTranslation();
	const { GROUP_FINANCE} = useStore();
	const {setupLoanGrid } = GROUP_FINANCE;

	return (
		<>
			<Row justify="end">
				<Link
					to="/loan-request-management"
					className="btn btn-primary btn-link ant-row-end"
				>
					{t("GO_TO_LOANS")}
				</Link>
			</Row>
			<AgGridWrapper
				type="serverSide"
				onGridReady={setupLoanGrid}
				rowSelection={"multiple"}
				overlayLoadingTemplate={t("PLEASE_WAIT_WHILE_DATA_IS_LOADING")} overlayNoRowsTemplate={t("NO_RECORD_FOUND")}
			>
				<AgGridColumn
					field="id"
					headerName={"# " + t('ID')} width={150}
					pinned={"left"}
					sortable={false}
					filter= {'agNumberColumnFilter'}
				/>
				<AgGridColumn
					field="user_name"
					headerName={t("MEMBER_NAME")}
				/>
				<AgGridColumn
					field="created_at"
					headerName={t("DATE")}
					valueGetter={({ data }) =>
						data.created_at &&
						moment(data.created_at).format(Config.dateTimeFormat)
					}
					filter={"agDateColumnFilter"}
					valueFormatter={(data:any) =>
						data?.data?.created_at && moment.utc(data?.data?.created_at).local().format(displayFormats.DATE_TIME_FORMAT)
					}
					filterParams={{
						comparator: DateComparator,
					}}
				/>
				<AgGridColumn
					field="number_of_installments"
					headerName={t("DURATION") + " (" + t("IN_MONTHS")}
					filter= {'agNumberColumnFilter'}
				/>

				<AgGridColumn
					field="interest_rate"
					headerName={t("INTEREST_RATE") + "(%)"}
					filter= {'agNumberColumnFilter'}
				/>
				<AgGridColumn
					field="loan_amount"
					headerName={t("LOAN_AMOUNT")}
					filter= {'agNumberColumnFilter'}
				/>
				<AgGridColumn
					field="repay_amount"
					headerName={t("REPAY_AMOUNT")}
					filter= {'agNumberColumnFilter'}
				/>
				<AgGridColumn
					field="display_approved_status"
					headerName={t("STATUS")}
				/>
				<AgGridColumn
					field="updated_at"
					headerName={t("UPDATED_AT")}
					valueGetter={({ data }) =>
						data.updated_at &&
						moment(data.updated_at).format(Config.dateTimeFormat)
					}
					filter={"agDateColumnFilter"}
					valueFormatter={(data:any) =>
						data?.data?.updated_at && moment.utc(data?.data?.updated_at).local().format(displayFormats.DATE_TIME_FORMAT)
					}
					filterParams={{
						comparator: DateComparator,
					}}
				/>
			</AgGridWrapper>
		</>
	);
};

export default ListLoanComponent;
