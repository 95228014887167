import React, { useEffect, useState } from "react";
import { observer } from "mobx-react";
import { Form } from 'antd';
import useStore from "../../../../../store";
import { useHistory, useParams } from "react-router";
import FormComponent from "./FormComponent";
import moment from "moment";
import RoleList from "../../../../../config/RoleList";
import { parsePhoneNumber } from "react-phone-number-input";

const EditUserComponent: React.FC = observer(() => {
	const { ROOT, USER, COMMON } = useStore();
	const {
		update,
		details,
	} = USER;

	const [roleToSelect, setRoleToSelect] = useState<any>([])
	const [user, setUser] = useState<any>([])
	const { id }: any = useParams();
	const [phoneNo, setPhoneNo] = useState<any>()
	const [form] = Form.useForm();
	const [saving, setSaving] = useState<boolean>(false);
	const history = useHistory();

	const {
		professionList,
		fetchProfession,
		fetchRole,
	} = COMMON;

	const { AssignErrorToInput } = ROOT;
	useEffect(() => {
		fetchProfession()
		details(id).then((data: any) => {
			const roleId: any = [];
			setUser(data);
			if (data?.user_type == 1) {
				data.roles.forEach((role: any) => {
					roleId.push(role.id);
				});
			} else {
				const roles = data.roles.filter((role: any) => (role.name == RoleList.VILLAGE_AGENT || role.name == RoleList.ELECTION_REFREE));
				roles.forEach((role: any) => {
					roleId.push(role.id);
				});
			}
			form.resetFields();
			form.setFieldsValue({
				firstname: data?.firstname,
				lastname: data?.lastname,
				email: data?.email,
				user_type: data?.user_type,
				profession_id: data?.profession_id,
				gender: data?.gender,
				phone_number: data?.country_calling_code + data?.phone_number,
				date_of_birth: moment(data?.date_of_birth),
				address: data?.address,
				role_id: data?.user_type == 1 ? roleId : roleId[0],
				currency_id:data?.currency_id
			});
			fetchRole().then((data: any) => {
				setRoleToSelect(data.filter((role: any) => role.name == RoleList.VILLAGE_AGENT || role.role_type == 1 || role.name == RoleList.ELECTION_REFREE));
			});
			setPhoneNo({ nationalNumber: data?.phone_number, countryCallingCode: data?.country_calling_code,country:data?.country_code })
		});
	}, [fetchProfession, fetchRole, details])


	const listRoute = () => {
		history.push('/user-management');
	}

	const setPhone = (val: any) => {
		const phoneDetails: any = val ? parsePhoneNumber(val) : "";
		setPhoneNo(phoneDetails);
	};
	// Handle submit and call function to save new record
	const handleSubmit = (data: any) => {
		setSaving(true);
		data.country_code=phoneNo?.country
		data.countryCallingCode="+" + phoneNo?.countryCallingCode
		const formData = new FormData();
		data.firstname && formData.append('firstname', data.firstname);
		data.lastname && formData.append('lastname', data.lastname);
		data.email && formData.append('email', data.email);
		data.phone_number && formData.append('phone_number', data.phone_number);
		data.gender && formData.append('gender', data.gender);
		data.currency_id && formData.append('currency_id',data.currency_id)
		data.user_type && formData.append('user_type', data.user_type);
		data.phone_number && formData.append('phone_number', phoneNo.nationalNumber);
		data.country_code && formData.append('country_code', phoneNo?.country)
		data.countryCallingCode && formData.append('countryCallingCode', phoneNo?.countryCallingCode)
		if (user.user_type == 1) {
			data.role_id.forEach((roleId: any) => {
				formData.append('role_id[]', roleId);
			});
		}
		data.profession_id && formData.append('profession_id', data.profession_id);
		data.address && formData.append('address', data.address);
		formData.append('date_of_birth', moment(data.date_of_birth).utc().format('YYYY-MM-DD'));
		update(user.id, formData)
			.then(() => {
				setSaving(false);
				form.resetFields();
				listRoute();
			})
			.catch((e: any) => {
				setSaving(false);
				AssignErrorToInput(form, e?.data?.errors);
			})
	};

	return (
		<FormComponent
			formId="editUserForm"
			form={form}
			handleSubmit={handleSubmit}
			saving={saving}
			listRoute={listRoute}
			isEdit={true}
			professionList={professionList}
			roleList={roleToSelect}
			phoneNo={phoneNo}
			setPhone={setPhone}
		/>
	);
});
export default EditUserComponent;
