import React, { useState } from "react";
import { Button, Modal, Col, Row } from "antd";
import { WarningFilled } from "@ant-design/icons";
import { useTranslation } from "react-i18next";

interface DeleteProps {
	DeleteID: number;
	DeleteData: (data: any) => Promise<any>;
	closeDeleteSliderPopup: () => void;
	visible?: boolean;
}
const DeleteComponent: React.FC<DeleteProps> = ({
	DeleteID,
	DeleteData,
	closeDeleteSliderPopup,
	visible,
}) => {
	const { t } = useTranslation();
	const [saving, setSaving] = useState<boolean>(false);

	const close = () => {
		closeDeleteSliderPopup();
	};

	// Handle submit and call function to delete record
	const handleSubmit = () => {
		setSaving(true);
		DeleteData(DeleteID)
			.then(() => {
				close();
			})
			.finally(() => {
				setSaving(false);
			});
	};

	return (
		<Modal
			centered
			title={t("DELETE_SLIDER")}
			visible={visible}
			destroyOnClose={true}
			onCancel={close}
			footer={[
				<Button
					key="1"
					loading={saving}
					type="primary"
					danger
					onClick={handleSubmit}
				>
					{t('DELETE')}
				</Button>,
				<Button key="2" onClick={close}>
					{t('CANCEL')}
				</Button>,
			]}
		>
			<Row align={"middle"}>
				<Col span={4}>
					<WarningFilled style={{ fontSize: 45, color: "#ff4d4f" }} />
				</Col>
				<Col span={20}>
					<h3>{t('ARE_YOU_SURE_YOU_WANT_TO_REMOVE_THIS_DATA')}</h3>
				</Col>
			</Row>
		</Modal>
	);
};

export default DeleteComponent;
