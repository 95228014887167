import React from "react";
import { AgGridColumn } from "ag-grid-react";
import AgGridWrapper from "../../../../../components/AgGridWrapper/AgGridWrapper";
import moment from "moment";
import DateComparator from "../../../../../utils/DateComparator";
import Config from "../../../../../config/Config";
import useStore from "../../../../../store";
import { Row } from "antd";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { displayFormats } from "../../../../../config/Global";
const ListDonationComponent: React.FC = () => {
	const { t } = useTranslation();
	const { GROUP_FINANCE} = useStore();
	const {setupDonationGrid } = GROUP_FINANCE;

	return (
		<>
			<Row justify="end">
				<Link
					to="/donation"
					className="btn btn-primary btn-link ant-row-end"
				>
					{t("GO_TO_DONATIONS")}
				</Link>
			</Row>
			<AgGridWrapper
				type="serverSide"
				onGridReady={setupDonationGrid}
				rowSelection={"multiple"}
				overlayLoadingTemplate={t("PLEASE_WAIT_WHILE_DATA_IS_LOADING")} overlayNoRowsTemplate={t("NO_RECORD_FOUND")}
			>
				<AgGridColumn
					field="id"
					headerName={"# " + t('ID')}
					width={100}
					pinned={"left"}
					filter="agNumberColumnFilter"
				/>
				<AgGridColumn field="name" headerName={t("NAME")} />
				<AgGridColumn field="amount" headerName={t("AMOUNT")} filter="agNumberColumnFilter"/>
				<AgGridColumn
					field="created_at"
					headerName={t('CREATED_AT')}
					valueGetter={({ data }) =>
						data.created_at &&
						moment(data.created_at).format(Config.dateTimeFormat)
					}
					filter={"agDateColumnFilter"}
					valueFormatter={(data:any) =>
						data?.data?.created_at && moment.utc(data?.data?.created_at).local().format(displayFormats.DATE_TIME_FORMAT)
					}
					filterParams={{
						comparator: DateComparator,
					}}
					width={220}
				/>
				<AgGridColumn field="phone_number" headerName={t("PHONE_NUMBER")} />
				<AgGridColumn field="email" headerName={t("EMAIL_ID")} />
				<AgGridColumn field="group_name" headerName={t("DONATING_TO")} />
				<AgGridColumn field="organisation" headerName={t("ORGANIZATION")} />
				<AgGridColumn field="subjectname" headerName={t("DONATION_SUBJECT")} />
				<AgGridColumn field="donation_paid_status" headerName={t('STATUS')} />
			</AgGridWrapper>
		</>
	);
};

export default ListDonationComponent;
