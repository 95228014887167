import React, { useState } from "react";
import { observer } from "mobx-react";
import BreadcrumbComponent from "../../../components/BreadcrumbComponent/BreadcrumbComponent";
import { FineBreadcrumb } from "../../../config/BreadcrumbConfig";
import HeaderButtons from "./components/HeaderButtons";
import ContentBox from "../../../components/ContentBox/ContentBox";
import ListComponent from "./components/ListComponent";
import AddComponent from "./components/AddComponent";
import EditComponent from "./components/EditComponent";
import DeleteComponent from "./components/DeleteComponent";
import RecordPerPage from "../../../utils/RecordPerPage";
import useStore from "../../../store";
import { Link } from "react-router-dom";
import { Button, Row } from "antd";
import { useTranslation } from "react-i18next";
import { GrDocumentExcel } from "react-icons/gr";
import ExportPopupComponent from "../../../utils/ExportPopupComponent";
const FinesView: React.FC = observer(() => {
	const { FINE, COMMON } = useStore();
	const { t } = useTranslation();
	const {
		setupGrid,
		create,
		update,
		details,
		remove,
		changeStatus,
		addFinePopup,
		openAddFinePopup,
		closeAddFinePopup,
		openEditFinePopup,
		editFineID,
		editFinePopup,
		closeEditFinePopup,
		deleteFineID,
		deleteFinePopup,
		openDeleteFinePopup,
		closeDeleteFinePopup,
		setPageSize,
		perPage,
		exportList,
	} = FINE;

	const {
		groupList,
		fineTypeList,
		groupUser,
		fetchGroup,
		fetchGroupUser,
		fetchFineTypeList,
	} = COMMON;

	const [exportSaving, setExportSaving] = useState<boolean>(false);
	const [exportPopup, showExportPopup] = useState<boolean>(false);

	const exportListData = (type: string) => {
		setExportSaving(true);
		exportList(type).finally(() => {
			setExportSaving(false);
			showExportPopup(false);
		});
	}

	return (
		<>
			<div className="Breadcrumb-bar">
				<BreadcrumbComponent items={FineBreadcrumb(t).path} />
				<div className="header_all_btn">
					<Button type="primary" className="export-btn" icon={<GrDocumentExcel />} onClick={()=> showExportPopup(true)}>
						{t("GENERATE_REPORT")}
					</Button>
							
					<ExportPopupComponent
						callbackExportFunction={exportListData}
						setVisible={showExportPopup}
						visible={exportPopup}
						saving={exportSaving}
					/>

					<HeaderButtons key={1} openAddFinePopup={openAddFinePopup} />
				</div>
			</div>
			<Row justify="end">
				<Link
					to="fine-type-management"
					className="btn btn-primary btn-link ant-row-end"
				>
					{t("VIEW_ALL_FINE_TYPES")}
				</Link>
				<div className="RecordPerPage__right">
					<RecordPerPage
						style={{ width: "150px" }}
						defaultValue={perPage + " per page"}
						onChange={setPageSize}
					/>

				</div>
			</Row>
			<ContentBox>
				<ListComponent
					setupGrid={setupGrid}
					openEditFinePopup={openEditFinePopup}
					openDeleteFinePopup={openDeleteFinePopup}
					ChangeStatus={changeStatus}
				/>
				<AddComponent
					fetchGroupList={fetchGroup}
					groupList={groupList}
					fetchGroupUser={fetchGroupUser}
					fetchFineTypeList={fetchFineTypeList}
					fineTypeList={fineTypeList}
					createData={create}
					visible={addFinePopup}
					closeAddFinePopup={closeAddFinePopup}
				/>
				<EditComponent
					EditID={editFineID}
					EditData={update}
					ReadData={details}
					visible={editFinePopup}
					closeEditFinePopup={closeEditFinePopup}
					fetchGroupList={fetchGroup}
					groupList={groupList}
					groupUser={groupUser}
					fetchGroupUser={fetchGroupUser}
					fetchFineTypeList={fetchFineTypeList}
					fineTypeList={fineTypeList}
				/>
				<DeleteComponent
					DeleteID={deleteFineID}
					DeleteData={remove}
					visible={deleteFinePopup}
					closeDeleteFinePopup={closeDeleteFinePopup}
				/>
			</ContentBox>
		</>
	);
});

export default FinesView;
