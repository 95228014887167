import { observer } from "mobx-react";
import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router";
import BreadcrumbComponent from "../../../../../components/BreadcrumbComponent/BreadcrumbComponent";
import ContentBox from "../../../../../components/ContentBox/ContentBox";
import { HomePageContentBreadcrumb } from "../../../../../config/BreadcrumbConfig";
import useStore from "../../../../../store";
import HeaderButtons from "./components/HeaderButtons";
import ListComponent from "./components/ListComponent";

const HomePageContentView: React.FC = observer(() => {
	const { t } = useTranslation();
	const { AUTH, HOME_PAGE_CONTENT, COMMON } = useStore();
	const { permissions } = AUTH;
	const { fetchList, listData, changeStatus } = HOME_PAGE_CONTENT;
	const pathHistory = useHistory();
	const {allPermissionList} = COMMON

	useEffect(()=>{
		//
	}, [allPermissionList]);

	const addHomePageContent = () => {
		pathHistory.push('/settings/home-page-content/create');
	}

	const editHomePageContent = (id:number) => {
		pathHistory.push('/settings/home-page-content/'+id+'/edit');
	}

	return (
		<>
			<div className="Breadcrumb-bar">
				<BreadcrumbComponent items={HomePageContentBreadcrumb(t).path} />
				<HeaderButtons
					key={1}
					addHomePageContentRedirect={addHomePageContent}
					addPermission={permissions && allPermissionList && permissions.indexOf(allPermissionList.ADD_USER) >= 0}
				/>
			</div>
			<ContentBox>
				<ListComponent fetchList={fetchList} rowDate={listData} ChangeStatus={changeStatus} editHomePageContentRedirect={editHomePageContent}/>
			</ContentBox>
		</>
	);
});

export default HomePageContentView;
