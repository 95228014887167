import React from "react";
import { AgGridColumn } from "ag-grid-react";
import moment from "moment";
import AgGridWrapper from "../../../../../components/AgGridWrapper/AgGridWrapper";
import Config from "../../../../../config/Config";
import DateComparator from "../../../../../utils/DateComparator";
import { GridReadyEvent } from "ag-grid-community";
import { EyeOutlined, EditOutlined, LockOutlined } from "@ant-design/icons";
import { Button, Switch } from "antd";
import { useTranslation } from "react-i18next";
import { displayFormats } from "../../../../../config/Global";

interface ListProps {
	setupGrid: (params: GridReadyEvent) => void;
	editUserRedirect: (id: number) => void;
	editPermission: boolean;
	detailPermission: boolean;
	userDetailsRedirect: (id: number) => void;
	resetPasswordRequest: (data: any) => void;
	ChangeStatus: (data: any) => void | any;
}

const ListComponent: React.FC<ListProps> = ({
	setupGrid,
	editUserRedirect,
	editPermission,
	detailPermission,
	userDetailsRedirect,
	resetPasswordRequest,
	ChangeStatus,
}) => {
	const { t } = useTranslation();

	const ActionRenderer = (params: any) => {
		return (
			<div className="action-column">
				<Button
					type="text"
					title={t("EDIT")}
					icon={<EditOutlined />}
					onClick={() => editUserRedirect(params.data?.id)}
					hidden={!editPermission}
				></Button>
				<Button
					type="text"
					title={t("DETAILS")}
					icon={<EyeOutlined />}
					onClick={() => userDetailsRedirect(params.data?.id)}
					hidden={!detailPermission}
				></Button>
				<Button
					type="text"
					title={t("RESET_PASSWORD")}
					icon={<LockOutlined />}
					onClick={() => resetPasswordRequest(params.data)}
					hidden={!detailPermission}
				></Button>
			</div>
		);
	};

	const changeStatus = (data: any) => {
		ChangeStatus(data)
			.then(() => {
				return;
			})
			.finally(() => {
				return;
			});
	};
	const SwitchRenderer = (params: any) => {
		return (
			<div>
				<Switch
					defaultChecked={params.data?.status == 1}
					onChange={() =>
						changeStatus({
							id: params.data?.id,
							status: params.data?.status == 1 ? 2 : 1,
						})
					}
					disabled={!editPermission || params.data?.status == 0}
				/>
			</div>
		);
	};

	return (
		<AgGridWrapper
			type="serverSide"
			onGridReady={setupGrid}
			suppressColumnMoveAnimation={true}
			className="ag-column-moving"
			frameworkComponents={{ ActionRenderer, SwitchRenderer }}
			overlayLoadingTemplate={t("PLEASE_WAIT_WHILE_DATA_IS_LOADING")}
			overlayNoRowsTemplate={t("NO_RECORD_FOUND")}
		>
			<AgGridColumn
				field="id"
				filter={"agNumberColumnFilter"}
				headerName={"# " + t("ID")}
				width={150}
				pinned={"left"}
			/>
			<AgGridColumn field="firstname" headerName={t("FIRST_NAME")} />
			<AgGridColumn field="lastname" headerName={t("LAST_NAME")} />
			<AgGridColumn field="email" headerName={t("EMAIL")} />
			<AgGridColumn field="phone_number" headerName={t("PHONE_NUMBER")} />
			<AgGridColumn field="profession_name" headerName={t("PROFESSION")} />
			<AgGridColumn field="language_name" headerName={t("LANGUAGE")} />
			<AgGridColumn field="address" headerName={t("ADDRESS")} />
			<AgGridColumn field="currency_name" headerName={t("CURRENCY")}/>
			<AgGridColumn
				field="created_at"
				headerName={t("CREATED_AT")}
				valueGetter={({ data }) =>
					data.created_at &&
					moment(data.created_at).format(Config.dateTimeFormat)
				}
				filter={"agDateColumnFilter"}
				valueFormatter={(data: any) =>
					data?.data?.created_at &&
					moment
						.utc(data?.data?.created_at)
						.local()
						.format(displayFormats.DATE_TIME_FORMAT)
				}
				filterParams={{
					comparator: DateComparator,
				}}
			/>
			<AgGridColumn
				field="updated_at"
				headerName={t("UPDATED_AT")}
				valueGetter={({ data }) =>
					data.updated_at &&
					moment(data.updated_at).format(Config.dateTimeFormat)
				}
				filter={"agDateColumnFilter"}
				valueFormatter={(data: any) =>
					data?.data?.updated_at &&
					moment
						.utc(data?.data?.updated_at)
						.local()
						.format(displayFormats.DATE_TIME_FORMAT)
				}
				filterParams={{
					comparator: DateComparator,
				}}
			/>
			<AgGridColumn
				field="status"
				headerName={t("STATUS")}
				cellRenderer="SwitchRenderer"
				width={120}
				pinned={"right"}
				sortable={false}
				filter={false}
			/>
			<AgGridColumn
				headerName={t("ACTION")}
				type="actionColumn"
				sortable={false}
				filter={false}
				width={120}
				pinned={"right"}
			/>
		</AgGridWrapper>
	);
};

export default ListComponent;
