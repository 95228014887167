import React, { useEffect, useState }  from "react";
import { observer } from "mobx-react";
import {  Button, Col, Layout, Row } from 'antd';
import useStore from "../../../../../store";
import { useHistory, useParams } from "react-router";
import moment from "moment";
import BreadcrumbComponent from "../../../../../components/BreadcrumbComponent/BreadcrumbComponent";
import { LoanRequestBreadcrumb } from "../../../../../config/BreadcrumbConfig";
import { useTranslation } from "react-i18next";
import { displayFormats } from "../../../../../config/Global";

const LoanRequestDetailComponent: React.FC = observer(() => {
	const { t } = useTranslation();
	const { LOAN } = useStore();
	const {
		details,
	} = LOAN;

	const [loan, setLoan] = useState<any>([])
	const { id }: any = useParams();

	useEffect(()=>{
		details(id).then((data:any) => {
			setLoan(data);

		});
	},[details])

	const history = useHistory();

	const listRoute = () =>{
		history.push('/loan-request-management');
  }

	const getApproveStatus = (status:number) => {
		switch (status) {
			case 0:
				return t("PENDING")
				break;
			case 1:
				return t("APPROVED")
				break;
			case 2:
				return t("REJECTED")
				break;

		}
	}

	return (
		<>
			<div className="Breadcrumb-bar Breadcrumb-bar-export">
				<BreadcrumbComponent items={LoanRequestBreadcrumb(t).path} />
			</div>
			<Layout className="details_table" style={{ padding: '0 24px' }}>
				<Row gutter={6} wrap={false}>
					<Col span={12} className="form__input__textbox form__input__selectBox">
						<label>{t("GROUP")} : </label>
						<span>{loan?.group?.name}</span>
					</Col>
					<Col span={12} className="form__input__textbox form__input__selectBox">
						<label>{t("USER")} : </label>
						<span>{loan?.user?.firstname + " " +loan?.user?.lastname + " ("+loan?.user?.email+")"}</span>
					</Col>
				</Row>
				<Row gutter={6} wrap={false}>
					<Col span={12} className="form__input__textbox">
						<label>{t("AMOUNT")} : </label>
						<span>{loan?.loan_amount}</span>
					</Col>
					<Col span={12} className="form__input__textbox">
						<label>{t("INTEREST_RATE")} (%) : </label>
						<span>{loan?.interest_rate}</span>
					</Col>
				</Row>
				<Row gutter={6} wrap={false}>
					<Col span={12} className="form__input__textbox">
						<label>{t("REPAY_AMOUNT")} : </label>
						<span>{loan?.repay_amount}</span>
					</Col>
					<Col span={12} className="form__input__textbox form__input__selectBox">
						<label>{t("NUMBER_OF_INSTALLMENTS")} : </label>
						<span>{loan?.number_of_installments}</span>
					</Col>
				</Row>
				<Row gutter={6} wrap={false}>
					<Col span={12} className="form__input__textbox form__input__selectBox">
						<label>{t("APPROVED_BY_PRESIDENT")} : </label>
						<span>{getApproveStatus(loan?.president_approve_status)}</span>
					</Col>
					<Col span={12} className="form__input__textbox form__input__selectBox">
						<label>{t("APPROVED_BY_SECRETARY")} : </label>
						<span>{getApproveStatus(loan?.secretary_approve_status)}</span>
					</Col>
				</Row>
				<Row gutter={6} wrap={false}>
					<Col span={12} className="form__input__textbox form__input__selectBox">
						<label>{t("APPROVED_BY_ACCOUNTANT")} : </label>
						<span>{getApproveStatus(loan?.accountant_approve_status)}</span>
					</Col>
					<Col span={12} className="form__input__textbox form__input__selectBox">
						<label>{t("DESCRIPTION")} : </label>
						<span>{loan?.description}</span>
					</Col>

				</Row>
				<Row gutter={6} wrap={false}>
				<Col span={12} className="form__input__textbox form__input__selectBox">
						<label>{t("PAYMENT_MODE")} : </label>
						<span>{loan?.loan_payment_mode}</span>
					</Col>
				</Row>
				<Row gutter={6} wrap={false}>
					<Col span={12} className="form__input__textbox">
						<label>{t("CREATED_AT")}: </label>						
						<span>{moment.utc(loan?.created_at).local().format(displayFormats.DATE_TIME_FORMAT)}</span>
					</Col>
					<Col span={12} className="form__input__textbox">
						<label>{t("UPDATED_AT")}: </label>
						<span>{moment.utc(loan?.updated_at).local().format(displayFormats.DATE_TIME_FORMAT)}</span>
					</Col>
				</Row>
				<Row gutter={6} wrap={false}>
					<Col span={12} className="form__input__textbox">
						<label>{t("CREATED_BY")}: </label>
						<span>{loan?.createdBy ? loan?.createdBy?.firstname + " "+loan?.createdBy?.lastname+" ("+loan?.createdBy?.email+")" : " - "}</span>
					</Col>
					<Col span={12} className="form__input__textbox">
						<label>{t("UPDATED_BY")}: </label>
						<span>{loan?.updatedBy ? loan?.updatedBy?.firstname + " "+loan?.updatedBy?.lastname+" ("+loan?.updatedBy?.email+")" : " - "}</span>
					</Col>
				</Row>
				<Row gutter={6} className="permission__button ant-row-center ant-row">
					<Button style={{ marginTop: 45 }} type="primary" key="resetCancel" danger onClick={listRoute}>{t("BACK")}</Button>
				</Row>
			</Layout>
		</>
	);
});
export default LoanRequestDetailComponent;
