import React, { useEffect } from "react";
import { observer } from "mobx-react";
import BreadcrumbComponent from "../../../components/BreadcrumbComponent/BreadcrumbComponent";
import { LoanDisbursedBreadcrumb } from "../../../config/BreadcrumbConfig";
import ContentBox from "../../../components/ContentBox/ContentBox";
import ListComponent from "./components/ListComponent";
import useStore from "../../../store";
import { useHistory } from "react-router";
import RecordPerPage from "../../../utils/RecordPerPage";
import { useTranslation } from "react-i18next";

const LoanDisbursedView: React.FC = observer(() => {
	const { t } = useTranslation();
	const pathHistory = useHistory();
    const { LOAN_DISBURSEMENT, } = useStore();
	const {
		setupGrid,
		setPageSize,
		perPage,
		setLoanDisbursedId,
	} = LOAN_DISBURSEMENT;

	useEffect(() => {
		setLoanDisbursedId();
	}, [setLoanDisbursedId])
	const LoanDisbursedDetails = (id:number) => {
		pathHistory.push('/loan-disbursed-management/'+id+'/details');
	}
    return (
		<>
            <div className="Breadcrumb-bar">
				<BreadcrumbComponent items={LoanDisbursedBreadcrumb(t).path} />				
			</div>
            <ContentBox>
				<div className="RecordPerPage__right">
					<RecordPerPage
						style={{ width: "150px" }}
						defaultValue={perPage + " per page"}
						onChange={setPageSize}
					/>
				</div>
                <ListComponent
					setupGrid={setupGrid}
					LoanDisbursedDetailsRedirect={LoanDisbursedDetails}
				/>
            </ContentBox>
        </>

);
});

export default LoanDisbursedView;