import React, { useState } from "react";
import BreadcrumbComponent from "../../../components/BreadcrumbComponent/BreadcrumbComponent";
import { ElectionProcessBreadcrumb } from "../../../config/BreadcrumbConfig";
import ContentBox from "../../../components/ContentBox/ContentBox";
import ListComponent from "./components/ListComponent";
import useStore from "../../../store";
import { observer } from "mobx-react-lite";
import ElectionProcessDetails from "./components/DetailsComponent";
import { Link } from "react-router-dom";
import { Button, Row,} from 'antd';
import HeaderButtons from "./components/HeaderButtons";
import { useHistory } from "react-router";
import RecordPerPage from "../../../utils/RecordPerPage";
import { useTranslation } from "react-i18next";
import { GrDocumentExcel } from "react-icons/gr";
import ExportPopupComponent from "../../../utils/ExportPopupComponent";

const ElectionProcessView: React.FC = observer(() => {
	const pathHistory = useHistory();
	const { ELECTION } = useStore();
	const { setupGrid, showDetails, details, closeDetails, showDetailsPopUp, ElectionProcessId, setPageSize, perPage, exportList } = ELECTION;
	const { t } = useTranslation();

	const [exportSaving, setExportSaving] = useState<boolean>(false);
	const [exportPopup, showExportPopup] = useState<boolean>(false);

	const ElectionRequestRedirect = () => {
		pathHistory.push('/election-request');
	}

	const exportListData = (type: string) => {
		setExportSaving(true);
		exportList(type).finally(() => {
			setExportSaving(false);
			showExportPopup(false);
		});
	}

	return (
		<>
			<div className="Breadcrumb-bar">
				<BreadcrumbComponent items={ElectionProcessBreadcrumb(t).path} />
				<div className="header_all_btn">
					<Button type="primary" className="export-btn" icon={<GrDocumentExcel />} onClick={()=> showExportPopup(true)}>
						{t("GENERATE_REPORT")}
					</Button>
							
					<ExportPopupComponent
						callbackExportFunction={exportListData}
						setVisible={showExportPopup}
						visible={exportPopup}
						saving={exportSaving}
					/>

					<HeaderButtons
						key={1}
						NewElectionRequestRedirect={ElectionRequestRedirect}
					/>
				</div>
			</div>
			<Row justify="end">
				<Link to="election-referee" className="btn btn-primary btn-link ant-row-end">{t("ELECTION_REFEREE_LIST")}</Link>				
				<div className="RecordPerPage__right">
					<RecordPerPage
						style={{ width: "150px" }}
						defaultValue={perPage + " per page"}
						onChange={setPageSize}
					/>
				</div>
			</Row>
			<ContentBox>
				<ListComponent
					setupGrid={setupGrid}
					ElectionProcessDetails = {showDetails}
				/>
				<ElectionProcessDetails
					ElectionProcessID={ElectionProcessId}
					ReadData={details}
					visible={showDetailsPopUp}
					closePopup={closeDetails}
				/>
			</ContentBox>
		</>
	);
});

export default ElectionProcessView;
