import React, { useEffect, useState } from "react";
import { Button, Modal, Form,} from "antd";
import useStore from "../../../../store";
import { FormBox, InputBox } from "../../../../components/AntdAddons";
import { FineRequest } from "../../../../requests/FineRequest";
import moment from "moment";
import { useTranslation } from "react-i18next";
import { displayFormats } from "../../../../config/Global";
interface AddProps {
	fetchGroupList:() => Promise<any>;
	groupList:[] | any;
	fetchGroupUser:(id:any) => Promise<any>;
	fetchFineTypeList:() => Promise<any>;
	fineTypeList:[] | any;
	createData: (data: any) => Promise<any>;
	closeAddFinePopup: () => void;
	visible?: boolean;
}

const AddComponent: React.FC<AddProps> = ({
	fetchGroupList,
	groupList,
	fetchGroupUser,
	fetchFineTypeList,
	fineTypeList,
	createData,
	closeAddFinePopup,
	visible,
}) => {

	const [form] = Form.useForm();
    const [saving, setSaving] = useState<boolean>(false);
	const [memberList, setMemeberList] = useState<any>([]);
	const { ROOT } = useStore();
	const { AssignErrorToInput } = ROOT;
	const { t } = useTranslation();

	// Handle submit and call function to save new record
	const handleSubmit = (data: any) => {
		setSaving(true);
		const formData = new FormData();
		formData.append('group_id', data.group_id);
		formData.append('user_id', data.user_id);
		formData.append('fine_type_id', data.fine_type_id);
		formData.append('fine_amount', data.fine_amount);
		formData.append('description', data.description);
		formData.append('fine_due_date',  moment(data.fine_due_date).utc().format('YYYY-MM-DD'));
		formData.append('date', moment(data.date).utc().format('YYYY-MM-DD'));
		createData(formData)
			.then(() => {
				close();
			})
			.catch((e) => {
				AssignErrorToInput(form, e?.data);
			})
			.finally(() => setSaving(false));
	};

	const getUserByGroupId = (id:any) => {
		fetchGroupUser(id).then((data:any) => {
			setMemeberList(data);
		});

	}
    const close = () => {
		form.resetFields();
		closeAddFinePopup();
	};

	const disableFutureDate = (current: any) => {
		return current && current >= moment().endOf("day");
	};

	const disableDate = (current:any) =>{
		return current && current < moment().endOf('day');
	}

	useEffect(() => {
		fetchGroupList();
		fetchFineTypeList();
	}, [fetchGroupList,fetchFineTypeList]);
	return (
		<Modal
			centered
			title={t("NEW_FINE")}
			visible={visible}
			destroyOnClose={true}
			onCancel={close}
			footer={[
				<Button
				key="1"
				loading={saving}
				form="addFineForm"
				type="primary"
				htmlType="submit"
				>
					{t('SAVE')}
				</Button>,
				<Button key="2" onClick={close}>
					{t('CANCEL')}
				</Button>,
			]}
		>
			<FormBox
				id="addFineForm"
				form={form}
				encType="multipart/formdata"
				onFinish={handleSubmit}>
				<InputBox.Select
					options={{
						list:groupList,
						valueKey:"id",
						textKey:"name"
					}}
					name="group_id"
					placeholder={t("SELECT_GROUP")}
					label={t("SELECT_GROUP")}
					rules={FineRequest(t).group_id}
					onChange={getUserByGroupId}
				/>
                <InputBox.Select
					options={{
						list:memberList,
						valueKey:"id",
						textKey:"user_name"
					}}
					name="user_id"
					placeholder={t("SELECT_MEMBER")}
					label={t("SELECT_MEMBER")}
					rules={FineRequest(t).user_id}
				/>
                <InputBox.Select
					options={{
						list:fineTypeList,
						valueKey:"id",
						textKey:"name"
					}}
					name="fine_type_id"
					placeholder={t("FINE_TYPE")}
					label={t("FINE_TYPE")}
					rules={FineRequest(t).fine_type_id}
				/>
				<InputBox.Text
					name="fine_amount"
					placeholder={t("AMOUNT")}
					label={t("AMOUNT")}
					maxLength={255}
					rules={FineRequest(t).fine_amount}
				/>
				<InputBox.DatePicker
					name="date"
					placeholder={t("DATE")}
					label={t("DATE")}
					rules={FineRequest(t).fine_date}
					disabledDate={disableFutureDate}
					format={displayFormats.DATE_FORMAT}
				/>
				<InputBox.DatePicker
					name="fine_due_date"
					placeholder={t("DUE_DATE")}
					label={t("DUE_DATE")}
					rules={FineRequest(t).fine_due_date}
					disabledDate={disableDate}
					format={displayFormats.DATE_FORMAT}
				/>
				<InputBox.TextArea
					name="description"
					rules={FineRequest(t).description}
					placeholder={t("DESCRIPTION")}
					label={t("DESCRIPTION")}
				/>
			</FormBox>
		</Modal>
	);
};

export default AddComponent;
