import { observer } from "mobx-react";
import React from "react";
import { useHistory } from "react-router";
import BreadcrumbComponent from "../../../components/BreadcrumbComponent/BreadcrumbComponent";
import ContentBox from "../../../components/ContentBox/ContentBox";
import { TemplatesBreadcrumb } from "../../../config/BreadcrumbConfig";
import useStore from "../../../store";
import ListComponent from "./components/ListComponent";
import { Tabs } from "antd";
import { useTranslation } from "react-i18next";

const TemplatView: React.FC = observer(() => {
	const { t } = useTranslation();
	const { TEMPLATE } = useStore();
	const { listData, fetchList, changeTemplateType, templateType } = TEMPLATE;
	const pathHistory = useHistory();
	const editEmailTemplatePage = (id: number) => {
		pathHistory.push("/templates-management/" + id + "/edit-email-templates");
	};
	const editSmsTemplatePage = (id: number) => {
		pathHistory.push("/templates-management/" + id + "/edit-sms-templates");
	};
	const editNotificationTemplatePage = (id: number) => {
		pathHistory.push("/templates-management/" + id + "/edit-notification-templates");
	};

	const setTemplateType = (key:any) => {
		changeTemplateType(key);
	}

	const { TabPane } = Tabs;

	return (
		<>
			<div className="Breadcrumb-bar">
				<BreadcrumbComponent items={TemplatesBreadcrumb(t).path} />
			</div>
			<ContentBox>
				<Tabs defaultActiveKey="1" onChange={setTemplateType} centered>
					<TabPane tab={t("EMAIL")} key="1" active={templateType == 1}>
						<ListComponent
							rowData={listData}
							getData={fetchList}
							templateType={templateType}
							editTemplateRedirect={editEmailTemplatePage}
						/>
					</TabPane>
					<TabPane tab={t("SMS")} key="2" active={templateType == 2}>
						<ListComponent
							rowData={listData}
							getData={fetchList}
							templateType={templateType}
							editTemplateRedirect={editSmsTemplatePage}
						/>
					</TabPane>
					<TabPane tab={t("NOTIFICATION")} key="3" active={templateType == 3}>
						<ListComponent
							rowData={listData}
							getData={fetchList}
							templateType={templateType}
							editTemplateRedirect={editNotificationTemplatePage}
						/>
					</TabPane>
				</Tabs>
			</ContentBox>
		</>
	);
});

export default TemplatView;
