import React from "react";
import { AgGridColumn } from "ag-grid-react";
import AgGridWrapper from "../../../../components/AgGridWrapper/AgGridWrapper";
import moment from "moment";
import Config from "../../../../config/Config";
import DateComparator from "../../../../utils/DateComparator";
import { GridReadyEvent } from "ag-grid-community";
import { Button } from "antd";
import { EyeOutlined } from "@ant-design/icons";
import { observer } from "mobx-react";
import { useTranslation } from "react-i18next";
import { displayFormats } from "../../../../config/Global";

interface ListProps {
	setupGrid: (params: GridReadyEvent) => void;
	type: number;
	detailsRedirect: (id: number, walletId: number) => void | any;
	detailsPermission: boolean;
}

const ListUserWalletComponent: React.FC<ListProps> = observer(
	({ setupGrid, type, detailsRedirect, detailsPermission }) => {
		const { t } = useTranslation();

		const ActionRenderer = (params: any) => {
			return (
				<div className="action-column">
					<Button
						type="text"
						title={t("DETAILS")}
						icon={<EyeOutlined />}
						onClick={() =>
							detailsRedirect(
								type == 1 ? params.data?.user_id : params.data?.group_id,
								params.data.id
							)
						}
						hidden={!detailsPermission}
					></Button>
				</div>
			);
		};

		return (
			<AgGridWrapper
				type="serverSide"
				onGridReady={setupGrid}
				rowSelection={"multiple"}
				frameworkComponents={{ ActionRenderer }}
				overlayLoadingTemplate={t("PLEASE_WAIT_WHILE_DATA_IS_LOADING")}
				overlayNoRowsTemplate={t("NO_RECORD_FOUND")}
			>
				<AgGridColumn
					field="id"
					filter={"agNumberColumnFilter"}
					headerName={"# " + t("ID")}
					width={200}
					pinned={"left"}
				/>
				<AgGridColumn
					width={300}
					field="user_name"
					headerName={t("USER_NAME")}
				/>
				<AgGridColumn width={220} field="balance" headerName={t("BALANCE")} />
				<AgGridColumn
					field="updated_at"
					headerName={t("UPDATED_AT")}
					valueGetter={({ data }) =>
						data.updated_at &&
						moment(data.updated_at).format(Config.dateTimeFormat)
					}
					filter={"agDateColumnFilter"}
					filterParams={{
						comparator: DateComparator,
					}}
					valueFormatter={(data: any) =>
						data?.data?.updated_at &&
						moment
							.utc(data?.data?.updated_at)
							.local()
							.format(displayFormats.DATE_TIME_FORMAT)
					}
					width={320}
				/>
				<AgGridColumn
					headerName={t("ACTION")}
					type="actionColumn"
					sortable={false}
					filter={false}
					width={120}
					pinned="right"
				/>
			</AgGridWrapper>
		);
	}
);

export default ListUserWalletComponent;
