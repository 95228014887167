import React, { useState } from "react";
import { Button, Card, Form, Space, } from "antd";
import { ForgotPasswordRequest} from "../../../requests/AuthRequest";
import { Link, useHistory } from "react-router-dom";
import useStore from "../../../store";
import { ErrorProps } from "../../../store/RootStore/RootInterface";
import { FormBox, InputBox } from "../../../components/AntdAddons";
import { MailOutlined } from '@ant-design/icons';
import { useTranslation } from "react-i18next";

const ForgetPassword: React.FC = () => {
	const [form] = Form.useForm();
	const [saving, setSaving] = useState<boolean>(false);
	const history = useHistory();
	const { AUTH, ROOT } = useStore();
	const { forgetPassword } = AUTH;
	const { AssignErrorToInput } = ROOT;
	const { t } = useTranslation();

	const handleSubmit = (data: any) => {
		setSaving(true);

		forgetPassword(data)
			.then(() => {
				history.replace("/");
			})
			.catch((e: ErrorProps) => {
				const errors = e?.errors || e.data?.errors
				AssignErrorToInput(form, errors);
				setSaving(false);
			});
	};

	return (
		<section className="section reset__section">
			<Card className="width-450">
				<h2 className="card__title">VSLA</h2>
				<FormBox className="text-center" form={form} onFinish={handleSubmit}>
					<InputBox.Text
						prefix={<MailOutlined />}
						name="email"
						placeholder={t("EMAIL_ID")}
						size="large"
						rules={ForgotPasswordRequest(t).username}
					/>
					<div className="text-center">
						<Space>
							<p style={{color:"blue"}}>{t("PLEASE_ENTER_YOUR_EMAIL_ADDRESS_WE_WILL_SEND_YOU_UPDATE_PASSWORD_LINK")}</p>
						</Space>
					</div>
						<Button
							className="width-250 mt-20 mb-20"
							loading={saving}
							type="primary"
							size="large"
							htmlType="submit"
						>
						{t("RESET_PASSWORD")}
					</Button>
					<div className="text-center">
						<Space>
							<Link to="/">
								{t("BACK_TO_LOGIN")}
							</Link>
						</Space>
					</div>
				</FormBox>
			</Card>
		</section>
	);
};

export default ForgetPassword;
