import React, { useEffect } from "react";
import { observer } from "mobx-react";
import BreadcrumbComponent from "../../../../components/BreadcrumbComponent/BreadcrumbComponent";
import ContentBox from "../../../../components/ContentBox/ContentBox";
import { FaqCategoryBreadcrumb } from "../../../../config/BreadcrumbConfig";
import useStore from "../../../../store";
import HeaderButtons from "./components/HeaderButtons";
import ListComponent from "./components/ListComponent";
import AddComponent from "./components/AddComponent";
import EditComponent from "./components/EditComponent";
import DeleteComponent from "./components/DeleteComponent";
import { useTranslation } from "react-i18next";

const FaqCategoryView: React.FC = observer(() => {
	const { t } = useTranslation();
	const { FAQ_CATEGORY, AUTH, COMMON } = useStore();
	const {
		listData,
		create,
		update,
		details,
		remove,
		fetchList,
		changeStatus,
		addFaqCategoryPopup,
		openAddFaqCategoryPopup,
		closeAddFaqCategoryPopup,
		editFaqCategoryID,
		editFaqCategoryPopup,
		openEditFaqCategoryPopup,
		closeEditFaqCategoryPopup,
		deleteFaqCategoryID,
		deleteFaqCategoryPopup,
		openDeleteFaqCategoryPopup,
		closeDeleteFaqCategoryPopup,
	} = FAQ_CATEGORY;

	const {permissions} = AUTH
	const {allPermissionList} = COMMON

	useEffect(()=>{
		//
	}, [allPermissionList]);

	return (
		<>
		<div className="Breadcrumb-bar">
			<BreadcrumbComponent items={FaqCategoryBreadcrumb(t).path} />
			<HeaderButtons
						key={1}
						openAddFaqCategoryPopup={openAddFaqCategoryPopup}
						addPermission={permissions && allPermissionList && permissions.indexOf(allPermissionList.ADD_FAQ_CATEGORY) >=0}
					/>
			</div>

			<ContentBox>
				<ListComponent
					rowData={listData}
					getData={fetchList}
					openEditFaqCategoryPopup={openEditFaqCategoryPopup}
					openDeleteFaqCategoryPopup={openDeleteFaqCategoryPopup}
					ChangeStatus={changeStatus}
					editPermission={permissions && allPermissionList && permissions.indexOf(allPermissionList.EDIT_FAQ_CATEGORY) >=0}
					deletePermission={permissions && allPermissionList && permissions.indexOf(allPermissionList.DELETE_FAQ_CATEGORY) >=0}
				/>
				<AddComponent
					createData={create}
					visible={addFaqCategoryPopup}
					closeAddFaqCategoryPopup={closeAddFaqCategoryPopup}
				/>
				<EditComponent
					EditID={editFaqCategoryID}
					EditData={update}
					ReadData={details}
					visible={editFaqCategoryPopup}
					closeEditFaqCategoryPopup={closeEditFaqCategoryPopup}
				/>
				<DeleteComponent
					DeleteID={deleteFaqCategoryID}
					DeleteData={remove}
					visible={deleteFaqCategoryPopup}
					closeDeleteFaqCategoryPopup={closeDeleteFaqCategoryPopup}
				/>
			</ContentBox>
		</>
	);
});

export default FaqCategoryView;
