import axios from "axios";
import { GridOptions, GridReadyEvent, IServerSideGetRowsParams } from "ag-grid-community";
import { makeAutoObservable } from "mobx";
import API_URL from "../../config/ApiUrl";
import RootStore from "../RootStore/RootStore";
import Config from "../../config/Config";

export default class TranslationStore {
	public grid?: any;
	private rootStore: RootStore;
	public perPage?:any = 100;
	public addTranslationPopup?: boolean;
	public editTranslationPopup?: boolean;
	public editTranslationID?: number;
	constructor() {
		this.rootStore = new RootStore();
		makeAutoObservable(this);
	}

	// Setter Functions

	public openAddTranslationPopup = (): void => {
		this.addTranslationPopup = true;
	};
	public closeAddTranslationPopup = (): void => {
		this.addTranslationPopup = false;
	};
	public openEditTranslationPopup = (id: number): void => {
		this.editTranslationPopup = true;
		this.setEditTranslationID(id);
	};
	public closeEditTranslationPopup = (): void => {
		this.editTranslationPopup = false;
		this.setEditTranslationID();
	};

	private setEditTranslationID = (id?: number): void => {
		this.editTranslationID = id;
	};

	private setGrid = (value: any) => {
		this.grid = value;
	};

	public setupGrid = (params: GridReadyEvent) => {
		this.setGrid(params);
		this.setPageSize()
		const datasource = this.createDatasource(Config.grid.server.gridOptions);
		params.api.setServerSideDatasource(datasource);
	};

	public setPageSize = (page = this.perPage) => {
		this.perPage = page;
		if (this.grid) {
			this.grid.api.paginationSetPageSize(parseInt(page));
		}
	};

	
	private createDatasource = (gridOptions?: GridOptions) => {
		return {
			gridOptions,
			getRows: (params: IServerSideGetRowsParams) => {
				const payload = this.rootStore.getServerListPayload(params);
				this.fetchList(payload).then((data) => {
					params.successCallback(data?.rows, data?.count);
					if (data.count <= 0) {
						params.api.showNoRowsOverlay();
					} else {
						params.api.hideOverlay();
					}
				});
			},
		};
	}

	// API Functions
	public fetchList = (payLoad:any): Promise<any> => {
		return axios.post(API_URL.TRASLATION.LIST,payLoad).then(({ data }) => {
			return data.data;
		});
	};

	public create = (payload: any): Promise<any> => {
		return axios
			.post(API_URL.TRASLATION.CREATE, payload)
			.then(({ data }) => {
				this.setupGrid(this.grid);
				return data.data;
			})
			.catch(({ data }) => {
				return Promise.reject(data);
			});
	};

	public details = (id: number): Promise<any> => {
		return axios.get(API_URL.TRASLATION.DETAILS(id)).then(({ data }) => {
			return data;
		});
	};

	public update = (payload: any): Promise<any> => {
		const editId = payload.id;
		delete payload['id']
		return axios.post(API_URL.TRASLATION.UPDATE(editId), payload)
			.then(({ data }) => {
				this.setupGrid(this.grid);
				return data;
			});
	};

}

