import React from "react";
import { observer } from "mobx-react";
import BreadcrumbComponent from "../../../components/BreadcrumbComponent/BreadcrumbComponent";
import { DonationCampaignBreadcrumb } from "../../../config/BreadcrumbConfig";
import HeaderButtons from "./components/HeaderButtons";
import ContentBox from "../../../components/ContentBox/ContentBox";
import ListComponent from "./components/ListComponent";
import DeleteComponent from "./components/DeleteComponent";
import useStore from "../../../store";
import { useHistory } from "react-router";
import { Link } from "react-router-dom";
import { Row } from "antd";
import { useTranslation } from "react-i18next";
const DonationCampaignView: React.FC = observer(() => {
	const pathHistory = useHistory();
	const { DONATION_CAMPAIGN } = useStore();
	const {
		setupGrid,
		remove,
		deleteDonationCampaignID,
		deleteDonationCampaignPopup,
		openDeleteDonationCampaignPopup,
		closeDeleteDonationCampaignPopup,
		changeStatus,
	} = DONATION_CAMPAIGN;
	const { t } = useTranslation();

	const addRedirect = () => {
		pathHistory.push("/donation-campaign/create");
	};

	const editRedirect = (id: number) => {
		pathHistory.push("/donation-campaign/" + id + "/edit");
	};
	return (
		<>
			<div className="Breadcrumb-bar">
				<BreadcrumbComponent items={DonationCampaignBreadcrumb(t).path} />
				<HeaderButtons key={1} addDonationCampaignRedirect={addRedirect} />
			</div>
			<Row justify="end">
				<Link to="donation" className="btn btn-primary btn-link ant-row-end">
					{t("BACK_TO_DONATION")}
				</Link>
			</Row>
			<ContentBox>
				<ListComponent
					setupGrid={setupGrid}
					editRedirect={editRedirect}
					openDeletePopup={openDeleteDonationCampaignPopup}
					ChangeStatus={changeStatus}
				/>
				<DeleteComponent
					DeleteID={deleteDonationCampaignID}
					DeleteData={remove}
					visible={deleteDonationCampaignPopup}
					closeDeleteFinePopup={closeDeleteDonationCampaignPopup}
				/>
			</ContentBox>
		</>
	);
});

export default DonationCampaignView;
