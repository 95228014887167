import React, { useEffect, useState }  from "react";
import { observer } from "mobx-react";
import {  Form } from 'antd';
import useStore from "../../../../store";
import { useHistory, useParams } from "react-router";
import { FormBox, InputBox } from "../../../../components/AntdAddons";
import { Row, Col, Layout, Button} from "antd";
import { useTranslation } from "react-i18next";

const AssignElectionRefreeComponent: React.FC = observer(() => {
	const { t } = useTranslation();
	const { ROOT, COMMON, NEW_ELECTION,ELECTION } = useStore();
	const {
		fetchelectionRefereeList,
		electionRefereeList,
	} = COMMON;
	const { assignelectionrefree,} = NEW_ELECTION;
	const { details} = ELECTION;
	const { AssignErrorToInput } = ROOT;
	const { id }: any = useParams();
	const [ElectionProcess, setElectionProcess] = useState<any>();

	useEffect(()=>{
			details(id).then(({ data }: any) => {
				setElectionProcess(data);
			});
		fetchelectionRefereeList();
	},[fetchelectionRefereeList, details])
	const [form] = Form.useForm();
	const [saving, setSaving] = useState<boolean>(false);
	const history = useHistory();
	const listRoute = () =>{
		history.push('/election-request');
  }
	// Handle submit and call function to save new record
	const handleSubmit = (data: any) => {
		setSaving(true);
		const formData =  new FormData();
		formData.append("election_id", id)
		data.election_refree_id && formData.append("election_refree_id", data.election_refree_id);

		assignelectionrefree(formData)
			.then(() => {
				setSaving(false);
				form.resetFields();
				listRoute();
			})
			.catch((e:any) => {
				setSaving(false);
				AssignErrorToInput(form, e?.data?.errors);
			})
	};

	return (
		<>
			<FormBox
				id="addFaqForm"
				form={form}
				encType="multipart/formdata"
				onFinish={handleSubmit}>
				<Layout style={{ padding: '0 24px' }}>
					<Row gutter={6} wrap={false}>
					<Col span={8} className="form__input__textbox">
							<InputBox.Text
								size="large"
								placeholder={ElectionProcess && ElectionProcess?.group.name}
								label={t("GROUP_NAME")}
								disabled
								required
							/>
						</Col>
						<Col span={8} className="form__input__textbox">
						<InputBox.Select
								options={{
									list:electionRefereeList,
									valueKey:"id",
									textKey:"user_name"
								}}
								name="election_refree_id"
								label={t("ELECTION_REFEREE")}
								placeholder={t("ELECTION_REFEREE")}
								required
							/>
				</Col>
				</Row>
				<Col span={24} className="permission__button ant-row-center ant-row mt-50">
						<Button type="primary"
							htmlType="submit"
							loading={saving}
							key="submit1">{t('SAVE')}</Button>
						<Button type="primary" key="resetCancel" danger onClick={listRoute}>{t('CANCEL')}</Button>
					</Col>
				</Layout>

			</FormBox>
		</>
	);
});

export default AssignElectionRefreeComponent;
