import React, { useState, useEffect } from "react";
import { Button, Modal, Layout, Row, Col } from "antd";
import moment from "moment";
import { useTranslation } from "react-i18next";
import { displayFormats } from "../../../../config/Global";

interface DetailProps {
	ElectionProcessID: number;
	closePopup: () => void;
	visible?: boolean;
	ReadData: (id: number) => Promise<any>;
}

const ElectionProcessDetails: React.FC<DetailProps> = ({
	ElectionProcessID,
	visible,
	closePopup,
	ReadData,
}) => {
	const [ElectionProcess, setElectionProcess] = useState<any>();
	const { t } = useTranslation();

	useEffect(() => {
		if (ElectionProcessID) {
			ReadData(ElectionProcessID).then(({ data }) => {
				setElectionProcess(data);
			});
		}
	}, [ElectionProcessID, ReadData]);

	const close = () => {
		setElectionProcess(null);
		closePopup();
	};

	return (
		<Modal
			centered
			title={t("ELECTION_PROCESS_DETAILS")}
			visible={visible}
			destroyOnClose={true}
			onCancel={close}
			width={'auto'}
			style={{ minWidth: "900px" }}
			footer={[
				<Button key="2" onClick={close}>
					{t("CLOSE")}
				</Button>,
			]}
		>
			<Layout className="details_table">
				<Row gutter={6} wrap={false} >
					<Col span={12} className="form__input__textbox form__input__selectBox">
						<label>{t("ELECTION_TITLE")} : </label>
						<span>{ElectionProcess && ElectionProcess?.title}</span>
					</Col>
					<Col span={12} className="form__input__textbox">
						<label>{t("ELECTION_DATE")} : </label>
						<span>{moment.utc(ElectionProcess && ElectionProcess?.election_date).local().format(displayFormats.DATE_FORMAT)}</span>	
					</Col>
				</Row>
				<Row gutter={6} wrap={false} >
					<Col span={12} className="form__input__textbox form__input__selectBox">
						<label>{t("GROUP_NAME")} : </label>
						<span>{ElectionProcess && ElectionProcess?.group.name}</span>
					</Col>
					<Col span={12} className="form__input__textbox">
						<label>{t("GROUP_DESCRIPTION")} : </label>
						<span>{ElectionProcess && ElectionProcess?.group.group_description}</span>
					</Col>
				</Row>
				
				<Row gutter={6} wrap={false}>
					<Col span={12} className="form__input__textbox">
						<label>{t("CREATED_AT")}: </label>
						<span>{moment.utc(ElectionProcess?.created_at).local().format(displayFormats.DATE_TIME_FORMAT)}</span>
					</Col>
					<Col span={12} className="form__input__textbox">
						<label>{t("UPDATED_AT")}: </label>
						<span>{moment.utc(ElectionProcess?.updated_at).local().format(displayFormats.DATE_TIME_FORMAT)}</span>
					</Col>
				</Row>
				<Row gutter={6} wrap={false}>
					<Col span={12} className="form__input__textbox">
						<label>{t("CREATED_BY")}: </label>
						<span>{ElectionProcess?.createdBy ? ElectionProcess?.createdBy?.firstname + " " + ElectionProcess?.createdBy?.lastname + " (" + ElectionProcess?.createdBy?.email + ")" : " - "}</span>
					</Col>
					<Col span={12} className="form__input__textbox">
						<label>{t("UPDATED_BY")}: </label>
						<span>{ElectionProcess?.updatedBy ? ElectionProcess?.updatedBy?.firstname + " " + ElectionProcess?.updatedBy?.lastname + " (" + ElectionProcess?.updatedBy?.email + ")" : " - "}</span>
					</Col>
				</Row>
			</Layout>
		</Modal>
	);
};

export default ElectionProcessDetails;
