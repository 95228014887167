import React, { useEffect } from "react";
import { AgGridColumn } from "ag-grid-react";
import moment from "moment";
import AgGridWrapper from "../../../../../components/AgGridWrapper/AgGridWrapper";
import Config from "../../../../../config/Config";
import DateComparator from "../../../../../utils/DateComparator";
import { RoleListDataProps } from "../../../../../store/RoleStore/RoleInterface";
import { Button, Switch } from "antd";
import { EditOutlined, DeleteOutlined } from "@ant-design/icons";
import { useTranslation } from "react-i18next";
import { displayFormats } from "../../../../../config/Global";

interface ListProps {
	rowData: RoleListDataProps[];
	getData: () => Promise<any>;
	editRole: (id: number) => void | any;
	openDeleteRolePopup: (id: number) => void | any;
	ChangeStatus:(data: any) => Promise<any>;
	ShowPermissionModel:(id:number) => void |any;
	editPermission:boolean;
	deletePermission:boolean;
}

const ListComponent: React.FC<ListProps> = ({
	rowData,
	getData,
	editRole,
	openDeleteRolePopup,
	ChangeStatus,
	ShowPermissionModel,
	editPermission,
	deletePermission,
	}) => {
	const { t } = useTranslation();
	useEffect(() => {
		getData();
	}, [getData]);

	const changeStatus = (data:any) => {
		ChangeStatus(data)
		.then(() => {
				getData();
				return;
			})
			.finally(() => {
				return;
			});
	}

	const ActionRenderer = (params: any) => {
		return (
			<div className="action-column">
				{(params.data?.is_editable == 1) && <Button
					type="text"
					title={t("EDIT")}
					icon={<EditOutlined />}
					onClick={() => editRole(params.data?.id)}
					hidden={!editPermission}
				></Button>}
				{(params.data?.is_deletable == 1) && <Button
					type="text"
					title={t("DELETE")}
					icon={<DeleteOutlined />}
					onClick={() => openDeleteRolePopup(params.data?.id)}
					hidden={!deletePermission}
				></Button>}
			</div>
		);
	};



	const PermissionRenderer = (params: any) => {
		return (
			<div className="action-column">
				<Button
					title={t("VIEW_PERMISSIONS")}
					type="primary"
					className="view-permissions-btn"
					key={"permissionbox"+params.data?.id}
					style={{backgroundColor:"#000000"}}
					onClick={() => ShowPermissionModel(params.data?.id)}
				>{t("VIEW_PERMISSIONS")}</Button>
			</div>
		);
	};

	const SwitchRenderer = (params: any) => {
		return (
			<div>
				<Switch
					defaultChecked={params.data?.status}
					onChange= {() => changeStatus({id:params.data?.id,status:params.data?.status ==1 ? 0 : 1})}
					disabled={!editPermission}
				/>
			</div>
		);
	};
	return (
		<AgGridWrapper rowData={rowData} frameworkComponents={{ ActionRenderer, SwitchRenderer, PermissionRenderer }} overlayLoadingTemplate={t("PLEASE_WAIT_WHILE_DATA_IS_LOADING")} overlayNoRowsTemplate={t("NO_RECORD_FOUND")}>
			<AgGridColumn field="id" headerName={"# " + t('ID')} width={100} filter= {'agNumberColumnFilter'}/>
			<AgGridColumn field="name" headerName={t("NAME")} />
			<AgGridColumn field="role_type"
				headerName={t("ROLE_TYPE")}
				valueGetter={({ data }) => (data?.role_type == 1 ? "Admin" : "User")}
				width={120}
			/>
			<AgGridColumn
				headerName={t("PERMISSIONS")}
				cellRenderer="PermissionRenderer"
				sortable={false}
				filter={false}
				width={200}
			/>
			<AgGridColumn
				field="status"
				headerName={t('STATUS')}
				cellRenderer="SwitchRenderer"
				width={120}
				pinned="right"
				sortable={false}
				filter={false}
			/>
			<AgGridColumn
				field="created_by_name"
				headerName={t("CREATED_BY")}
				width={150}
			/>
			<AgGridColumn
				field="updated_by_name"
				headerName={t("UPDATED_BY")}
				width={150}
			/>
			<AgGridColumn
				field="created_at"
				headerName={t('CREATED_AT')}
				valueGetter={({ data }) =>
					data.created_at &&
					moment(data.created_at).format(Config.dateTimeFormat)
				}
				filter={"agDateColumnFilter"}
				valueFormatter={(data:any) =>
					data?.data?.created_at && moment.utc(data?.data?.created_at).local().format(displayFormats.DATE_TIME_FORMAT)
				}
				filterParams={{
					comparator: DateComparator,
				}}
				width={220}
			/>
			<AgGridColumn
				field="updated_at"
				headerName={t("UPDATED_AT")}
				valueGetter={({ data }) =>
					data.updated_at &&
					moment(data.updated_at).format(Config.dateTimeFormat)
				}
				filter={"agDateColumnFilter"}
				valueFormatter={(data:any) =>
					data?.data?.updated_at && moment.utc(data?.data?.updated_at).local().format(displayFormats.DATE_TIME_FORMAT)
				}
				filterParams={{
					comparator: DateComparator,
				}}
				width={220}
			/>
			<AgGridColumn
				headerName={t('ACTION')}
				type="actionColumn"
				sortable={false}
				filter={false}
				width={100}
				pinned={"right"}
			/>
		</AgGridWrapper>
	);
};

export default ListComponent;
