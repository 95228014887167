import React from "react";
import { PlusOutlined } from "@ant-design/icons";
import { Button, Space } from "antd";
import { useTranslation } from "react-i18next";

interface HeaderButtonsProps {
	openAddFaqCategoryPopup: () => void;
	addPermission:boolean;
}

const HeaderButtons: React.FC<HeaderButtonsProps> = ({
	openAddFaqCategoryPopup,
	addPermission
}) => {
	const { t } = useTranslation();
	return (
		<Space hidden={!addPermission}>
			<Button
				type="primary"
				icon={<PlusOutlined />}
				onClick={openAddFaqCategoryPopup}
			>
				{t("CREATE")}
			</Button>
		</Space>
	);
};

export default HeaderButtons;
