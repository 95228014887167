import React, { useState } from 'react'
import { FormBox, InputBox } from '../../../../components/AntdAddons'
import { Button, Col, Form, Layout, message, Row, Upload } from 'antd'
import { UploadOutlined } from '@ant-design/icons'
import { useTranslation } from 'react-i18next';
import useStore from '../../../../store';
import { uploadedFileOnPreview } from '../../../../config/Global';
import { RcFile } from 'antd/lib/upload';
import { ImportDataRequest } from '../../../../requests/ImportDataRequest';

export default function AddComponent() {

    const { t } = useTranslation();
    const { COMMON, IMPORT_TYPE, ROOT } = useStore();
    const { importDataList } = COMMON;
    const { importTypeData } = IMPORT_TYPE;
    const { AssignErrorToInput } = ROOT;
    const [fileList, setFileList] = useState<any>([])
    const [displayErrors, setDisplayErrors] = useState<any>()
    const [form] = Form.useForm();


    const wrapperBeforeUpload = (file: RcFile) => {
        const ifXlsxOrXls =
            file.type == "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" ||
            file.type == "application/vnd.ms-excel"
        if (!ifXlsxOrXls) {
            message.error(t("FILE_TYPE_MSG"))
        }
        return false
    };

    const handleSubmit = (data: any) => {
        setDisplayErrors("")
        const formData = new FormData();
        fileList?.file && formData.append('file', fileList?.file);
        data.type && formData.append('type', data.type);

        importTypeData(formData)
            .then(() => {
                form.resetFields();
                setFileList([])
            })
            .catch((e: any) => {
                e?.is_display_error && setDisplayErrors(e.error)
                //AssignErrorToInput(form, e?.data?.errors);
                AssignErrorToInput(form, e?.errors);
            })
    }

    const uploadButton = (
		<div>
			{<UploadOutlined />}
			<div style={{ marginTop: 8 }}>{t("CLICK_TO_UPLOAD_FILE")}</div>
		</div>
	);

    return (
        <FormBox onFinish={handleSubmit} form={form}>
            <Layout style={{ padding: '0 24px' }} className='importdatalayout'>
                <Row gutter={6} wrap={false} className='importdata'>
                    <Col span={6} className="form__input__textbox">
                        <InputBox.Select
                            required
                            options={{
                                list: importDataList,
                                valueKey: "id",
                                textKey: "name"
                            }}
                            label={t("TYPE")}
                            name="type"
                            placeholder={t("TYPE")}
                            rules={ImportDataRequest(t).type}
                        />
                    </Col>
                    <Col span={8} >
                        <Form.Item name={"file"} rules={ImportDataRequest(t).file} label={t("FILE")}>
                            <Upload
                                accept={"application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, image/jpegapplication/vnd.ms-excel"}
                                maxCount={1}
                                beforeUpload={wrapperBeforeUpload}
                                onPreview={uploadedFileOnPreview}
                                fileList={fileList ? fileList?.fileList : []}
                                onChange={(e) => {
                                    setFileList(e)
                                }}
                                showUploadList={true}
                                listType={"picture-card"}
                                
                                onRemove={()=>{
                                    setFileList([])
                                }}
                            >
                                {(fileList?.fileList?.length<1 || fileList?.length<1) && uploadButton}
                            </Upload>
                        </Form.Item>
                    </Col>
                    <Col span={6} className="form__input__textbox">
                        <Button type="primary"
                            htmlType="submit"
                            key="submit">{t('SAVE')}</Button>

                    </Col>
                </Row>
                <Row className='importdatacontent'>
                    <div dangerouslySetInnerHTML={{ __html: displayErrors }} />
                </Row>
            </Layout>
        </FormBox>
    )
}
