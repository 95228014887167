import { makeAutoObservable } from "mobx";
import axios from "axios";
import { doLoginType, doRegisterType, forgotPassewordType, resetPasswordType, userType } from "./AuthInterface";
import RootStore from "../RootStore/RootStore";
import API_URL from "../../config/ApiUrl";
import CommonStore from "../CommonStore/CommonStore";

export default class AuthStore {
	// Variables
	public user?: userType;
	public token = localStorage.getItem("token");
	public appLoading: boolean;
	public email?: string;
	public permissions? : any[];
	public activeKey? : any[];
	private rootStore: RootStore;
	private commonStore: CommonStore;
	constructor(commonStore: CommonStore) {
		this.appLoading = true;
		this.rootStore = new RootStore();
		this.commonStore = commonStore;
		makeAutoObservable(this);
	}

	// Initiate Application Functions
	public InitializeApp = async () => {
		this.rootStore.SetAxiosBaseUrl();
		this.rootStore.setAxiosInterceptor();
		this.rootStore.SetNotificationConfig();
		await this.commonStore.fetchSyncData();
		this.SetupHeaders(localStorage.getItem("token"));
	};

	public SetupHeaders = (token?: any): void => {
		this.setAppLoading(true);
		this.rootStore
			.setAxiosHeaders(token)
			.then((token) => {
				this.setToken(token);
				this.fetchAuthUser();
			})
			.catch(() => {
				this.setToken();
				this.setUser();
				this.setAppLoading(false);
			});
	};

	// Setter Functions
	public setActiveKey = (value?: any): void => {
		this.activeKey = value;
	};
	public setUser = (value?: userType): void => {
		this.user = value;
	};
	public setToken = (value?: any): void => {
		this.token = value;
	};
	public setAppLoading = (value: boolean): void => {
		this.appLoading = value;
	};
	public setEmail = (value: string): void => {
		this.email = value;
	};

	public setPermissions = (value:any[]): void => {
		this.permissions = value;
	};
	// API Functions
	public doLogin = (payload: doLoginType): Promise<any> => {
		return axios
			.post(API_URL.ADMIN_LOGIN, payload)
			.then(({ data }) => {
				this.SetupHeaders(data?.data?.token);
				return data;
			})
			.catch(({ data }) => {
				this.setToken();
				this.setUser();
				return Promise.reject(data);
			});
	};

	public doRegister = (payload: doRegisterType): Promise<any> => {
		return axios.post(API_URL.REGISTER_WITH_EMAIL, payload).then(() => {
			this.setEmail(payload.email);
		});
	};

	public fetchAuthUser = (): Promise<any> => {
		return axios
			.get(API_URL.ME)
			.then(({ data }) => {
				this.setUser(data.data.user);
				const permissions = data.data.permissions.map((item:any) => {return item.permission_name});
				this.setPermissions(permissions);
				return data;
			})
			.catch(({ data }) => {
				this.setToken();
				this.setUser();
				this.setPermissions([]);
				return Promise.reject(data);
			})
			.finally(() => this.setAppLoading(false));
	};
	public getprofileDetails = (): Promise<any> => {
		return axios.get(API_URL.ME).then(({ data }) => {
			return data.data.user;
		});
	};

	public seditprofileDetails = (payLoad:any): Promise<any> => {
		return axios.post(API_URL.ME,payLoad).then(({ data }) => {
			return data.data.user;
		});
	};

	public logOut = () => {
		return axios
			.post(API_URL.LOGOUT)
			.then(({ data }) => {
				this.setToken();
				this.setUser();
				this.setPermissions([]);
				return data;
			})
			.catch(({ data }) => {
				this.setToken();
				this.setUser();
				this.setPermissions([]);
				return Promise.reject(data);
			})
			.finally(() => this.setAppLoading(false));
	}

	public forgetPassword = (payload: forgotPassewordType): Promise<any> => {
		return axios.post(API_URL.FORGET_PASSWORD, payload).then(() => {
			this.setEmail(payload.email);
		});
	};

	public resetPasswordWithOtp = (otp:string, payload: resetPasswordType): Promise<any> => {
		return axios.post(API_URL.RESET_PASSWORD_OTP(otp), payload).then((data:any) => {
			return data;
		});
	};

	public changePassword = (payload: any): Promise<any> => {
		return axios.post(API_URL.CHANGE_PASSWORD,payload).then((data:any) => {
			return data;
		});
	};
}
