import React, { useEffect, useState }  from "react";
import { observer } from "mobx-react";
import {  Form } from 'antd';
import useStore from "../../../../store";
import { useHistory, useParams } from "react-router";
import FormComponent from "./FormComponent";

const EditdonationComponent: React.FC = observer(() => {
	const { DONATION, COMMON, ROOT } = useStore();
	const {
		update,
		donationList,
		details,
	} = DONATION;

	const {
		donationFormData, 
		getDonationFormData, 
		selectedLanguage
	} = COMMON;

	const [donation, setdonation] = useState<any>(null);
	const [donationSubjectList, setDonationSubjectList] =  useState<boolean>();
	const [groupList, setGroupList] =  useState<boolean>();
	const [countryList, setCountryList] =  useState<boolean>();	
	const [showGroupList, setShowGroupList] = useState<boolean>(false);
	const { id }: any = useParams();
	const { AssignErrorToInput } = ROOT;
	useEffect(()=>{
		selectedLanguage && !donationFormData && getDonationFormData();
		selectedLanguage && donationFormData && setDonationSubjectList(donationFormData.donationSubjectList);
		selectedLanguage && donationFormData && setGroupList(donationFormData.groupList);
		selectedLanguage && donationFormData && setCountryList(donationFormData.countryList);
		form.setFieldsValue({donation_type: 1});		
		details(id).then((data:any) => {
			setdonation(data);
			form.resetFields();
			form.setFieldsValue({
					name:data?.name,
					address:data?.address,
					amount:data?.amount,
					organisation:data?.organisation,
					group_id:data?.group_id,
					phone_number:data?.phone_number,
					donation_subject:data?.donation_subject,
					email:data?.email,
					donation_type: data.donation_type,
					zipcode: data.zipcode,
					country_id: data.country_id,
			});			
			
			setShowGroupList(form.getFieldValue("donation_type")  === 2)	
	});
	},[details,selectedLanguage, donationFormData])
	const [form] = Form.useForm();
	const [saving, setSaving] = useState<boolean>(false);
	const history = useHistory();
	const listRoute = () =>{
		history.push('/donation');
  }

  

	// Handle submit and call function to save new record
	const handleSubmit = (data: any) => {
		setSaving(true);
		const formData = new FormData();
		data.name && formData.append('name', data.name);
		data.address && formData.append('address', data.address);
		data.amount && formData.append('amount', data.amount);
		data.organisation && formData.append('organisation', data.organisation);
		data.donation_type && formData.append('donation_type', data.donation_type);
		data.group_id && formData.append('group_id', data.group_id);
		data.phone_number && formData.append('phone_number', data.phone_number);
		data.donation_subject && formData.append('donation_subject', data.donation_subject);
		data.email && formData.append('email', data.email);
		data.zipcode && formData.append('zipcode', data.zipcode);
		data.country_id && formData.append('country_id', data.country_id);
		update(donation.id, formData)
			.then(() => {
				setSaving(false);
				form.resetFields();
				listRoute();
			})
			.catch((e:any) => {
				setSaving(false);
				const errors = e?.data?.errors || e?.errors
				AssignErrorToInput(form, errors);
			})
	};

	return (
		donation && <FormComponent
		formId="editDonationForm"
		form={form}
		handleSubmit={handleSubmit}
		saving={saving}
		isEdit={true}
		groupList={groupList}
		donationSubjectList={donationSubjectList}
		countryList={countryList}
		listRoute={listRoute}
		donationList={donationList}
		showGroupList={showGroupList}
		setShowGroupList={setShowGroupList}
	/>
	);
});
export default EditdonationComponent;
