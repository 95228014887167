import axios from "axios";
import { makeAutoObservable } from "mobx";
import API_URL from "../../config/ApiUrl";
import { ImportTypeDataProps } from "./ImportTypeInterface";

export default class ImportTypeStore {
    public listData?: ImportTypeDataProps[];

    constructor() {
        makeAutoObservable(this);

    }

    // Setter Functions

    // API Functions
    public importTypeData = (payload: FormData): Promise<any> => {
        return axios({
            method: "post",
            url: API_URL.IMPORT_DATA.CREATE,
            data: payload,
            headers: { "Content-Type": "multipart/form-data" },
        }).then(({ data }) => {
            return data;
        })
            .catch(({ data }) => {
                return Promise.reject(data);
            });
    };
}
