import React from "react";
import { AgGridColumn } from "ag-grid-react";
import AgGridWrapper from "../../../../../components/AgGridWrapper/AgGridWrapper";
import moment from "moment";
import DateComparator from "../../../../../utils/DateComparator";
import Config from "../../../../../config/Config";
import useStore from "../../../../../store";
import { Row } from "antd";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { displayFormats } from "../../../../../config/Global";
const ListFineComponent: React.FC = () => {
	const { t } = useTranslation();
	const { GROUP_FINANCE} = useStore();
	const {setUpFineGrid, } = GROUP_FINANCE;
	return (
		<>
			<Row justify="end">
				<Link
					to="/fine-management"
					className="btn btn-primary btn-link ant-row-end"
				>
					{t("GO_TO_FINES")}
				</Link>
			</Row>

				<AgGridWrapper
					type="serverSide"
					onGridReady={setUpFineGrid}
					rowSelection={"multiple"}
					overlayLoadingTemplate={t("PLEASE_WAIT_WHILE_DATA_IS_LOADING")} overlayNoRowsTemplate={t("NO_RECORD_FOUND")}
				>
				<AgGridColumn
					field="id"
					headerName={"# " + t('ID')}
					width={100}
					pinned={"left"}
				/>
				<AgGridColumn
					field="date"
					headerName={t("DATE")}
					valueGetter={({ data }) =>
						data.created_at &&
						moment(data.created_at).format(Config.dateTimeFormat)
					}
					filter={"agDateColumnFilter"}
					valueFormatter={(data:any) =>
						data?.data?.created_at && moment.utc(data?.data?.created_at).local().format(displayFormats.DATE_TIME_FORMAT)
					}
					filterParams={{
						comparator: DateComparator,
					}}
					width={220}
				/>
				<AgGridColumn field="user_name" headerName={t("MEMBER_NAME")} />
				<AgGridColumn field="fine_type_name" headerName={t("FINE_TYPE")} />
				<AgGridColumn field="fine_amount" headerName={t("AMOUNT")} />
				<AgGridColumn
					field="fine_due_date"
					headerName={t("DUE_DATE")}
					width={220}
					valueFormatter={(data:any) =>
						data?.data?.fine_due_date && moment.utc(data?.data?.fine_due_date).local().format(displayFormats.DATE_FORMAT)
					}
				/>
				<AgGridColumn
					field="fine_paid_status"
					headerName={t("PAID_STATUS")}
					width={220}
				/>
			</AgGridWrapper>
		</>
	);
};

export default ListFineComponent;
