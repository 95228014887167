import { RequestProps } from "../config/InterfacesAndTypes";
import Message from "../config/Message";

export const SliderRequest = (t: any): RequestProps => ({
	title: [{ required: true, message: Message(t).required(t("TITLE")) }],
	description: [{ required: true, message: Message(t).required(t("DESCRIPTION")) }],
	image: [{ required: true, message: Message(t).required(t("IMAGE")) }],
	sequence: [{ required: true, message: Message(t).required(t("SEQUENCE")) }],
	slider_type: [{ required: true, message: Message(t).required(t("SLIDER_TYPE")) }],
});
