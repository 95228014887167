import { RequestProps } from "../config/InterfacesAndTypes";
import Message from "../config/Message";

export const FineRequest = (t: any): RequestProps => ({
	group_id: [{ required: true, message: Message(t).required(t("GROUP_NAME")) }],
	user_id: [{ required: true, message: Message(t).required(t("MEMBER_NAME")) }],
	fine_type_id: [{ required: true, message: Message(t).required(t("FINE_TYPE")) }],
	fine_amount: [
		{ required: true, message: Message(t).required(t("AMOUNT")) },
		{ pattern: /^([0-9]+)$/, message: Message(t).type.pattern(t("AMOUNT")) },
		({ getFieldValue }) => ({
			validator() {
				if (getFieldValue("fine_amount") < 1) {
					return Promise.reject(Message(t).minIntFloat(1, t('AMOUNT')));
				}
				return Promise.resolve();
			},
		}),
	],
	fine_date: [{ required: true, message: Message(t).required(t("DATE")) }],
	fine_due_date: [
		{ required: true, message: Message(t).required(t("DUE_DATE")) },
	],
	description: [
		{ required: true, message: Message(t).required(t("DESCRIPTION")) },
		{ min: 3, message: Message(t).min(3, t("DESCRIPTION")) },
		{ max: 5000, message: Message(t).max(5000, t("DESCRIPTION")) },
	],
});
