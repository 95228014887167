import {
	GridOptions,
	GridReadyEvent,
	IServerSideGetRowsParams,
} from "ag-grid-community";
import axios from "axios";
import { makeAutoObservable } from "mobx";
import API_URL from "../../config/ApiUrl";
import Config from "../../config/Config";
import RootStore from "../RootStore/RootStore";
import { GroupListDataProps } from "./GroupInterface";

export default class GroupStore {
	public listData?: GroupListDataProps[];
	public grid?: any;
	private rootStore: RootStore;
	public perPage?:any = 100;
	constructor() {
		this.rootStore = new RootStore();
		makeAutoObservable(this);
	}

	// Setter Functions
	private setListData = (value?: GroupListDataProps[]): void => {
		this.listData = value;
	};
	private setGrid = (value: any) => {
		this.grid = value;
	};

	public setupGrid = (params: GridReadyEvent) => {
		this.setGrid(params);
		this.setPageSize()
		const datasource = this.createDatasource(Config.grid.server.gridOptions);
		params.api.setServerSideDatasource(datasource);
	};

	public setPageSize = (page = this.perPage) => {
		this.perPage = page;
		if (this.grid) {
			this.grid.api.paginationSetPageSize(parseInt(page));
		}
	};

	private createDatasource = (gridOptions?: GridOptions) => {
		return {
			gridOptions,
			getRows: (params: IServerSideGetRowsParams) => {
				const payload = this.rootStore.getServerListPayload(params);
				this.fetchList(payload).then((data) => {
					params.successCallback(data?.rows, data?.count);
					if (data.count <= 0) {
						params.api.showNoRowsOverlay();
					} else {
						params.api.hideOverlay();
					}
				});
			},
		};
	};
	// API Functions
	public fetchList = (payLoad: any): Promise<any> => {
		return axios.post(API_URL.GROUP.LIST, payLoad).then(({ data }) => {
			this.setListData(data.data);
			return data.data;
		});
	};

	public create = (payLoad: FormData): Promise<any> => {
		return axios.post(API_URL.GROUP.CREATE, payLoad).then(({ data }) => {
			return data.data;
		});
	};

	public details = (id: number): Promise<any> => {
		return axios.get(API_URL.GROUP.DETAILS(id)).then(({ data }) => {
			return data.data;
		});
	};

	public update = (id: number, payload: FormData): Promise<any> => {
		return axios.post(API_URL.GROUP.UPDATE(id), payload).then(({ data }) => {
			return data.data;
		});
	};

	public remove = (id: number): Promise<any> => {
		return axios.post(API_URL.GROUP.DELETE(id)).then(({ data }) => {
			return data.data;
		});
	};

	public changeStatus = (payload: any): Promise<any> => {
		return axios
			.post(API_URL.GROUP.CHANGE_STATUS(payload.id), payload)
			.then(({ data }) => {
				this.setupGrid(this.grid);
				return data.data;
			});
	};

	public exportData = (exportType: any): Promise<any> => {
		const payLoad = this.rootStore.getServerListPayloadForExport(this.grid);
		payLoad.export_type = exportType;
		return this.fetchList(payLoad).then((data: any) => {
			this.rootStore.downloadUrl(data.data?.downloadExportUrl);
			return data;
		});
	};

	public exportList = (exportType: any): Promise<any> => {
		const payLoad: any = this.rootStore.getServerListPayloadForExport(this.grid);
		payLoad.export_type = exportType;
		return this.fetchList(payLoad).then((data: any) => {
			this.rootStore.downloadUrl(data?.downloadExportUrl);
			return data;
		});
	};
}
