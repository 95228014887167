import React, { useEffect, useState } from "react";
import { observer } from "mobx-react";
import { Form } from 'antd';
import useStore from "../../../../../store";
import { useHistory } from "react-router";
import FormComponent from "./FormComponent";
import moment from "moment";
import RoleList from "../../../../../config/RoleList";
import { parsePhoneNumber } from "react-phone-number-input";

const AddUserComponent: React.FC = observer(() => {
	const { USER, COMMON } = useStore();
	const {
		create,
	} = USER;

	const {
		professionList,
		fetchProfession,
		fetchRole,

	} = COMMON;

	const { ROOT } = useStore();
	const { AssignErrorToInput } = ROOT;
	const [form] = Form.useForm();
	const [saving, setSaving] = useState<boolean>(false);
	const history = useHistory();
	const [roleToSelect, setRoleToSelect] = useState<any>([])
	const [phoneNo, setPhoneNo] = useState<any>()

	useEffect(() => {
		fetchProfession()
		fetchRole().then((data: any) => {
			setRoleToSelect(data.filter((role: any) => role.name == RoleList.VILLAGE_AGENT || role.role_type == 1 || role.name == RoleList.ELECTION_REFREE));
		});
	}, [fetchProfession, fetchRole]);

	const listRoute = () => {
		history.push('/user-management');
	}

	const setPhone = (val: any) => {
		const phoneDetails: any = val ? parsePhoneNumber(val) : "";
		setPhoneNo(phoneDetails);
	};
	// Handle submit and call function to save new record
	const handleSubmit = (data: any) => {
		setSaving(true);
		data.country_code=phoneNo?.country
		data.countryCallingCode="+" + phoneNo?.countryCallingCode
		const formData = new FormData();
		data.firstname && formData.append('firstname', data.firstname);
		data.lastname && formData.append('lastname', data.lastname);
		data.email && formData.append('email', data.email);
		data.gender && formData.append('gender', data.gender);
		data.currency_id && formData.append('currency_id',data.currency_id)
		data.user_type && formData.append('user_type', data.user_type);
		data.phone_number && formData.append('phone_number', phoneNo.nationalNumber);
		data.country_code && formData.append('country_code', phoneNo?.country)
		data.countryCallingCode && formData.append('countryCallingCode', "+" + phoneNo?.countryCallingCode)
		if (data.user_type == 1) {
			data.role_id.forEach((roleId: any) => {
				formData.append('role_id[]', roleId);
			});
		} else {
			data.role_id && formData.append('role_id', data.role_id);
		}

		data.profession_id && formData.append('profession_id', data.profession_id);
		data.address && formData.append('address', data.address);
		data.date_of_birth && formData.append('date_of_birth', moment(data.date_of_birth).utc().format('YYYY-MM-DD'));
		create(formData)
			.then(() => {
				setSaving(false);
				form.resetFields();
				listRoute();
			})
			.catch((e: any) => {
				setSaving(false);
				AssignErrorToInput(form, e?.data?.errors);
			})
	};

	return (
		<FormComponent
			formId="addUserForm"
			form={form}
			handleSubmit={handleSubmit}
			saving={saving}
			listRoute={listRoute}
			isEdit={false}
			professionList={professionList}
			roleList={roleToSelect}
			phoneNo={phoneNo}
			setPhone={setPhone}
		/>
	);
});
export default AddUserComponent;
