import { observer } from "mobx-react";
import React from "react";
import { useHistory } from "react-router";
import BreadcrumbComponent from "../../../../components/BreadcrumbComponent/BreadcrumbComponent";
import ContentBox from "../../../../components/ContentBox/ContentBox";
import { GroupFinanceManagementBreadcrumb } from "../../../../config/BreadcrumbConfig";
import useStore from "../../../../store";
import ListComponent from "./components/ListComponent";
import RecordPerPage from "../../../../utils/RecordPerPage";
import { useTranslation } from "react-i18next";

const GroupFinanceView: React.FC = observer(() => {
	const { t } = useTranslation();
	const { GROUP_FINANCE } = useStore();
	const { setupGrid, setPageSize, perPage } = GROUP_FINANCE;
	const pathHistory = useHistory();

	const detailsRedirect = (id: number) => {
		pathHistory.push(
			"/group-finance-management/" + id + "/group-finance-details"
		);
	};

	return (
		<>
			<div className="Breadcrumb-bar">
				<BreadcrumbComponent items={GroupFinanceManagementBreadcrumb(t).path} />
			</div>

			<ContentBox>
				<div className="RecordPerPage__right">
					<RecordPerPage
						style={{ width: "150px" }}
						defaultValue={perPage + " per page"}
						onChange={setPageSize}
					/>
				</div>
				<ListComponent
					setupGrid={setupGrid}
					detailsRedirect={detailsRedirect}
				/>
			</ContentBox>
		</>
	);
});

export default GroupFinanceView;
