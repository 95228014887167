import React from "react";
import { AgGridColumn } from "ag-grid-react";
import AgGridWrapper from "../../../../../components/AgGridWrapper/AgGridWrapper";
import { GridReadyEvent } from "ag-grid-community";
import { EyeOutlined } from "@ant-design/icons";
import { Button} from "antd";
import { useTranslation } from "react-i18next";

interface ListProps {
	setupGrid: (params: GridReadyEvent) => void;
	detailsRedirect: (id: number) => void;
}

const ListComponent: React.FC<ListProps> = ({
	setupGrid,
	detailsRedirect,
}) => {
	const { t } = useTranslation();

	const ActionRenderer = (params: any) => {
		return (
			<div className="action-column">
				<Button
					type="text"
					title={t("DETAILS")}
					icon={<EyeOutlined />}
					onClick={() => detailsRedirect(params.data?.id)}
				></Button>
			</div>
		);
	};


	return (
		<AgGridWrapper
			type="serverSide"
			onGridReady={setupGrid}
			rowSelection={"multiple"}
			frameworkComponents={{ ActionRenderer}}
			overlayLoadingTemplate={t("PLEASE_WAIT_WHILE_DATA_IS_LOADING")} overlayNoRowsTemplate={t("NO_RECORD_FOUND")}

		>
			<AgGridColumn
				field="id"
				headerName={"# " + t('ID')}
				width={140}
				pinned={"left"}
				filter="agNumberColumnFilter"
			/>
			<AgGridColumn
				field="name"
				headerName={t('GROUP_NAME')}
				width={350}
			/>
			<AgGridColumn field="numbers_of_member" headerName={t("NUMBER_OF_MEMBERS")} width={180}  filter="agNumberColumnFilter"/>
			<AgGridColumn field="balance" headerName={t("TOTAL_SAVING")} width={180} filter="agNumberColumnFilter"/>
			<AgGridColumn
				headerName={t('ACTION')}
				type="actionColumn"
				sortable={false}
				filter={false}
				width={200}
			/>
		</AgGridWrapper>
	);
};

export default ListComponent;
