import React, { useEffect, useState } from "react";
import { AgGridColumn } from "ag-grid-react";
import AgGridWrapper from "../../../../components/AgGridWrapper/AgGridWrapper";
import moment from "moment";
import Config from "../../../../config/Config";
import DateComparator from "../../../../utils/DateComparator";
import useStore from "../../../../store";
import { observer } from "mobx-react";
import { useHistory, useParams } from "react-router";
import BreadcrumbComponent from "../../../../components/BreadcrumbComponent/BreadcrumbComponent";
import { WalletBreadcrumb } from "../../../../config/BreadcrumbConfig";
import { Button, Space } from "antd";
import { ArrowLeftOutlined } from "@ant-design/icons";
import { useTranslation } from "react-i18next";
import { displayFormats } from "../../../../config/Global";


const WalletTransactionComponent: React.FC = observer(() => {
	const { t } = useTranslation();
	const {WALLET} = useStore();
	const { setupTransactionGrid, changeWalletType, changeId, fetchWalletDetails } = WALLET;
	const {walletType, id, walletId }: any = useParams();
	const [walletDetails, setWalletDetails] = useState<any>(null);
	const pathHistory = useHistory();

	useEffect(() => {
		changeWalletType(walletType=='user' ? 1 : 2);
		changeId(id);
		fetchWalletDetails(walletId).then((data:any) => {
			setWalletDetails(data);
		})
	}, [changeWalletType, changeId]);

	const listRoute = () => {
		pathHistory.push('/wallet-management');
	}

	return (
		<>
			<div className="Breadcrumb-bar">
				<BreadcrumbComponent items={WalletBreadcrumb(t).path} />
				<Space hidden={!walletDetails}>
					<Button
						type="primary"
						icon={<ArrowLeftOutlined />}
						onClick={listRoute}
					>{t("BACK_TO_WALLET_LIST")}
					</Button>
				</Space>
			</div>
			<div className="Breadcrumb-bar">
				<b style={{color:"orange", padding:"20px"} }>{walletDetails?.group ? walletDetails?.group?.name : walletDetails?.user?.firstname + " "+ walletDetails?.user?.lastname }</b>
					<b style={{color:"orange", padding:"30px"} }>Total :{walletDetails? walletDetails?.balance : "0"} {walletDetails?.user?.currency?.symbol || "FCFA"}</b>
			</div>
			<AgGridWrapper
				type="serverSide"
				onGridReady={setupTransactionGrid}
				rowSelection={"multiple"}
			>
				<AgGridColumn
					field="id"
					headerName={"# " + t('ID')}
					pinned="left"
					filter="agNumberColumnFilter"
					width={120}
				/>
				<AgGridColumn
					field="transaction_id"
					headerName={t("TRANSACTION_ID")}
					width={140}
				/>
				<AgGridColumn
					field="user_name"
					headerName={t("USER_NAME")}
					width={200}
				/>
				<AgGridColumn
					field="group_name"
					headerName={t('GROUP_NAME')}
					width={160}
				/>
				<AgGridColumn
					field="transaction_details"
					headerName={t("TRANSACTION_DETAILS")}
					width={200}
				/>
				<AgGridColumn
					field="payment_status"
					headerName={t("PAYMENT_STATUS")}
					filter={false}
					width={160}
				/>
				<AgGridColumn
					field="payment_type"
					headerName={t("CREDIT") + "/" + t("DEBIT")}
					valueGetter={({ data }) =>
						data.payment_type && data.payment_type == 1 ? t("CREDIT") : t("DEBIT")
					}
					filter={false}
					width={160}
				/>
				<AgGridColumn
					field="amount"
					headerName={t("AMOUNT")}
					filter= {'agNumberColumnFilter'}
					width={160}
				/>
				<AgGridColumn
					field="created_at"
					headerName={t("DATE")}
					valueGetter={({ data }) =>
						data.created_at &&
						moment(data.created_at).format(Config.dateTimeFormat)
					}
					filter={"agDateColumnFilter"}
					valueFormatter={(data:any) =>
						data?.data?.created_at && moment.utc(data?.data?.created_at).local().format(displayFormats.DATE_TIME_FORMAT)
					}
					filterParams={{
						comparator: DateComparator,
					}}
					width={220}
				/>
			</AgGridWrapper>
		</>
	);
});

export default WalletTransactionComponent;
