import React from "react";
import { AgGridColumn } from "ag-grid-react";
import AgGridWrapper from "../../../../components/AgGridWrapper/AgGridWrapper";
import { Button, Switch } from "antd";
import { EditOutlined, DeleteOutlined } from "@ant-design/icons";
import Config from "../../../../config/Config";
import DateComparator from "../../../../utils/DateComparator";
import moment from "moment";
import { GridReadyEvent } from "ag-grid-community";
import { useTranslation } from "react-i18next";
import { displayFormats } from "../../../../config/Global";
interface ListProps {
	setupGrid: (params: GridReadyEvent) => void;
	editNewsRedirect: (id: number) => void;
	ChangeStatus: (params: any) => Promise<any>;
	openDeleteNewsPopup: (id: number) => void | any;
}
const ListComponent: React.FC<ListProps> = ({
	setupGrid,
	editNewsRedirect,
	ChangeStatus,
	openDeleteNewsPopup
}) => {
	const { t } = useTranslation();
	const changeStatus = (params: any) => {
		ChangeStatus(params)
			.then(() => {
				return;
			})
			.finally(() => {
				return;
			});
	}

	const ActionRenderer = (params: any) => {
		return (
			<div className="action-column">
				<Button
					type="text"
					title={t("EDIT")}
					icon={<EditOutlined />}
					onClick={() => editNewsRedirect(params.data?.id)}
				></Button>
				<Button
					type="text"
					title={t("DELETE")}
					icon={<DeleteOutlined />}
					onClick={() => openDeleteNewsPopup(params.data?.id)}
				></Button>
			</div>
		);
	};

	const SwitchRenderer = (params: any) => {
		return (
			<div>
				<Switch
					defaultChecked={params.data?.status}
					onChange={() => changeStatus({ id: params.data?.id, status: params.data?.status == 1 ? 0 : 1 })}
				/>
			</div>
		);
	};
	return (
		<>
			<AgGridWrapper
				type="serverSide"
				onGridReady={setupGrid}
				rowSelection={"multiple"}
				frameworkComponents={{ ActionRenderer, SwitchRenderer }}
				overlayLoadingTemplate={t("PLEASE_WAIT_WHILE_DATA_IS_LOADING")} overlayNoRowsTemplate={t("NO_RECORD_FOUND")}
				>

				<AgGridColumn
					field="id"
					headerName={"# " + t('ID')}
					width={100}
					pinned={"left"}
					filter="agNumberColumnFilter"
				/>
				<AgGridColumn width={221} field="title" headerName={t('TITLE')} />
				<AgGridColumn width={290} field="description" headerName={t('DESCRIPTION')} />
				<AgGridColumn
					field="created_at"
					headerName={t('CREATED_AT')}
					valueGetter={({ data }) =>
						data.created_at &&
						moment(data.created_at).format(Config.dateTimeFormat)
					}
					filter={"agDateColumnFilter"}
					valueFormatter={(data:any) =>
						data?.data?.created_at && moment.utc(data?.data?.created_at).local().format(displayFormats.DATE_TIME_FORMAT)
					}
					filterParams={{
						comparator: DateComparator,
					}}
					width={220}
				/>
				<AgGridColumn
					field="status"
					headerName={t('STATUS')}
					cellRenderer="SwitchRenderer"
					width={120}
					sortable={false}
					filter={false}
				/>
				<AgGridColumn
					headerName={t('ACTION')}
					type="actionColumn"
					sortable={false}
					filter={false}
					width={100}
				/>
			</AgGridWrapper>
		</>
	);
};

export default ListComponent;
