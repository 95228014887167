import React from "react";
import { AgGridColumn } from "ag-grid-react";
import moment from "moment";
import AgGridWrapper from "../../../../../components/AgGridWrapper/AgGridWrapper";
import Config from "../../../../../config/Config";
import DateComparator from "../../../../../utils/DateComparator";
import { GridReadyEvent } from "ag-grid-community";
import { EyeOutlined, EditOutlined, DeleteOutlined } from "@ant-design/icons";
import { Button} from "antd";
import { useTranslation } from "react-i18next";
import { displayFormats } from "../../../../../config/Global";

interface ListProps {
	setupGrid: (params: GridReadyEvent) => void;
	editRedirect:(id:number) => void;
	editPermission:boolean;
	detailPermission:boolean;
	detailsRedirect:(id:number) => void;
	openDeletePopup: (id:any) => void;
	deletePermission:boolean;
}

const ListComponent: React.FC<ListProps> = ({
	setupGrid,
	editRedirect,
	editPermission,
	detailPermission,
	detailsRedirect,
	openDeletePopup,
	deletePermission,
}) => {
	const { t } = useTranslation();

	const ActionRenderer = (params: any) => {
		return (
			<div className="action-column">
				{params.data?.is_started == 0 && <Button
					type="text"
					title={t("EDIT")}
					icon={<EditOutlined />}
					onClick={() => editRedirect(params.data?.id)}
					hidden={!editPermission}
				></Button>}
				<Button
					type="text"
					title={t("DETAILS")}
					icon={<EyeOutlined />}
					onClick={() => detailsRedirect(params.data?.id)}
					hidden={!detailPermission}
				></Button>
				{params.data?.status == 2 && <Button
					type="text"
					title={t("DELETE")}
					icon={<DeleteOutlined />}
					onClick={() => openDeletePopup(params.data?.id)}
					hidden={!deletePermission}
				></Button>}
			</div>
		);
	};

	return (
		<AgGridWrapper
			type="serverSide"
			onGridReady={setupGrid}
			rowSelection={"multiple"}
			frameworkComponents={{ ActionRenderer}}
			overlayLoadingTemplate={t("PLEASE_WAIT_WHILE_DATA_IS_LOADING")} overlayNoRowsTemplate={t("NO_RECORD_FOUND")}
		>
			<AgGridColumn
				field="id"
				headerName={"#" + t('MEETING_ID')} width={150}
				pinned={"left"}
				sortable={false}
				filter= {'agNumberColumnFilter'}
			/>
			
			<AgGridColumn field="group_name" headerName={t('GROUP_NAME')} />

			<AgGridColumn
				field="title"
				headerName={t("MEETING_TITLE")}
			/>

			<AgGridColumn
				field="meeting_start_date_time"
				headerName={t("START_DATE_TIME")}
				valueGetter={({ data }) =>
					data.meeting_start_date_time &&
					moment(data.meeting_start_date_time).format(Config.dateTimeFormat)
				}
				filter={"agDateColumnFilter"}
				valueFormatter={(data:any) =>
					data?.data?.meeting_start_date_time && moment.utc(data?.data?.meeting_start_date_time).local().format(displayFormats.DATE_TIME_FORMAT)
				}
				filterParams={{
					comparator: DateComparator,
				}}
			/>

			<AgGridColumn
				field="meeting_end_date_time"
				headerName={t("END_DATE_TIME")}
				valueGetter={({ data }) =>
					data.meeting_end_date_time &&
					moment(data.meeting_end_date_time).format(Config.dateTimeFormat)
				}
				filter={"agDateColumnFilter"}
				valueFormatter={(data:any) =>
					data?.data?.meeting_end_date_time && moment.utc(data?.data?.meeting_end_date_time).local().format(displayFormats.DATE_TIME_FORMAT)
				}
				filterParams={{
					comparator: DateComparator,
				}}
			/>

			<AgGridColumn
				field="meeting_type"
				headerName={t("MEETING_TYPE")}
			/>

			<AgGridColumn
				field="display_status"
				headerName={t('STATUS')}
			/>

			<AgGridColumn
				field="created_by_name"
				headerName={t("CREATED_BY")}
			/>
			<AgGridColumn
				field="updated_by_name"
				headerName={t("UPDATED_BY")}
			/>
			<AgGridColumn
				headerName={t('ACTION')}
				type="actionColumn"
				sortable={false}
				filter={false}
				width={120}
				pinned={"right"}
			/>
		</AgGridWrapper>
	);
};

export default ListComponent;
