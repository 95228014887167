import { FormProps } from "antd";
import React from "react";
import { useTranslation } from "react-i18next";
import { FormBox, InputBox } from "../../../../../components/AntdAddons";
import { FaqCategoryRequest } from "../../../../../requests/FaqCategoryRequest";

const FormComponent: React.FC<FormProps> = (props) => {
	const { t } = useTranslation();
	return (
		<FormBox {...props}>
			<InputBox.Text
				required
				label={t("NAME")}
				name="name"
				placeholder={t("NAME")}
				rules={FaqCategoryRequest(t).name}
				maxLength={100}
			/>
		</FormBox>
	);
};

export default FormComponent;
