import { GridOptions, GridReadyEvent, IServerSideGetRowsParams } from "ag-grid-community";
import axios from "axios";
import { makeAutoObservable } from "mobx";
import API_URL from "../../config/ApiUrl";
import Config from "../../config/Config";
import RootStore from "../RootStore/RootStore";

export default class UserStore {
	public grid?: any;
	private rootStore: RootStore;
	public perPage?:any = 100;
	public userReportList: any;

	constructor() {
		this.rootStore = new RootStore();
		makeAutoObservable(this);
	}

	// Setter Functions
	private setGrid = (value: any) => {
		this.grid = value;
	};

	public setupGrid = (params: GridReadyEvent) => {
		this.setGrid(params);
		this.setPageSize()
		const datasource = this.createDatasource(Config.grid.server.gridOptions);
		params.api.setServerSideDatasource(datasource);
	};

	public setPageSize = (page = this.perPage) => {
		this.perPage = page;
		if (this.grid) {
			this.grid.api.paginationSetPageSize(parseInt(page));
		}
	};

	private createDatasource = (gridOptions?: GridOptions) => {
		return {
			gridOptions,
			getRows: (params: IServerSideGetRowsParams) => {
				const payload = this.rootStore.getServerListPayload(params);
				this.fetchList(payload).then((data) => {
					params.successCallback(data?.rows, data?.count);
					if (data.count <= 0) {
						params.api.showNoRowsOverlay();
					} else {
						params.api.hideOverlay();
					}
				});
			},
		};
	};
	private setUserReportList = (value: any): void => {
		this.userReportList = value;
	};
	// API Functions
	public fetchList = (payLoad:any): Promise<any> => {
		return axios.post(API_URL.USER.LIST,payLoad).then(({ data }) => {
			return data.data;
		});
	};

	public create = (payLoad:FormData): Promise<any> => {
		return axios.post(API_URL.USER.CREATE,payLoad).then(({ data }) => {
			return data.data;
		});
	};

	public details = (id:number): Promise<any> => {
		return axios.get(API_URL.USER.DETAILS(id)).then(({ data }) => {
			return data.data;
		});
	};

	public update = (id:number,payload: FormData): Promise<any> => {
		return axios.post(API_URL.USER.UPDATE(id), payload).then(({ data }) => {
			return data.data;
		});
	};

	public remove = (id:number): Promise<any> => {
		return axios.post(API_URL.USER.DELETE(id)).then(({ data }) => {
			return data.data;
		});
	};

	public changeStatus = (payload:any): Promise<any> => {
		return axios.post(API_URL.USER.CHANGE_STATUS(payload.id),payload).then(({ data }) => {
			this.setupGrid(this.grid);
			return data.data;
		});
	};

	public sendPasswordRequest = (id: number): Promise<any> => {
		return axios.post(API_URL.SEND_RESET_PASSWORD_LINK(id), {}).then(({ data }) => {
			return data.data;
		});
	};

	public updateMyLanguage = (payLoad: any): Promise<any> => {
		return axios.post(API_URL.USER.UPDATE_MY_LANGUAGE, payLoad).then(({ data }) => {
			return data.data;
		});
	};

	public getUserReportList = (): Promise<any> => {
		return axios.get(API_URL.USER.REPORT_LIST).then(({ data }) => {
			this.setUserReportList(data.data);
			return data.data;
		});
	};

	public exportReportData = (url: string, payLoad: any): Promise<any> => {
		return axios.post(url, payLoad).then(({ data }) => {
			this.rootStore.downloadUrl(data.data.downloadExportUrl)
			return data.data;
		});
	};

	public exportList = (exportType: any): Promise<any> => {
		const payLoad: any = this.rootStore.getServerListPayloadForExport(this.grid);
		payLoad.export_type = exportType;
		return this.fetchList(payLoad).then((data: any) => {
			this.rootStore.downloadUrl(data?.downloadExportUrl);
			return data;
		});
	};
}
