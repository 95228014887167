import { GridOptions, GridReadyEvent, IServerSideGetRowsParams } from "ag-grid-community";
import axios from "axios";
import { makeAutoObservable } from "mobx";
import API_URL from "../../config/ApiUrl";
import Config from "../../config/Config";
import RootStore from "../RootStore/RootStore";
import { FineListDataProps } from "./FineInterface";

export default class FineStore {

	public grid?: any;
	public fineTypeList: [] | any;
	public fine?: FineListDataProps;
	public addFinePopup?: boolean;
	public editFinePopup?: boolean;
	public editFineID?: number;
	public deleteFinePopup?: boolean;
	public deleteFineID?: number;
	private rootStore: RootStore;
	public perPage?:any = 100;

	constructor() {
		this.rootStore = new RootStore();
		makeAutoObservable(this);
	}

	// Setter Functions
	private setGrid = (value: any) => {
		this.grid = value;
	};

	public openAddFinePopup = (): void => {
		this.addFinePopup = true;
	};
	public closeAddFinePopup = (): void => {
		this.addFinePopup = false;
	};
	public openEditFinePopup = (id: number): void => {
		this.editFinePopup = true;
		this.setEditFineID(id);
	};
	public closeEditFinePopup = (): void => {
		this.editFinePopup = false;
		this.setEditFineID();
	};
	public openDeleteFinePopup = (id: number): void => {
		this.deleteFinePopup = true;
		this.setDeleteFineID(id);
	};
	public closeDeleteFinePopup = (): void => {
		this.deleteFinePopup = false;
		this.setDeleteFineID();
	};

	private setEditFineID = (id?: number): void => {
		this.editFineID = id;
	};
	private setDeleteFineID = (id?: number): void => {
		this.deleteFineID = id;
	};

	public setFine = (value?: FineListDataProps): void => {
		this.fine = value;
	};

	public setFineTypeList = (value?: FineListDataProps): void => {
		this.fineTypeList = value;
	};

	// Grid Setup Functions
	public setupGrid = (params: GridReadyEvent) => {
		this.setGrid(params);
		this.setPageSize()
		const datasource = this.createDatasource(Config.grid.server.gridOptions);
		params.api.setServerSideDatasource(datasource);
	};

	public setPageSize = (page = this.perPage) => {
		this.perPage = page;
		if (this.grid) {
			this.grid.api.paginationSetPageSize(parseInt(page));
		}
	};

	private createDatasource = (gridOptions?: GridOptions) => {
		return {
			gridOptions,
			getRows: (params: IServerSideGetRowsParams) => {
				const payload = this.rootStore.getServerListPayload(params);
				this.fetchList(payload).then((data) => {
					params.successCallback(data.rows, data.count);
					if (data.count <= 0) {
						params.api.showNoRowsOverlay();
					} else {
						params.api.hideOverlay();
					}
				});
			},
		};
	};

	// API Functions
	public fetchList = (payload: any): Promise<any> => {
		return axios
			.post(API_URL.FINE.LIST, payload)
		.then(({ data }) => {
			return data.data;
		});
	};

	public create = (payload: FormData): Promise<any> => {
		return axios({
			method: "post",
			url: API_URL.FINE.CREATE,
			data: payload,
			headers: { "Content-Type": "multipart/form-data" },
		}).then(({ data }) => {
			this.setupGrid(this.grid);
			return data;
		})
			.catch(({ data }) => {
				return Promise.reject(data);
			});
	};

	public update = (id: number, payload: FormData): Promise<any> => {
		return axios
			.post(API_URL.FINE.UPDATE(id), payload, { headers: { "Content-Type": "multipart/form-data" } })
			.then(({ data }) => {
				this.setupGrid(this.grid);
				return data;
			});
	};

	public details = (id: number): Promise<any> => {
		return axios.get(API_URL.FINE.DETAILS(id)).then(({ data }) => {
			return data;
		});
	};

	public remove = (id: number): Promise<any> => {
		return axios.delete(API_URL.FINE.DELETE(id)).then(({ data }) => {
			this.setupGrid(this.grid);
			return data;
		});
	};

	public changeStatus = (payload: any): Promise<any> => {
		return axios.post(API_URL.FINE.CHANGE_STATUS(payload.id), payload)
			.then(({ data }) => {
				this.setupGrid(this.grid);
				return data;
			});
	};

	public fetchFineTypeList = (): Promise<any> => {
		return axios.get(API_URL.FINE_TYPE_LIST)
			.then(({ data }) => {
				this.setFineTypeList(data.data);
				return data;
			});
	};

	public exportList = (exportType: any): Promise<any> => {
		const payLoad: any = this.rootStore.getServerListPayloadForExport(this.grid);
		payLoad.export_type = exportType;
		return this.fetchList(payLoad).then((data: any) => {
			this.rootStore.downloadUrl(data?.downloadExportUrl);
			return data;
		});
	};

}
