import React, { useState } from "react";
import { observer } from "mobx-react";
import BreadcrumbComponent from "../../../components/BreadcrumbComponent/BreadcrumbComponent";
import { DonationBreadcrumb } from "../../../config/BreadcrumbConfig";
import HeaderButtons from "./components/HeaderButtons";
import ContentBox from "../../../components/ContentBox/ContentBox";
import ListComponent from "./components/ListComponent";
import useStore from "../../../store";
import { Link } from "react-router-dom";
import { Button, Row } from "antd";
import { useHistory } from "react-router";
import RecordPerPage from "../../../utils/RecordPerPage";
import { useTranslation } from "react-i18next";
import { GrDocumentExcel } from "react-icons/gr";
import ExportPopupComponent from "../../../utils/ExportPopupComponent";

const DonationView: React.FC = observer(() => {
	const pathHistory = useHistory();
	const { DONATION } = useStore();
	const { setupGrid, setPageSize, perPage, exportList } = DONATION;
	const { t } = useTranslation();

	const [exportSaving, setExportSaving] = useState<boolean>(false);
	const [exportPopup, showExportPopup] = useState<boolean>(false);

	const addDonation = () => {
		pathHistory.push("/donation/create");
	};

	const editDonation = (id: number) => {
		pathHistory.push("/donation/" + id + "/edit");
	};

	const exportListData = (type: string) => {
		setExportSaving(true);
		exportList(type).finally(() => {
			setExportSaving(false);
			showExportPopup(false);
		});
	}

	return (
		<>
			<div className="Breadcrumb-bar">
				<BreadcrumbComponent items={DonationBreadcrumb(t).path} />
				<div className="header_all_btn">
					<Button type="primary" className="export-btn" icon={<GrDocumentExcel />} onClick={()=> showExportPopup(true)}>
						{t("GENERATE_REPORT")}
					</Button>
							
					<ExportPopupComponent
						callbackExportFunction={exportListData}
						setVisible={showExportPopup}
						visible={exportPopup}
						saving={exportSaving}
					/>

					<HeaderButtons key={1} addDonationRedirect={addDonation} />
				</div>
			</div>
			<Row justify="end">
				<Link
					to="donation-campaign"
					className="btn btn-primary btn-link ant-row-end"
				>
					{t("VIEW_ALL_DONATION_CAMPAIGN")}
				</Link>
				<div className="RecordPerPage__right">
					<RecordPerPage
						style={{ width: "150px" }}
						defaultValue={perPage + " per page"}
						onChange={setPageSize}
					/>
				</div>
			</Row>
			<ContentBox>
				<ListComponent
					setupGrid={setupGrid}
					editDonationRedirect={editDonation}
				/>
			</ContentBox>
		</>
	);
});

export default DonationView;
