import React, { useEffect, useState } from "react";
import { observer } from "mobx-react";
import BreadcrumbComponent from "../../../../components/BreadcrumbComponent/BreadcrumbComponent";
import ContentBox from "../../../../components/ContentBox/ContentBox";
import { RoleBreadcrumb } from "../../../../config/BreadcrumbConfig";
import useStore from "../../../../store";
import HeaderButtons from "./components/HeaderButtons";
import ListComponent from "./components/ListComponent";
import DeleteComponent from "./components/DeleteComponent";
import { useHistory } from "react-router";
import PermissionComponent from "./components/PermissionComponent";
import { useTranslation } from "react-i18next";

const RoleView: React.FC = observer(() => {
	const { t } = useTranslation();
	const { ROLE, AUTH, COMMON } = useStore();

	const {
		listData,
		remove,
		fetchList,
		changeStatus,
		deleteRoleID,
		deleteRolePopup,
		openDeleteRolePopup,
		closeDeleteRolePopup,
		details,
		getPermissionList,
		permissionList,
		openPermissionModel,
		closePermissionModel,
		showPermissionModel,
	} = ROLE;

	const { permissions } = AUTH;
	const { allPermissionList } = COMMON;
	const [selectedPermission, setPermission] = useState<any>([]);
	const [roleType, setRoleType] = useState<number>(1);

	const pathHistory = useHistory();

	const editRole = (id: number) => {
		pathHistory.push("/role-management/" + id + "/edit");
	};

	const addRole = () => {
		pathHistory.push("/role-management/create");
	};

	useEffect(() => {
		//
	}, [allPermissionList]);

	useEffect(() => {
		getPermissionList();
	}, [getPermissionList]);

	const setPermissionModel = (id: number) => {
		setPermission([]);
		details(id).then((data: any) => {
			const options: any = [];
			data.data.permissions.forEach((permissionObj: any) => {
				options.push(permissionObj.id);
			});
			setPermission(options);
			setRoleType(data.data.role_type);
			openPermissionModel();
		});
	};
	return (
		<>
			<div className="Breadcrumb-bar">
				<BreadcrumbComponent items={RoleBreadcrumb(t).path} />
				<HeaderButtons
					addRoleRedirect={addRole}
					key={"addrole"}
					addPermission={
						permissions &&
						allPermissionList &&
						permissions.indexOf(allPermissionList.ADD_ROLE) >= 0
					}
				/>
			</div>
			<ContentBox>
				<ListComponent
					rowData={listData}
					getData={fetchList}
					editRole={editRole}
					openDeleteRolePopup={openDeleteRolePopup}
					ChangeStatus={changeStatus}
					ShowPermissionModel={setPermissionModel}
					editPermission={
						permissions &&
						allPermissionList &&
						permissions.indexOf(allPermissionList.EDIT_ROLE) >= 0
					}
					deletePermission={
						permissions &&
						allPermissionList &&
						permissions.indexOf(allPermissionList.DELETE_ROLE) >= 0
					}
				/>
				<DeleteComponent
					DeleteID={deleteRoleID}
					DeleteData={remove}
					visible={deleteRolePopup}
					closeDeleteRolePopup={closeDeleteRolePopup}
				/>
				<PermissionComponent
					permissionList={permissionList}
					selectedPermission={selectedPermission}
					onCheckChange={(e) => {
						e.preventDefault();
					}}
					visible={showPermissionModel}
					title={t("PERMISSIONS")}
					closePermissionModel={closePermissionModel}
					disabled={true}
					roleType={roleType}
				/>
			</ContentBox>
		</>
	);
});

export default RoleView;
