import React from "react";
import { observer } from "mobx-react";
import BreadcrumbComponent from "../../../components/BreadcrumbComponent/BreadcrumbComponent";
import { SettingsBreadcrumb } from "../../../config/BreadcrumbConfig";
import HeaderButtons from "./components/HeaderButtons";
import ContentBox from "../../../components/ContentBox/ContentBox";
import ListComponent from "./components/ListComponent";
import DeleteComponent from "./components/DeleteComponent";
import useStore from "../../../store";
import { useHistory } from "react-router";
import { useTranslation } from "react-i18next";
const RedirectLinkView: React.FC = observer(() => {
	const { t } = useTranslation();
	const pathHistory = useHistory();
    const { REDIRECT_LINK } = useStore();
	const {
		listData,
		fetchList,
		remove,
		deleteRedirectLinkID,
		deleteRedirectLinkPopup,
		openDeleteRedirectLinkPopup,
		closeDeleteRedirectLinkPopup,
		changeStatus
	} = REDIRECT_LINK;


	const addRedirect = () => {
		pathHistory.push('/redirect-link/create');
	}

	const editRedirect = (id:number) => {
		pathHistory.push('/redirect-link/'+id+'/edit');
	}
    return (
		<>
            <div className="Breadcrumb-bar">
				<BreadcrumbComponent items={SettingsBreadcrumb(t).path} />
				<HeaderButtons
				key={1}
				addRedirectLinkRedirect={addRedirect}
			/>
			</div>
            <ContentBox>
				<ListComponent
					rowData={listData}
					getData={fetchList}
					editRedirect={editRedirect}
					openDeletePopup={openDeleteRedirectLinkPopup}
					ChangeStatus={changeStatus}
				/>
				<DeleteComponent
					DeleteID={deleteRedirectLinkID}
					DeleteData={remove}
					visible={deleteRedirectLinkPopup}
					closeDeleteFinePopup={closeDeleteRedirectLinkPopup}
				/>
            </ContentBox>
        </>

);
});

export default RedirectLinkView;
