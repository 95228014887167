import { observer } from "mobx-react";
import React, { useEffect, useState } from "react";
import BreadcrumbComponent from "../../../components/BreadcrumbComponent/BreadcrumbComponent";
import ContentBox from "../../../components/ContentBox/ContentBox";
import { WalletBreadcrumb } from "../../../config/BreadcrumbConfig";
import useStore from "../../../store";
import { Button, Tabs } from "antd";
import { useHistory } from "react-router";
import ListUserWalletComponent from "./components/ListUserWalletComponent";
import ListGroupWalletComponent from "./components/ListGroupWalletCompoent";
import RecordPerPage from "../../../utils/RecordPerPage";
import { useTranslation } from "react-i18next";
import { GrDocumentExcel } from "react-icons/gr";
import ExportPopupComponent from "../../../utils/ExportPopupComponent";

const WalletView: React.FC = observer(() => {
	const { t } = useTranslation();
	const { WALLET, AUTH, COMMON } = useStore();
	const {
		setupGrid,
		walletType,
		setPageSize,
		perPage,
		changeWalletType,
		changeId,
		exportList,
	} = WALLET;
	const { permissions } = AUTH;
	const { allPermissionList } = COMMON;
	const [isWallet, setIsWallet] = useState<boolean>(false);

	const [exportSaving, setExportSaving] = useState<boolean>(false);
	const [exportPopup, showExportPopup] = useState<boolean>(false);

	const pathHistory = useHistory();
	const { TabPane } = Tabs;
	const setWallet = (id: any) => {
		changeWalletType(id);
	};

	const detailsRedirect = (id: number, walletId: number) => {
		changeId(id);
		const type = walletType == 1 ? "user" : "group";
		pathHistory.push(
			"wallet-management/" + type + "/" + id + "/" + walletId + "/transactions"
		);
	};

	const exportListData = (type: string) => {
		setExportSaving(true);
		exportList(type).finally(() => {
			setExportSaving(false);
			showExportPopup(false);
		});
	};

	useEffect(() => {
		//
	}, [allPermissionList]);

	useEffect(() => {
		if (!isWallet) {
			setWallet(1);
			setIsWallet(true);
		}
	}, [isWallet, setIsWallet, setWallet]);

	return (
		<>
			<div className="Breadcrumb-bar">
				<BreadcrumbComponent items={WalletBreadcrumb(t).path} />
				<div className="header_all_btn">
					<Button
						type="primary"
						className="export-btn"
						icon={<GrDocumentExcel />}
						onClick={() => showExportPopup(true)}
					>
						{t("GENERATE_REPORT")}
					</Button>

					<ExportPopupComponent
						callbackExportFunction={exportListData}
						setVisible={showExportPopup}
						visible={exportPopup}
						saving={exportSaving}
					/>
				</div>
			</div>
			<ContentBox>
				<Tabs defaultActiveKey={walletType} onChange={setWallet} centered>
					<TabPane tab={t("USER")} key="1" active={walletType == 1}>
						<div className="RecordPerPage__right">
							<RecordPerPage
								style={{ width: "150px" }}
								defaultValue={perPage + " per page"}
								onChange={setPageSize}
							/>
						</div>
						<ListUserWalletComponent
							setupGrid={setupGrid}
							type={walletType}
							detailsRedirect={detailsRedirect}
							detailsPermission={
								permissions &&
								allPermissionList &&
								permissions.indexOf(allPermissionList.LIST_TRANSACTION) >= 0
							}
						/>
					</TabPane>
					<TabPane tab={t("GROUP")} key="2" active={walletType == 2}>
						<div className="RecordPerPage__right">
							<RecordPerPage
								style={{ width: "150px" }}
								defaultValue={perPage + " per page"}
								onChange={setPageSize}
							/>
						</div>
						<ListGroupWalletComponent
							setupGrid={setupGrid}
							type={walletType}
							detailsRedirect={detailsRedirect}
							detailsPermission={
								permissions &&
								allPermissionList &&
								permissions.indexOf(allPermissionList.LIST_TRANSACTION) >= 0
							}
						/>
					</TabPane>
				</Tabs>
			</ContentBox>
		</>
	);
});

export default WalletView;
