import i18next from './../i18n'
import { RequestProps } from "../config/InterfacesAndTypes";
import Message from "../config/Message";
import Regex from "../config/Regex";

export const RegisterRequest = (t: any): RequestProps => ({
	firstname: [{ required: true, message: Message(t).required(t("FIRST_NAME")) }],
	lastname: [{ required: true, message: Message(t).required(t("LAST_NAME")) }],
	email: [
		{ required: true, message: Message(t).required(t("EMAIL_ID")) },
		{ type: "email", message: Message(t).type.email(t("EMAIL_ID")) },
	],
	password: [
		{ required: true, message: Message(t).required(t("PASSWORD")) },
		{ pattern: Regex.password, message: Message(t).pattern.password(t("PASSWORD")) },
	],
});

export const LoginRequest = (t: any): RequestProps => ({
	username: [
		{ required: true, message: Message(t).required(i18next.t("EMAIL_ID")) },
		{ type: "email", message: Message(t).type.email(i18next.t("EMAIL_ID")) },
	],
	password: [
		{ required: true, message: Message(t).required(t("PASSWORD")) },
		{ pattern: Regex.password, message: Message(t).pattern.password(t("PASSWORD")) }
	],
});

export const ForgotPasswordRequest = (t: any): RequestProps => ({
	email: [
		{ required: true, message: Message(t).required(t("EMAIL_ID")) },
		{ type: "email", message: Message(t).type.email(t("EMAIL_ID")) },
	],
});

export const ResetPasswordRequest = (t: any): RequestProps => ({
	password: [
		{ required: true, message: Message(t).required(t("PASSWORD")) },
		{ pattern: Regex.password, message: Message(t).pattern.password(t("PASSWORD")) }
	],
	password_confirmation: [
		{ required: true, message: Message(t).required(t("CONFIRM_PASSWORD")) },
		{ pattern: Regex.password, message: Message(t).pattern.password(t("CONFIRM_PASSWORD")) }
	],
});
