import {
	GridOptions,
	GridReadyEvent,
	IServerSideGetRowsParams,
} from "ag-grid-community";
import axios from "axios";
import { makeAutoObservable } from "mobx";
import API_URL from "../../config/ApiUrl";
import Config from "../../config/Config";
import RootStore from "../RootStore/RootStore";

export default class VillageAgentStore {
	public grid?: any;
	public showDetailsPopUp?: boolean;
	public VillageAgentId?: any;
	private rootStore: RootStore;
	public perPage?:any = 100;

	constructor() {
		this.rootStore = new RootStore();
		makeAutoObservable(this);
	}

	// Setter Functions
	private setGrid = (value: any) => {
		this.grid = value;
	};

	private setDetailsPopup = (value?: boolean) => {
		this.showDetailsPopUp = value;
	}

	private setVillageAgentId = (value?: any) => {
		this.VillageAgentId = value;
	}

	// Grid Setup Functions
	public setupGrid = (params: GridReadyEvent) => {
		this.setGrid(params);
		this.setPageSize()
		const datasource = this.createDatasource(Config.grid.server.gridOptions);
		params.api.setServerSideDatasource(datasource);
	};

	public setPageSize = (page = this.perPage) => {
		this.perPage = page;
		if (this.grid) {
			this.grid.api.paginationSetPageSize(parseInt(page));
		}
	};

	private createDatasource = (gridOptions?: GridOptions) => {
		return {
			gridOptions,
			getRows: (params: IServerSideGetRowsParams) => {
				const payload = this.rootStore.getServerListPayload(params);
				this.fetchList(payload).then((data) => {
					params.successCallback(data.rows, data.count);
					if (data.count <= 0) {
						params.api.showNoRowsOverlay();
					} else {
						params.api.hideOverlay();
					}
				});
			},
		};
	};

	// API Functions
	public fetchList = (payload: any): Promise<any> => {
		return axios
			.post(API_URL.VILLAGE_AGENT.LIST, payload)
			.then(({ data }) => {
				return data.data;
			});
	};

	public details = (id: any): Promise<any> => {
		return axios.get(API_URL.VILLAGE_AGENT.DETAILS(id)).then(({ data }) => {
			return data.data;
		});
	};

	public showDetails = (id:any) => {
		this.setDetailsPopup(true);
		this.setVillageAgentId(id);
	}

	public closeDetails = () => {
		this.setDetailsPopup(false);
		this.setVillageAgentId();
	}

	public assignVillageAgentToGroup = (payload:any): Promise<any> => {
		return axios.post(API_URL.VILLAGE_AGENT.ASSIGN_VILLAGE_AGENT_TO_GROUP, payload)
			.then(({ data }) => {
				return data;
			});
	};

	public removeVillageAgentFromGroup = (payload:any): Promise<any> => {
		return axios.post(API_URL.VILLAGE_AGENT.REMOVE_VILLAGE_AGENT_FROM_GROUP, payload)
			.then(({ data }) => {
				return data;
			});
	};

	public exportList = (exportType: any): Promise<any> => {
		const payLoad: any = this.rootStore.getServerListPayloadForExport(this.grid);
		payLoad.export_type = exportType;
		return this.fetchList(payLoad).then((data: any) => {
			this.rootStore.downloadUrl(data?.downloadExportUrl);
			return data;
		});
	};
}
