import React from "react";
import { AgGridColumn } from "ag-grid-react";
import AgGridWrapper from "../../../../components/AgGridWrapper/AgGridWrapper";
import { Button, Switch } from "antd";
import { EditOutlined, DeleteOutlined } from "@ant-design/icons";
import { useTranslation } from "react-i18next";
interface ListProps {
	setupGrid: () => Promise<any>;
	openEditDonationSubjectPopup: (id: number) => void | any;
	openDeleteDonationSubjectPopup: (id: number) => void | any;
	InactiveData: (data: any) => Promise<any>;
	ChangeStatus: (params: any) => Promise<any>;
}
const ListComponent: React.FC<ListProps> = ({
	setupGrid,
	openEditDonationSubjectPopup,
	openDeleteDonationSubjectPopup,
	ChangeStatus,
}) => {
	const { t } = useTranslation();
	const changeStatus = (params: any) => {
		ChangeStatus(params)
			.then(() => {
				return;
			})
			.finally(() => {
				return;
			});
	};
	const ActionRenderer = (params: any) => {
		return (
			<div className="action-column">
				<Button
					type="text"
					title={t("EDIT")}
					icon={<EditOutlined />}
					onClick={() => openEditDonationSubjectPopup(params.data?.id)}
				></Button>
				<Button
					type="text"
					title={t("DELETE")}
					icon={<DeleteOutlined />}
					onClick={() => openDeleteDonationSubjectPopup(params.data?.id)}
				></Button>
			</div>
		);
	};
	const SwitchRenderer = (params: any) => {
		return (
			<div>
				<Switch
					defaultChecked={params.data?.status}
					onChange={() =>
						changeStatus({
							id: params.data?.id,
							status: params.data?.status == 1 ? 0 : 1,
						})
					}
				/>
			</div>
		);
	};
	return (
		<>
			<AgGridWrapper
				type="serverSide"
				onGridReady={setupGrid}
				frameworkComponents={{ ActionRenderer, SwitchRenderer }}
				overlayLoadingTemplate={t("PLEASE_WAIT_WHILE_DATA_IS_LOADING")}
				overlayNoRowsTemplate={t("NO_RECORD_FOUND")}
			>
				<AgGridColumn
					field="id"
					headerName={"# " + t("ID")}
					filter="agNumberColumnFilter"
					minWidth={250}
				/>
				<AgGridColumn
					field="name"
					headerName={t("DONATION_SUBJECT")}
					minWidth={451}
				/>
				<AgGridColumn
					field="status"
					headerName={t("STATUS")}
					cellRenderer="SwitchRenderer"
					width={205}
				/>
				<AgGridColumn
					headerName={t("ACTION")}
					type="actionColumn"
					sortable={false}
					filter={false}
					width={150}
				/>
			</AgGridWrapper>
		</>
	);
};

export default ListComponent;
