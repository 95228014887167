import { RequestProps } from "../config/InterfacesAndTypes";
import Message from "../config/Message";

export const DonationRequest = (t: any): RequestProps => ({
	name: [
		{ required: true, message: Message(t).required(t("NAME")) },
		{ min: 3, message: Message(t).min(3, t('NAME')) },
		{ max: 50, message: Message(t).max(50, t('NAME')) },
		({ getFieldValue }) => ({
			validator() {
				const name = getFieldValue("name");
				if (name && !name.trim()) {
					return Promise.reject(Message(t).required(t('NAME')));
				}
				return Promise.resolve();
			},
		}),
	],
	email: [
		{ required: true, message: Message(t).required(t("EMAIL_ID")) },
		{ type: "email", message: Message(t).type.email(t("EMAIL_ID")) },
		{ min: 7, message: Message(t).min(7, t('EMAIL_ID')) },
		{ max: 255, message: Message(t).max(255, t('EMAIL_ID')) },
	],
	phone_number: [
		{ required: true, message: Message(t).required(t("PHONE_NUMBER")) },
		{ pattern: /^([0-9]+)$/, message: t("PLEASE_ENTER_DIGITS_ONLY")},
		({ getFieldValue }) => ({
			validator() {
				const phone_number = getFieldValue("phone_number");
				if (phone_number && phone_number.charAt(0)==0) {
					return Promise.reject(t("PHONE_NUMBER_CANNOT_START_FROM_ZERO"));
				}
				if (phone_number < 10000000 || phone_number > 99999999) {
					return Promise.reject(t("PLEASE_ENTER_8_DIGIT_PHONE_NUMBER"));
				}
				return Promise.resolve();
			},
		}),
	],
	amount: [
		{ required: true, message: Message(t).required(t('AMOUNT')) },
		{ pattern: /^\d*(\.\d{0,2})?$/, message: Message(t).pattern.decimal(2) },
		({ getFieldValue }) => ({
			validator() {
				const amount = getFieldValue("amount");
				if (amount < 1) {
					return Promise.reject(Message(t).minIntFloat(1, t('AMOUNT')));
				}
				return Promise.resolve();
			},
		}),
	],
	address: [
		{ required: true, message: Message(t).required(t('ADDRESS')) },
		{ min: 3, message: Message(t).min(3, t('ADDRESS')) },
		{ max: 1000, message: Message(t).max(1000, t('ADDRESS')) },
	],
	organisation: [
		{ required: true, message: Message(t).required(t('ORGANIZATION')) },
		{ min: 3, message: Message(t).min(3, t('ORGANIZATION')) },
		{ max: 5000, message: Message(t).max(5000, t('ORGANIZATION')) },
	],
	zipcode: [
		{ required: true, message: Message(t).required(t('ZIP_CODE')) },
		{ min: 6, message: Message(t).min(6, t('ZIP_CODE')) },
		{ max: 6, message: Message(t).max(6, t('ZIP_CODE')) },
	],
	group_id: [{ required: true, message: Message(t).required(t("GROUP_NAME")) }],
	donation_subject: [{ required: true, message: Message(t).required(t("DONATION_SUBJECT")) }],
	payment_from: [{ required: true, message: Message(t).required(t("PAYMENT_FROM")) }],
	country_id: [{ required: true, message: Message(t).required(t("COUNTRY_NAME")) }],
});
