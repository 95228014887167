import React from "react";
interface HeaderViewProps {
	visible?: boolean;
}

const HeaderView: React.FC<HeaderViewProps> = () => {
	return null;
};

export default HeaderView;
