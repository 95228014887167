import { createContext, useContext } from "react";
import { Context } from "vm";
import TransactionStore from "./TransactionStore/TransactionStore";
import AuthStore from "./AuthStore/AuthStore";
import RootStore from "./RootStore/RootStore";
import UserStore from "./UserStore/UserStore";
import ProfessionStore from "./ProfessionStore/ProfessionStore";
import RoleStore from "./RoleStore/RoleStore";
import FaqCategoryStore from "./FaqCategoryStore/FaqCategoryStore";
import FaqStore from "./FaqStore/FaqStore";
import CommonStore from "./CommonStore/CommonStore";
import GroupStore from "./GroupStore/GroupStore";
import FineStore from "./FineStore/FineStore";
import FineTypeStore from "./FineTypeStore/FineTypeStore";
import CmsStore from "./CmsStore/CmsStore";
import TemplateStore from "./TemplateStore/TemplateStore";
import DonationSubjectStore from "./DonationSubjectStore/DonationSubjectStore";
import WalletStore from "./WalletStore/WalletStore";
import LoanStore from "./LoanRequestStore/LoanStore";
import MeetingStore from "./MeetingStore/MeetingStore";
import DonationStore from "./DonationStore/DonationStore";
import DonationCampaignStore from "./DonationCampaignStore/DonationCampaignStore";
import LoanDisbursedStore from "./LoanDisbursedStore/LoanDisbursedStore";
import GroupExpenseStore from "./GroupExpenseStore/GroupExpenseStore";
import BannerStore from "./BannerStore/BannerStore";
import GroupFinanceStore from "./GroupFinanceStore/GroupFinanceStore";
import SliderStore from "./SliderStore/SliderStore";
import NewsStore from "./NewsStore/NewsStore";
import VillageAgentStore from "./VillageAgentStore/VillageAgentStore";
import SettingsStore from "./SettingsStore/SettingsStore";
import RedirectLinkStore from "./RedirectLinkStore/RedirectLinkStore";
import ElectionRefereeStore from "./ElectionRefereeStore/ElectionRefereeStore";
import ElectionProcessStore from "./ElectionProcessStore/ElectionProcessStore";
import NewElectionRequestStore from "./NewElectionRequestStore/NewElectionRequestStore";
import ConfederationStore from  "./ConfederationStore/ConfederationStore";
import TranslationStore from  "./TranslationStore/TranslationStore";
import HomePageContentStore from "./HomePageContentStore/HomePageContentStore";
import ImportTypeStore from "./ImportTypeStore/ImportTypeStore";

const common = new CommonStore();
const AppContext = createContext({
	ROOT: new RootStore(),
	AUTH: new AuthStore(common),
	USER: new UserStore(),
	TRANSACTIONS: new TransactionStore(),
	PROFESSION: new ProfessionStore(),
	ROLE: new RoleStore(),
	FAQ_CATEGORY: new FaqCategoryStore(),
	FAQ: new FaqStore(),
	COMMON: common,
	GROUP: new GroupStore(),
	FINE: new FineStore(),
	FINETYPE : new FineTypeStore(),
	DONATIONSUBJECT : new DonationSubjectStore(),
	DONATION : new DonationStore(),
	CMS : new CmsStore(),
	TEMPLATE : new TemplateStore(),
	WALLET : new WalletStore(),
	LOAN :  new LoanStore(),
	MEETING: new MeetingStore(),
	DONATION_CAMPAIGN : new DonationCampaignStore(),
	LOAN_DISBURSEMENT :  new LoanDisbursedStore(),
	GROUP_EXPENSE : new GroupExpenseStore(),
	BANNER : new BannerStore(),
	GROUP_FINANCE: new GroupFinanceStore(),
	SLIDER : new SliderStore(),
	NEWS : new NewsStore(),
	VILLAGE_AGENT : new VillageAgentStore(),
	SETTINGS : new SettingsStore(),
	REDIRECT_LINK : new RedirectLinkStore(),
	ELECTION_REFEREE : new ElectionRefereeStore(),
	ELECTION : new ElectionProcessStore(),
	NEW_ELECTION : new NewElectionRequestStore(),
	CONFEDERATION: new ConfederationStore(),
	TRANSLATION: new TranslationStore(),
	HOME_PAGE_CONTENT: new HomePageContentStore(),
	IMPORT_TYPE:new ImportTypeStore(),
});

const useStore = (): Context => useContext(AppContext);

export default useStore;
