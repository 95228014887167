import {
	GridOptions,
	GridReadyEvent,
	IServerSideGetRowsParams,
} from "ag-grid-community";
import axios from "axios";
import { makeAutoObservable } from "mobx";
import API_URL from "../../config/ApiUrl";
import Config from "../../config/Config";
import RootStore from "../RootStore/RootStore";
import { MeetingDataProps } from "./MeetingInterface";

export default class MeetingStore {
	public listData?: MeetingDataProps[];
	public grid?: any;
	private rootStore: RootStore;
	public deleteMeetingPopup?: boolean;
	public deleteMeetingID?: number;
	public meetingType?: number = 1;
	public perPage?:any = 100;

	constructor() {
		this.rootStore = new RootStore();
		makeAutoObservable(this);
	}

	// Setter Functions
	private setListData = (value?: MeetingDataProps[]): void => {
		this.listData = value;
	};
	private setGrid = (value: any) => {
		this.grid = value;
	};

	public setupGrid = (params: GridReadyEvent) => {
		this.setGrid(params);
		this.setPageSize()
		const datasource = this.createDatasource(Config.grid.server.gridOptions);
		params.api.setServerSideDatasource(datasource);
	};

	public setPageSize = (page = this.perPage) => {
		this.perPage = page;
		if (this.grid) {
			this.grid.api.paginationSetPageSize(parseInt(page));
		}
	};

	private createDatasource = (gridOptions?: GridOptions) => {
		return {
			gridOptions,
			getRows: (params: IServerSideGetRowsParams) => {
				const payload = this.rootStore.getServerListPayload(params);
				this.fetchList(payload).then((data) => {
					params.successCallback(data?.rows, data?.count);
					if (data.count <= 0) {
						params.api.showNoRowsOverlay();
					} else {
						params.api.hideOverlay();
					}
				});
			},
		};
	};

	private setDeleteMeetingID = (id?: number): void => {
		this.deleteMeetingID = id;
	};

	private setMeetingType = (meetingType:number):void => {
		this.meetingType = meetingType;
	}

	// API Functions
	public fetchList = (payLoad: any): Promise<any> => {
		payLoad.meeting_type = this.meetingType;
		return axios.post(API_URL.MEETING.LIST, payLoad).then(({ data }) => {
			this.setListData(data.data);
			return data.data;
		});
	};

	public create = (payLoad: FormData): Promise<any> => {
		return axios.post(API_URL.MEETING.CREATE, payLoad).then(({ data }) => {
			return data.data;
		});
	};

	public details = (id: number): Promise<any> => {
		return axios.get(API_URL.MEETING.DETAILS(id)).then(({ data }) => {
			return data.data;
		});
	};

	public update = (id: number, payload: FormData): Promise<any> => {
		return axios.post(API_URL.MEETING.UPDATE(id), payload).then(({ data }) => {
			return data.data;
		});
	};

	public remove = (id: number): Promise<any> => {
		return axios.delete(API_URL.MEETING.DELETE(id)).then(({ data }) => {
			return data.data;
		});
	};

	public openDeleteMeetingPopup = (id: number): void => {
		this.deleteMeetingPopup = true;
		this.setDeleteMeetingID(id);
	};

	public closeDeleteMeetingPopup = (): void => {
		this.deleteMeetingPopup = false;
		this.setDeleteMeetingID();
	};

	public changeMeetinType = (meetingType : number): void => {
		this.setMeetingType(meetingType);
	}

}
