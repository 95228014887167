import { FormProps } from "antd";
import React from "react";
import { useTranslation } from "react-i18next";
import { FormBox, InputBox } from "../../../../components/AntdAddons";
import { DonationSubjectRequest } from "../../../../requests/DonationSubjectRequest";

const FormComponent: React.FC<FormProps> = (props) => {
	const { t } = useTranslation();
	return (
		<FormBox {...props}>
			<InputBox.Text
				required
				label={t("DONATION_SUBJECT")}
				name="name"
				placeholder={t("DONATION_SUBJECT")}
				rules={DonationSubjectRequest(t).name}
				maxLength={100}
			/>
		</FormBox>
	);
};

export default FormComponent;
