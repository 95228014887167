import React, { useEffect, useState }  from "react";
import { Button, Row, Col, Layout  } from "antd";
import useStore from "../../../../store";
import { observer } from "mobx-react-lite";
import { FormBox, InputBox } from "../../../../components/AntdAddons";
import BreadcrumbComponent from "../../../../components/BreadcrumbComponent/BreadcrumbComponent";
import { SettingsBreadcrumb } from "../../../../config/BreadcrumbConfig";
import { SmsRequest } from "../../../../requests/SettingsRequest";
import {  Form } from 'antd';
import { useHistory } from "react-router";
import { useTranslation } from "react-i18next";


const SmsComponent: React.FC = observer(() => {
	const { SETTINGS, ROOT } = useStore();
	const { getSettingDetails, saveSettingDetails, } = SETTINGS;
	const [settings, setSettings] = useState<any>()
	const { AssignErrorToInput } = ROOT;
	const key = "sms";
	const listRoute = () =>{
		history.push('/settings');
	}

	useEffect(() => {
		if (!settings) {
			getSettingDetails(key).then((data:any) => {
				const settingData = JSON.parse(data?.setting_value);
				form.resetFields();
				form.setFieldsValue({
					sid: settingData?.sid,
					auth_token: settingData?.auth_token,
					phone_number: settingData?.phone_number,
					message_services_id: settingData?.message_services_id,
				});
				setSettings(settingData);
			});
		}
	}, [getSettingDetails, settings, setSettings])
	const [form] = Form.useForm();
	const [saving, setSaving] = useState<boolean>(false);
	const history = useHistory();
	const { t } = useTranslation();

	// Handle submit and call function to save new record
	const handleSubmit = (data: any) => {
		setSaving(true);
		const formData = new FormData();
		data.sid && formData.append('sid', data.sid);
		data.auth_token && formData.append('auth_token', data.auth_token);
		data.phone_number && formData.append('phone_number', data.phone_number);
		data.message_services_id && formData.append('message_services_id', data.message_services_id);
		saveSettingDetails(key, formData)
			.then(() => {
				setSaving(false);
				form.resetFields();
				listRoute();
			})
			.catch((e:any) => {
				setSaving(false);
				const errors = e?.data?.errors || e?.errors
				AssignErrorToInput(form, errors);
			})
	};
	return (
	<>
	<div className="Breadcrumb-bar Breadcrumb-bar-export">
		<BreadcrumbComponent items={SettingsBreadcrumb(t).path} />
	</div>
	<FormBox
		id="siteManagementForm"
		form={form}
		encType="multipart/formdata"
		onFinish={handleSubmit} key="formbasic">
			<Layout style={{ padding: '0 24px' }}>
				<Row gutter={6} wrap={false}>
					<Col span={8} className="form__input__textbox">
						<InputBox.Text
							size="large"
							placeholder={t("SID")}
							name="sid"
							label={t("SID")}
							rules={SmsRequest(t).sid}
						/>
					</Col>
					<Col span={8} className="form__input__textbox">
						<InputBox.Text
							size="large"
							placeholder={t("AUTH_TOKEN")}
							name="auth_token"
							label={t("AUTH_TOKEN")}
							rules={SmsRequest(t).auth_token}
						/>
					</Col>
				</Row>
				<Row gutter={6} wrap={false}>
					<Col span={8} className="form__input__textbox">
						<InputBox.Text
							size="large"
							placeholder={t("PHONE_NUMBER") + "/" + t("SENDER_ID")}
							name="phone_number"
							label={t("PHONE_NUMBER") + "/" + t("SENDER_ID")}
							rules={SmsRequest(t).phone_number}
						/>
					</Col>
					<Col span={8} className="form__input__textbox">
						<InputBox.Text
							size="large"
							placeholder={t("MESSAGE_SERVICES_ID")}
							name="message_services_id"
							label={t("MESSAGE_SERVICES_ID")}
							rules={SmsRequest(t).message_services_id}
						/>
					</Col>
				</Row>
				<Col span={24} className="permission__button ant-row-center ant-row mt-50">
					<Button
						type="primary"
						htmlType="submit"
						loading={saving}
						key="submitsms"
					>
						{t('SAVE')}
					</Button>
					<Button type="primary" key="resetCancel" danger onClick={listRoute}>{t('CANCEL')}</Button>
				</Col>
			</Layout>
		</FormBox>
		</>
	);
});

export default SmsComponent;
