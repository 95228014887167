import React, { useEffect } from "react";
import { AgGridColumn } from "ag-grid-react";
import { FineTypeListDataProps } from "../../../../store/FineTypeStore/FineTypeInterface";
import AgGridWrapper from "../../../../components/AgGridWrapper/AgGridWrapper";
import { Button} from "antd";
import { EditOutlined, DeleteOutlined } from "@ant-design/icons";
import { useTranslation } from "react-i18next";
interface ListProps {
	rowData: FineTypeListDataProps[];
	getData: () => Promise<any>;
	openEditFinestypePopup: (id: number) => void | any;
	openDeleteFinestypePopup: (id: number) => void | any;
	InactiveData:(data: any) => Promise<any>;
}
const ListComponent: React.FC<ListProps> = ({
	rowData,
	getData,
	openEditFinestypePopup,
	openDeleteFinestypePopup,
}) => {
	const { t } = useTranslation();
	useEffect(() => {
		getData();
	}, [getData]);
	const ActionRenderer = (params: any) => {
		return (
			<div className="action-column">
				<Button
					type="text"
					title={t("EDIT")}
					icon={<EditOutlined />}
					onClick={() => openEditFinestypePopup(params.data?.id)}
				></Button>
				<Button
					type="text"
					title={t("DELETE")}
					icon={<DeleteOutlined />}
					onClick={() => openDeleteFinestypePopup(params.data?.id)}
				></Button>
			</div>
		);

		};
    return (
        <>
					<AgGridWrapper rowData={rowData} frameworkComponents={{ ActionRenderer}} overlayLoadingTemplate={t("PLEASE_WAIT_WHILE_DATA_IS_LOADING")} overlayNoRowsTemplate={t("NO_RECORD_FOUND")}>
						<AgGridColumn field="id" headerName={"# " + t('ID')} minWidth={250} />
						<AgGridColumn field="name" headerName={t("FINE_TYPE")}  minWidth={590} />
						<AgGridColumn
							headerName={t('ACTION')}
							type="actionColumn"
							sortable={false}
							filter={false}
						/>
					</AgGridWrapper>
        </>
	);
};

export default ListComponent;
