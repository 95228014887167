import React, { useEffect, useState }  from "react";
import { observer } from "mobx-react";
import { Form } from 'antd';
import useStore from "../../../../../store";
import { useHistory } from "react-router";
import FormComponent from "./FormComponent";

const AddLoanRequestComponent: React.FC = observer(() => {
	const { LOAN, COMMON } = useStore();
	const {
		create,
	} = LOAN;

	const {
		groupList,
		groupUser,
		fetchGroup,
		fetchGroupUser,
		Groupdetails,
		fetchGroupdetails,
		preferenceList
	} = COMMON;

	useEffect(() => {
		fetchGroup();
	}, [fetchGroup])
	const { ROOT } = useStore();
	const { AssignErrorToInput } = ROOT;
	const [form] = Form.useForm();
	const [saving, setSaving] = useState<boolean>(false);
	const history = useHistory();

	const listRoute = () =>{
		history.push('/loan-request-management');
  }

	// Handle submit and call function to save new record
	const handleSubmit = (data: any) => {
		setSaving(true);
		data.preference=data.preference.toString()
		const formData =  new FormData();
		data.group_id && formData.append("group_id", data.group_id);
		data.user_id && formData.append("user_id", data.user_id);
		data.loan_amount && formData.append("loan_amount", data.loan_amount);
		data.preference && formData.append("preference", data.preference);
		data.number_of_installments && formData.append("number_of_installments", data.number_of_installments);
		data.description && data.description?.trim() && formData.append("description", data.description);

		create(formData)
			.then(() => {
				setSaving(false);
				form.resetFields();
				listRoute();
			})
			.catch((e:any) => {
				setSaving(false);
				AssignErrorToInput(form, e?.data?.errors);
			})
	};

	return (
		<FormComponent
			formId="addLoanForm"
			form={form}
			handleSubmit={handleSubmit}
			saving={saving}
			listRoute={listRoute}
			isEdit={false}
			groupList={groupList}
			groupUser={groupUser}
			fetchGroupUser={fetchGroupUser}
			fetchGroupdetails={fetchGroupdetails}
			Groupdetails={Groupdetails}
			preferenceList={preferenceList}
		/>
	);
});
export default AddLoanRequestComponent;
