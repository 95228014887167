import React, { useEffect, useState }  from "react";
import { observer } from "mobx-react";
import {  Form } from 'antd';
import useStore from "../../../../store";
import { useHistory, useParams } from "react-router";
import FormComponent from "./FormComponent";
import moment from "moment";

const EditDonationCampaignComponent: React.FC = observer(() => {
	const { DONATION_CAMPAIGN, COMMON, ROOT } = useStore();
	const {
		update,
		details,
		donationList,
	} = DONATION_CAMPAIGN;

	const {
		donationFormData, getDonationFormData, selectedLanguage 
	} = COMMON;

	const [donationCampaign, setdonationcampaign] = useState<any>([])
	const [showGroupList, setShowGroupList] =  useState<boolean>(false);
	const [donationSubjectList, setDonationSubjectList] =  useState<boolean>();
    const [groupList, setGroupList] =  useState<boolean>();
	const { id }: any = useParams();
	const { AssignErrorToInput } = ROOT;
	useEffect(()=>{		
		selectedLanguage && !donationFormData && getDonationFormData();
		selectedLanguage && donationFormData && setDonationSubjectList(donationFormData.donationSubjectList);
        selectedLanguage && donationFormData && setGroupList(donationFormData.groupList);
		form.setFieldsValue({donation_type: 1});
		details(id).then((data:any) => {
			setdonationcampaign(data);
			form.resetFields();
			form.setFieldsValue({
				name:data?.name,					
				group_id:data?.group_id,					
				donation_subject:data?.donation_subject,
				start_date_time: moment(data?.start_date_time),
				end_date_time: moment(data?.end_date_time, "YYYY-MM-DD HH:mm"),
				donation_type: data.donation_type,
			});
			setShowGroupList(form.getFieldValue("donation_type") === 2)
	});
	},[selectedLanguage, donationFormData, details])
	const [form] = Form.useForm();
	const [saving, setSaving] = useState<boolean>(false);
	const history = useHistory();
	const listRoute = () =>{
		history.push('/donation-campaign');
  }

	// Handle submit and call function to save new record
	const handleSubmit = (data: any) => {
		setSaving(true);
		const formData = new FormData();
		data.name && formData.append('name', data.name);
		data.donation_type && formData.append('donation_type', data.donation_type);
		data.donation_subject && formData.append('donation_subject', data.donation_subject);		
		data.start_date_time && formData.append('start_date_time', moment(data.start_date_time).utc().format('YYYY-MM-DD HH:mm:ss'));	
		data.end_date_time && formData.append('end_date_time',  moment(data.end_date_time).utc().format('YYYY-MM-DD HH:mm:ss'));
		data.group_id && formData.append('group_id', data.group_id);
		update(donationCampaign.id, formData)
			.then(() => {
				setSaving(false);
				form.resetFields();
				listRoute();
			})
			.catch((e:any) => {
				setSaving(false);
				AssignErrorToInput(form, e?.data?.errors);
			})
	};
	return (
		donationCampaign && <FormComponent
		formId="editDonationCampaignForm"
		form={form}
		handleSubmit={handleSubmit}
		saving={saving}
		groupList={groupList}		
		donationSubjectList={donationSubjectList}
		listRoute={listRoute}
		donationList={donationList}	
		donationType={donationCampaign?.group_id ? true : false}	
		showGroupList={showGroupList}
		setShowGroupList={setShowGroupList}
		
	/>
	);
});
export default EditDonationCampaignComponent;
