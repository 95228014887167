import axios from "axios";
import { makeAutoObservable } from "mobx";
import API_URL from "../../config/ApiUrl";
import RootStore from "../RootStore/RootStore";
import { CmsListDataProps } from "./CmsInterface";

export default class CmsStore {
	public listData?: CmsListDataProps[];
	public grid?: any;
	public cmsList: [] | any;
	public cms?: CmsListDataProps;
	private rootStore: RootStore;
	public editCmsPopup?: boolean;
	public perPage?:any = 100;
	public editCmsID?: number;
	constructor() {
		this.rootStore = new RootStore();
		makeAutoObservable(this);
	}

	public openEditCmsPopup = (id: number): void => {
		this.editCmsPopup = true;
		this.setEditCmsID(id);
	};
	public closeEditCmsPopup = (): void => {
		this.editCmsPopup = false;
		this.setEditCmsID();
	};
	public setCms = (value?: CmsListDataProps): void => {
		this.cmsList = value;
	};

	// Setter Functions
	private setListData = (value?: CmsListDataProps[]): void => {
		this.listData = value;
	};

	private setEditCmsID = (id?: number): void => {
		this.editCmsID = id;
	};

	// API Functions
	public fetchList = (payLoad:any): Promise<any> => {
		return axios.post(API_URL.CMS.LIST,payLoad).then(({ data }) => {
			this.setListData(data.data.rows);
			return data.data;
		});
	};

	public details = (id: number): Promise<any> => {
		return axios.get(API_URL.CMS.DETAILS(id)).then(({ data }) => {
			return data;
		});
	};

	public update = (id:number,payload:FormData): Promise<any> => {
		return axios
			.post(API_URL.CMS.UPDATE(id), payload, {headers: { "Content-Type": "multipart/form-data" }})
			.then(({ data }) => {
				return data;
			});
	};

	public changeStatus = (payload:any): Promise<any> => {
		return axios.post(API_URL.CMS.CHANGE_STATUS(payload.id),payload)
		.then(({ data }) => {
			return data;
		});
	};

}

