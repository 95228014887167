import React, { useEffect, useState } from "react";
import { Button, Row, Col, Layout, } from "antd";
import { useHistory } from "react-router-dom";
import useStore from "../../../store";
import { FormBox, InputBox } from "../../../components/AntdAddons";
import { Form } from 'antd';
import moment from "moment";
import gendericon from "../../../assets/images/gender.svg";
import { observer } from "mobx-react";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { genderList } from "../../../config/Global";
import PhoneInput, { parsePhoneNumber } from "react-phone-number-input";
import 'react-phone-number-input/style.css';

const EditProfileComponent: React.FC = observer(() => {
	const [saving, setSaving] = useState<boolean>(false);
	const history = useHistory();
	const { AUTH, ROOT, COMMON } = useStore();
	const { professionList, fetchProfession, fetchLanguage, languageList, currencyList } = COMMON;
	const { getprofileDetails, seditprofileDetails } = AUTH;
	const { AssignErrorToInput } = ROOT;
	const [EditProfile, setEditProfile] = useState<any>(null)
	const [phoneNo, setPhoneNo] = useState<any>()
	const [curList, setCurList] = useState(currencyList)
	const [form] = Form.useForm();
	const { t } = useTranslation();

	const disableDate = (current: any) => {
		return current && (current > moment().endOf('day'));
	}
	const listRoute = () => {
		history.push('/dashboard');
	}
	useEffect(() => {
		!languageList && fetchLanguage()
		fetchProfession()
		getprofileDetails().then((data: any) => {
			setEditProfile(data);
			form.resetFields();
			setCurList([data?.currency])
			form.setFieldsValue({
				firstname: data?.firstname,
				lastname: data?.lastname,
				email: data?.email,
				phone_number: data?.phone_number,
				profession_name: data?.profession_name,
				date_of_birth: moment(data?.date_of_birth),
				address: data?.address,
				gender: data?.gender,
				language_id: data?.language_id,
				currency_id: data?.currency_id,
			});
			//setPhoneNo({ nationalNumber: data?.phone_number, countryCallingCode: data?.country_calling_code, country: data?.country_code })
		});
	}, [getprofileDetails, setEditProfile, fetchProfession, fetchLanguage])
	const handleSubmit = (data: any) => {
		setSaving(true);
		data.country_code = phoneNo?.country
		data.countryCallingCode = "+" + phoneNo?.countryCallingCode
		data.phone_number = phoneNo?.nationalNumber
		const formData = new FormData();
		data.firstname && formData.append('firstname', data.firstname);
		data.lastname && formData.append('lastname', data.lastname);
		data.email && formData.append('email', data.email);
		data.profession_id && formData.append('profession_id', data.profession_id);
		data.date_of_birth && formData.append('date_of_birth', data.date_of_birth = moment(data.date_of_birth).utc().format("YYYY-MM-DD"));
		data.address && formData.append('address', data.address);
		data.gender && formData.append('gender', data.gender);
		data.language_id && formData.append('language_id', data.language_id);
		data.currency_id && formData.append('currency_id', data.currency_id);
		data.phone_number && formData.append('phone_number', phoneNo?.nationalNumber);
		data.country_code && formData.append('country_code', phoneNo?.country)
		data.countryCallingCode && formData.append('countryCallingCode', "+" + phoneNo?.countryCallingCode)
		seditprofileDetails(data)
			.then(() => {
				setSaving(false);
				form.resetFields();
				listRoute();
			})
			.catch((e: any) => {
				setSaving(false);
				const errors = e?.data?.errors || e?.errors
				AssignErrorToInput(form, errors);
			})

	};

	const setPhone = (val: any) => {
		const phoneDetails: any = val ? parsePhoneNumber(val) : "";
		setPhoneNo(phoneDetails);
	};
	return (
		<>
			{EditProfile && <FormBox
				id="editFineForm"
				form={form}
				encType="multipart/formdata"
				onFinish={handleSubmit}>
				<Layout style={{ padding: '0 24px' }}>
					<Row gutter={6} wrap={false}>
						<Col span={8} className="form__input__textbox">
							<InputBox.Text
								required
								name="firstname"
								placeholder={t("FIRST_NAME")}
								label={t("FIRST_NAME")}
								maxLength={255}
							/>
						</Col>
						<Col span={8} className="form__input__textbox">
							<InputBox.Text
								required
								name="lastname"
								placeholder={t("LAST_NAME")}
								label={t("LAST_NAME")}
								maxLength={255}
							/>
						</Col>
					</Row>
					<Row gutter={6} wrap={false}>
						<Col span={8} className="form__input__textbox">
							<InputBox.Text
							required
								name="email"
								placeholder={t("EMAIL")}
								label={t("EMAIL")}
								maxLength={255}
								disabled
							/>
						</Col>
						<Col span={8} className="form__input__textbox">
							<Form.Item
								name="phone_number"
								label={t("PHONE_NUMBER")}
								className="phoneInputContent"
								required
							>
								<PhoneInput
									disabled={true}
									countrySelectProps={{ unicodeFlags: true }}
									placeholder={t('PHONE_NUMBER')}
									name="phone_number"
									value={phoneNo}
									international
									defaultCountry="IN"
									countryCallingCodeEditable={false}
									onChange={setPhone}
								/>
							</Form.Item>
						</Col>
					</Row>
					<Row gutter={6} wrap={false}>
						<Col span={8} className="form__input__textbox form__input__selectBox">
							<InputBox.Select
								required
								options={{
									list: professionList,
									valueKey: "id",
									textKey: "name"
								}}
								name="profession_name"
								placeholder={t("SELECT_PROFESSION")}
								label={t("SELECT_PROFESSION")}
							/>
						</Col>
						<Col span={8} className="form__input__textbox form__input__selectBox">
							<InputBox.DatePicker
								required
								name="date_of_birth"
								placeholder={t("DATE_OF_BIRTH")}
								label={t("DATE_OF_BIRTH")}
								disabledDate={disableDate}
							/>
						</Col>
					</Row>
					<Row gutter={12} wrap={false}>
						<Col span={8} className="form__input__textbox form__input__selectBox">
							<InputBox.TextArea
								required
								name="address"
								placeholder={t("ADDRESS")}
								label={t("ADDRESS")}
							/>
						</Col>
						<Col span={8} className="form__input__textbox">
							<InputBox.Select
								required
								options={{
									list: curList,
									valueKey: "id",
									textKey: "name"
								}}
								label={t("CURRENCY")}
								name="currency_id"
								placeholder={t("CURRENCY")}
								disabled
							/>
						</Col>
					</Row>
					<Row gutter={6} wrap={false}>
						<Col span={8} className="form__input__textbox form__input__selectBox">
							<label >{<img src={gendericon} />} {t("GENDER")} </label>
							<InputBox.Radio
								options={{
									list: genderList(t),
									valueKey: "id",
									textKey: "value"
								}}
								name="gender"
								required
							/>
						</Col>
						<Col span={8} hidden className="form__input__textbox form__input__selectBox">
							<InputBox.Text
								name="language_id"
							/>
						</Col>
					</Row>
					<Col span={24} className="permission__button ant-row-center ant-row mt-20">
						<Link to="/change-password" className="btn btn-primary btn-link" style={{ backgroundColor: "#000000", marginLeft: "0px", padding: "6px 50px" }}> {t("CHANGE_PASSWORD")} </Link>
						<Button type="primary"
							htmlType="submit"
							loading={saving}
							key="submit1">{t("SAVE")}</Button>
						<Button type="primary" key="resetCancel" danger onClick={listRoute}>{t("CANCEL")}</Button>
					</Col>
				</Layout>
			</FormBox>}
		</>
	);
});

export default EditProfileComponent;
