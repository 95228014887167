import React from "react";
import { HomeOutlined } from "@ant-design/icons";
import { BreadcrumbConfigProps } from "./InterfacesAndTypes";

const defaultBreadcrumbPath = [{ name: <HomeOutlined />, link: "/dashboard" }];

export const DashboardBreadcrumb = (t: any): BreadcrumbConfigProps => ({
	title: t("DASHBOARD"),
	path: [
		...defaultBreadcrumbPath,
		{ name: t("DASHBOARD"), link: "/dashboard" },
	],
});

export const UserBreadcrumb = (t: any): BreadcrumbConfigProps => ({
	title: t("USER_MANAGEMENT"),
	path: [
		...defaultBreadcrumbPath,
		{ name: t("USER_MANAGEMENT"), link: "/user-management" },
	],
});

export const TransactionBreadcrumb = (t: any): BreadcrumbConfigProps => ({
	title: t("TRANSACTIONS"),
	path: [
		...defaultBreadcrumbPath,
		{ name: t("TRANSACTIONS"), link: "/transactions" },
	],
});

export const ProfessionBreadcrumb = (t: any): BreadcrumbConfigProps => ({
	title: t("PROFESSION_MANAGEMENT"),
	path: [
		...defaultBreadcrumbPath,
		{ name: t("PROFESSION_MANAGEMENT"), link: "/profession-management" },
	],
});

export const ConfederationBreadcrumb = (t: any): BreadcrumbConfigProps => ({
	title: t("CONFEDERATION_MANAGEMENT"),
	path: [
		...defaultBreadcrumbPath,
		{ name: t("CONFEDERATION_MANAGEMENT"), link: "/confederation-management" },
	],
});

export const RoleBreadcrumb = (t: any): BreadcrumbConfigProps => ({
	title: t("ROLE_MANAGEMENT"),
	path: [
		...defaultBreadcrumbPath,
		{ name: t("ROLE_MANAGEMENT"), link: "/role-management" },
	],
});

export const FaqCategoryBreadcrumb = (t: any): BreadcrumbConfigProps => ({
	title: t("FAQ_CATEGORY_MANAGEMENT"),
	path: [
		...defaultBreadcrumbPath,
		{ name: t("FAQ_CATEGORY_MANAGEMENT"), link: "/faq-category-management" },
	],
});

export const FaqBreadcrumb = (t: any): BreadcrumbConfigProps => ({
	title: t("FAQ_MANAGEMENT"),
	path: [
		...defaultBreadcrumbPath,
		{ name: t("FAQ_MANAGEMENT"), link: "/faq-management" },
	],
});

export const GroupBreadcrumb = (t: any): BreadcrumbConfigProps => ({
	title: t("SAVING_GROUP"),
	path: [
		...defaultBreadcrumbPath,
		{ name: t("SAVING_GROUP"), link: "/group-management" },
	],
});
export const FineBreadcrumb = (t: any): BreadcrumbConfigProps => ({
	title: t("FINE_MANAGEMENT"),
	path: [
		...defaultBreadcrumbPath,
		{ name: t("FINE_MANAGEMENT"), link: "/fine-management" },
	],
});

export const FinestypeBreadcrumb = (t: any): BreadcrumbConfigProps => ({
	title: t("FINE_TYPE_MANAGEMENT"),
	path: [
		...defaultBreadcrumbPath,
		{ name: t("FINE_TYPE_MANAGEMENT"), link: "/fine-type-management" },
	],
});

export const CMSBreadcrumb = (t: any): BreadcrumbConfigProps => ({
	title: t("CMS_MANAGEMENT"),
	path: [
		...defaultBreadcrumbPath,
		{ name: t("CMS_MANAGEMENT"), link: "/cms-management" },
	],
});

export const TemplatesBreadcrumb = (t: any): BreadcrumbConfigProps => ({
	title: t("TEMPLATES_MANAGEMENT"),
	path: [
		...defaultBreadcrumbPath,
		{ name: t("TEMPLATES"), link: "/templates-management" },
	],
});

export const DonationSubjecteBreadcrumb = (t: any): BreadcrumbConfigProps => ({
	title: t("DONATION_SUBJECT"),
	path: [
		...defaultBreadcrumbPath,
		{ name: t("DONATION_SUBJECT"), link: "/donation-subject" },
	],
});

export const WalletBreadcrumb = (t: any): BreadcrumbConfigProps => ({
	title: t("WALLET_MANAGEMENT"),
	path: [
		...defaultBreadcrumbPath,
		{ name: t("WALLET_MANAGEMENT"), link: "/wallet-management" },
	],
});

export const LoanRequestBreadcrumb = (t: any): BreadcrumbConfigProps => ({
	title: t("LOAN_REQUEST_MANAGEMENT"),
	path: [
		...defaultBreadcrumbPath,
		{ name: t("LOAN_REQUEST"), link: "/loan-request-management" },
	],
});

export const MeetingBreadcrumb = (t: any): BreadcrumbConfigProps => ({
	title: t("MEETING_MANAGEMENT"),
	path: [
		...defaultBreadcrumbPath,
		{ name: t("MEETING_MANAGEMENT"), link: "/meeting-management" },
	],
});
export const DonationBreadcrumb = (t: any): BreadcrumbConfigProps => ({
	title: t("DONATION"),
	path: [
		...defaultBreadcrumbPath,
		{ name: t("DONATION"), link: "/donation" },
	],
});

export const DonationCampaignBreadcrumb = (t: any): BreadcrumbConfigProps => ({
	title: t("DONATION_CAMPAIGN"),
	path: [
		...defaultBreadcrumbPath,
		{ name: t("DONATION_CAMPAIGN"), link: "/donation-campaign" },
	],
});

export const LoanDisbursedBreadcrumb = (t: any): BreadcrumbConfigProps => ({
	title: t("LOAN_DISBURSED_MANAGEMENT"),
	path: [
		...defaultBreadcrumbPath,
		{ name: t("LOAN_DISBURSED"), link: "/loan-disbursed-management" },
	],
});

export const GroupExpenseBreadcrumb = (t: any): BreadcrumbConfigProps => ({
	title: t("GROUP_EXPENSE_MANAGEMENT"),
	path: [
		...defaultBreadcrumbPath,
		{ name: t("GROUP_EXPENSE"), link: "/group-expense" },
	],
});

export const BannerBreadcrumb = (t: any): BreadcrumbConfigProps => ({
	title: t("BANNER_MANAGEMENT"),
	path: [
		...defaultBreadcrumbPath,
		{ name: t("BANNER_MANAGEMENT"), link: "/banner" },
	],
});
export const GroupFinanceManagementBreadcrumb = (t: any): BreadcrumbConfigProps => ({
	title: t("GROUP_FINANCE_MANAGEMENT"),
	path: [
		...defaultBreadcrumbPath,
		{ name: t("GROUP_FINANCE_MANAGEMENT"), link: "/group-finance-management" },
	],
});

export const SliderBreadcrumb = (t: any): BreadcrumbConfigProps => ({
	title: t("SLIDER_MANAGEMENT"),
	path: [
		...defaultBreadcrumbPath,
		{ name: t("SLIDER_MANAGEMENT"), link: "/slider" },
	],
});

export const NewsBreadcrumb = (t: any): BreadcrumbConfigProps => ({
	title: t("NEWS_MANAGEMENT"),
	path: [
		...defaultBreadcrumbPath,
		{ name: t("NEWS_MANAGEMENT"), link: "/news-management" },
	],
});

export const VillageAgentBreadcrumb = (t: any): BreadcrumbConfigProps => ({
	title: t("VILLAGE_AGENT"),
	path: [
		...defaultBreadcrumbPath,
		{ name: t("VILLAGE_AGENT"), link: "/village-agent" },
	],
});

export const SettingsBreadcrumb = (t: any): BreadcrumbConfigProps => ({
	title: t("SETTINGS"),
	path: [
		...defaultBreadcrumbPath,
		{ name: t("SETTINGS"), link: "/settings" },
	],
});

export const RedirectLinkBreadcrumb = (t: any): BreadcrumbConfigProps => ({
	title: t("REDIRECT_LINK"),
	path: [
		...defaultBreadcrumbPath,
		{ name: t("REDIRECT_LINK"), link: "/redirect-link" },
	],
});

export const ElectionRefereeBreadcrumb = (t: any): BreadcrumbConfigProps => ({
	title: t("ELECTION_REFEREE"),
	path: [
		...defaultBreadcrumbPath,
		{ name: t("ELECTION_REFEREE"), link: "/election-referee" },
	],
});

export const ElectionProcessBreadcrumb = (t: any): BreadcrumbConfigProps => ({
	title: t("ELECTION_PROCESS"),
	path: [
		...defaultBreadcrumbPath,
		{ name: t("ELECTION_PROCESS"), link: "/election-process" },
	],
});

export const ElectionRequestBreadcrumb = (t: any): BreadcrumbConfigProps => ({
	title: t("ELECTION_REQUEST"),
	path: [
		...defaultBreadcrumbPath,
		{ name: t("ELECTION_REQUEST"), link: "/election-request" },
	],
});

export const TranslationBreadcrumb = (t: any): BreadcrumbConfigProps => ({
	title: t("TRANSLATION"),
	path: [
		...defaultBreadcrumbPath,
		{ name: t("TRANSLATION"), link: "/translation" },
	],
});
export const ReportBreadcrumb = (t: any): BreadcrumbConfigProps => ({
	title: t("ALL_REPORTS"),
	path: [
		...defaultBreadcrumbPath,
		{ name: t("ALL_REPORTS"), link: "/all-reports" },
	],
});
export const ImportTypeBreadcrumb = (t: any): BreadcrumbConfigProps => ({
	title: t("IMPORT_DATA"),
	path: [
		...defaultBreadcrumbPath,
		{ name: t("IMPORT_DATA"), link: "/import-data" },
	],
});

export const HomePageContentBreadcrumb = (t: any): BreadcrumbConfigProps => ({
	title: t("HOME_PAGE_CONTENT"),
	path: [
		...defaultBreadcrumbPath,
		{ name: t("HOME_PAGE_CONTENT"), link: "/settings/home-page-content" },
	],
});