import axios from "axios";
import { makeAutoObservable } from "mobx";
import API_URL from "../../config/ApiUrl";
import RootStore from "../RootStore/RootStore";

export default class CommonStore {
	public professionList?: any[];
	public languageList?: any[];
	public currencyList?: any[];
	public faqCategoryList?: any[];
	public roleList?: any[] = [];
	public userList?: any[];
	public groupUser? : any[];
	public groupList? : any[];
	public fineTypeList?: any[];
	public confederationList?: any[];
	public Groupdetails?: any[];
	private rootStore: RootStore;
	public donationSubjectList?: any[];
	public dashboardDetails?: any[];
	public electionRefereeList?: any[];
	public translationData?: any;
	public selectedLanguage: any;
	public donationFormData?: any;
	public allPermissionList?: any[];
	public preferenceList?:any[];
	public importDataList?:any[];

	constructor() {
		this.rootStore = new RootStore();
		makeAutoObservable(this);
	}

	// Setter Functions
	private setProfessionList = (value?: any[]): void => {
		this.professionList = value;
	};

	private setLanguageList = (value?: any[]): void => {
		this.languageList = value;
	};

	private setCurrencyList = (value?: any[]): void => {
		this.currencyList = value;
	};

	private setFaqCategoryList = (value?: any[]): void => {
		this.faqCategoryList = value;
	};

	private setRoleList = (value?: any[]): void => {
		this.roleList = value;
	};

	private setUserList = (value?: any[]): void => {
		this.userList = value;
	};

	private setGroupList = (value?: any[]): void => {
		this.groupList = value;
	};

	private setGroupUser = (value?: any[]): void => {
		this.groupUser = value;
	};

	private setConfederationList = (value?: any[]): void => {
		this.confederationList = value;
	}

	private setGroupdetails = (value?: any[]): void => {
		this.Groupdetails = value;
	}

	private setFineTypeList = (value?: any[]): void => {
		this.fineTypeList = value;
	};
	private setDonationSubjectList = (value?: any[]): void => {
		this.donationSubjectList = value;
	};

	private setDashboardDetails = (value?: any[]): void => {
		this.dashboardDetails = value;
	}
	private setelectionRefereeList= (value?: any[]): void => {
		this.electionRefereeList = value;
	}
	public setTranslationData = (value?: any): void => {
		this.translationData = value;
	};
	public setSelectedLanguage = (selectedLanguage: any) => {
		this.selectedLanguage = selectedLanguage;
	}
	public setDonationFormData = (value?: any): void => {
		this.donationFormData = value;
	};
	public setAllPermissionList = (value?: any): void => {
		this.allPermissionList = value;
	};
	public setPreferenceList = (value?: any): void => {
		this.preferenceList = value;
	};
	public setImportDataList = (value?: any): void => {
		this.importDataList = value;
	};
	// API Functions

	public fetchSyncData = (): Promise<any> => {
		return axios.post(API_URL.SYNC, {}).then(({ data }) => {
			this.setAllPermissionList(data.data.allPermissionList);
			this.setPreferenceList(data.data.paymentMode)
			this.setImportDataList(data.data.importType)
			return data.data;
		});
	}

	public fetchProfession = (): Promise<any> => {
		return axios.get(API_URL.PROFESSION_LIST).then(({ data }) => {
			this.setProfessionList(data.data);
			return data.data;
		});
	};

	public fetchLanguage = (): Promise<any> => {
		return axios.get(API_URL.LANGUAGE_LIST).then(({ data }) => {
			this.setLanguageList(data.data);
			return data.data;
		});
	};

	public fetchCurrency = (): Promise<any> => {
		return axios.get(API_URL.CURRENCY_LIST).then(({ data }) => {
			this.setCurrencyList(data.data);
			return data.data;
		});
	};

	public fetchFaqCategory = (): Promise<any> => {
		return axios.get(API_URL.FAQ_CATEGORY_LIST).then(({ data }) => {
			this.setFaqCategoryList(data.data);
			return data.data;
		});
	};

	public fetchRole = (): Promise<any> => {
		return axios.get(API_URL.ROLE_LIST).then(({ data }) => {
			this.setRoleList(data.data);
			return data.data;
		});
	};

	public fetchUser = (userType = 0, status=1, perPage = 1000, page = 1, search:any): Promise<any> => {
		const params = {
			user_type:userType.toString(),
			per_page:perPage.toString(),
			page:page.toString(),
			search: search? search :null,
			status:status.toString()
		};
		return axios.post(API_URL.USER_LIST,params).then(({ data }) => {
			this.setUserList(data.data.rows);
		});
	}

	public fetchGroup = (search:any = null, status:any=null): Promise<any> => {
		let searchUrl= API_URL.GROUP_LIST+"?";
		searchUrl = search ?  searchUrl+"search="+search : searchUrl;
		searchUrl = status ? searchUrl+"&status="+status : searchUrl;
		return axios.get(searchUrl).then(({ data }) => {
			this.setGroupList(data.data);
			return data.data;
		});
	};

	public fetchGroupUser = (id:any): Promise<any> => {
		return axios.post(API_URL.GROUP_USER_LIST(id),{}).then(({ data }) => {
			this.setGroupUser(data.data.users);
			return data.data.users;
		});
	};

	public fetchFineTypeList = () => {
		return axios.get(API_URL.FINE_TYPE_LIST).then(({ data }) => {
			this.setFineTypeList(data.data);
			return data.data;
		});
	}
	public fetchDonationSubjectList = () => {
		return axios.get(API_URL.DONATION_SUBJECT_LIST).then(({ data }) => {
			this.setDonationSubjectList(data.data);
			return data.data;
		});
	}

	public fetchConfederationList = () => {
		return axios.get(API_URL.CONFEDERATION_LIST).then(({ data }) => {
			this.setConfederationList(data.data);
			return data.data;
		});
	}

	public fetchGroupdetails = (id: number) => {
		return axios.get(API_URL.GROUP.DETAILS(id)).then(({ data }) => {
			this.setGroupdetails(data.data);
			return data.data;
		});
	}

	public fetchDashboardDetails = (payLoad: any) => {
		return axios.post(API_URL.DASHBOARD, payLoad).then(({ data }) => {
			this.setDashboardDetails(data.data);
			return data.data.data;
		});
	}
	public fetchelectionRefereeList = (payLoad: any) => {
		return axios.post(API_URL.ELECTION_REFEREE_LIST, payLoad).then(({ data }) => {
			this.setelectionRefereeList(data.data.rows);
			return data.data.rows;
		});
	}

	public loadTranslationData = async (locale: string) => {
		return await axios.get(API_URL.LANGUAGE_DATA(locale))
			.then((response: any) => {
				this.setTranslationData(response.data);
				return response.data;
			});
	};
	public getDonationFormData = (): Promise<any> => {
		return axios.get(API_URL.DONATION_FORM_DATA).then(({ data }) => {
			this.setDonationFormData(data.data);
			return data.data;
		});
	};
}
