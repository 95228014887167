import {
	GridOptions,
	GridReadyEvent,
	IServerSideGetRowsParams,
} from "ag-grid-community";
import axios from "axios";
import { makeAutoObservable } from "mobx";
import API_URL from "../../config/ApiUrl";
import Config from "../../config/Config";
import RootStore from "../RootStore/RootStore";

export default class WalletStore {
	public grid?: any = {};
	public showDetailsPopUp?: boolean;
	public transactionId?: any;
	private rootStore: RootStore;
	public perPage?: any = 100;
	public walletType = 1;
	public id?: number;
	private gridApi?: any;
	constructor() {
		this.rootStore = new RootStore();
		makeAutoObservable(this);
	}

	// Setter Functions
	private setGrid = (value: any, index: any) => {
		this.grid[index] = value;
	};

	private setTransactionGrid = (value: any) => {
		this.grid = value;
	};

	private setId = (value?: number) => {
		this.id = value;
	};

	private setWalletType = (value: number) => {
		this.walletType = value as number;
	};

	// Grid Setup Functions
	public setupGrid = (params: GridReadyEvent) => {
		this.setGrid(params, this.walletType);
		this.setPageSize();
		const datasource = this.createDatasource(Config.grid.server.gridOptions);
		params.api.refreshServerSideStore({ purge: true });
		params.api.setServerSideDatasource(datasource);
	};

	public setPageSize = (page = this.perPage) => {
		this.perPage = page;
		if (this.grid[this.walletType]) {
			this.grid[this.walletType].api.paginationSetPageSize(parseInt(page));
		}
	};

	private createDatasource = (gridOptions?: GridOptions) => {
		return {
			gridOptions,
			getRows: (params: IServerSideGetRowsParams) => {
				const payload = this.rootStore.getServerListPayload(params);
				this.fetchList(payload).then((data) => {
					params.successCallback(data.rows, data.count);
					this.setGrid(params, this.walletType);
					if (data.count <= 0) {
						params.api.showNoRowsOverlay();
					} else {
						params.api.hideOverlay();
					}
				});
			},
		};
	};

	private createTransactionDatasource = (gridOptions?: GridOptions) => {
		return {
			gridOptions,
			getRows: (params: IServerSideGetRowsParams) => {
				const payload = this.rootStore.getServerListPayload(params);
				this.walletType == 1 && Object.assign(payload, { user_id: this.id });
				this.walletType == 2 && Object.assign(payload, { group_id: this.id });
				this.fetchTransactionList(payload).then((data) => {
					params.successCallback(data.rows, data.count);
				});
			},
		};
	};

	public setupTransactionGrid = (params: GridReadyEvent) => {
		this.setTransactionGrid(params);
		const datasource = this.createTransactionDatasource(
			Config.grid.server.gridOptions
		);
		params.api.setServerSideDatasource(datasource);
	};

	public changeWalletType = (value: number) => {
		this.setWalletType(value);
	};

	public changeId = (value: number) => {
		this.setId(value);
	};

	// API Functions
	public fetchList = (payload: any): Promise<any> => {
		const url = this.walletType == 1 ? API_URL.WALLET.USER : API_URL.WALLET.GROUP;
		return axios.post(url, payload).then(({ data }) => {
			return data.data;
		});
	};

	public fetchTransactionList = (payload: any): Promise<any> => {
		return axios.post(API_URL.TRANSACTION.LIST, payload).then(({ data }) => {
			return data.data;
		});
	};

	public fetchWalletDetails = (id: any): Promise<any> => {
		return axios.post(API_URL.WALLET.DETAILS(id)).then(({ data }) => {
			return data.data;
		});
	};

	public exportList = (exportType: any): Promise<any> => {
		const payLoad: any = this.rootStore.getServerListPayloadForExport(this.grid[this.walletType]);
		payLoad.export_type = exportType;
		return this.fetchList(payLoad).then((data: any) => {
			this.rootStore.downloadUrl(data?.downloadExportUrl);
			return data;
		});
	};
}
