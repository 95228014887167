import React, { useState } from "react";
import { Button, Card, Form, Space } from "antd";
import { ResetPasswordRequest } from "../../../requests/AuthRequest";
import { Link, useHistory } from "react-router-dom";
import { useParams } from "react-router";
import useStore from "../../../store";
import { ErrorProps } from "../../../store/RootStore/RootInterface";
import { FormBox, InputBox } from "../../../components/AntdAddons";
import { LockOutlined } from "@ant-design/icons";
import { useTranslation } from "react-i18next";
import { aesEncryptionPassword } from "../../../config/Global";

const ResetPasswordOtp: React.FC = () => {
	const [form] = Form.useForm();
	const [saving, setSaving] = useState<boolean>(false);
	const history = useHistory();
	const { AUTH, ROOT } = useStore();
	const { resetPasswordWithOtp } = AUTH;
	const { AssignErrorToInput } = ROOT;
	const { token }: any = useParams();
	const { t } = useTranslation();
	const handleSubmit = async (data: any) => {
		setSaving(true);

		data.password = await aesEncryptionPassword(data?.password)
		data.password_confirmation = await aesEncryptionPassword(data?.password_confirmation)

		resetPasswordWithOtp(token, data)
			.then(() => {
				history.replace("/");
			})
			.catch((e: ErrorProps) => {
				const errors = e?.errors || e.data?.errors;
				AssignErrorToInput(form, errors);
				setSaving(false);
			});
	};

	return (
		<section className="section reset__section">
			<Card className="width-450">
				<h2 className="card__title">VSLA</h2>
				<FormBox className="text-center" form={form} onFinish={handleSubmit}>
					<InputBox.Password
						prefix={<LockOutlined />}
						name="password"
						//label={t("NEW_PASSWORD")}
						placeholder={t("NEW_PASSWORD")}
						size="large"
						rules={ResetPasswordRequest(t).password}
					/>
					<InputBox.Password
						prefix={<LockOutlined />}
						name="password_confirmation"
						//label={t("CONFIRM_PASSWORD")}
						placeholder={t("CONFIRM_PASSWORD")}
						size="large"
						rules={ResetPasswordRequest(t).password_confirmation}
					/>
					<div className="text-center">
						<Space style={{ color: "blue" }}>
							{t("PLEASE_ENTER_A_NEW_PASSWORD")}
						</Space>
					</div>
					<Button
						className="width-250 mt-30 mb-20"
						loading={saving}
						type="primary"
						size="large"
						htmlType="submit"
					>
						{t("CHANGE_PASSWORD")}
					</Button>
					<div className="text-center">
						<Space>
							<Link to="/">{t("BACK_TO_LOGIN")}</Link>
						</Space>
					</div>
				</FormBox>
			</Card>
		</section>
	);
};

export default ResetPasswordOtp;
