import { UserOutlined } from "@ant-design/icons";
import { Button, Col, Layout, Row } from "antd";
import { observer } from "mobx-react";
import React from "react";
import { useTranslation } from "react-i18next";
import { FormBox, InputBox } from "../../../../../components/AntdAddons";
import BreadcrumbComponent from "../../../../../components/BreadcrumbComponent/BreadcrumbComponent";
import { RoleBreadcrumb } from "../../../../../config/BreadcrumbConfig";
import { RoleRequest } from "../../../../../requests/RoleRequest";
import PermissionComponent from "./PermissionComponent";

interface FormDataProps {
	formId: string;
	form: any;
	handleSubmit: (data: any) => void | any;
	roleType: any;
	permissionList: any[];
	selectedPermission: any[];
	onCheckChange: (e: any) => void | any;
	showPermissionModel: boolean;
	closePermissionModel: () => void | any;
	openPermissionModel: () => void | any;
	saving: boolean;
	listRoute: () => void | any;
	isEdit: boolean;
}

const FormComponent: React.FC<FormDataProps> = observer(
	({
		formId,
		form,
		handleSubmit,
		roleType,
		permissionList,
		selectedPermission,
		onCheckChange,
		showPermissionModel,
		closePermissionModel,
		openPermissionModel,
		saving,
		listRoute,
	}) => {
		const { t } = useTranslation();

		return (
			<FormBox
				id={formId}
				form={form}
				encType="multipart/formdata"
				onFinish={handleSubmit}
				key="formbasic"
			>
				<div className="Breadcrumb-bar Breadcrumb-bar-export">
					<BreadcrumbComponent items={RoleBreadcrumb(t).path} />
				</div>
				<Layout style={{ padding: "0 24px" }}>
					<Row gutter={6} wrap={false}>
						<Col span={8} className="form__input__textbox">
							<InputBox.Text
								size="large"
								label={t("ROLE_NAME")}
								placeholder={t("ROLE_NAME")}
								name="name"
								prefix={<UserOutlined />}
								rules={RoleRequest(t).name}
							/>
						</Col>
					</Row>
					<Row gutter={6} wrap={false}>
						<Col span={8} className="form__input__textbox">
							<InputBox.Select
								options={{
									list: roleType,
									valueKey: "id",
									textKey: "value",
								}}
								name="role_type"
								label={t("SELECT_ROLE_TYPE")}
								placeholder={t("SELECT_ROLE_TYPE")}
								rules={RoleRequest(t).role_type}
								disabled={true}
							/>
						</Col>
					</Row>
					<Row gutter={6} wrap={false}>
						<Col span={8} className="form__input__textbox">
							<PermissionComponent
								permissionList={permissionList}
								selectedPermission={selectedPermission}
								onCheckChange={onCheckChange}
								visible={showPermissionModel}
								title={t("SELECT_PERMISSION_TO_ADD_ROLE")}
								closePermissionModel={closePermissionModel}
								disabled={false}
								roleType={form.getFieldValue("role_type")}
							/>
							<Button
								type="primary"
								className="view-permissions-btn"
								key="permission"
								style={{ backgroundColor: "#000000" }}
								onClick={openPermissionModel}
							>
								{" "}
								{t("PERMISSIONS")}{" "}
							</Button>
						</Col>
					</Row>
					<Row gutter={6} wrap={false}>
						<Col
							span={24}
							className="permission__button ant-row-center ant-row mt-50"
						>
							<Button
								type="primary"
								htmlType="submit"
								loading={saving}
								key="submit1"
							>
								{t("SAVE")}
							</Button>
							<Button
								type="primary"
								key="resetCancel"
								danger
								onClick={listRoute}
							>
								{t("CANCEL")}
							</Button>
						</Col>
					</Row>
				</Layout>
			</FormBox>
		);
	}
);
export default FormComponent;
