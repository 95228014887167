import React, { useEffect } from "react";
import BreadcrumbComponent from "../../../components/BreadcrumbComponent/BreadcrumbComponent";
import { ElectionRequestBreadcrumb } from "../../../config/BreadcrumbConfig";
import ContentBox from "../../../components/ContentBox/ContentBox";
import ListComponent from "./components/ListComponent";
import useStore from "../../../store";
import { observer } from "mobx-react-lite";
import { Link } from "react-router-dom";
import { Row,} from 'antd';
import { useHistory } from "react-router";
import { useTranslation } from "react-i18next";

const NewElectionRequestView: React.FC = observer(() => {
	const { t } = useTranslation();
	const { NEW_ELECTION, AUTH, COMMON } = useStore();
	const { setupGrid } = NEW_ELECTION;
	const { permissions } = AUTH;
	const pathHistory = useHistory();
	const {allPermissionList} = COMMON

	useEffect(()=>{
		//
	}, [allPermissionList]);
	const editLoanRequest= (id:number) => {
		pathHistory.push(''+id+'/assign-election-refre');
	}
	return (
			<>
			<div className="Breadcrumb-bar">
				<BreadcrumbComponent items={ElectionRequestBreadcrumb(t).path} />
			</div>
			<Row justify="end">
				<Link to="election-process" className="btn btn-primary btn-link ant-row-end">{t("BACK_TO_ELECTION_PROCESS")}</Link>
			</Row>
			<ContentBox>
				<ListComponent
					setupGrid={setupGrid}
					editRedirect={editLoanRequest}
					editPermission={permissions && allPermissionList && permissions.indexOf(allPermissionList.EDIT_USER_LOAN_REQUEST) >=0}
				/>
			</ContentBox>
		</>
	);
});

export default NewElectionRequestView;
