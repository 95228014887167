import axios from "axios";
import { makeAutoObservable } from "mobx";
import API_URL from "../../config/ApiUrl";
import { FaqCategoryListDataProps } from "./FaqCategoryInterface";

export default class FaqCategoryStore {
	public listData?: FaqCategoryListDataProps[];
	public faqCategory?: FaqCategoryListDataProps;
	public addFaqCategoryPopup?: boolean;
	public editFaqCategoryPopup?: boolean;
	public editFaqCategoryID?: number;
	public deleteFaqCategoryPopup?: boolean;
	public deleteFaqCategoryID?: number;
	constructor() {
		makeAutoObservable(this);
	}

	// Setter Functions
	public openAddFaqCategoryPopup = (): void => {
		this.addFaqCategoryPopup = true;
	};
	public closeAddFaqCategoryPopup = (): void => {
		this.addFaqCategoryPopup = false;
	};
	public openEditFaqCategoryPopup = (id: number): void => {
		this.editFaqCategoryPopup = true;
		this.setEditFaqCategoryID(id);
	};
	public closeEditFaqCategoryPopup = (): void => {
		this.editFaqCategoryPopup = false;
		this.setEditFaqCategoryID();
	};
	public openDeleteFaqCategoryPopup = (id: number): void => {
		this.deleteFaqCategoryPopup = true;
		this.setDeleteFaqCategoryID(id);
	};
	public closeDeleteFaqCategoryPopup = (): void => {
		this.deleteFaqCategoryPopup = false;
		this.setDeleteFaqCategoryID();
	};

	private setEditFaqCategoryID = (id?: number): void => {
		this.editFaqCategoryID = id;
	};
	private setDeleteFaqCategoryID = (id?: number): void => {
		this.deleteFaqCategoryID = id;
	};

	private setListData = (value?: FaqCategoryListDataProps[]): void => {
		this.listData = value;
	};

	public setFaqCategory = (value?: FaqCategoryListDataProps): void => {
		this.faqCategory = value;
	};

	// API Functions
	public fetchList = (): Promise<any> => {
		return axios.post(API_URL.FAQ_CATEGORY.LIST).then(({ data }) => {
			this.setListData(data.data.rows);
			return data;
		});
	};

	public create = (payload: any): Promise<any> => {
		return axios
			.post(API_URL.FAQ_CATEGORY.CREATE, payload)
			.then(({ data }) => {
				this.fetchList();
				return data;
			})
			.catch(({ data }) => {
				return Promise.reject(data);
			});
	};

	public update = (payload: any): Promise<any> => {
		return axios
			.post(API_URL.FAQ_CATEGORY.UPDATE(payload.id), payload)
			.then(({ data }) => {
				this.fetchList();
				return data;
			});
	};

	public details = (id: number): Promise<any> => {
		return axios.get(API_URL.FAQ_CATEGORY.DETAILS(id)).then(({ data }) => {
			return data;
		});
	};

	public remove = (id: number): Promise<any> => {
		return axios.delete(API_URL.FAQ_CATEGORY.DELETE(id)).then(({ data }) => {
			this.fetchList();
			return data;
		});
	};

	public changeStatus = (payload:any): Promise<any> => {
		return axios.post(API_URL.FAQ_CATEGORY.CHANGE_STATUS(payload.id),payload)
		.then(({ data }) => {
			this.fetchList();
			return data;
		});
	};

}
