import React from "react";
import { AgGridColumn } from "ag-grid-react";
import AgGridWrapper from "../../../../components/AgGridWrapper/AgGridWrapper";
import { GridReadyEvent } from "ag-grid-community";
import { Button } from "antd";
import { EyeOutlined } from "@ant-design/icons";
import moment from "moment";
import Config from "../../../../config/Config";
import DateComparator from "../../../../utils/DateComparator";
import { t } from "i18next";
import { displayFormats } from "../../../../config/Global";

interface ListComponentProps {
	setupGrid: (params: GridReadyEvent) => void;
	transactionDetails:(id:string) => void | any;
}

const ListTransactionComponent: React.FC<ListComponentProps> = ({ setupGrid, transactionDetails }) => {
	const ActionRenderer = (params:any) => {
		return (
			<div className="action-column">
				<Button
					title={t("VIEW_TRADE_TRANSACTION")}
					type="text"
					onClick={() => transactionDetails(params.data?.transaction_id)}

				>
					<EyeOutlined />
				</Button>
			</div>
		);
	};

	return (
		<AgGridWrapper
			type="serverSide"
			onGridReady={setupGrid}
			rowSelection={"multiple"}
			frameworkComponents={{ ActionRenderer }}
			overlayLoadingTemplate={t("PLEASE_WAIT_WHILE_DATA_IS_LOADING")} overlayNoRowsTemplate={t("NO_RECORD_FOUND")}
		>
			<AgGridColumn
				field="id"
				headerName={"# " + t('ID')}
				pinned="left"
				filter="agNumberColumnFilter"
				width={120}
			/>
			<AgGridColumn
				field="transaction_id"
				headerName={t("TRANSACTION_ID")}
				width={140}
			/>
			<AgGridColumn
				field="user_name"
				headerName={t("USER_NAME")}
				width={200}
			/>
			<AgGridColumn
				field="group_name"
				headerName={t('GROUP_NAME')}
				width={160}
			/>
			<AgGridColumn
				field="transaction_details"
				headerName={t("TRANSACTION_DETAILS")}
				width={200}
			/>
			<AgGridColumn
				field="payment_status"
				headerName={t("PAYMENT_STATUS")}
				width={160}

			/>
			<AgGridColumn
				field="payment_type"
				headerName={t("CREDIT") + "/" + t("DEBIT")}

				width={160}
			/>
			<AgGridColumn
				field="transaction_for"
				headerName={t("TRANSACTION_FOR")}
				width={160}

			/>
			<AgGridColumn
				field="amount"
				headerName={t("AMOUNT")}
				filter= {'agNumberColumnFilter'}
				width={160}
			/>
			<AgGridColumn
				field="transaction_payment_mode"
				headerName={t("PAYMENT_MODE")}
				filter= {'agTextColumnFilter'}
				width={160}
			/>
			<AgGridColumn
				field="created_at"
				headerName={t('CREATED_AT')}
				valueGetter={({ data }) =>
					data.created_at &&
					moment(data.created_at).format(Config.dateTimeFormat)
				}
				filter={"agDateColumnFilter"}
				valueFormatter={(data:any) =>
					data?.data?.created_at && moment.utc(data?.data?.created_at).local().format(displayFormats.DATE_TIME_FORMAT)
				}
				filterParams={{
					comparator: DateComparator,
				}}
				width={220}
			/>
			<AgGridColumn
				field="updated_at"
				headerName={t("UPDATED_AT")}
				valueGetter={({ data }) =>
					data.updated_at &&
					moment(data.updated_at).format(Config.dateTimeFormat)
				}
				filter={"agDateColumnFilter"}
				valueFormatter={(data:any) =>
					data?.data?.updated_at && moment.utc(data?.data?.updated_at).local().format(displayFormats.DATE_TIME_FORMAT)
				}
				filterParams={{
					comparator: DateComparator,
				}}
				width={220}
			/>
			<AgGridColumn
				headerName={t('ACTION')}
				type="actionColumn"
				sortable={false}
				filter={false}
				width={100}
				pinned="right"
			/>
		</AgGridWrapper>
	);
};

export default ListTransactionComponent;
