import React, { useEffect, useState }  from "react";
import { observer } from "mobx-react";
import {  Button, Col, Form, Layout, Row, Upload } from 'antd';
import useStore from "../../../../store";
import { useHistory, useParams } from "react-router";
import { FormBox, InputBox } from "../../../../components/AntdAddons";
import { BannerRequest } from "../../../../requests/BannerRequest";
import { useTranslation } from "react-i18next";
import { uploadedFileOnPreview } from "../../../../config/Global";

const EditBannerComponent: React.FC = observer(() => {
	const { BANNER, ROOT } = useStore();
	const {
		update,
		details,
	} = BANNER;

	const [banner, setbanner] = useState<any>(null);
	const { id }: any = useParams();
	const { AssignErrorToInput } = ROOT;
	const[image, setBannerImage] = useState(null);
	const onFileChange = (info:any) =>{
		setBannerImage(info);
		return false;
	}

	useEffect(()=>{
		details(id).then((data:any) => {
			setbanner(data);
			form.resetFields();
			form.setFieldsValue({
				title:data?.title,
				description:data?.description,
				link:data?.link,
				link_title:data?.link_title,
			});
	});
	},[details])
	const [form] = Form.useForm();
	const [saving, setSaving] = useState<boolean>(false);
	const history = useHistory();
	const { t } = useTranslation();
	const listRoute = () =>{
		history.push('/banner');
  }

	// Handle submit and call function to save new record
	const handleSubmit = (data: any) => {
		setSaving(true);
		const formData = new FormData();
		image && formData.append('image',image);
		data.title && formData.append('title', data.title);
		data.description && formData.append('description', data.description);
		data.link && formData.append('link', data.link);
		data.link_title && formData.append('link_title', data.link_title);
		update(banner.id, formData)
			.then(() => {
				setSaving(false);
				form.resetFields();
				listRoute();
			})
			.catch((e:any) => {
				setSaving(false);
				AssignErrorToInput(form, e?.data?.errors);
			})
	};

	return (
		banner && <FormBox
			id="editBannerForm"
			form={form}
			encType="multipart/formdata"
			onFinish={handleSubmit} key="formbasic">
			<Layout style={{ padding: '0 24px' }}>
				<Row gutter={6} wrap={false}>
					<Col span={8} className="form__input__textbox">
						<InputBox.Text
							size="large"
							placeholder={t("TITLE")}
							label={t("TITLE")}
							name="title"
							rules={BannerRequest(t).title}
						/>
					</Col>
					<Col span={8} className="form__input__textbox">
							<InputBox.TextArea
								label={t("DESCRIPTION")}
								placeholder={t("DESCRIPTION")}
								name="description"
								rules={BannerRequest(t).description}
								maxLength={100}
							/>
					</Col>
				</Row>
				<Row gutter={6} wrap={false}>
					<Col span={16} className="form__input__textbox">
						<Upload
							name="image"
							accept="image/png, image/jpeg"
							listType="picture"
							defaultFileList={[
								{
									uid: '1',
									name: banner.title,
									status: 'done',
									response: 'Server Error 500', // custom error message to show
									url: banner.image,
									thumbUrl: banner.image,
								}]}
							maxCount={1}
							beforeUpload={onFileChange}
							onPreview={uploadedFileOnPreview}
							>
							<Button className="mb-20">{t("CLICK_TO_UPLOAD_IMAGE")}</Button>
						</Upload>
						<em>{t("WE_SUGGEST_TO_SET_THE_IMAGE_WIDTH_PX_WIDTH_X_HEIGHT_PX_HEIGHT",{widthPX:"1900px", heightPX: "500px"})}</em>
					</Col>
				</Row>
				<Row gutter={6} wrap={false}>
					<Col span={8} className="form__input__textbox">
						<InputBox.Text
							label={t("LINK")}
							placeholder={t("LINK")}
							name="link"
							maxLength={100}
						/>
					</Col>
					<Col span={8} className="form__input__textbox">
						<InputBox.Text
							label={t("LINK_TITLE")}
							placeholder={t("LINK_TITLE")}
							name="link_title"
							maxLength={100}
						/>
					</Col>
				</Row>
				<Col span={24} className="permission__button ant-row-center ant-row mt-20">
					<Button type="primary"
						htmlType="submit"
						loading={saving}
						key="submit1">{t("SAVE")}</Button>
					<Button type="primary" key="resetCancel" danger onClick={listRoute}>{t("CANCEL")}</Button>
				</Col>
			</Layout>
		</FormBox>
	);
});
export default EditBannerComponent;
