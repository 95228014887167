import React from "react";
import BreadcrumbComponent from "../../../components/BreadcrumbComponent/BreadcrumbComponent";
import { ElectionRefereeBreadcrumb } from "../../../config/BreadcrumbConfig";
import ContentBox from "../../../components/ContentBox/ContentBox";
import ListComponent from "./components/ListComponent";
import useStore from "../../../store";
import { observer } from "mobx-react-lite";
import ElectionRefereeDetails from "./components/DetailsComponent";
import RecordPerPage from "../../../utils/RecordPerPage";
import { Link } from "react-router-dom";
import { Row,} from 'antd';
import { useTranslation } from "react-i18next";

const ElectionRefereeView: React.FC = observer(() => {
	const { t } = useTranslation();
	const { ELECTION_REFEREE } = useStore();
	const { setupGrid, showDetails, details, closeDetails, showDetailsPopUp, ElectionRefereeId, setPageSize, perPage } = ELECTION_REFEREE;
	return (
			<>
			<div className="Breadcrumb-bar">
				<BreadcrumbComponent items={ElectionRefereeBreadcrumb(t).path} />
			</div>
			<Row justify="end">
				<Link to="election-process" className="btn btn-primary btn-link ant-row-end">{t("BACK_TO_ELECTION_PROCESS")}</Link>
				<div className="RecordPerPage__right">
					<RecordPerPage
						style={{ width: "150px" }}
						defaultValue={perPage + " per page"}
						onChange={setPageSize}
					/>
				</div>
			</Row>
			<ContentBox>
				
				<ListComponent
					setupGrid={setupGrid}
					ElectionRefereeDetails = {showDetails}
				/>
				<ElectionRefereeDetails
					ElectionRefereeID={ElectionRefereeId}
					ReadData={details}
					visible={showDetailsPopUp}
					closePopup={closeDetails}
				/>
			</ContentBox>
		</>
	);
});

export default ElectionRefereeView;
