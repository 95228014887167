import React from "react";
import { AgGridColumn } from "ag-grid-react";
import moment from "moment";
import AgGridWrapper from "../../../../../components/AgGridWrapper/AgGridWrapper";
import Config from "../../../../../config/Config";
import DateComparator from "../../../../../utils/DateComparator";
import { GridReadyEvent } from "ag-grid-community";
import { EyeOutlined, EditOutlined, CheckOutlined, CloseOutlined, UsergroupAddOutlined } from "@ant-design/icons";
import { Button } from "antd";
import { useTranslation } from "react-i18next";
import { displayFormats } from "../../../../../config/Global";

interface ListProps {
	setupGrid: (params: GridReadyEvent) => void;
	editRedirect: (id: number) => void;
	editPermission: boolean;
	detailPermission: boolean;
	detailsRedirect: (id: number) => void;
	ChangeStatus: (data: any) => void | any;
}

const ListComponent: React.FC<ListProps> = ({
	setupGrid,
	editRedirect,
	editPermission,
	detailPermission,
	detailsRedirect,
	ChangeStatus
}) => {
	const { t } = useTranslation();

	const ActionRenderer = (params: any) => {
		return (
			<div className="action-column">
				<Button
					type="text"
					title={t("EDIT")}
					icon={<EditOutlined />}
					onClick={() => editRedirect(params.data?.id)}
					hidden={!editPermission || (params.data?.publish_status == 2 || params.data?.publish_status == 3)}
				></Button>
				<Button
					type="text"
					title={t("DETAILS")}
					icon={<EyeOutlined />}
					onClick={() => detailsRedirect(params.data?.id)}
					hidden={!detailPermission}
				></Button>
			</div>
		);
	};

	const changeStatus = (data: any) => {
		ChangeStatus(data)
			.then(() => {
				return;
			})
			.finally(() => {
				return;
			});
	}

	const SwitchRenderer = (params: any) => {
		return (
			<div>
				<Button
					type="default"
					icon={<CheckOutlined />}
					hidden={params.data?.publish_status == 2 || params.data?.publish_status == 3}
					onClick={() => { if (params.data?.publish_status == 0 && !params.data?.is_created_by_admin) { changeStatus({ id: params.data?.id, status: 1 }) } }}
					disabled={(!editPermission || params.data?.publish_status != 0 || params.data?.is_created_by_admin)}
					style={{ color: "green" }}
				/>
				<Button
					type="default"
					icon={<CloseOutlined />}
					hidden={params.data?.publish_status == 1 || params.data?.publish_status == 3}
					onClick={() => { if (params.data?.publish_status == 0 && !params.data?.is_created_by_admin) { changeStatus({ id: params.data?.id, status: 2 }) } }}
					disabled={(!editPermission || params.data?.publish_status != 0 || params.data?.is_created_by_admin)}
					style={{ color: "red" }}
				/>
			</div>
		);
	};

	const GroupImageRenderer = (params: any) => {
		return (
			<div>
				{params.data?.group_image ? <img className="groupImages__rou" src={params.data?.group_image} height={30} width={30} /> : <UsergroupAddOutlined className="groupImages" />}
			</div>
		);
	};

	return (
		<AgGridWrapper
			type="serverSide"
			onGridReady={setupGrid}
			rowSelection={"multiple"}
			frameworkComponents={{ ActionRenderer, SwitchRenderer, GroupImageRenderer }}
			overlayLoadingTemplate={t("PLEASE_WAIT_WHILE_DATA_IS_LOADING")} overlayNoRowsTemplate={t("NO_RECORD_FOUND")}

		>
			<AgGridColumn
				field="id"
				headerName={"# " + t('ID')}
				width={100}
				pinned={"left"}
				filter="agNumberColumnFilter"
			/>
			<AgGridColumn field="group_image"
				headerName={"#" + t("GROUP_IMAGE")} width={150}
				cellRenderer="GroupImageRenderer"
				pinned={"left"}
				sortable={false}
				filter={false}
			/>
			<AgGridColumn
				field="name"
				headerName={t('GROUP_NAME')}
			/>
			<AgGridColumn
				field="confederation_name"
				headerName={t("CONFEDERATION_NAME")}
			/>
			<AgGridColumn
				field="group_description"
				headerName={t('DESCRIPTION')}
			/>
			<AgGridColumn field="numbers_of_member" width={150} headerName={t("NUMBER_OF_MEMBERS")} filter="agNumberColumnFilter" />
			<AgGridColumn field="share_price" width={150} headerName={t("SHARE_PRICE")} filter="agNumberColumnFilter" />
			<AgGridColumn
				field="group_welfare_rate"
				headerName={t("GROUP_WELFARE_RATE")}
				filter="agNumberColumnFilter"
			/>
			<AgGridColumn
				field="borrowing_limit"
				headerName={t("BORROWING_LIMIT")}
				filter="agNumberColumnFilter"
			/>
			<AgGridColumn
				field="operating_cycle_date"
				headerName={t("OPERATING_CYCLE_DATE")}
				filter={"agDateColumnFilter"}
				valueFormatter={(data: any) =>
					data?.data?.operating_cycle_date && moment.utc(data?.data?.operating_cycle_date).local().format(displayFormats.DATE_FORMAT)
				}
			/>
			<AgGridColumn field="currency_name" headerName={t("CURRENCY")} />
			<AgGridColumn
				field="created_by_admin"
				headerName={t("ADMIN_CREATED")}
			/>
			<AgGridColumn
				field="created_at"
				headerName={t('CREATED_AT')}
				valueGetter={({ data }) =>
					data.created_at &&
					moment(data.created_at).format(Config.dateTimeFormat)
				}
				filter={"agDateColumnFilter"}
				valueFormatter={(data: any) =>
					data?.data?.created_at && moment.utc(data?.data?.created_at).local().format(displayFormats.DATE_TIME_FORMAT)
				}
				filterParams={{
					comparator: DateComparator,
				}}
			/>
			<AgGridColumn
				field="updated_at"
				headerName={t("UPDATED_AT")}
				valueGetter={({ data }) =>
					data.updated_at &&
					moment(data.updated_at).format(Config.dateTimeFormat)
				}
				filter={"agDateColumnFilter"}
				valueFormatter={(data: any) =>
					data?.data?.updated_at && moment.utc(data?.data?.updated_at).local().format(displayFormats.DATE_TIME_FORMAT)
				}
				filterParams={{
					comparator: DateComparator,
				}}
			/>
			<AgGridColumn
				field="status"
				headerName={t('STATUS')}
				cellRenderer="SwitchRenderer"
				width={120}
				pinned={"right"}
				sortable={false}
				filter={false}
				cellClass="Switch__right"
			/>
			<AgGridColumn
				headerName={t('ACTION')}
				type="actionColumn"
				sortable={false}
				filter={false}
				width={100}
				pinned={"right"}
			/>
		</AgGridWrapper>
	);
};

export default ListComponent;
