import React, { useState } from "react";
import { Button } from "antd";
import BreadcrumbComponent from "../../../components/BreadcrumbComponent/BreadcrumbComponent";
import { TransactionBreadcrumb } from "../../../config/BreadcrumbConfig";
import ContentBox from "../../../components/ContentBox/ContentBox";
import ListComponent from "./components/ListComponent";
import useStore from "../../../store";
import { observer } from "mobx-react-lite";
import TransactionDetails from "./components/TransactionDetails";
import RecordPerPage from "../../../utils/RecordPerPage";
import { useTranslation } from "react-i18next";
import { GrDocumentExcel } from "react-icons/gr";
import ExportPopupComponent from "../../../utils/ExportPopupComponent";

const TransactionView: React.FC = observer(() => {
	const { t } = useTranslation();
	const { TRANSACTIONS } = useStore();
	const { setupGrid, showDetails, details, closeDetails, showDetailsPopUp, setPageSize, perPage, transactionId, exportList } = TRANSACTIONS;

	const [exportSaving, setExportSaving] = useState<boolean>(false);
	const [exportPopup, showExportPopup] = useState<boolean>(false);

	const exportListData = (type: string) => {
		setExportSaving(true);
		exportList(type).finally(() => {
			setExportSaving(false);
			showExportPopup(false);
		});
	}

	return (
			<>
			<div className="Breadcrumb-bar">
				<BreadcrumbComponent items={TransactionBreadcrumb(t).path} />
				<div className="header_all_btn">
					<Button type="primary" className="export-btn" icon={<GrDocumentExcel />} onClick={()=> showExportPopup(true)}>
						{t("GENERATE_REPORT")}
					</Button>
							
					<ExportPopupComponent
						callbackExportFunction={exportListData}
						setVisible={showExportPopup}
						visible={exportPopup}
						saving={exportSaving}
					/>
				</div>
			</div>
			<ContentBox>
				<div className="RecordPerPage__right">
					<RecordPerPage
						style={{ width: "150px" }}
						defaultValue={perPage + " per page"}
						onChange={setPageSize}
					/>

				</div>
				<ListComponent
					setupGrid={setupGrid}
					transactionDetails = {showDetails}
				/>
				<TransactionDetails
					TransactionID={transactionId}
					ReadData={details}
					visible={showDetailsPopUp}
					closePopup={closeDetails}
				/>
			</ContentBox>
		</>
	);
});

export default TransactionView;
