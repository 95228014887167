import React from "react";
import { observer } from "mobx-react";
import BreadcrumbComponent from "../../../components/BreadcrumbComponent/BreadcrumbComponent";
import { DonationSubjecteBreadcrumb } from "../../../config/BreadcrumbConfig";
import HeaderButtons from "./components/HeaderButtons";
import ContentBox from "../../../components/ContentBox/ContentBox";
import ListComponent from "./components/ListComponent";
import useStore from "../../../store";
import AddComponent from "./components/AddComponent";
import EditComponent from "./components/EditComponent";
import DeleteComponent from "./components/DeleteComponent";
import { useTranslation } from "react-i18next";
import RecordPerPage from "../../../utils/RecordPerPage";
import { Row } from "antd";
const DonationSubjectView: React.FC = observer(() => {
	const { t } = useTranslation();
	const { DONATIONSUBJECT } = useStore();
	const {
		create,
		update,
		details,
		remove,
		changeStatus,
		setupGrid, setPageSize, perPage,
		addDonationSubjectPopup,
		openAddDonationSubjectPopup,
		closeAddDonationSubjectPopup,
		editDonationSubjectID,
		editDonationSubjectPopup,
		openEditDonationSubjectPopup,
		closeEditDonationSubjectPopup,
		deleteDonationSubjectID,
		deleteDonationSubjectPopup,
		openDeleteDonationSubjectPopup,
		closeDeleteDonationSubjectPopup,
	} = DONATIONSUBJECT;

    return (
		<>
			<div className="Breadcrumb-bar">
				<BreadcrumbComponent items={DonationSubjecteBreadcrumb(t).path} />
				<HeaderButtons key={1} openAddDonationSubjectePopup={openAddDonationSubjectPopup}/>
			</div>
			<Row justify="end">
				<div className="RecordPerPage__right">
					<RecordPerPage
						style={{ width: "150px" }}
						defaultValue={perPage + " per page"}
						onChange={setPageSize}
					/>
				</div>
			</Row>
			<ContentBox>
				<ListComponent
					setupGrid={setupGrid}
					openEditDonationSubjectPopup={openEditDonationSubjectPopup}
					openDeleteDonationSubjectPopup={openDeleteDonationSubjectPopup}
					InactiveData={changeStatus}
					ChangeStatus={changeStatus}
				/>
				<AddComponent
					createData={create}
					visible={addDonationSubjectPopup}
					closeAddDonationSubjectePopup={closeAddDonationSubjectPopup}
				/>
				<EditComponent
					EditID={editDonationSubjectID}
					EditData={update}
					ReadData={details}
					visible={editDonationSubjectPopup}
					closeEditDonationSubjectPopup={closeEditDonationSubjectPopup}
				/>
				<DeleteComponent
					DeleteID={deleteDonationSubjectID}
					DeleteData={remove}
					visible={deleteDonationSubjectPopup}
					closeDeleteDonationSubjectPopup={closeDeleteDonationSubjectPopup}
				/>
			</ContentBox>
		</>
	);
});

export default DonationSubjectView;
