import React, { useState, useEffect } from "react";
import { Button, Layout, Row, Col } from "antd";
import moment from "moment";
import { observer } from "mobx-react";
import useStore from "../../../../store";
import { useParams, useHistory } from "react-router";
import BreadcrumbComponent from "../../../../components/BreadcrumbComponent/BreadcrumbComponent";
import { VillageAgentBreadcrumb } from "../../../../config/BreadcrumbConfig";
import AssignVillageAgent from "./AssignVillageAgent";
import RemoveVillageAgent from "./RemoveVillageAgent";
import { useTranslation } from "react-i18next";
import { displayFormats, genderList } from "../../../../config/Global";

const VillageAgentDetails: React.FC = observer(() => {
	const { t } = useTranslation();
	const { VILLAGE_AGENT } = useStore();
	const {
		details,
	} = VILLAGE_AGENT;
	const [villageAgent, setVillageAgent] = useState<any>();
	const { id }: any = useParams();
	const [isAssignGroupModelVisiable, setIsAssignGroupModelVisiable] = useState<boolean>(false);
	const [isRemoveGroupModelVisiable, setIsRemoveGroupModelVisiable] = useState<boolean>(false);

	const getgender = (gender: number) => {
		const selectdGender = genderList(t).filter((element:any) => element.id == gender);
		return selectdGender[0].value;
	}

	const showAssignGroupModel = () => {
		setIsAssignGroupModelVisiable(true);
	};
	const handleCancel = () => {
		setIsAssignGroupModelVisiable(false);
		setIsRemoveGroupModelVisiable(false);
		details(id).then((data: any) => {
			const assignedGroups = data.roles?.map((element:any) => {
				if(element.name== "Village Agent")  return  element.group?.name;
			});
			data.assignedGroups = assignedGroups;
			const assignedGroupIds = data.roles?.map((element:any) => {
				if(element.name== "Village Agent")  return  element.group?.id;
			});
			data.assignedGroupIds = assignedGroupIds;
			setVillageAgent(data);
		});
	};

	const showRemoveGroupModel = () => {
		setIsRemoveGroupModelVisiable(true);
	};

	const history = useHistory();
	const listRoute = () =>{
		history.push('/village-agent');
	}

	useEffect(() => {
		!villageAgent && details(id).then((data: any) => {
			const assignedGroups = data.roles?.map((element:any) => {
				if(element.name== "Village Agent")  return  element.group?.name;
			});
			data.assignedGroups = assignedGroups;
			const assignedGroupIds = data.roles?.map((element:any) => {
				if(element.name== "Village Agent")  return  element.group?.id;
			});
			data.assignedGroupIds = assignedGroupIds;
			setVillageAgent(data);
		});
	}, [id, villageAgent]);
	
	return (
		<>
			<div className="Breadcrumb-bar Breadcrumb-bar-export">
				<BreadcrumbComponent items={VillageAgentBreadcrumb(t).path} />
			</div>
			{villageAgent && <Layout className="details_table" style={{ padding: '0 24px' }}>
				<Row gutter={6} wrap={false} >
					<Col span={12} className="form__input__textbox form__input__selectBox">
						<label>{t("USER_NAME")} : </label>
						<span>{villageAgent && villageAgent?.firstname + " " + villageAgent?.lastname}</span>
					</Col>
					<Col span={12} className="form__input__textbox">
						<label>{t("ADDRESS")} : </label>
						<span>{villageAgent && villageAgent?.address}</span>
					</Col>
				</Row>
				<Row gutter={6} wrap={false}>
					<Col span={12} className="form__input__textbox">
						<label>{t("EMAIL")} : </label>
						<span>{villageAgent && villageAgent?.email}</span>
					</Col>
					<Col span={12} className="form__input__textbox">
						<label>{t("PHONE_NUMBER")} : </label>
						<span>{villageAgent && villageAgent?.phone_number}</span>
					</Col>
				</Row>
				<Row gutter={6} wrap={false}>
					<Col span={12} className="form__input__textbox">
						<label>{t("GENDER")} : </label>
						<span>{villageAgent && getgender(villageAgent?.gender)}</span>
					</Col>
					<Col span={12} className="form__input__textbox">
						<label>{t("DATE_OF_BIRTH")} : </label>
						<span>{moment.utc(villageAgent && villageAgent?.date_of_birth).local().format(displayFormats.DATE_FORMAT)}</span>						
					</Col>
				</Row>
				<Row gutter={6} wrap={false}>
					<Col span={12} className="form__input__textbox">
						<label>{t("ASSIGNED_GROUPS")} : </label>
						<span>{villageAgent && villageAgent.assignedGroups.length > 0 ? villageAgent.assignedGroups?.join(", ") : "N/A"}</span>
					</Col>
					<Col span={12} className="form__input__textbox">
					<label>{t("PROFESSION")} : </label>
					<span>{villageAgent && villageAgent?.profession_name}</span>
				</Col>
				</Row>
				<Row gutter={6} wrap={false}>
					<Col span={12} className="form__input__textbox">
						<label>{t("CREATED_AT")}: </label>
						<span>{moment.utc(villageAgent?.created_at).local().format(displayFormats.DATE_TIME_FORMAT)}</span>
					</Col>
					<Col span={12} className="form__input__textbox">
						<label>{t("UPDATED_AT")}: </label>
						<span>{moment.utc(villageAgent?.updated_at).local().format(displayFormats.DATE_TIME_FORMAT)}</span>
					</Col>
				</Row>
				<Row gutter={6} wrap={false}>
					<Col span={12} className="form__input__textbox">
						<label>{t("CREATED_BY")}: </label>
						<span>{villageAgent?.createdBy ? villageAgent?.createdBy?.firstname + " " + villageAgent?.createdBy?.lastname + " (" + villageAgent?.createdBy?.email + ")" : " - "}</span>
					</Col>
					<Col span={12} className="form__input__textbox">
						<label>{t("UPDATED_BY")}: </label>
						<span>{villageAgent?.updatedBy ? villageAgent?.updatedBy?.firstname + " " + villageAgent?.updatedBy?.lastname + " (" + villageAgent?.updatedBy?.email + ")" : " - "}</span>
					</Col>
				</Row>				
				<AssignVillageAgent
					isAssignGroupModelVisiable={isAssignGroupModelVisiable}
					id={id}
					handleCancel={handleCancel}
					villageAgent={villageAgent}
				/>
				<RemoveVillageAgent
					isRemoveGroupModelVisiable={isRemoveGroupModelVisiable}
					setIsRemoveGroupModelVisiable={setIsRemoveGroupModelVisiable}
					id={id}
					handleCancel={handleCancel}
				/>
				<Row gutter={6} className="permission__button ant-row-center ant-row mt-50">
					<Button type="primary" className="view-permissions-btn" key="permission" style={{ backgroundColor: "#000000" }} onClick={showAssignGroupModel}> {t("ASSIGN_GROUP")} </Button>
					<Button type="primary"  hidden={villageAgent && !villageAgent.assignedGroups?.join(", ")}  className="view-permissions-btn" key="permission" style={{ backgroundColor: "#000000" }} onClick={showRemoveGroupModel}> {t("REMOVE_GROUP")} </Button>					
					<Button type="primary" key="resetCancel" danger onClick={listRoute}>{t("BACK")}</Button>
				</Row>
			</Layout>}
		</>
	);
});
export default VillageAgentDetails;
