import { Tabs } from "antd";
import { observer } from "mobx-react";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router";
import BreadcrumbComponent from "../../../../../components/BreadcrumbComponent/BreadcrumbComponent";
import ContentBox from "../../../../../components/ContentBox/ContentBox";
import { GroupFinanceManagementBreadcrumb } from "../../../../../config/BreadcrumbConfig";
import useStore from "../../../../../store";
import ListDonationComponent from "./ListDonationComponent";
import ListFineComponent from "./ListFineComponent";
import ListLoanComponent from "./ListLoanComponent";

const GroupFinanceDetails: React.FC = observer(() => {
	const { t } = useTranslation();
	const { AUTH, GROUP_FINANCE, GROUP, COMMON } = useStore();
	const { permissions } = AUTH;
	const { setGroupId } = GROUP_FINANCE;
	const { details } = GROUP;
	const { TabPane } = Tabs;
	const { id }: any = useParams();
	const [groupName, setGroupName] = useState("");
	const {allPermissionList} = COMMON

	useEffect(()=>{
		//
	}, [allPermissionList]);
	
	useEffect(() => {
		id && setGroupId(id);
		id && details(id).then((data: any) => {
			setGroupName(data.name);
		});
	}, [id]);

	return (
		<>
			<div className="Breadcrumb-bar">
				<BreadcrumbComponent items={GroupFinanceManagementBreadcrumb(t).path} />
			</div>

			<ContentBox>
				<b>{` ${t("GROUP_NAME")}: ${groupName}`}</b>
				<Tabs defaultActiveKey={"fine"} centered>
					{permissions && allPermissionList &&
						permissions.indexOf(allPermissionList.LIST_FINE) >= 0 && (
							<TabPane tab={t("FINES")} key="fine" active={true}>
								<ListFineComponent />
							</TabPane>
						)}
					{permissions && allPermissionList &&
						permissions.indexOf(allPermissionList.LIST_USER_LOAN_REQUEST) >=
							0 && (
							<TabPane tab={t("LOAN")} key="loan">
								<ListLoanComponent />
							</TabPane>
						)}
					{permissions && allPermissionList &&
						permissions.indexOf(allPermissionList.LIST_DONATION) >= 0 && (
							<TabPane tab={t("DONATION")} key="donation">
								<ListDonationComponent />
							</TabPane>
						)}
				</Tabs>
			</ContentBox>
		</>
	);
});

export default GroupFinanceDetails;
