import React from "react";
import { observer } from "mobx-react";
import AddComponent from "./components/AddComponent";
import BreadcrumbComponent from "../../../components/BreadcrumbComponent/BreadcrumbComponent";
import { ImportTypeBreadcrumb } from "../../../config/BreadcrumbConfig";
import { useTranslation } from "react-i18next";

const ImportData: React.FC = observer(() => {
	const { t } = useTranslation();

	return (
		<>
			<div className="Breadcrumb-bar">
				<BreadcrumbComponent items={ImportTypeBreadcrumb(t).path} />
			</div>
			<div>
				<AddComponent />
			</div>
		</>
	);
});

export default ImportData;
