import { UserOutlined } from "@ant-design/icons";
import { Button, Row, Col, Layout } from "antd";
import { observer } from "mobx-react";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { FormBox, InputBox } from "../../../../../components/AntdAddons";
import BreadcrumbComponent from "../../../../../components/BreadcrumbComponent/BreadcrumbComponent";
import { LoanRequestBreadcrumb } from "../../../../../config/BreadcrumbConfig";
import { LoanRequest } from "../../../../../requests/LoanRequest";

interface FormDataProps {
	formId: string;
	form: any;
	handleSubmit: (data: any) => void | any;
	saving: boolean;
	listRoute: () => void;
	isEdit: boolean;
	groupList: any[];
	groupUser: any[];
	fetchGroupUser: (id: number) => Promise<any>;
	Groupdetails: any[];
	fetchGroupdetails: (id: number) => Promise<any>;
	preferenceList: any;
}


const FormComponent: React.FC<FormDataProps> = observer(({
	formId,
	form,
	handleSubmit,
	saving,
	listRoute,
	groupList,
	groupUser,
	fetchGroupUser,
	fetchGroupdetails,
	preferenceList
}) => {
	const getGroupUsers = (id: any) => {
		form.setFieldsValue({ user_id: undefined });
		fetchGroupUser(id);
		fetchGroupdetails(id).then((data: any) => {
			setGroupdetails(data);

		});
	}
	const [groupdetails, setGroupdetails] = useState<any>([]);
	const { t } = useTranslation();

	return (
		<FormBox
			id={formId}
			form={form}
			encType="multipart/formdata"
			onFinish={handleSubmit} key="formbasic">
			<div className="Breadcrumb-bar Breadcrumb-bar-export">
				<BreadcrumbComponent items={LoanRequestBreadcrumb(t).path} />
			</div>
			<Layout style={{ padding: '0 24px' }}>
				<Row gutter={6} wrap={false}>
					<Col span={8} className="form__input__textbox">
						{groupList && <InputBox.Select
							options={{
								list: groupList,
								valueKey: "id",
								textKey: "name"
							}}
							label={t("GROUP")}
							placeholder={t("GROUP")}
							name="group_id"
							onChange={getGroupUsers}
							rules={LoanRequest(t).group_id}
						/>}
					</Col>
					<Col span={8} className="form__input__textbox form__input__selectBox">
						<p>{t("AMOUNT")} {groupUser && <i>(Maximum amount of loan as {groupdetails?.borrowing_limit} CFA)</i>}</p>
						<InputBox.Text
							size="large"
							placeholder={t("AMOUNT")}
							name="loan_amount"
							prefix={<UserOutlined />}
							rules={LoanRequest(t).loan_amount}
						/>
					</Col>

				</Row>
				<Row gutter={6} wrap={false}>
					<Col span={8} className="form__input__textbox form__input__selectBox">
						{groupUser && <InputBox.Select
							options={{
								list: groupUser,
								valueKey: "id",
								textKey: "user_name"
							}}
							label={t("USER")}
							placeholder={t("USER")}
							name="user_id"
							rules={LoanRequest(t).user_id}
						/>}
					</Col>
					<Col span={8} className="form__input__textbox">
						<InputBox.Text
							size="large"
							name="number_of_installments"
							placeholder={t("DURATION") + " (" + t("IN_MONTHS")+")"}
							prefix={<UserOutlined />}
							label={t("DURATION") + " (" + t("IN_MONTHS")+")"}
							rules={LoanRequest(t).number_of_installments}
						/>
					</Col>

				</Row>
				<Row gutter={6} wrap={false}>
					<Col span={8} className="form__input__textbox">
						<InputBox.TextArea
							size="large"
							name="description"
							placeholder={t("DESCRIPTION")}
							prefix={<UserOutlined />}
							label={t("DESCRIPTION")}
						/>
					</Col>
					<Col span={8} className="form__input__textbox">
						<InputBox.Select
							required
							name="preference"
							placeholder={t("PREFERENCE")}
							label={t("PREFERENCE")}
							options={{
								list: preferenceList,
								valueKey: "id",
								textKey: "name"
							}}
						/>
					</Col>

				</Row>
				<Col span={24} className="permission__button ant-row-center ant-row mt-50">
					<Button type="primary"
						htmlType="submit"
						loading={saving}
						key="submit1">{t('SAVE')}</Button>
					<Button type="primary" key="resetCancel" danger onClick={listRoute}>{t('CANCEL')}</Button>
				</Col>
			</Layout>
		</FormBox>
	);
});
export default FormComponent;
