import React from "react";
import { observer } from "mobx-react";
import BreadcrumbComponent from "../../../components/BreadcrumbComponent/BreadcrumbComponent";
import { BannerBreadcrumb } from "../../../config/BreadcrumbConfig";
import ContentBox from "../../../components/ContentBox/ContentBox";
import ListComponent from "./components/ListComponent";
import useStore from "../../../store";
import { useHistory } from "react-router";
import { useTranslation } from "react-i18next";
const BannerView: React.FC = observer(() => {
	const { t } = useTranslation();
	const { BANNER } = useStore();
	const {
		listData,
		changeStatus,
		fetchList,
		openDeleteBannerPopup,
	} = BANNER;
	const pathHistory = useHistory();
	const editBanner = (id: number) => {
		pathHistory.push('/banner-management/' + id + '/edit');
	}
	return (
		<>
			<div className="Breadcrumb-bar">
				<BreadcrumbComponent items={BannerBreadcrumb(t).path} />
			</div>
			<ContentBox>
				<ListComponent
					rowData={listData}
					getData={fetchList}
					InactiveData={changeStatus}
					ChangeStatus={changeStatus}
					editBannerRedirect={editBanner}
					openDeleteBannerPopup={openDeleteBannerPopup}
				/>				
			</ContentBox>
		</>

	);
});

export default BannerView;