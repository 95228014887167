import { GridOptions, GridReadyEvent, IServerSideGetRowsParams } from "ag-grid-community";
import axios from "axios";
import { makeAutoObservable } from "mobx";
import API_URL from "../../config/ApiUrl";
import Config from "../../config/Config";
import RootStore from "../RootStore/RootStore";
import {  TemplateListDataProps } from "./TemplateInterface";

export default class  TemplateStore {
	public listData?: TemplateListDataProps[];
	public template?: TemplateListDataProps;
	public edittemplatePopup?: boolean;
	public editTemplateID?: number;
	public templateType?: number = 1;
	public perPage?:any = 100;
	public grid?: any;
	private rootStore: RootStore;

	constructor() {
		this.rootStore = new RootStore();
		makeAutoObservable(this);
	}

	// Setter Functions
	private setEditTemplateID = (id?: number): void => {
		this.editTemplateID = id;
	};
	
	private setListData = (value?:  TemplateListDataProps[]): void => {
		this.listData = value;
	};

	public setTemplate = (value?:  TemplateListDataProps): void => {
		this.template = value;
	};

	private setTemplateType = (templateType:number):void => {
		this.templateType = templateType;
	}

	private setGrid = (value: any) => {
		this.grid = value;
	};

	public setupGrid = (params: GridReadyEvent) => {
		this.setGrid(params);
		this.setPageSize()
		const datasource = this.createDatasource(Config.grid.server.gridOptions);
		params.api.setServerSideDatasource(datasource);
	};

	public setPageSize = (page = this.perPage) => {
		this.perPage = page;
		if (this.grid) {
			this.grid.api.paginationSetPageSize(parseInt(page));
		}
	};

	private createDatasource = (gridOptions?: GridOptions) => {
		return {
			gridOptions,
			getRows: (params: IServerSideGetRowsParams) => {
				const payload = this.rootStore.getServerListPayload(params);
				this.fetchList(payload).then((data) => {
					params.successCallback(data?.rows, data?.count);
					if (data.count <= 0) {
						params.api.showNoRowsOverlay();
					} else {
						params.api.hideOverlay();
					}
				});
			},
		};
	};

	// API Functions
	
	public fetchList = (payLoad: any): Promise<any> => {
		if (payLoad) {
			payLoad.template_type = this.templateType;
		} else {
			payLoad = {template_type: this.templateType};
		}
		return axios.post(API_URL.TEMPLATE.LIST, payLoad).then(({ data }) => {
			this.setListData(data.data.rows);
			return data.data;
		});
	};

	public update = (id:number,payload:FormData): Promise<any> => {
		return axios
			.post(API_URL.TEMPLATE.UPDATE(id), payload, {headers: { "Content-Type": "multipart/form-data" }})
			.then(({ data }) => {				
				return data;
			});
	};
	public details = (id: number): Promise<any> => {
		return axios.get(API_URL.TEMPLATE.DETAILS(id)).then(({ data }) => {
			return data;
		});
	};

	public changeStatus = (payload:any): Promise<any> => {
		return axios.post(API_URL.TEMPLATE.CHANGE_STATUS(payload.id),payload)
		.then(({ data }) => {
			return data;
		});
	};

	public changeTemplateType = (templateType : number): void => {
		this.setTemplateType(templateType);
	}

}
