import React, { useEffect } from "react";
import { observer } from "mobx-react";
import BreadcrumbComponent from "../../../components/BreadcrumbComponent/BreadcrumbComponent";
import { SliderBreadcrumb } from "../../../config/BreadcrumbConfig";
import ContentBox from "../../../components/ContentBox/ContentBox";
import ListComponent from "./components/ListComponent";
import useStore from "../../../store";
import DeleteComponent from "./components/DeleteComponent";
import { useHistory } from "react-router";
import HeaderButtons from "./components/HeaderButtons";
import { useTranslation } from "react-i18next";
const SliderView: React.FC = observer(() => {
	const { t } = useTranslation();
	const { SLIDER, AUTH, COMMON } = useStore();
	const {
		listData,
		remove,
		changeStatus,
		fetchList,
		deleteSliderID,
		deleteSliderPopup,
		openDeleteSliderPopup,
		closeDeleteSliderPopup,
	} = SLIDER;
	const pathHistory = useHistory();
	const { permissions } = AUTH;
	const { allPermissionList } = COMMON;

	useEffect(() => {
		//
	}, [allPermissionList]);
	const editSlider = (id: number) => {
		pathHistory.push("/slider-management/" + id + "/edit");
	};
	const addRedirect = () => {
		pathHistory.push("slider-management/create");
	};
	return (
		<>
			<div className="Breadcrumb-bar">
				<BreadcrumbComponent items={SliderBreadcrumb(t).path} />
				<HeaderButtons
					key={1}
					addRedirect={addRedirect}
					addPermission={
						permissions &&
						allPermissionList &&
						permissions.indexOf(allPermissionList.ADD_SLIDER) >= 0
					}
				/>
			</div>
			<ContentBox>
				<ListComponent
					rowData={listData}
					getData={fetchList}
					InactiveData={changeStatus}
					ChangeStatus={changeStatus}
					editSliderRedirect={editSlider}
					openDeleteSliderPopup={openDeleteSliderPopup}
				/>
				<DeleteComponent
					DeleteID={deleteSliderID}
					DeleteData={remove}
					visible={deleteSliderPopup}
					closeDeleteSliderPopup={closeDeleteSliderPopup}
				/>
			</ContentBox>
		</>
	);
});

export default SliderView;
