import {
	GridOptions,
	GridReadyEvent,
	IServerSideGetRowsParams,
} from "ag-grid-community";
import axios from "axios";
import { makeAutoObservable } from "mobx";
import API_URL from "../../config/ApiUrl";
import Config from "../../config/Config";
import RootStore from "../RootStore/RootStore";

export default class NewElectionRequestStore {
	public grid?: any;
	public showDetailsPopUp?: boolean;
	public NewElectionRequestId?: any;
	private rootStore: RootStore;

	constructor() {
		this.rootStore = new RootStore();
		makeAutoObservable(this);
	}

	// Setter Functions
	private setGrid = (value: any) => {
		this.grid = value;
	};

	private setDetailsPopup = (value?: boolean) => {
		this.showDetailsPopUp = value;
	}

	private setTransactionId = (value?: any) => {
		this.NewElectionRequestId = value;
	}
	// Grid Setup Functions

	public setupGrid = (params: GridReadyEvent) => {
		this.setGrid(params);
		const datasource = this.createDatasource(Config.grid.server.gridOptions);
		params.api.setServerSideDatasource(datasource);
	};

	private createDatasource = (gridOptions?: GridOptions) => {
		return {
			gridOptions,
			getRows: (params: IServerSideGetRowsParams) => {
				const payload = this.rootStore.getServerListPayload(params);
				this.fetchList(payload).then((data) => {
					params.successCallback(data.rows, data.count);
					if (data.count <= 0) {
						params.api.showNoRowsOverlay();
					} else {
						params.api.hideOverlay();
					}
				});
			},
		};
	};

	// API Functions
	public fetchList = (payload: any): Promise<any> => {
		return axios
			.post(API_URL.NEW_ELECTION.LIST, payload)
			.then(({ data }) => {
				return data.data;
			});
	};

	public assignelectionrefree = (payload:any): Promise<any> => {
		return axios.post(API_URL.ASSIGN_ELECTION_REFREE.ASSIGN_REFREE, payload)
			.then(({ data }) => {
				return data.data;
			});
	};

	public showDetails = (id:any) => {
		this.setDetailsPopup(true);
		this.setTransactionId(id);
	}

	public closeDetails = () => {
		this.setDetailsPopup(false);
		this.setTransactionId();
	}
}
