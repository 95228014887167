import React, { useState } from "react";
import { Button, Card, Col, Divider, Form, Row, Space } from "antd";
import { RegisterRequest } from "../../../requests/AuthRequest";
import Config from "../../../config/Config";
import { Link, useHistory } from "react-router-dom";
import useStore from "../../../store";
import { ErrorProps } from "../../../store/RootStore/RootInterface";
import { FormBox, InputBox } from "../../../components/AntdAddons";
import { useTranslation } from "react-i18next";
import { aesEncryptionPassword } from "../../../config/Global";

const Register: React.FC = () => {
	const [form] = Form.useForm();
	const history = useHistory();

	const [saving, setSaving] = useState<boolean>(false);

	const { AUTH, ROOT } = useStore();
	const { doRegister } = AUTH;
	const { AssignErrorToInput } = ROOT;
	const { t } = useTranslation();

	const handleSubmit = async (data: any) => {
		setSaving(true);
		data.password = await aesEncryptionPassword(data?.password)
		doRegister(data)
			.then(() => {
				history.push("/resend-verification-link");
			})
			.catch((e: ErrorProps) => {
				AssignErrorToInput(form, e?.errors);
			})
			.finally(() => setSaving(false));
	};

	return (
		<section className="section register__section">
			<Card className="width-500">
				<h2 className="card__title">{t("REGISTRATIONS")}</h2>
				<Divider />
				<FormBox form={form} onFinish={handleSubmit}>
					<Row gutter={Config.gutter}>
						<Col span={12}>
							<InputBox.Text
								name="firstname"
								label={t("FIRST_NAME")}
								placeholder={t("FIRST_NAME")}
								rules={RegisterRequest(t).firstname}
								size="large"
								autoFocus
							/>
						</Col>
						<Col span={12}>
							<InputBox.Text
								name="lastname"
								label={t("LAST_NAME")}
								placeholder={t("LAST_NAME")}
								rules={RegisterRequest(t).lastname}
								size="large"
							/>
						</Col>
					</Row>
					<InputBox.Text
						name="email"
						label={t("EMAIL_ID")}
						placeholder="example@domain.com"
						size="large"
						rules={RegisterRequest(t).email}
					/>
					<InputBox.Password
						type="password"
						name="password"
						label={t("PASSWORD")}
						placeholder="xxxxxxx"
						size="large"
						rules={RegisterRequest(t).password}
					/>
					<Button
						loading={saving}
						className="width-150"
						type="primary"
						size="large"
						htmlType="submit"
					>
						{t("SIGN_UP")}
					</Button>
					<Divider />
					<div className="text-center">
						<Space>
							{t("ALREADY_HAVE_AN_ACCOUNT")}
							<Link to="/login">
								<b>{t("LOGIN")}</b>
							</Link>
						</Space>
					</div>
				</FormBox>
			</Card>
		</section>
	);
};

export default Register;
