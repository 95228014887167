import React from "react";
import { AgGridColumn } from "ag-grid-react";
import AgGridWrapper from "../../../../components/AgGridWrapper/AgGridWrapper";
import { GridReadyEvent } from "ag-grid-community";
import { Button } from "antd";
import { EyeOutlined } from "@ant-design/icons";
import { useTranslation } from "react-i18next";

interface ListComponentProps {
	setupGrid: (params: GridReadyEvent) => void;
	DetailsRedirect:(id:number) => void;
}

const ListVillageAgentComponent: React.FC<ListComponentProps> = ({ setupGrid, DetailsRedirect }) => {
	const { t } = useTranslation();
	const ActionRenderer = (params: any) => {
		return (
			<div className="action-column">
				<Button
					title={t("VIEW_VILLAGE_AGENT")}
					type="text"
					onClick={() =>DetailsRedirect(params.data?.id)}
					icon={<EyeOutlined />}
				>
				</Button>
			</div>
		);
	};


	return (
		<AgGridWrapper
			type="serverSide"
			onGridReady={setupGrid}
			rowSelection={"multiple"}
			frameworkComponents={{ ActionRenderer }}
			overlayLoadingTemplate={t("PLEASE_WAIT_WHILE_DATA_IS_LOADING")} overlayNoRowsTemplate={t("NO_RECORD_FOUND")}
		>
			<AgGridColumn
				field="id"
				headerName={"# " + t('ID')}
				pinned="left"
				filter="agNumberColumnFilter"
				width={120}
			/>
			<AgGridColumn
				field="user_name"
				headerName={t("NAME")}
				width={280}
			/>
			<AgGridColumn
				field="email"
				headerName={t("EMAIL")}
				width={300}
			/>
			<AgGridColumn
				field="phone_number"
				headerName={t("PHONE_NUMBER")}
				width={240}
			/>
			<AgGridColumn
				headerName={t('ACTION')}
				type="actionColumn"
				sortable={false}
				filter={false}
				width={100}
			/>
		</AgGridWrapper>
	);
};

export default ListVillageAgentComponent;
