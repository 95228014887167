import React, { useState, useEffect } from "react";
import { Button, Modal, Layout, Row, Col } from "antd";
import moment from "moment";
import { useTranslation } from "react-i18next";
import { displayFormats } from "../../../../config/Global";

interface DetailProps {
	ElectionRefereeID: number;
	closePopup: () => void;
	visible?: boolean;
	ReadData: (id: number) => Promise<any>;
}

const ElectionRefereeDetails: React.FC<DetailProps> = ({
	ElectionRefereeID,
	visible,
	closePopup,
	ReadData,
}) => {
	const [ElectionReferee, setElectionReferee] = useState<any>();
	const { t } = useTranslation();
	useEffect(() => {
		if (ElectionRefereeID) {
			ReadData(ElectionRefereeID).then(({ data }) => {
				setElectionReferee(data);
			});
		}
	}, [ElectionRefereeID, ReadData]);

	const close = () => {
		setElectionReferee(null);
		closePopup();
	};

	const getgender = (gender: number) => {
		switch (gender) {
			case 1:
				return t("MALE")
				break;
			case 2:
				return t("FEMALE")
				break;

		}
	}

	return (
		<Modal
			centered
			title={t("ELECTION_REFEREE_DETAILS")}
			visible={visible}
			destroyOnClose={true}
			onCancel={close}
			width={'auto'}
			style={{ minWidth: "900px" }}
			footer={[
				<Button key="2" onClick={close}>
					{t("CLOSE")}
				</Button>,
			]}
		>
			<Layout className="details_table">
				<Row gutter={6} wrap={false} >
					<Col span={12} className="form__input__textbox form__input__selectBox">
						<label>{t("USER_NAME")} : </label>
						<span>{ElectionReferee && ElectionReferee?.firstname + " " + ElectionReferee?.lastname}</span>
					</Col>
					<Col span={12} className="form__input__textbox">
						<label>{t("ADDRESS")} : </label>
						<span>{ElectionReferee && ElectionReferee?.address}</span>
					</Col>
				</Row>
				<Row gutter={6} wrap={false}>
					<Col span={12} className="form__input__textbox">
						<label>{t("EMAIL")} : </label>
						<span>{ElectionReferee && ElectionReferee?.email}</span>
					</Col>
					<Col span={12} className="form__input__textbox">
						<label>{t("PHONE_NUMBER")} : </label>
						<span>{ElectionReferee && ElectionReferee?.phone_number}</span>
					</Col>
				</Row>
				<Row gutter={6} wrap={false}>
					<Col span={12} className="form__input__textbox">
						<label>{t("GENDER")} : </label>
						<span>{ElectionReferee && getgender(ElectionReferee?.gender)}</span>
					</Col>
					<Col span={12} className="form__input__textbox">
						<label>{t("DATE_OF_BIRTH")} : </label>
						<span>{ElectionReferee && ElectionReferee?.date_of_birth}</span>
					</Col>
				</Row>
				<Row gutter={6} wrap={false}>
					<Col span={12} className="form__input__textbox">
						<label>{t("CREATED_AT")}: </label>
						<span>{moment.utc(ElectionReferee?.created_at).local().format(displayFormats.DATE_TIME_FORMAT)}</span>
					</Col>
					<Col span={12} className="form__input__textbox">
						<label>{t("UPDATED_AT")}: </label>
						<span>{moment.utc(ElectionReferee?.updated_at).local().format(displayFormats.DATE_TIME_FORMAT)}</span>
					</Col>
				</Row>
				<Row gutter={6} wrap={false}>
					<Col span={12} className="form__input__textbox">
						<label>{t("CREATED_BY")}: </label>
						<span>{ElectionReferee?.createdBy ? ElectionReferee?.createdBy?.firstname + " " + ElectionReferee?.createdBy?.lastname + " (" + ElectionReferee?.createdBy?.email + ")" : " - "}</span>
					</Col>
					<Col span={12} className="form__input__textbox">
						<label>{t("UPDATED_BY")}: </label>
						<span>{ElectionReferee?.updatedBy ? ElectionReferee?.updatedBy?.firstname + " " + ElectionReferee?.updatedBy?.lastname + " (" + ElectionReferee?.updatedBy?.email + ")" : " - "}</span>
					</Col>
				</Row>
			</Layout>
		</Modal>
	);
};

export default ElectionRefereeDetails;
