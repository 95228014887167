import axios from "axios";
import { makeAutoObservable } from "mobx";
import API_URL from "../../config/ApiUrl";
import { ProfessionListDataProps } from "./ProfessionInterface";

export default class ProfessionStore {
	public listData?: ProfessionListDataProps[];
	public profession?: ProfessionListDataProps;
	public addProfessionPopup?: boolean;
	public editProfessionPopup?: boolean;
	public editProfessionID?: number;
	public deleteProfessionPopup?: boolean;
	public deleteProfessionID?: number;

	constructor() {
		makeAutoObservable(this);
	}

	// Setter Functions
	public openAddProfessionPopup = (): void => {
		this.addProfessionPopup = true;
	};
	public closeAddProfessionPopup = (): void => {
		this.addProfessionPopup = false;
	};
	public openEditprofessionPopup = (id: number): void => {
		this.editProfessionPopup = true;
		this.setEditProfessionID(id);
	};
	public closeEditPofessionPopup = (): void => {
		this.editProfessionPopup = false;
		this.setEditProfessionID();
	};
	public openDeleteprofessionPopup = (id: number): void => {
		this.deleteProfessionPopup = true;
		this.setDeleteProfessionID(id);
	};

	public closeDeleteprofessionPopup = (): void => {
		this.deleteProfessionPopup = false;
		this.setDeleteProfessionID();
	};

	private setEditProfessionID = (id?: number): void => {
		this.editProfessionID = id;
	};
	private setDeleteProfessionID = (id?: number): void => {
		this.deleteProfessionID = id;
	};

	private setListData = (value?: ProfessionListDataProps[]): void => {
		this.listData = value;
	};

	public setProfession = (value?: ProfessionListDataProps): void => {
		this.profession = value;
	};

	// API Functions
	public fetchList = (): Promise<any> => {
		return axios.post(API_URL.PROFESSION.LIST).then(({ data }) => {
			this.setListData(data.data.rows);
			return data;
		});
	};

	public create = (payload: any): Promise<any> => {
		return axios
			.post(API_URL.PROFESSION.CREATE, payload)
			.then(({ data }) => {
				this.fetchList();
				return data;
			})
			.catch(({ data }) => {
				return Promise.reject(data);
			});
	};

	public update = (payload: any): Promise<any> => {
		return axios
			.post(API_URL.PROFESSION.UPDATE(payload.id), payload)
			.then(({ data }) => {
				this.fetchList();
				return data;
			});
	};

	public details = (id: number): Promise<any> => {
		return axios.get(API_URL.PROFESSION.DETAILS(id)).then(({ data }) => {
			return data;
		});
	};

	public remove = (id: number): Promise<any> => {
		return axios.delete(API_URL.PROFESSION.DELETE(id)).then(({ data }) => {
			this.fetchList();
			return data;
		});
	};

	public changeStatus = (payload:any): Promise<any> => {
		return axios.post(API_URL.PROFESSION.CHANGE_STATUS(payload.id),payload)
		.then(({ data }) => {
			this.fetchList();
			return data;
		});
	};

}
