import React, { useState, useEffect } from "react";
import { Button, Col, Form, Layout } from "antd";
import useStore from "../../../../store";
import { useHistory, useParams } from "react-router";
import { observer } from "mobx-react";
import { FormBox, InputBox } from "../../../../components/AntdAddons";
import ReactQuill from "react-quill";
import '../../../../../node_modules/react-quill/dist/quill.snow.css';
import BreadcrumbComponent from "../../../../components/BreadcrumbComponent/BreadcrumbComponent";
import { CMSBreadcrumb } from "../../../../config/BreadcrumbConfig";
import { CmsRequest } from "../../../../requests/CmsRequest";
import { useTranslation } from "react-i18next";

const EditComponent: React.FC = observer(() => {
	const modules = {
		toolbar: [
		[{ header: [1, 2, false] }],
		["bold", "italic", "underline", "strike", "blockquote"],
		[{ list: "ordered" }, { list: "bullet" }],
		["link", "image"]
	]
};
	const formats = [
	"header",
	"bold",
	"italic",
	"underline",
	"strike",
	"blockquote",
	"list",
	"bullet",
	"indent",
	"link",
	"image"
	];
	const { ROOT, CMS, COMMON } = useStore();
	const {
		update,
		details,
	} = CMS;

	const [cms, setCms] = useState<any>(null)
	const [cmsContent, setCmsContent] = useState<any>(null)
	const { id }: any = useParams();
	const { t } = useTranslation();

	const {
		languageList,
		fetchLanguage,
	} = COMMON;
	const { AssignErrorToInput } = ROOT;
	const [form] = Form.useForm();
	const [saving, setSaving] = useState<boolean>(false);

	const history = useHistory();

	useEffect(()=>{
		if(!languageList) {
			fetchLanguage()
		}
		if(!cms) {
			details(id).then((data:any) => {
				setCmsContent(data.data?.cmsLanguageContent)
				setCms(data?.data);
			})
		}
	},[fetchLanguage, languageList, details])

	const listRoute = () =>{
		history.push('/cms-management');
	}

	// Handle submit and call function to save new record
	const handleSubmit = () => {
		const bodyFormData = new FormData();
		cmsContent.map((data:any) => {
			bodyFormData.append("cms["+data.language_id+"][page_title]", form.getFieldValue(["cms["+data.language_id+"][page_title]"]));
			bodyFormData.append("cms["+data.language_id+"][page_description]", form.getFieldValue(["cms["+data.language_id+"][page_description]"]));
			bodyFormData.append("cms["+data.language_id+"][meta_title]", form.getFieldValue(["cms["+data.language_id+"][meta_title]"]));
			bodyFormData.append("cms["+data.language_id+"][meta_description]", form.getFieldValue(["cms["+data.language_id+"][meta_description]"]));
			bodyFormData.append("cms["+data.language_id+"][language_id]", form.getFieldValue(["cms["+data.language_id+"][language_id]"]));
		})

		update(id, bodyFormData)
			.then(() => {
				setSaving(false);
				form.resetFields();
				listRoute();
			})
			.catch((e:any) => {
				setSaving(false);
				AssignErrorToInput(form, e?.data?.errors);
			})
	};
	return (
		<FormBox
			id={"EDITCMS"}
			form={form}
			onFinish={handleSubmit} key="formbasic"
		>
			<div className="Breadcrumb-bar Breadcrumb-bar-export">
				<BreadcrumbComponent items={CMSBreadcrumb(t).path} />
			</div>

			<Layout style={{ padding: '0 24px' }}>
			<Form.Item>
				{cmsContent && cmsContent.map((data:any, index:number) => {
					form.setFieldsValue({["cms["+data.language_id+"][page_title]"]:data.page_title});
					form.setFieldsValue({["cms["+data.language_id+"][page_description]"]:data.page_description});
					form.setFieldsValue({["cms["+data.language_id+"][meta_title]"]:data.meta_title});
					form.setFieldsValue({["cms["+data.language_id+"][meta_description]"]:data.meta_description});
					form.setFieldsValue({["cms["+data.language_id+"][language_id]"]:data.language_id});
					return (
					<div key={"language"+index}>
						<p>{data.language.name} {t("CONTENT")}</p>
						<InputBox.Text
							name={"cms["+data.language_id+"][page_title]"}
							placeholder={t('TITLE')}
							maxLength={255}
							rules={CmsRequest(t).title}
						/>
						<ReactQuill
								placeholder={t("PAGE_DESCRIPTION")} theme="snow"
								modules={modules}
								formats={formats}
								onChange={ (e:any) => {
									form.setFieldsValue({["cms["+data.language_id+"][page_description]"] : e});
								}}
								value={form.getFieldValue(["cms["+data.language_id+"][page_description]"]) ?? ""}
							/>
						<InputBox.Text
							name={"cms["+data.language_id+"][meta_title]"}
							placeholder={t("META_TITLE")}
							maxLength={255}
						/>
						<InputBox.Text
							name={"cms["+data.language_id+"][meta_description]"}
							placeholder={t("META_DESCRIPTION")}
							maxLength={255}
						/>
					</div>
					);
				})}
			</Form.Item>
			<Col span={24} className="permission__button ant-row-center ant-row mt-50">
				<Button type="primary"
					htmlType="submit"
					loading={saving}
					key="submit1">{t('SAVE')}</Button>
				<Button type="primary" key="resetCancel" danger onClick={listRoute}>{t('CANCEL')}</Button>
			</Col>
			</Layout>
		</FormBox>
	);
});

export default EditComponent;
