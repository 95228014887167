import React, { useState } from "react";
import { Button, Card, Form, Space } from "antd";
import { LoginRequest } from "../../../requests/AuthRequest";
import { Link, useHistory } from "react-router-dom";
import useStore from "../../../store";
import { ErrorProps } from "../../../store/RootStore/RootInterface";
import { FormBox, InputBox } from "../../../components/AntdAddons";
import { MailOutlined, LockOutlined } from '@ant-design/icons';
import { useTranslation } from "react-i18next";
import { aesEncryptionPassword } from "../../../config/Global";

const Login: React.FC = () => {
	const [form] = Form.useForm();
	const [saving, setSaving] = useState<boolean>(false);
	const history = useHistory();
	const { AUTH, ROOT } = useStore();
	const { doLogin } = AUTH;
	const { AssignErrorToInput } = ROOT;
	const { t } = useTranslation();

	const handleSubmit = async (data: any) => {
		setSaving(true);

		data.password = await aesEncryptionPassword(data?.password)

		doLogin(data)
			.then(() => {
				history.replace("/dashboard");
			})
			.catch((e: ErrorProps) => {
				const errors = e?.errors || e.data?.errors
				AssignErrorToInput(form, errors);
				setSaving(false);
			});
	};

	return (
		<section className="section login__section">
			<Card className="width-450">
				<h2 className="card__title">{t("VSLA")}</h2>
				<FormBox className="text-center" form={form} onFinish={handleSubmit}>
					<InputBox.Text
						prefix={<MailOutlined />}
						name="username"
						placeholder={t("EMAIL_ID")}
						size="large"
						rules={LoginRequest(t).username}
						autoFocus
					/>
					<InputBox.Password
						prefix={<LockOutlined />}
						name="password"
						placeholder={t("PASSWORD")}
						size="large"
						rules={LoginRequest(t).password}
					/>
					<div className="text-right">
						<Space>
							<Link to="/forget-password">
								{t('FORGOT_PASSWORD')}
							</Link>
						</Space>
					</div>
					<Button
						className="width-250 mt-30 mb-20"
						loading={saving}
						type="primary"
						size="large"
						htmlType="submit"
					>
						{t('LOGIN')}
					</Button>
				</FormBox>
			</Card>
		</section>
	);
};

export default Login;
