import React, { useEffect } from "react";
import { AgGridColumn } from "ag-grid-react";
import AgGridWrapper from "../../../../components/AgGridWrapper/AgGridWrapper";
import { Button } from "antd";
import { EditOutlined } from "@ant-design/icons";
import { useTranslation } from "react-i18next";
import { TemplateListDataProps } from "../../../../store/TemplateStore/TemplateInterface";

interface ListProps {
	rowData: TemplateListDataProps[];
	getData: () => Promise<any>;
	templateType: number;
	editTemplateRedirect: (id: number) => void;
}

const ListComponent: React.FC<ListProps> = ({
	rowData,
	getData,
	templateType,
	editTemplateRedirect,
}) => {
	const { t } = useTranslation();

	const ActionRenderer = (params: any) => {
		return (
			<div className="action-column">
				<Button
					type="text"
					title={t("EDIT")}
					icon={<EditOutlined />}
					onClick={() => editTemplateRedirect(params.data?.id)}
				></Button>
			</div>
		);
	};

	useEffect(() => {
		getData();
	}, [getData, templateType]);

	return (
		<AgGridWrapper
			rowData={rowData}
			frameworkComponents={{ ActionRenderer }}
			overlayLoadingTemplate={t("PLEASE_WAIT_WHILE_DATA_IS_LOADING")}
			overlayNoRowsTemplate={t("NO_RECORD_FOUND")}
		>
			<AgGridColumn
				field="id"
				filter={"agNumberColumnFilter"}
				headerName={"# " + t("ID")}
				width={200}
				pinned={"left"}
			/>
			<AgGridColumn width={350} field="title" headerName={t("TITLE")} />
			<AgGridColumn
				width={380}
				field="description"
				headerName={t("DESCRIPTION")}
			/>
			<AgGridColumn
				headerName={t("ACTION")}
				type="actionColumn"
				sortable={false}
				filter={false}
				width={120}
			/>
		</AgGridWrapper>
	);
};

export default ListComponent;
