import React, { useState, useEffect } from "react";
import { Button, Modal, Layout, Row, Col } from "antd";
import { paymentStatus, paymentType } from "../../../../config/Global";
import { useTranslation } from "react-i18next";

interface EditProps {
	TransactionID: number;
	closePopup: () => void;
	visible?: boolean;
	ReadData: (id: number) => Promise<any>;
}

const TransactionDetails: React.FC<EditProps> = ({
	TransactionID,
	visible,
	closePopup,
	ReadData,
}) => {
	const [transaction, setTransaction] = useState<any>();
	const { t } = useTranslation();
	useEffect(() => {
		if (TransactionID) {
			ReadData(TransactionID).then(({ data }) => {
				setTransaction(data);
			});
		}
	}, [TransactionID, ReadData]);

	const close = () => {
		closePopup();
	};

	const renderPaidStatus = (param: any) => {
		const paidStatus = paymentStatus(t).filter((item: any) => item.id == param);
		return paidStatus[0]?.payment_status;
	};

	const renderPaymentType = (param: any) => {
		const payment = paymentType(t).filter((item: any) => item.id == param);
		return payment[0]?.payment_type;
	};

	return (
		<Modal
			centered
			title={t("TRANSACTION_DETAILS")}
			visible={visible}
			destroyOnClose={true}
			onCancel={close}
			width={"auto"}
			style={{ minWidth: "900px" }}
			footer={[
				<Button key="2" onClick={close}>
					{t("CLOSE")}
				</Button>,
			]}
		>
			<Layout
				style={{ padding: "0 24px" }}
				hidden={!transaction || !transaction.length}
			>
				<Row gutter={6} wrap={false}>
					<Col span={8} className="form__input__textbox form__input__selectBox">
						<label>{t("TRANSACTION_ID")} : </label>
						<b>{transaction && transaction[0]?.transaction_id}</b>
					</Col>
					<Col span={8} className="form__input__textbox">
						<label>{t("AMOUNT")} : </label>
						<b>{transaction && transaction[0]?.amount}</b>
					</Col>
				</Row>
				<Row gutter={6} wrap={false}>
					<Col span={8} className="form__input__textbox">
						<label>{t("USER_NAME")} : </label>
						<b>
							{transaction &&
								transaction[0]?.user?.firstname +
								" " +
								transaction[0]?.user?.lastname}
						</b>
					</Col>
					<Col span={8} className="form__input__textbox">
						<label>{t("PAYMENT_MODE")} : </label>
						<b>{transaction && transaction[0]?.transaction_payment_mode}</b>
					</Col>
				</Row>
				<Row gutter={6} wrap={false}>
					<Col span={8} className="form__input__textbox">
						<label>{t("GROUP_NAME")} : </label>
						<b>{(transaction && transaction[0]?.group?.name) ?? "N/A"}</b>
					</Col>
				</Row>

				<div className="permission__table table__resp">
					<table key="table" width={"100%"}>
						<thead key="thead">
							<tr key="headtr">
								<th key="tr_id" style={{ width: "20%" }}>
									{t("TRANSACTION_ID")}
								</th>
								<th key="User_Name" style={{ width: "20%" }}>
									{t("USER")}
								</th>
								<th key="Group_Name" style={{ width: "20%" }}>
									{t("GROUP")}
								</th>
								<th key="Payment_Status" style={{ width: "20%" }}>
									{t("STATUS")}
								</th>
								<th key="CR_DR" style={{ width: "20%" }}>
									{t("CREDIT") + "/" + t("DEBIT")}
								</th>
								<th key="Amount_details" style={{ width: "20%" }}>
									{t("AMOUNT")}
								</th>
							</tr>
						</thead>
						<tbody key={"module"}>
							{transaction?.map((item: any) => {
								return (
									<tr key={"tr" + item.id}>
										<td>{item?.transaction_id}</td>
										<td>{item?.user?.firstname && item?.user?.lastname ?item?.user?.firstname + " " + item?.user?.lastname:"N/A"}</td>
										<td>{item?.group?.name ?? "N/A"}</td>
										<td>{renderPaidStatus(item?.payment_status)}</td>
										<td>{renderPaymentType(item?.payment_type)}</td>
										<td>{item.amount}</td>
									</tr>
								);
							})}
						</tbody>
					</table>
				</div>
			</Layout>
		</Modal>
	);
};

export default TransactionDetails;
