import { UserOutlined } from "@ant-design/icons";
import { Button, Row, Col, Layout, } from "antd";
import { observer } from "mobx-react";
import React from "react";
import { FormBox, InputBox } from "../../../../components/AntdAddons";
import BreadcrumbComponent from "../../../../components/BreadcrumbComponent/BreadcrumbComponent";
import { DonationCampaignBreadcrumb } from "../../../../config/BreadcrumbConfig";
import { DonationCampaignRequest } from "../../../../requests/DonationCampaignRequest";
import moment from "moment";
import { useTranslation } from "react-i18next";
import { displayFormats } from "../../../../config/Global";

interface FormDataProps {
	formId:string;
	form:any;
	handleSubmit:(data: any) => void |any;
	saving:boolean;
	groupList:[] | any;
	donationSubjectList:[] | any;
	listRoute:() => void;
	donationList:[] | any;
	donationType: boolean;
	showGroupList: boolean;
	setShowGroupList: any;

}
const FormComponent: React.FC<FormDataProps> = observer(({
		formId,
		form,
		handleSubmit,
		saving,
		listRoute,
		donationSubjectList,
		groupList,
		showGroupList,
		setShowGroupList
	}) => {
	const { t } = useTranslation();
	const checkGroup =  () => {
		setShowGroupList(form.getFieldValue("donation_type") === 2)
	}

	
	const disableStartDate = (current:any) =>{
		return current && current < moment().endOf('day');
	}

	const disableEndDate = (current:any) =>{
		return (form.getFieldValue('start_date_time') ? current && current <  moment(form.getFieldValue('start_date_time')).add(24, 'hours') : current && current < moment().endOf('day'));
	}

	return (
		<FormBox
			id={formId}
			form={form}
			encType="multipart/formdata"
			onFinish={handleSubmit} key="formbasic">
			<div className="Breadcrumb-bar Breadcrumb-bar-export">
				<BreadcrumbComponent items={DonationCampaignBreadcrumb(t).path} />
			</div>
			<Layout style={{ padding: '0 24px' }}>
				<Row gutter={6} wrap={false}>
					<Col span={8} className="form__input__textbox">
						<InputBox.Text
							size="large"
							placeholder={t("CAMPAIGN_NAME")}
							label={t("CAMPAIGN_NAME")}
							name="name"
							prefix={<UserOutlined />}
							rules={DonationCampaignRequest(t).name} />
					</Col>
					<Col span={8} className="form__input__textbox form__input__selectBox">
						<label>{t("DONATING_TO")}</label>
						<InputBox.Radio
							options={{
								list:[{id:1, name:"VSLA"}, {id:2, name:"GROUP"}],
								valueKey:"id",
								textKey:"name"
							}}
							defaultValue={1}
							name="donation_type"
							onChange={checkGroup}
						/>
					</Col>
				</Row>
				<Row gutter={6} wrap={false}>
					<Col span={8} className="form__input__textbox">
						<InputBox.Select
							options={{
								list:donationSubjectList,
								valueKey: "id",
								textKey: "name"
							}}
							name="donation_subject"
							placeholder={t("DONATION_SUBJECT")}
							label={t("DONATION_SUBJECT")}
							rules={DonationCampaignRequest(t).donation_subject}
						/>
					</Col>
					{showGroupList && <Col span={8} className="form__input__textbox" >
						<InputBox.Select
							options={{
								list:groupList,
								valueKey:"id",
								textKey:"name"
							}}
							name="group_id"
							placeholder={t("SELECT_GROUP")}
							label={t("SELECT_GROUP")}
							rules={DonationCampaignRequest(t).group_id}
						/>
					</Col>}
				</Row>
				<Row gutter={6} wrap={false}>
					<Col span={8} className="form__input__textbox">
						<InputBox.DateTimePicker
							name="start_date_time"
							placeholder={t("START_DATE_TIME")}
							label={t("START_DATE_TIME")}
							rules={DonationCampaignRequest(t).start_date_time}
							disabledDate={disableStartDate}
							onChange={(e: any) => {form.setFieldsValue({'start_date_time': moment(e),'end_date_time': moment(e.add(24, 'hours'))})}}
							format={displayFormats.DATE_TIME_FORMAT}
						/>
					</Col>
					<Col span={8} className="form__input__textbox">
						<InputBox.DateTimePicker
							name="end_date_time"
							placeholder={t("END_DATE_TIME")}
							label={t("END_DATE_TIME")}
							rules={DonationCampaignRequest(t).end_date_time}
							disabledDate={disableEndDate}
							format={displayFormats.DATE_TIME_FORMAT}
						/>
					</Col>
				</Row>

				<Col span={24} className="permission__button ant-row-center ant-row mt-50">
					<Button type="primary"
						htmlType="submit"
						loading={saving}
						key="submit1">{t('SAVE')}</Button>
					<Button type="primary" key="resetCancel" danger onClick={listRoute}>{t('CANCEL')}</Button>
				</Col>
			</Layout>
		</FormBox>
	);
});
export default FormComponent;
