import React, { useEffect } from "react";
import { observer } from "mobx-react";
import BreadcrumbComponent from "../../../../components/BreadcrumbComponent/BreadcrumbComponent";
import ContentBox from "../../../../components/ContentBox/ContentBox";
import { ConfederationBreadcrumb } from "../../../../config/BreadcrumbConfig";
import useStore from "../../../../store";
import HeaderButtons from "./components/HeaderButtons";
import ListComponent from "./components/ListComponent";
import AddComponent from "./components/AddComponent";
import EditComponent from "./components/EditComponent";
import DeleteComponent from "./components/DeleteComponent";
import { useTranslation } from "react-i18next";

const ConfederationView: React.FC = observer(() => {
	const { t } = useTranslation();
	const { CONFEDERATION, AUTH, COMMON } = useStore();
	const {
		listData,
		create,
		update,
		details,
		remove,
		changeStatus,
		fetchList,
		addConfederationPopup,
		openAddConfederationPopup,
		closeAddConfederationPopup,
		editConfederationID,
		editConfederationPopup,
		openEditConfederationPopup,
		closeEditPofessionPopup,
		deleteConfederationID,
		deleteConfederationPopup,
		openDeleteConfederationPopup,
		closeDeleteConfederationPopup,
		readConfederationData
	} = CONFEDERATION;

	const {permissions} = AUTH
	const {allPermissionList} = COMMON

	useEffect(()=>{
		//
	}, [allPermissionList]);

	return (
		<>
		<div className="Breadcrumb-bar">
			<BreadcrumbComponent items={ConfederationBreadcrumb(t).path} />
			<HeaderButtons
						key={1}
						openAddConfederationPopup={openAddConfederationPopup}
						addPermission={permissions && allPermissionList && permissions.indexOf(allPermissionList.ADD_CONFEDERATION) >=0}
					/>
			</div>

			<ContentBox>
				<ListComponent
					rowData={listData}
					getData={fetchList}
					openEditConfederationPopup={openEditConfederationPopup}
					openDeleteConfederationPopup={openDeleteConfederationPopup}
					ChangeStatus={changeStatus}
					editPermission={permissions && allPermissionList && permissions.indexOf(allPermissionList.EDIT_ROLE) >=0}
					deletePermission={permissions && allPermissionList && permissions.indexOf(allPermissionList.DELETE_ROLE) >=0}
				/>
				<AddComponent
					createData={create}
					visible={addConfederationPopup}
					closeAddConfederationPopup={closeAddConfederationPopup}
				/>
				<EditComponent
					EditID={editConfederationID}
					EditData={update}
					ReadData={details}
					visible={editConfederationPopup}
					closeEditPofessionPopup={closeEditPofessionPopup}
					Read={readConfederationData}
				/>
				<DeleteComponent
					DeleteID={deleteConfederationID}
					DeleteData={remove}
					visible={deleteConfederationPopup}
					closeDeleteConfederationPopup={closeDeleteConfederationPopup}
				/>
			</ContentBox>
		</>
	);
});

export default ConfederationView;
