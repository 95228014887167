import React, { useEffect, useState }  from "react";
import { observer } from "mobx-react";
import { Form, message } from 'antd';
import useStore from "../../../../../store";
import { useHistory, useParams } from "react-router";
import FormComponent from "./FormComponent";
import { useTranslation } from "react-i18next";

const EditRoleComponent: React.FC = observer(() => {
	const { t } = useTranslation();
	const { ROLE, ROOT } = useStore();
	const {
		permissionList,
		update,
		openEditRolePopup,
		roleType,
		setRoleType,
		details,
		editRoleID,
		setRole,
		showPermissionModel,
		openPermissionModel,
		closePermissionModel
	} = ROLE;
	const { AssignErrorToInput } = ROOT;

	const { id }: any = useParams();
	const [roleDetails, setRoleDetails] = useState<any>(null);
	const [form] = Form.useForm();
	const [saving, setSaving] = useState<boolean>(false);
	const [selectedPermission, setPermission] = useState<any>([]);
	const history = useHistory();
	const listRoute = () =>{
		history.push('/role-management');
  }

	useEffect(()=> {
		setPermission([]);
		openEditRolePopup(id);
		form.resetFields();
		if(roleDetails ==null) {
			//setAppLoading(true);
			details(id).then((data:any) => {
				const options:any = [];
				setRoleDetails(data.data);
				data.data.permissions.forEach((permissionObj:any) => {
					options.push(permissionObj.id);
				});

				setPermission(options);
				form.setFieldsValue({name:data.data?.name, role_type:data.data?.role_type});
				//setAppLoading(false);
			});
		}

		setRoleType();
	},[setRoleType,openEditRolePopup,details,editRoleID])

	const onCheckChange= (e:any) => {
		const options = selectedPermission;
    let index

		if (e.target.checked) {
      options.push(+e.target.value)
    } else {
      index = options.indexOf(+e.target.value)
      options.splice(index, 1)
    }
		setPermission(options);
	}

	// Handle submit and call function to save new record
	const handleSubmit = (data: any) => {
		if (!selectedPermission.length) {
			message.error(t("PLEASE_SELECT_AT_LEAST_ONE_PERMISSION_FOR_ROLE"));
			return;
		}
		data.id=editRoleID;
		data.permission = selectedPermission;
		setSaving(true);

		update(data)
			.then(() => {
				setSaving(false);
				setPermission([]);
				form.resetFields();
				setRole();
				listRoute();
			})
			.catch((e:any) => {
				setSaving(false);
				AssignErrorToInput(form, e?.data?.errors);
			})
	};

	return (
		<FormComponent
			formId="editRoleForm"
			form={form}
			handleSubmit={handleSubmit}
			roleType={roleType}
			permissionList={permissionList}
			selectedPermission={selectedPermission}
			onCheckChange={onCheckChange}
			showPermissionModel={showPermissionModel}
			closePermissionModel={closePermissionModel}
			openPermissionModel={openPermissionModel}
			saving={saving}
			listRoute={listRoute}
			isEdit={true}
		/>
  );
});
export default EditRoleComponent;
