import axios from "axios";
import { makeAutoObservable } from "mobx";
import API_URL from "../../config/ApiUrl";
import { BannerListDataProps } from "./BannerInterface";

export default class BannerStore {
	
	public listData?: BannerListDataProps[];
	public Banner?: BannerListDataProps;

	constructor() {
		makeAutoObservable(this);
	}

	// Setter Functions
	private setListData = (value?: BannerListDataProps[]): void => {
		this.listData = value;
	};

	public setBanner = (value?: BannerListDataProps): void => {
		this.Banner = value;
	};

	// API Functions
	public fetchList = (): Promise<any> => {
		return axios.post(API_URL.BANNER.LIST).then(({ data }) => {
			this.setListData(data.data.rows);
			return data.data;
		});
	};

	public update = (id: number, payload:any): Promise<any> => {
		return axios.post(API_URL.BANNER.UPDATE(id), payload).then(({ data }) => {
			return data.data;
		});
	};

	public details = (id: number): Promise<any> => {
		return axios.get(API_URL.BANNER.DETAILS(id)).then(({ data }) => {
			return data.data;
		});
	};

	public changeStatus = (payload:any): Promise<any> => {
		return axios.post(API_URL.BANNER.CHANGE_STATUS(payload.id),payload)
		.then(({ data }) => {
			this.fetchList();
			return data.data;
		});
	};

}
