import React, { useEffect } from "react";
import Login from "../views/app_pages/auth/Login";
import Register from "../views/app_pages/auth/Register";
import Dashboard from "../views/app_pages/Dashboard";
import UserView from "../views/app_pages/UserManagement/UserView";
import PageNotFound from "../views/errors/PageNotFound";
import Unauthorized from "../views/errors/Unauthorized";
import ProfessionView from "../views/app_pages/ProfessionManagement/ProfessionView";
import ConfederationView from "../views/app_pages/ConfederationManagement/ConfederationView";
import FinesView from "../views/app_pages/Fines/index";
import FinesTypeView from "../views/app_pages/FineTypes/index";
import CmsView from "../views/app_pages/CmsManagement/index";
import TransactionView from "../views/app_pages/Transaction";
import RoleView from "../views/app_pages/RoleManagement/RoleView";
import FaqCategoryView from "../views/app_pages/FaqCategoryManagement/FaqCategoryView";
import FaqView from "../views/app_pages/FaqManagement/FaqView";
import AddRoleComponent from "../views/app_pages/RoleManagement/RoleView/components/AddComponent";
import EditRoleComponent from "../views/app_pages/RoleManagement/RoleView/components/EditComponent";
import useStore from "../store";
import { Route, Switch } from "react-router-dom";
import LayoutView from "../views/layouts";
import { RouterConfigProps } from "../config/InterfacesAndTypes";
import AddUserComponent from "../views/app_pages/UserManagement/UserView/components/AddComponent";
import EditUserComponent from "../views/app_pages/UserManagement/UserView/components/EditComponent";
import UserDetailComponent from "../views/app_pages/UserManagement/UserView/components/DetailComponent";
import GroupView from "../views/app_pages/GroupManagement/GroupView";
import AddGroupComponent from "../views/app_pages/GroupManagement/GroupView/components/AddComponent";
import EditGroupComponent from "../views/app_pages/GroupManagement/GroupView/components/EditComponent";
import GroupDetailComponent from "../views/app_pages/GroupManagement/GroupView/components/DetailComponent";
import EditCmsComponent from "../views/app_pages/CmsManagement/components/EditComponent";
import EditemailtemplatesComponent from "../views/app_pages/TemplatesManagement/components/EditEmailComponent";
import EditsmstemplatesComponent from "../views/app_pages/TemplatesManagement/components/EditSmsComponent";
import DonationSubjectView  from "../views/app_pages/DonationSubject/index";
import TemplatesView from "../views/app_pages/TemplatesManagement/index";
import WalletView from "../views/app_pages/Wallet";
import WalletTransactionComponent from "../views/app_pages/Wallet/components/WalletTransactions";
import LoanRequestView from "../views/app_pages/LoanRequestManagement/LoanView";
import AddLoanRequestComponent from "../views/app_pages/LoanRequestManagement/LoanView/components/AddComponent";
import EditLoanRequestComponent from "../views/app_pages/LoanRequestManagement/LoanView/components/EditComponent";
import LoanRequestDetailComponent from "../views/app_pages/LoanRequestManagement/LoanView/components/DetailComponent";
import MeetingView from "../views/app_pages/MeetingManagement/MeetingView";
import AddMeetingComponent from "../views/app_pages/MeetingManagement/MeetingView/components/AddComponent";
import EditMeetingComponent from "../views/app_pages/MeetingManagement/MeetingView/components/EditComponent";
import MeetingDetailComponent from "../views/app_pages/MeetingManagement/MeetingView/components/DetailComponent";
import DonationView from "../views/app_pages/Donation/index";
import EditdonationComponent from "../views/app_pages/Donation/components/EditComponent";
import AddDonationComponent from "../views/app_pages/Donation/components/AddComponent";
import DonationCampaignView from "../views/app_pages/DonationCampaign/index";
import AddDonationCampaignComponent from "../views/app_pages/DonationCampaign/components/AddComponent";
import EditDonationCampaignComponent from "../views/app_pages/DonationCampaign/components/EditComponent";
import LoanDisbursedView from "../views/app_pages/LoanDisbursedManagement/index";
import LoanDisbursedDetailComponent from "../views/app_pages/LoanDisbursedManagement/components/DetailComponent"
import ForgetPassword from "../views/app_pages/auth/ForgetPassword";
import ResetPasswordOtp from "../views/app_pages/auth/ResetPasswordOtp";
import GroupExpenseView from "../views/app_pages/GroupExpense/index"
import BannerView from "../views/app_pages/BannerManagement/index"
import GroupFinanceView from "../views/app_pages/GroupFinanceManagement/GroupFinanceView";
import GroupFinanceDetails from "../views/app_pages/GroupFinanceManagement/GroupFinanceView/components/GroupFinanceDetails";
import EditBannerComponent from "../views/app_pages/BannerManagement/components/EditComponent"
import SliderView from "../views/app_pages/SliderManagement/index"
import AddSliderComponent from "../views/app_pages/SliderManagement/components/AddComponent";
import EditSliderComponent from "../views/app_pages/SliderManagement/components/EditComponent";
import NewsView from "../views/app_pages/News/index"
import AddNewsComponent from "../views/app_pages/News/components/AddComponent";
import EditNewsComponent from "../views/app_pages/News/components/EditComponent";
import VillageAgentView from "../views/app_pages/VillageAgent/index"
import SettingsView from "../views/app_pages/SettingsManagement/index"
import SettingsFooterView from "../views/app_pages/SettingsManagement/components/FooterSectionComponent"
import RedirectLinkView from "../views/app_pages/RedirectLink/index"
import AddRedirectLinkComponent from "../views/app_pages/RedirectLink/components/AddComponent"
import EditRedirectLinkComponent from "../views/app_pages/RedirectLink/components/EditComponent"
import ElectionRefereeView from "../views/app_pages/ElectionReferee/index"
import EditVillageAgentComponent from "../views/app_pages/VillageAgent/components/DetailsComponent"
import ElectionProcessView from "../views/app_pages/ElectionProcess/index"
import ElectionRequestView from "../views/app_pages/NewElectionRequest/index"
import AssignElectionRefreView from "../views/app_pages/NewElectionRequest/components/AssignElectionRefree"
import SmtpComponent from "../views/app_pages/SettingsManagement/components/SmtpComponent";
import SmsComponent from "../views/app_pages/SettingsManagement/components/SmsComponent";
import EditProfileView from "../views/app_pages/auth/EditProfile";
import ChangePassword from "../views/app_pages/auth/ChangePassword";
import SetHomePageMessageComponent from "../views/app_pages/SettingsManagement/components/SetHomePageMessageComponent";
// import TranslationView from "../views/app_pages/Translation/index";
import AllReports from "../views/app_pages/AllReports/Reports";
import EditNotificationTemplatesComponent from "../views/app_pages/TemplatesManagement/components/EditNotificationTemplatesComponent";
import AlIzzaPaymentGatewayComponent from "../views/app_pages/SettingsManagement/components/AlIzzaPaymentGatewayComponent";
import HomePageContentView from "../views/app_pages/SettingsManagement/components/HomePageContentManagement";
import AddHomePageContentComponent from "../views/app_pages/SettingsManagement/components/HomePageContentManagement/components/AddComponent"
import EditHomePageContentComponent from "../views/app_pages/SettingsManagement/components/HomePageContentManagement/components/EditComponent"
import MTNInterpayAfricaPaymentGateway from "../views/app_pages/SettingsManagement/components/MTNInterpayAfricaPaymentGateway";
import ImportData from "../views/app_pages/ImportData";

const AppRouter: React.FC = () => {

	const { AUTH, COMMON } = useStore();
	const { permissions } = AUTH;
	const { allPermissionList } = COMMON;

	useEffect(() => {
		//
	}, [allPermissionList]);
	

	const RouterConfig: RouterConfigProps[] = [
		{
			title: "Home Page",
			path: "/",
			component: <Login />,
			settings: ["Header", "Footer"],
			display:true,
		},
		{
			title: "Register Page",
			path: "/register",
			component: <Register />,
			settings: ["Header", "Footer"],
			display:true,
		},
		{
			title: "Forget Password",
			path: "/forget-password",
			component: <ForgetPassword />,
			settings: ["Header", "Footer"],
			display:true,
		},
		{
			title: "Reset Password",
			path: "/reset/:token",
			component: <ResetPasswordOtp />,
			settings: ["Header", "Footer"],
			display:true,
		},
		{
			title: "Change Password",
			path: "/change-password",
			component: <ChangePassword />,
			settings: ["Header", "Footer"],
			display:true,
		},
		{
			title: "Dashboard",
			path: "/dashboard",
			component: <Dashboard />,
			settings: ["AppHeader", "AppSidebar"],
			auth: true,
			display:true
		},
		{
			title: "Users",
			path: "/user-management",
			component: <UserView />,
			settings: ["AppHeader", "AppSidebar"],
			auth: true,
			display: permissions && allPermissionList && permissions.indexOf(allPermissionList.LIST_USER) >= 0
		},
		{
			title: "Add User",
			path: "/user-management/create",
			component: <AddUserComponent />,
			settings: ["AppHeader", "AppSidebar"],
			auth: true,
			display:permissions && allPermissionList && permissions.indexOf(allPermissionList.ADD_USER) >= 0
		},
		{
			title: "Edit User",
			path: "/user-management/:id/edit",
			component: <EditUserComponent />,
			settings: ["AppHeader", "AppSidebar"],
			auth: true,
			display:permissions && allPermissionList && permissions.indexOf(allPermissionList.EDIT_USER) >= 0
		},
		{
			title: "User Details",
			path: "/user-management/:id/details",
			component: <UserDetailComponent />,
			settings: ["AppHeader", "AppSidebar"],
			auth: true,
			display:permissions && allPermissionList && permissions.indexOf(allPermissionList.USER_DETAILS) >= 0
		},
		{
			title: "Transaction List", // for server side grid example
			path: "/transactions",
			component: <TransactionView />,
			settings: ["AppHeader", "AppSidebar"],
			auth: true,
			display:permissions && allPermissionList && permissions.indexOf(allPermissionList.LIST_TRANSACTION) >= 0
		},
		{
			title: "Profession",
			path: "/profession-management",
			component: <ProfessionView />,
			settings: ["AppHeader", "AppSidebar"],
			auth: true,
			display:permissions && allPermissionList && permissions.indexOf(allPermissionList.LIST_PROFESSION) >= 0
		},
		{
			title: "Confederation",
			path: "/confederation-management",
			component: <ConfederationView />,
			settings: ["AppHeader", "AppSidebar"],
			auth: true,
			display:permissions && allPermissionList && permissions.indexOf(allPermissionList.LIST_CONFEDERATION) >= 0
		},
		{
			title: "Role Management",
			path: "/role-management",
			component: <RoleView />,
			settings: ["AppHeader", "AppSidebar"],
			auth: true,
			display:permissions && allPermissionList && permissions.indexOf(allPermissionList.LIST_ROLE) >= 0
		},
		{
			title: "Add Role",
			path: "/role-management/create",
			component: <AddRoleComponent />,
			settings: ["AppHeader", "AppSidebar"],
			auth: true,
			display:permissions && allPermissionList && permissions.indexOf(allPermissionList.ADD_ROLE) >= 0
		},
		{
			title: "Edit Role",
			path: "/role-management/:id/edit",
			component: <EditRoleComponent />,
			settings: ["AppHeader", "AppSidebar"],
			auth: true,
			display:permissions && allPermissionList && permissions.indexOf(allPermissionList.EDIT_ROLE) >= 0
		},
		{
			title: "Faq Category Management",
			path: "/faq-category-management",
			component: <FaqCategoryView />,
			settings: ["AppHeader", "AppSidebar"],
			auth: true,
			display:permissions && allPermissionList && permissions.indexOf(allPermissionList.LIST_FAQ_CATEGORY) >= 0
		},
		{
			title: "Faq Management",
			path: "/faq-management",
			component: <FaqView />,
			settings: ["AppHeader", "AppSidebar"],
			auth: true,
			display:permissions && allPermissionList && permissions.indexOf(allPermissionList.LIST_FAQ) >= 0
		},
		{
			title: "Saving Group",
			path: "/group-management",
			component: <GroupView />,
			settings: ["AppHeader", "AppSidebar"],
			auth: true,
			display:permissions && allPermissionList && permissions.indexOf(allPermissionList.LIST_GROUP) >= 0
		},
		{
			title: "Add Saving Group",
			path: "/group-management/create",
			component: <AddGroupComponent />,
			settings: ["AppHeader", "AppSidebar"],
			auth: true,
			display:permissions && allPermissionList && permissions.indexOf(allPermissionList.ADD_GROUP) >= 0
		},
		{
			title: "Edit Saving Group",
			path: "/group-management/:id/edit",
			component: <EditGroupComponent />,
			settings: ["AppHeader", "AppSidebar"],
			auth: true,
			display:permissions && allPermissionList && permissions.indexOf(allPermissionList.EDIT_GROUP) >= 0
		},
		{
			title: "Saving Group Details",
			path: "/group-management/:id/details",
			component: <GroupDetailComponent />,
			settings: ["AppHeader", "AppSidebar"],
			auth: true,
			display:permissions && allPermissionList && permissions.indexOf(allPermissionList.GROUP_DETAILS) >= 0
		},
		{
			title: "Fine Management",
			path: "/fine-management",
			component: <FinesView />,
			settings: ["AppHeader", "AppSidebar"],
			auth: true,
			display:permissions && allPermissionList && permissions.indexOf(allPermissionList.LIST_FINE) >= 0
		},
		{
			title: "Fine Type Management",
			path: "/fine-type-management",
			component: <FinesTypeView />,
			settings: ["AppHeader", "AppSidebar"],
			auth: true,
			display:permissions && allPermissionList && permissions.indexOf(allPermissionList.LIST_FINE_TYPE) >= 0
		},
		{
			title: "CMS Management",
			path: "/cms-management",
			component: <CmsView />,
			settings: ["AppHeader", "AppSidebar"],
			auth: true,
			display:permissions && allPermissionList && permissions.indexOf(allPermissionList.LIST_CMS) >= 0
		},
		{
			title: "Edit CMS",
			path: "/cms-management/:id/edit",
			component: <EditCmsComponent />,
			settings: ["AppHeader", "AppSidebar"],
			auth: true,
			display:permissions && allPermissionList && permissions.indexOf(allPermissionList.EDIT_CMS) >= 0
		},
		{
			title: "Templates Management",
			path: "/templates-management",
			component: <TemplatesView />,
			settings: ["AppHeader", "AppSidebar"],
			auth: true,
			display:permissions && allPermissionList && permissions.indexOf(allPermissionList.LIST_TEMPLATE) >= 0
		},
		{
			title: "Edit Email Templates",
			path: "/templates-management/:id/edit-email-templates",
			component: <EditemailtemplatesComponent />,
			settings: ["AppHeader", "AppSidebar"],
			auth: true,
			display:permissions && allPermissionList && permissions.indexOf(allPermissionList.EDIT_TEMPLATE) >= 0
		},
		{
			title: "Edit SMS Templates",
			path: "/templates-management/:id/edit-sms-templates",
			component: <EditsmstemplatesComponent />,
			settings: ["AppHeader", "AppSidebar"],
			auth: true,
			display:permissions && allPermissionList && permissions.indexOf(allPermissionList.EDIT_TEMPLATE) >= 0
		},
		{
			title: "Edit Notification Templates",
			path: "/templates-management/:id/edit-notification-templates",
			component: <EditNotificationTemplatesComponent />,
			settings: ["AppHeader", "AppSidebar"],
			auth: true,
			display:permissions && allPermissionList && permissions.indexOf(allPermissionList.EDIT_TEMPLATE) >= 0
		},
		{
			title: "Donation Subject",
			path: "/donation-subject",
			component: <DonationSubjectView />,
			settings: ["AppHeader", "AppSidebar"],
			auth: true,
			display:permissions && allPermissionList && permissions.indexOf(allPermissionList.LIST_DONATION_SUBJECT) >= 0
		},
		{
			title: "Donation",
			path: "/donation",
			component: <DonationView />,
			settings: ["AppHeader", "AppSidebar"],
			auth: true,
			display:permissions && allPermissionList && permissions.indexOf(allPermissionList.LIST_DONATION) >= 0
		},
		{
			title: "Add Donation",
			path: "/donation/create",
			component: <AddDonationComponent />,
			settings: ["AppHeader", "AppSidebar"],
			auth: true,
			display:permissions && allPermissionList && permissions.indexOf(allPermissionList.ADD_DONATION) >= 0
		},
		{
			title: "Edit Donation",
			path: "/donation/:id/edit",
			component: <EditdonationComponent />,
			settings: ["AppHeader", "AppSidebar"],
			auth: true,
			display:permissions && allPermissionList && permissions.indexOf(allPermissionList.EDIT_DONATION) >= 0
		},
		{
			title: "Donation Campaign",
			path: "/donation-campaign",
			component: <DonationCampaignView />,
			settings: ["AppHeader", "AppSidebar"],
			auth: true,
			display:permissions && allPermissionList && permissions.indexOf(allPermissionList.LIST_DONATION_CAMPAIGN) >= 0
		},
		{
			title: "Add Donation Campaign",
			path: "/donation-campaign/create",
			component: <AddDonationCampaignComponent />,
			settings: ["AppHeader", "AppSidebar"],
			auth: true,
			display:permissions && allPermissionList && permissions.indexOf(allPermissionList.ADD_DONATION_CAMPAIGN) >= 0
		},
		{
			title: "Edit Donation Campaign",
			path: "/donation-campaign/:id/edit",
			component: <EditDonationCampaignComponent />,
			settings: ["AppHeader", "AppSidebar"],
			auth: true,
			display:permissions && allPermissionList && permissions.indexOf(allPermissionList.EDIT_DONATION_CAMPAIGN) >= 0
		},
		{
			title: "Wallet Management",
			path: "/wallet-management",
			component: <WalletView />,
			settings: ["AppHeader", "AppSidebar"],
			auth: true,
			display:permissions && allPermissionList && permissions.indexOf(allPermissionList.LIST_FAQ) >= 0
		},
		{
			title: "Wallet Transactions",
			path: "/wallet-management/:walletType/:id/:walletId/transactions",
			component: <WalletTransactionComponent />,
			settings: ["AppHeader", "AppSidebar"],
			auth: true,
			display:permissions && allPermissionList && permissions.indexOf(allPermissionList.LIST_TRANSACTION) >= 0
		},
		{
			title: "Loan Request Management",
			path: "/loan-request-management",
			component: <LoanRequestView />,
			settings: ["AppHeader", "AppSidebar"],
			auth: true,
			display:permissions && allPermissionList && permissions.indexOf(allPermissionList.LIST_USER_LOAN_REQUEST) >= 0
		},
		{
			title: "Add Loan Request",
			path: "/loan-request-management/create",
			component: <AddLoanRequestComponent />,
			settings: ["AppHeader", "AppSidebar"],
			auth: true,
			display:permissions && allPermissionList && permissions.indexOf(allPermissionList.ADD_USER_LOAN_REQUEST) >= 0
		},
		{
			title: "Edit Loan Request",
			path: "/loan-request-management/:id/edit",
			component: <EditLoanRequestComponent />,
			settings: ["AppHeader", "AppSidebar"],
			auth: true,
			display:permissions && allPermissionList && permissions.indexOf(allPermissionList.EDIT_USER_LOAN_REQUEST) >= 0
		},
		{
			title: "Loan Request Details",
			path: "/loan-request-management/:id/details",
			component: <LoanRequestDetailComponent />,
			settings: ["AppHeader", "AppSidebar"],
			auth: true,
			display:permissions && allPermissionList && permissions.indexOf(allPermissionList.USER_LOAN_REQUEST_DETAILS) >= 0
		},
		{
			title: "Meeting Management",
			path: "/meeting-management",
			component: <MeetingView />,
			settings: ["AppHeader", "AppSidebar"],
			auth: true,
			display:permissions && allPermissionList && permissions.indexOf(allPermissionList.LIST_MEETING) >= 0
		},
		{
			title: "Add Meeting",
			path: "/meeting-management/create",
			component: <AddMeetingComponent />,
			settings: ["AppHeader", "AppSidebar"],
			auth: true,
			display:permissions && allPermissionList && permissions.indexOf(allPermissionList.ADD_MEETING) >= 0
		},
		{
			title: "Edit Meeting",
			path: "/meeting-management/:id/edit",
			component: <EditMeetingComponent />,
			settings: ["AppHeader", "AppSidebar"],
			auth: true,
			display:permissions && allPermissionList && permissions.indexOf(allPermissionList.EDIT_MEETING) >= 0
		},
		{
			title: "Meeting Details",
			path: "/meeting-management/:id/details",
			component: <MeetingDetailComponent />,
			settings: ["AppHeader", "AppSidebar"],
			auth: true,
			display:permissions && allPermissionList && permissions.indexOf(allPermissionList.MEETING_DETAILS) >= 0
		},
		{
			title: "Loan Disbursed Management",
			path: "/loan-disbursed-management",
			component: <LoanDisbursedView />,
			settings: ["AppHeader", "AppSidebar"],
			auth: true,
			display:permissions && allPermissionList && permissions.indexOf(allPermissionList.USER_LOAN_DISBURSEMENT_LIST) >= 0
		},
		{
			title: "Loan Disbursed Details",
			path: "/loan-disbursed-management/:id/details",
			component: <LoanDisbursedDetailComponent />,
			settings: ["AppHeader", "AppSidebar"],
			auth: true,
			display:permissions && allPermissionList && permissions.indexOf(allPermissionList.USER_LOAN_DISBURSEMENT_DETAILS) >= 0
		},

		{
			title: "Group Expense", // for server side grid example
			path: "/group-expense",
			component: <GroupExpenseView />,
			settings: ["AppHeader", "AppSidebar"],
			auth: true,
			display:permissions && allPermissionList && permissions.indexOf(allPermissionList.LIST_GROUP_EXPENSE) >= 0
		},
		{
			title: "Banner", // for server side grid example
			path: "/banner",
			component: <BannerView />,
			settings: ["AppHeader", "AppSidebar"],
			auth: true,
			display:permissions && allPermissionList && permissions.indexOf(allPermissionList.LIST_BANNER) >= 0
		},
		{
			title: "Group Finance Management",
			path: "/group-finance-management",
			component: <GroupFinanceView />,
			settings: ["AppHeader", "AppSidebar"],
			auth: true,
			display:permissions && allPermissionList && permissions.indexOf(allPermissionList.GROUP_FINANCE_MANAGEMENT) >= 0
		},
		{
			title: "Group Finance Details",
			path: "/group-finance-management/:id/group-finance-details",
			component: <GroupFinanceDetails />,
			settings: ["AppHeader", "AppSidebar"],
			auth: true,
			display:permissions && allPermissionList && permissions.indexOf(allPermissionList.GROUP_FINANCE_MANAGEMENT) >= 0
		},
		{
			title: "Edit Banner",
			path: "/banner-management/:id/edit",
			component: <EditBannerComponent />,
			settings: ["AppHeader", "AppSidebar"],
			auth: true,
			display:permissions && allPermissionList && permissions.indexOf(allPermissionList.EDIT_BANNER) >= 0
		},
		{
			title: "Slider", // for server side grid example
			path: "/slider",
			component: <SliderView />,
			settings: ["AppHeader", "AppSidebar"],
			auth: true,
			display:permissions && allPermissionList && permissions.indexOf(allPermissionList.LIST_SLIDER) >= 0
		},
		{
			title: "Add Slider",
			path: "/slider-management/create",
			component: <AddSliderComponent />,
			settings: ["AppHeader", "AppSidebar"],
			auth: true,
			display:permissions && allPermissionList && permissions.indexOf(allPermissionList.ADD_SLIDER) >= 0
		},
		{
			title: "Edit Slider",
			path: "/slider-management/:id/edit",
			component: <EditSliderComponent />,
			settings: ["AppHeader", "AppSidebar"],
			auth: true,
			display:permissions && allPermissionList && permissions.indexOf(allPermissionList.EDIT_SLIDER) >= 0
		},
		{
			title: "News",
			path: "/news-management",
			component: <NewsView />,
			settings: ["AppHeader", "AppSidebar"],
			auth: true,
			display:permissions && allPermissionList && permissions.indexOf(allPermissionList.LIST_NEWS) >= 0
		},
		{
			title: "Add News",
			path: "/news-management/create",
			component: <AddNewsComponent />,
			settings: ["AppHeader", "AppSidebar"],
			auth: true,
			display:permissions && allPermissionList && permissions.indexOf(allPermissionList.ADD_NEWS) >= 0
		},
		{
			title: "Edit News",
			path: "/news-management/:id/edit",
			component: <EditNewsComponent />,
			settings: ["AppHeader", "AppSidebar"],
			auth: true,
			display:permissions && allPermissionList && permissions.indexOf(allPermissionList.EDIT_NEWS) >= 0
		},

		{
			title: "Village Agent Assignment",
			path: "/village-agent",
			component: <VillageAgentView />,
			settings: ["AppHeader", "AppSidebar"],
			auth: true,
			display:permissions && allPermissionList && permissions.indexOf(allPermissionList.LIST_VILLAGE_AGENT) >= 0
		},

		{
			title: "Village Agent Assignment",
			path: "/village-agent/:id/edit",
			component: <EditVillageAgentComponent />,
			settings: ["AppHeader", "AppSidebar"],
			auth: true,
			display:permissions && allPermissionList && permissions.indexOf(allPermissionList.VILLAGE_AGENT_DETAILS) >= 0
		},

		{
			title: "Settings",
			path: "/settings",
			component: <SettingsView />,
			settings: ["AppHeader", "AppSidebar"],
			auth: true,
			display:permissions && allPermissionList && permissions.indexOf(allPermissionList.LIST_SETTING) >= 0
		},
		{
			title: "Site Settings",
			path: "/settings/site-settings",
			component: <SettingsFooterView />,
			settings: ["AppHeader", "AppSidebar"],
			auth: true,
			display:permissions && allPermissionList && permissions.indexOf(allPermissionList.LIST_SETTING) >= 0
		},
		{
			title: "Smtp Settings",
			path: "/settings/smtp-settings",
			component: <SmtpComponent />,
			settings: ["AppHeader", "AppSidebar"],
			auth: true,
			display:permissions && allPermissionList && permissions.indexOf(allPermissionList.LIST_SETTING) >= 0
		},
		{
			title: "SMS API",
			path: "/settings/sms-settings",
			component: <SmsComponent />,
			settings: ["AppHeader", "AppSidebar"],
			auth: true,
			display:permissions && allPermissionList && permissions.indexOf(allPermissionList.LIST_SETTING) >= 0
		},
		{
			title: "Al-Izza payment gateway",
			path: "/settings/al-izza-payment-gateway-settings",
			component: <AlIzzaPaymentGatewayComponent />,
			settings: ["AppHeader", "AppSidebar"],
			auth: true,
			display:permissions && allPermissionList && permissions.indexOf(allPermissionList.LIST_SETTING) >= 0
		},
		{
			title: "Al-Izza payment gateway",
			path: "/settings/mtn-interpay-africa-payment-gateway-settings",
			component: <MTNInterpayAfricaPaymentGateway />,
			settings: ["AppHeader", "AppSidebar"],
			auth: true,
			display:permissions && allPermissionList && permissions.indexOf(allPermissionList.LIST_SETTING) >= 0
		},
		{
			title: "Redirect Link Section",
			path: "/settings/redirect-link",
			component: <RedirectLinkView />,
			settings: ["AppHeader", "AppSidebar"],
			auth: true,
			display:permissions && allPermissionList && permissions.indexOf(allPermissionList.LIST_REDIRECT_LINK) >= 0
		},
		{
			title: "Set Welcome Message",
			path: "/settings/welcome-message",
			component: <SetHomePageMessageComponent />,
			settings: ["AppHeader", "AppSidebar"],
			auth: true,
			display:permissions && allPermissionList && permissions.indexOf(allPermissionList.LIST_SETTING) >= 0
		},
		{
			title: "Set Footer Message",
			path: "/settings/footer-message",
			component: <SetHomePageMessageComponent />,
			settings: ["AppHeader", "AppSidebar"],
			auth: true,
			display:permissions && allPermissionList && permissions.indexOf(allPermissionList.LIST_SETTING) >= 0
		},
		{
			title: "Set Passbook Message",
			path: "/settings/passbook-message",
			component: <SetHomePageMessageComponent />,
			settings: ["AppHeader", "AppSidebar"],
			auth: true,
			display:permissions && allPermissionList && permissions.indexOf(allPermissionList.LIST_SETTING) >= 0
		},
		{
			title: "Set Election Message",
			path: "/settings/election-message",
			component: <SetHomePageMessageComponent />,
			settings: ["AppHeader", "AppSidebar"],
			auth: true,
			display:permissions && allPermissionList && permissions.indexOf(allPermissionList.LIST_SETTING) >= 0
		},
		{
			title: "Set Meetings Message",
			path: "/settings/meetings-message",
			component: <SetHomePageMessageComponent />,
			settings: ["AppHeader", "AppSidebar"],
			auth: true,
			display:permissions && allPermissionList && permissions.indexOf(allPermissionList.LIST_SETTING) >= 0
		},
		{
			title: "Home Page Content",
			path: "/settings/home-page-content",
			component: <HomePageContentView />,
			settings: ["AppHeader", "AppSidebar"],
			auth: true,
			display:permissions && allPermissionList && permissions.indexOf(allPermissionList.LIST_SETTING) >= 0
		},
		{
			title: "Home Page Content",
			path: "/settings/home-page-content/create",
			component: <AddHomePageContentComponent />,
			settings: ["AppHeader", "AppSidebar"],
			auth: true,
			display:permissions && allPermissionList && permissions.indexOf(allPermissionList.LIST_SETTING) >= 0
		},
		{
			title: "Home Page Content",
			path: "/settings/home-page-content/:id/edit",
			component: <EditHomePageContentComponent />,
			settings: ["AppHeader", "AppSidebar"],
			auth: true,
			display:permissions && allPermissionList && permissions.indexOf(allPermissionList.LIST_SETTING) >= 0
		},
		{
			title: "Add Redirect Link",
			path: "/redirect-link/create",
			component: <AddRedirectLinkComponent />,
			settings: ["AppHeader", "AppSidebar"],
			auth: true,
			display:permissions && allPermissionList && permissions.indexOf(allPermissionList.ADD_REDIRECT_LINK) >= 0
		},
		{
			title: "Edit Redirect Link",
			path: "/redirect-link/:id/edit",
			component: <EditRedirectLinkComponent />,
			settings: ["AppHeader", "AppSidebar"],
			auth: true,
			display:permissions && allPermissionList && permissions.indexOf(allPermissionList.EDIT_REDIRECT_LINK) >= 0
		},
		{
			title: "Election Referee",
			path: "/election-referee",
			component: <ElectionRefereeView />,
			settings: ["AppHeader", "AppSidebar"],
			auth: true,
			display:permissions && allPermissionList && permissions.indexOf(allPermissionList.LIST_ELECTION_REFEREE) >= 0
		},
		{
			title: "Election Process",
			path: "/election-process",
			component: <ElectionProcessView />,
			settings: ["AppHeader", "AppSidebar"],
			auth: true,
			display:permissions && allPermissionList && permissions.indexOf(allPermissionList.LIST_ELECTION) >= 0
		},
		{
			title: "New Election Request",
			path: "/election-request",
			component: <ElectionRequestView />,
			settings: ["AppHeader", "AppSidebar"],
			auth: true,
			display:permissions && allPermissionList && permissions.indexOf(allPermissionList.NEW_ELECTION_LIST) >= 0
		},
		{
			title: "Assign Election Refre",
			path: "/:id/assign-election-refre",
			component: <AssignElectionRefreView />,
			settings: ["AppHeader", "AppSidebar"],
			auth: true,
			display:permissions && allPermissionList && permissions.indexOf(allPermissionList.NEW_ELECTION_LIST) >= 0
		},
		{
			title: "Edit Profile",
			path: "/edit-profile",
			component: <EditProfileView />,
			settings: ["AppHeader", "AppSidebar"],
			auth: true,
			display:permissions && allPermissionList && permissions.indexOf(allPermissionList.NEW_ELECTION_LIST) >= 0
		},
		// {
		// 	title: "Translation",
		// 	path: "/translation",
		// 	component: <TranslationView />,
		// 	settings: ["AppHeader", "AppSidebar"],
		// 	auth: true,
		// 	display:permissions && allPermissionList && permissions.indexOf(allPermissionList.LIST_TRANSLATION) >= 0
		// },
		{
			title: "All Reports",
			path: "/all-reports",
			component: <AllReports />,
			settings: ["AppHeader", "AppSidebar"],
			auth: true,
			display:permissions && allPermissionList && permissions.indexOf(allPermissionList.ALL_REPORTS) >= 0
		},
		{
			title: "Import Data",
			path: "/import-data",
			component: <ImportData />,
			settings: ["AppHeader", "AppSidebar"],
			auth: true,
			display:permissions && allPermissionList && permissions.indexOf(allPermissionList.ALL_REPORTS) >= 0
		},
		{
			title: "Unauthorized",
			path: "/unauthorized",
			component: <Unauthorized />,
			settings: ["Header", "Footer"],
			display:true
		},
		{
			title: "Page Not Found",
			path: "/",
			component: <PageNotFound />,
			settings: ["Header", "Footer"],
			exact: false,
			display:true
		},

	];

	return (
		<Switch>
			{RouterConfig.map(({ exact, path, component, settings, auth,display }, index) => {
					return (
					<Route key={index} exact={exact !== false} path={path}>
						<LayoutView
							component={component}
							settings={settings}
							isAuthenticationRequired={auth}
							isDisplay={display}
						/>
					</Route>
				);
			})}
		</Switch>
	);
};

export default AppRouter;
