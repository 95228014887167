import { UserOutlined, MailOutlined, PhoneOutlined, EnvironmentOutlined, DollarOutlined } from "@ant-design/icons";
import { Button, Row, Col, Layout } from "antd";
import { observer } from "mobx-react";
import React from "react";
import { useTranslation } from "react-i18next";
import { FormBox, InputBox } from "../../../../components/AntdAddons";
import BreadcrumbComponent from "../../../../components/BreadcrumbComponent/BreadcrumbComponent";
import { DonationBreadcrumb } from "../../../../config/BreadcrumbConfig";
import { DonationRequest } from "../../../../requests/DonationRequest";

interface FormDataProps {
	formId:string;
	form:any;
	handleSubmit:(data: any) => void |any;
	saving:boolean;
	groupList:[] | any;
	donationSubjectList:[] | any;
	countryList: [] | any;
	isEdit:boolean;
	listRoute:() => void;
	donationList:[] | any;
	showGroupList:  any;
	setShowGroupList: any;
}
const FormComponent: React.FC<FormDataProps> = observer(({
		formId,
		form,
		handleSubmit,
		saving,
		groupList,
		donationSubjectList,
		countryList,
		listRoute,
		showGroupList,
		setShowGroupList
	}) => {
		const { t } = useTranslation();
		const checkGroup =  (e:any) => {
			form.setFieldsValue({group_id:null})
			setShowGroupList(e.target.value == 2);
		}

	return (
		<FormBox
			id={formId}
			form={form}
			encType="multipart/formdata"
			onFinish={handleSubmit} key="formbasic">
				<div className="Breadcrumb-bar Breadcrumb-bar-export">
					<BreadcrumbComponent items={DonationBreadcrumb(t).path} />
				</div>
				<Layout style={{ padding: '0 24px' }}>
					<Row gutter={6} wrap={false}>
						<Col span={8} className="form__input__textbox">
							<InputBox.Text
								size="large"
								placeholder={t("NAME")}
								name="name"
								label={t("NAME")}
								prefix={<UserOutlined />}
								rules={DonationRequest(t).name} />
						</Col>
						<Col span={8} className="form__input__textbox form__input__selectBox">
							<InputBox.TextArea
								size="large"
								placeholder={t("ADDRESS")}
								name="address"
								label={t("ADDRESS")}
								prefix={<EnvironmentOutlined />}
								rules={DonationRequest(t).address} />
						</Col>
					</Row>
					<Row gutter={6} wrap={false}>
						<Col span={8} className="form__input__textbox">
							<InputBox.Text
								size="large"
								placeholder={t("PHONE_NUMBER")}
								name="phone_number"
								label={t("PHONE_NUMBER")}
								prefix={<PhoneOutlined />}
								rules={DonationRequest(t).phone_number}
							/>
						</Col>
						<Col span={8} className="form__input__textbox">
							<InputBox.Text
								size="large"
								name="email"
								placeholder={t("EMAIL_ID")}
								label={t("EMAIL_ID")}
								prefix={<MailOutlined />}
								rules={DonationRequest(t).email}
							/>
						</Col>
					</Row>
					<Row gutter={6} wrap={false}>
						<Col span={8} className="form__input__textbox">
							<InputBox.Text
								size="large"
								placeholder={t("AMOUNT")}
								name="amount"
								label={t("AMOUNT")}
								prefix={<DollarOutlined />}
								rules={DonationRequest(t).amount}
							/>
						</Col>
						<Col span={8} className="form__input__textbox form__input__selectBox">
							<InputBox.Text
								size="large"
								placeholder={t("ORGANIZATION")}
								name="organisation"
								label={t("ORGANIZATION")}
								prefix={<UserOutlined />}
								rules={DonationRequest(t).organisation}
							/>
						</Col>
					</Row>
					<Row gutter={6} wrap={false}>
						<Col span={8} className="form__input__textbox">
							<InputBox.Select
								name="country_id"
								options={{
									list: countryList,
									valueKey: "id",
									textKey: "name"
								}}
								placeholder={t("COUNTRY_NAME")}
								label={t("COUNTRY_NAME")}
								rules={DonationRequest(t).country_id}
								showSearch
								optionFilterProp="children"
								filterOption={(input, option: any) =>
									option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
								}
							/>
						</Col>
						<Col span={8} className="form__input__textbox">
							<InputBox.Text
								size="large"
								prefix={<EnvironmentOutlined />}
								name="zipcode"
								placeholder={t('ZIP_CODE')}
								label={t('ZIP_CODE')}
								rules={DonationRequest(t).zipcode}
							/>
						</Col>
					</Row>
					<Row gutter={6} wrap={false}>
						<Col span={8} className="form__input__textbox">
							<InputBox.Select
								options={{
									list:donationSubjectList,
									valueKey:"id",
									textKey:"name"
								}}
								label={t("DONATION_SUBJECT")}
								name="donation_subject"
								placeholder={t("DONATION_SUBJECT")}
								rules={DonationRequest(t).donation_subject}
							/>
						</Col>
						<Col span={8} className="form__input__textbox form__input__selectBox">
							<label>{t("DONATING_TO")}</label>
							<InputBox.Radio
								options={{
									list:[{id:1, name:"VSLA"}, {id:2, name:"GROUP"}],
									valueKey:"id",
									textKey:"name"
								}}
								defaultValue={1}
								name="donation_type"
								onChange={checkGroup}
							/>
						</Col>
					</Row>
					<Row gutter={6} wrap={false}>
						<Col span={8} className="form__input__textbox form__input__selectBox"></Col>
						{showGroupList &&  <Col span={8} className="form__input__textbox" >
							<InputBox.Select
								options={{
									list: groupList,
									valueKey: "id",
									textKey: "name"
								}}
								label={t("GROUP_NAME")}
								name="group_id"
								placeholder={t("GROUP_NAME")}
								rules={DonationRequest(t).group_id}
							/>
					</Col>}
					
				</Row>

				<Col span={24} className="permission__button ant-row-center ant-row mt-50">
					<Button type="primary"
						htmlType="submit"
						loading={saving}
						key="submit1">{t('SAVE')}</Button>
					<Button type="primary" key="resetCancel" danger onClick={listRoute}>{t('CANCEL')}</Button>
				</Col>
			</Layout>
		</FormBox>
	);
});
export default FormComponent;
