import axios from "axios";
import { makeAutoObservable } from "mobx";
import API_URL from "../../config/ApiUrl";
import { RedirectLinkListDataProps } from "./RedirectLinkIntrface";

export default class RedirectLinkStore {
	public listData?:RedirectLinkListDataProps[];
	public RedirectLink?:RedirectLinkListDataProps;
	public addRedirectLinkPopup?: boolean;
	public editRedirectLinkPopup?: boolean;
	public editRedirectLinkID?: number;
	public deleteRedirectLinkPopup?: boolean;
	public deleteRedirectLinkID?: number;

	constructor() {
		makeAutoObservable(this);
	}

	// Setter Functions
	public openDeleteRedirectLinkPopup = (id: number): void => {
		this.deleteRedirectLinkPopup = true;
		this.setDeleteRedirectLinkID(id);
	};

	public closeDeleteRedirectLinkPopup = (): void => {
		this.deleteRedirectLinkPopup = false;
		this.setDeleteRedirectLinkID();
	};

	private setDeleteRedirectLinkID = (id?: number): void => {
		this.deleteRedirectLinkID = id;
	};

	private setListData = (value?: RedirectLinkListDataProps[]): void => {
		this.listData = value;
	};

	public setRedirectLinkType = (value?: RedirectLinkListDataProps): void => {
		this.RedirectLink = value;
	};

	// API Functions
	public fetchList = (): Promise<any> => {
		return axios.post(API_URL.REDIRECT_LINK.LIST).then(({ data }) => {
			this.setListData(data.data.rows);
			return data;
		});
	};

	public create = (payload: any): Promise<any> => {
		return axios
			.post(API_URL.REDIRECT_LINK.CREATE, payload)
			.then(({ data }) => {
				this.fetchList();
				return data;
			})
			.catch(({ data }) => {
				return Promise.reject(data);
			});
	};

	public update = (id:number, payload: any): Promise<any> => {
		return axios
		.post(API_URL.REDIRECT_LINK.UPDATE(id), payload).then(({ data }) => {
			return data;
		});
	};


	public details = (id: number): Promise<any> => {
		return axios.get(API_URL.REDIRECT_LINK.DETAILS(id)).then(({ data }) => {
			return data.data;
		});
	};


	public remove = (id: number): Promise<any> => {
		return axios.delete(API_URL.REDIRECT_LINK.DELETE(id)).then(({ data }) => {
			this.fetchList();
			return data;
		});
	};

	public changeStatus = (payload:any): Promise<any> => {
		return axios.post(API_URL.REDIRECT_LINK.CHANGE_STATUS(payload.id),payload)
		.then(({ data }) => {
			this.fetchList().then(() => {
				return data;
			});

		});
	};

}
