import React from "react";
import { PlusOutlined } from "@ant-design/icons";
import { Button, Space } from "antd";
import { useTranslation } from "react-i18next";

interface HeaderButtonsProps {
	addNewsRedirect: () => void;
}
const HeaderButtons: React.FC<HeaderButtonsProps> = ({addNewsRedirect}) => {
	const { t } = useTranslation();
	return (<Space>
			<Button type="primary" icon={<PlusOutlined />} onClick={addNewsRedirect}>
				{t("CREATE")}
			</Button>
		</Space>
	);
};

export default HeaderButtons;
