import React from "react";
import { PlusOutlined } from "@ant-design/icons";
import { Button, Space } from "antd";
import { useTranslation } from "react-i18next";

interface HeaderButtonsProps {
	addRoleRedirect: () => void;
	addPermission : boolean;
}
const HeaderButtons: React.FC<HeaderButtonsProps>= ({addRoleRedirect, addPermission}) => {
	const { t } = useTranslation();
	return (
		<Space hidden={!addPermission}>
			<Button
				type="primary"
				icon={<PlusOutlined />}
				onClick={addRoleRedirect}
			>
				{t("CREATE")}
			</Button>
		</Space>
	);
};

export default HeaderButtons;
