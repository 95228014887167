import React, { useEffect, useState }  from "react";
import { Button, Row, Col, Layout  } from "antd";
import useStore from "../../../../store";
import { observer } from "mobx-react-lite";
import { FormBox, InputBox } from "../../../../components/AntdAddons";
import BreadcrumbComponent from "../../../../components/BreadcrumbComponent/BreadcrumbComponent";
import { SettingsBreadcrumb } from "../../../../config/BreadcrumbConfig";
import {  Form } from 'antd';
import { useHistory } from "react-router";
import { useTranslation } from "react-i18next";
import { AlIzzaPaymentGatewayRequest } from "../../../../requests/SettingsRequest";


const AlIzzaPaymentGatewayComponent: React.FC = observer(() => {
	const { SETTINGS, ROOT } = useStore();
	const { getSettingDetails, saveSettingDetails, } = SETTINGS;
	const [settings, setSettings] = useState<any>()
	const { AssignErrorToInput } = ROOT;
	const key = "al_izza_payment_gateway";
	const listRoute = () =>{
		history.push('/settings');
	}

	useEffect(() => {
		if (!settings) {
			getSettingDetails(key).then((data:any) => {
				if (data) {
					const settingData = JSON.parse(data?.setting_value);
					form.resetFields();
					form.setFieldsValue({
						api_base_url:settingData?.api_base_url,
						phone_number:settingData?.phone_number,
						account_pin:settingData?.account_pin,
						transaction_pin:settingData?.transaction_pin,
					});
					setSettings(settingData);
				}
			});
		}
	}, [getSettingDetails, settings, setSettings])
	const [form] = Form.useForm();
	const [saving, setSaving] = useState<boolean>(false);
	const history = useHistory();
	const { t } = useTranslation();

	// Handle submit and call function to save new record
	const handleSubmit = (data: any) => {
		setSaving(true);
		const formData = new FormData();
		data.api_base_url && formData.append('api_base_url', data.api_base_url);
		data.phone_number && formData.append('phone_number', data.phone_number);
		data.account_pin && formData.append('account_pin', data.account_pin);
		data.transaction_pin && formData.append('transaction_pin', data.transaction_pin);
		saveSettingDetails(key, formData)
			.then(() => {
				setSaving(false);
				form.resetFields();
				listRoute();
			})
			.catch((e:any) => {
				setSaving(false);
				const errors = e?.data?.errors || e?.errors
				AssignErrorToInput(form, errors);
			})
	};
	return (
	<>
	<div className="Breadcrumb-bar Breadcrumb-bar-export">
		<BreadcrumbComponent items={SettingsBreadcrumb(t).path} />
	</div>
	<FormBox
		id="siteManagementForm"
		form={form}
		encType="multipart/formdata"
		onFinish={handleSubmit} key="formbasic">
			<Layout style={{ padding: '0 24px' }}>
				<Row gutter={6} wrap={false}>
					<Col span={8} className="form__input__textbox">
						<InputBox.Text
							size="large"
							placeholder={t("API_BASE_URL")}
							name="api_base_url"
							label={t("API_BASE_URL")}
							rules={AlIzzaPaymentGatewayRequest(t).api_base_url}
						/>
					</Col>
					<Col span={8} className="form__input__textbox">
						<InputBox.Text
							size="large"
							placeholder={t("PHONE_NUMBER")}
							name="phone_number"
							label={t("PHONE_NUMBER")}
							rules={AlIzzaPaymentGatewayRequest(t).phone_number}
						/>
					</Col>
				</Row>
				<Row gutter={6} wrap={false}>
					<Col span={8} className="form__input__textbox">
						<InputBox.Text
							size="large"
							placeholder={t("ACCOUNT_PIN")}
							name="account_pin"
							label={t("ACCOUNT_PIN")}
							rules={AlIzzaPaymentGatewayRequest(t).account_pin}
						/>
					</Col>
					<Col span={8} className="form__input__textbox">
						<InputBox.Text
							size="large"
							placeholder={t("TRANSACTION_PIN")}
							name="transaction_pin"
							label={t("TRANSACTION_PIN")}
							rules={AlIzzaPaymentGatewayRequest(t).transaction_pin}
						/>
					</Col>
				</Row>
				<Col span={24} className="permission__button ant-row-center ant-row mt-50">
					<Button type="primary"
						htmlType="submit"
						loading={saving}
						key="submit">{t('SAVE')}</Button>
					<Button type="primary" key="resetCancel" danger onClick={listRoute}>{t('CANCEL')}</Button>
				</Col>
			</Layout>
		</FormBox>
		</>
	);
});

export default AlIzzaPaymentGatewayComponent;
