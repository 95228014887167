import React, { useState } from "react";
import { observer } from "mobx-react";
import { Form } from 'antd';
import useStore from "../../../../store";
import { useHistory } from "react-router";
import FormComponent from "./FormComponent";

const AddRedirectLinkComponent: React.FC = observer(() => {
	const { REDIRECT_LINK } = useStore();
	const {
		create,
	} = REDIRECT_LINK;

	const { ROOT } = useStore();
	const { AssignErrorToInput } = ROOT;
	const [form] = Form.useForm();
	const [saving, setSaving] = useState<boolean>(false);
	const history = useHistory();

	const listRoute = () => {
		history.push('/settings/redirect-link');
	}
	// Handle submit and call function to save new record
	const handleSubmit = (data: any) => {
		setSaving(true);
		const formData = new FormData();
		data.link_caption && formData.append('link_caption', data.link_caption);
		data.link_url && formData.append('link_url', data.link_url);
		formData.append('link_type', '1');

		create(formData)
			.then(() => {
				setSaving(false);
				form.resetFields();
				listRoute();
			})
			.catch((e: any) => {
				setSaving(false);
				AssignErrorToInput(form, e?.data?.errors);
			})
	};

	return (
		<FormComponent
			formId="addDonationCampaignForm"
			form={form}
			handleSubmit={handleSubmit}
			saving={saving}
			isEdit={false}
			listRoute={listRoute}

		/>
	);
});
export default AddRedirectLinkComponent;
