import React from "react";
import { AgGridColumn } from "ag-grid-react";
import AgGridWrapper from "../../../../components/AgGridWrapper/AgGridWrapper";
import { GridReadyEvent } from "ag-grid-community";
import { Button } from "antd";
import { EditOutlined } from "@ant-design/icons";
import { useTranslation } from "react-i18next";
import { displayFormats } from "../../../../config/Global";
import moment from "moment";

interface ListProps {
	setupGrid: (params: GridReadyEvent) => void;
	editRedirect:(id:number) => void;
	editPermission:boolean;
}

const ListElectionProcessComponent: React.FC<ListProps> = ({ setupGrid, editRedirect, editPermission }) => {
	const { t } = useTranslation();
	const ActionRenderer = (params: any) => {
		return (
			<div className="action-column">
				<Button
					type="text"
					title={t("EDIT")}
					icon={<EditOutlined />}
					onClick={() => editRedirect(params.data?.id)}
					hidden={!editPermission}
				></Button>
			</div>
		);
	};


	return (
		<AgGridWrapper
			type="serverSide"
			onGridReady={setupGrid}
			rowSelection={"multiple"}
			frameworkComponents={{ ActionRenderer }}
			overlayLoadingTemplate={t("PLEASE_WAIT_WHILE_DATA_IS_LOADING")} overlayNoRowsTemplate={t("NO_RECORD_FOUND")}
		>
			<AgGridColumn
				field="id"
				headerName={"# " + t('ID')}
				pinned="left"
				filter="agNumberColumnFilter"
				width={120}
			/>
			<AgGridColumn
				field="group_name"
				headerName={t('GROUP_NAME')}
				width={280}
			/>
			<AgGridColumn
				field="election_title"
				headerName={t("ELECTION_TITLE")}
				width={300}
			/>
			<AgGridColumn
				field="election_date"
				headerName={t("ELECTION_DATE")}
				width={240}
				valueFormatter={(data:any) =>
					data?.data?.election_date && moment.utc(data?.data?.election_date).local().format(displayFormats.DATE_FORMAT)
				}
			/>
			{/* <AgGridColumn
				field="group_name"
				headerName={t('GROUP_NAME')}
				width={240}
			/> */}
			<AgGridColumn
				headerName={t('ACTION')}
				type="actionColumn"
				sortable={false}
				filter={false}
				width={100}
				pinned="right"
			/>
		</AgGridWrapper>
	);
};

export default ListElectionProcessComponent;
