import React, { useEffect, useState } from "react";
import { observer } from "mobx-react";
import { Button, Col, Layout, Row } from 'antd';
import useStore from "../../../../../store";
import { useHistory, useParams } from "react-router";
import moment from "moment";
import RoleList from "../../../../../config/RoleList";
import BreadcrumbComponent from "../../../../../components/BreadcrumbComponent/BreadcrumbComponent";
import { UserBreadcrumb } from "../../../../../config/BreadcrumbConfig";
import { useTranslation } from "react-i18next";
import { displayFormats } from "../../../../../config/Global";

const UserDetailComponent: React.FC = observer(() => {
	const { t } = useTranslation();
	const { USER } = useStore();
	const {
		details,
	} = USER;

	const [user, setUser] = useState<any>([]);
	const { id }: any = useParams();

	useEffect(() => {
		details(id).then((data: any) => {
			const roleId: any = [];
			setUser(data);
			if (data?.user_type == 1) {
				data.roles.forEach((role: any) => {
					roleId.push(role.id);
				});
			} else {
				const roles = data.roles.filter((role: any) => (role.name == RoleList.VILLAGE_AGENT || role.name == RoleList.ELECTION_REFREE));
				roles.forEach((role: any) => {
					roleId.push(role.id);
				});
			}

		});
	}, [details])

	const history = useHistory();

	const listRoute = () => {
		history.push('/user-management');
	}

	return (
		<>

			<div className="Breadcrumb-bar Breadcrumb-bar-export">
				<BreadcrumbComponent items={UserBreadcrumb(t).path} />
			</div>
			<Layout className="details_table" style={{ padding: '0 24px' }}>
				<Row gutter={6} wrap={false}>
					<Col span={12} className="form__input__textbox">
						<label>{t("FIRST_NAME")} : </label>
						<span>{user.firstname}</span>
					</Col>
					<Col span={12} className="form__input__textbox form__input__selectBox">
						<label>{t("ADDRESS")} : </label>
						<span>{user.address}</span>
					</Col>

				</Row>
				<Row gutter={6} wrap={false}>
					<Col span={12} className="form__input__textbox">
						<label>{t("LAST_NAME")} : </label>
						<span>{user.lastname}</span>
					</Col>
					<Col span={12} className="form__input__textbox form__input__selectBox">
						<label>{t("DATE_OF_BIRTH")} : </label>
						<span>{moment.utc(user.date_of_birth).local().format(displayFormats.DATE_FORMAT)}</span>

					</Col>
				</Row>
				<Row gutter={6} wrap={false}>
					<Col span={12} className="form__input__textbox">
						<label>{t("EMAIL")} : </label>
						<span>{user.email}</span>
					</Col>
					<Col span={12} className="form__input__textbox form__input__selectBox">
						<label>{t("PROFESSION")} : </label>
						<span>{user.profession_name}</span>
					</Col>

				</Row>
				<Row gutter={6} wrap={false}>
					<Col span={12} className="form__input__textbox">
						<label>{t("PHONE_NUMBER")} : </label>
						<span>{user.phone_number}</span>
					</Col>
					<Col span={12} className="form__input__textbox form__input__selectBox">
						<label>{t("USER_TYPE")} : </label>
						<span>{user.user_type == 1 ? "Admin" : "User"}</span>
					</Col>

				</Row>
				<Row gutter={6} wrap={false}>
					<Col span={12} className="form__input__textbox form__input__selectBox">
						<label > {t("GENDER")} : </label>
						<span>{user.gender == 1 ? "Male" : "female"}</span>
					</Col>
					<Col span={12} className="form__input__textbox form__input__selectBox form__input__sim_list">
						<label > {t("GROUPS")} : {(!(user.groups) || (user.groups && user.groups.length == 0)) && "N/A"}</label>
						<ul>
							{
								user.groups && user.groups.map((element: any, index: number) => {
									return (<li key={"group" + index}> <span>{element?.name}, </span></li>)
								})
							}
						</ul>
					</Col>
				</Row>
				<Row gutter={6} wrap={false}>
					<Col span={12} className="form__input__textbox form__input__selectBox">
						<label > {t("CURRENCY")} : </label>
						<span>{user.currency?.name} {user.currency?.symbol && "("+user.currency?.symbol+")"}</span>
					</Col>
					<Col span={24} className="form__input__textbox form__input__selectBox form__input__sim_list">
						<label > {t("ROLES")} : {(!(user.roles) || (user.roles && user.roles.length == 0)) && "N/A"}</label>
						<ul>
							{
								user.roles && user.roles.map((element: any, index: number) => {
									if (element.name == RoleList.VILLAGE_AGENT || element.name == RoleList.ELECTION_REFREE) {
										return (<li key={"role" + index}> {element.group?.name ? <span>{element.name} - {element.group?.name},</span> : <span>{element.name},</span>}</li>)
									} else {
										return (<li key={"role" + index}> {element.group?.name ? <span>{element.name} - {element.group?.name},</span> : <span>{element.name},</span>}</li>)
									}
								})}
						</ul>
					</Col>

				</Row>
				<Row gutter={6} className="permission__button ant-row-center ant-row mt-50">
					<Button type="primary" key="resetCancel" danger onClick={listRoute}>{t("BACK")}</Button>
				</Row>
			</Layout>
		</>
	);
});
export default UserDetailComponent;
