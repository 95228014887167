import React from "react";
import { AgGridColumn } from "ag-grid-react";
import AgGridWrapper from "../../../../components/AgGridWrapper/AgGridWrapper";
import { GridReadyEvent } from "ag-grid-community";
import { Button } from "antd";
import { EyeOutlined } from "@ant-design/icons";
import moment from "moment";
import Config from "../../../../config/Config";
import DateComparator from "../../../../utils/DateComparator";
import { useTranslation } from "react-i18next";
import { displayFormats } from "../../../../config/Global";

interface ListComponentProps {
	setupGrid: (params: GridReadyEvent) => void;
	GroupExpenseDetails:(id:string) => void | any;
}

const ListGroupExpenseComponent: React.FC<ListComponentProps> = ({ setupGrid, GroupExpenseDetails }) => {
	const { t } = useTranslation();
	const ActionRenderer = (params:any) => {
		return (
			<div className="action-column">
				<Button
					title={t("VIEW_GROUP_EXPENSE")}
					type="text"
					onClick={() => GroupExpenseDetails(params.data?.id)}

				>
					<EyeOutlined />
				</Button>
			</div>
		);
	};


	return (
		<AgGridWrapper
			type="serverSide"
			onGridReady={setupGrid}
			rowSelection={"multiple"}
			overlayLoadingTemplate={t("PLEASE_WAIT_WHILE_DATA_IS_LOADING")} overlayNoRowsTemplate={t("NO_RECORD_FOUND")}
			frameworkComponents={{ ActionRenderer }}
		>
			<AgGridColumn
				field="id"
				headerName={"# " + t('ID')}
				pinned="left"
				filter="agNumberColumnFilter"
				width={120}
			/>
			<AgGridColumn
				field="group_name"
				headerName={t('GROUP_NAME')}
				width={160}
			/>
			<AgGridColumn
				field="user_name"
				headerName={t("USER_NAME")}
				width={200}
			/>
			<AgGridColumn
				field="user_email"
				headerName={t("USER_EMAIL")}
				width={200}
			/>
			<AgGridColumn
				field="user_phone_number"
				headerName={t("PHONE_NUMBER")}
				width={200}
			/>
			<AgGridColumn
				field="amount"
				headerName={t("AMOUNT")}
				filter= {'agNumberColumnFilter'}
				width={160}
			/>
			<AgGridColumn
				field="group_expense_payment_mode"
				headerName={t("PAYMENT_MODE")}
				filter= {'agTextColumnFilter'}
				width={160}
			/>
			<AgGridColumn
				field="display_approved_status"
				headerName={t('STATUS')}
				width={200}
			/>
			<AgGridColumn
				field="created_at"
				headerName={t('CREATED_AT')}
				valueGetter={({ data }) =>
					data.created_at &&
					moment(data.created_at).format(Config.dateTimeFormat)
				}
				filter={"agDateColumnFilter"}
				valueFormatter={(data:any) =>
					data?.data?.created_at && moment.utc(data?.data?.created_at).local().format(displayFormats.DATE_TIME_FORMAT)
				}
				filterParams={{
					comparator: DateComparator,
				}}
				width={220}
			/>
			<AgGridColumn
				field="updated_at"
				headerName={t("UPDATED_AT")}
				valueGetter={({ data }) =>
					data.updated_at &&
					moment(data.updated_at).format(Config.dateTimeFormat)
				}
				filter={"agDateColumnFilter"}
				valueFormatter={(data:any) =>
					data?.data?.updated_at && moment.utc(data?.data?.updated_at).local().format(displayFormats.DATE_TIME_FORMAT)
				}
				filterParams={{
					comparator: DateComparator,
				}}
				width={220}
			/>
			<AgGridColumn
				headerName={t('ACTION')}
				type="actionColumn"
				sortable={false}
				filter={false}
				width={100}
				pinned="right"
			/>
		</AgGridWrapper>
	);
};

export default ListGroupExpenseComponent;
