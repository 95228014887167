import React, { useState, useEffect } from "react";
import { Button, Col, Form, Layout, Row, Upload } from "antd";
import useStore from "../../../../../../store";
import { useHistory, useParams } from "react-router";
import { observer } from "mobx-react";
import { FormBox, InputBox } from "../../../../../../components/AntdAddons";
import ReactQuill from "react-quill";
import '../../../../../../../node_modules/react-quill/dist/quill.snow.css';
import BreadcrumbComponent from "../../../../../../components/BreadcrumbComponent/BreadcrumbComponent";
import { HomePageContentBreadcrumb } from "../../../../../../config/BreadcrumbConfig";
import { HomePageContentRequest } from "../../../../../../requests/HomePageContentRequest";
import { useTranslation } from "react-i18next";
import { UploadOutlined } from "@ant-design/icons";
import { uploadedFileOnPreview } from "../../../../../../config/Global";

const EditComponent: React.FC = observer(() => {
	const modules = {
		toolbar: [
		[{ header: [1, 2, false] }],
		["bold", "italic", "underline", "strike", "blockquote"],
		[{ list: "ordered" }, { list: "bullet" }],
		["link", "image"]
	]
};
	const formats = [
	"header",
	"bold",
	"italic",
	"underline",
	"strike",
	"blockquote",
	"list",
	"bullet",
	"indent",
	"link",
	"image"
	];
	const { ROOT, HOME_PAGE_CONTENT, COMMON } = useStore();
	const {
		update,
		details,
	} = HOME_PAGE_CONTENT;

	const [homePageContent, setHomePageContent] = useState<any>(null)
	const [homePageContentLanguage, setHomePageContentLanguage] = useState<any>(null)
	const { id }: any = useParams();
	const { t } = useTranslation();

	const {
		languageList,
		fetchLanguage,
	} = COMMON;
	const { AssignErrorToInput } = ROOT;
	const [form] = Form.useForm();
	const [saving, setSaving] = useState<boolean>(false);
	const[image, setImage] = useState(null);

	const history = useHistory();

	const onFileChange = (info:any) => {
		setImage(info);
		return false;
	}

	useEffect(()=>{
		if(!languageList) {
			fetchLanguage()
		}
		if(!homePageContent) {
			details(id).then((data:any) => {
				form.setFieldsValue({
					sequence: data?.data.sequence,
				});
				setHomePageContentLanguage(data.data?.homePageContentLanguage)
				setHomePageContent(data?.data);
			})
		}
	},[fetchLanguage, languageList, details])

	const listRoute = () =>{
		history.push('/settings/home-page-content');
	}

	// Handle submit and call function to save new record
	const handleSubmit = (data: any) => {
		const bodyFormData = new FormData();

		image && bodyFormData.append('image', image);
		data.sequence && bodyFormData.append("sequence",data.sequence);

		homePageContentLanguage.map((data:any) => {
			bodyFormData.append("home_page_content["+data.language_id+"][title]", form.getFieldValue(["home_page_content["+data.language_id+"][title]"]));
			bodyFormData.append("home_page_content["+data.language_id+"][description]", form.getFieldValue(["home_page_content["+data.language_id+"][description]"]));
			bodyFormData.append("home_page_content["+data.language_id+"][link_title]", form.getFieldValue(["home_page_content["+data.language_id+"][link_title]"]));
			bodyFormData.append("home_page_content["+data.language_id+"][link_url]", form.getFieldValue(["home_page_content["+data.language_id+"][link_url]"]));
			bodyFormData.append("home_page_content["+data.language_id+"][language_id]", form.getFieldValue(["home_page_content["+data.language_id+"][language_id]"]));
		})

		update(id, bodyFormData)
			.then(() => {
				setSaving(false);
				form.resetFields();
				listRoute();
			})
			.catch((e:any) => {
				setSaving(false);
				AssignErrorToInput(form, e?.data?.errors);
			})
	};
	return (
		homePageContent && <FormBox
			id={"EDIT_HOME_PAGE_CONTENT"}
			form={form}
			onFinish={handleSubmit} key="formbasic"
		>
			<div className="Breadcrumb-bar Breadcrumb-bar-export">
				<BreadcrumbComponent items={HomePageContentBreadcrumb(t).path} />
			</div>

			<Layout style={{ padding: '0 24px' }}>
			<Form.Item>
				<Row gutter={6} wrap={false}>
					<Col span={8} className="form__input__textbox">
						<InputBox.Number
							size="large"
							label={t("SEQUENCE")}
							name="sequence"
							placeholder={t("SEQUENCE")}
							rules={HomePageContentRequest(t).sequence}
							maxLength={100}
						/>
					</Col>
					<Upload
						name="image"
						accept="image/png, image/jpeg"
						listType="picture"
						defaultFileList={[
							{
								uid: '1',
								name: homePageContentLanguage[0]?.title,
								status: 'done',
								response: 'Server Error 500', // custom error message to show
								url: homePageContent.image,
								thumbUrl: homePageContent.image,
							}]}
						maxCount={1}
						beforeUpload={onFileChange}
						onPreview={uploadedFileOnPreview}
					>
						<Button icon={<UploadOutlined />}>{t("CLICK_TO_UPLOAD_IMAGE")}</Button>
					</Upload>
				</Row>

				{homePageContentLanguage && homePageContentLanguage.map((data:any, index:number) => {
					form.setFieldsValue({["home_page_content["+data.language_id+"][title]"]: data.title});
					form.setFieldsValue({["home_page_content["+data.language_id+"][description]"]: data.description});
					form.setFieldsValue({["home_page_content["+data.language_id+"][link_title]"]: data.link_title});
					form.setFieldsValue({["home_page_content["+data.language_id+"][link_url]"]: data.link_url});
					form.setFieldsValue({["home_page_content["+data.language_id+"][language_id]"]:data.language_id});
					return (
					<div key={"language"+index}>
						<p>{data.language.name} {t("CONTENT")}</p>
						<InputBox.Text
							name={"home_page_content["+data.language_id+"][title]"}
							placeholder={t('TITLE')}
							maxLength={255}
							rules={HomePageContentRequest(t).title}
						/>
						<ReactQuill
							placeholder={t("DESCRIPTION")} theme="snow"
							modules={modules}
							formats={formats}
							onChange={ (e:any) => {
								form.setFieldsValue({["home_page_content["+data.language_id+"][description]"] : e});
							}}
							value={form.getFieldValue(["home_page_content["+data.language_id+"][description]"]) ?? ""}
						/>
						<InputBox.Text
							name={"home_page_content["+data.language_id+"][link_title]"}
							placeholder={t("LINK_TITLE")}
							maxLength={255}
						/>
						<InputBox.Text
							name={"home_page_content["+data.language_id+"][link_url]"}
							placeholder={t("LINK_URL")}
							maxLength={255}
						/>
					</div>
					);
				})}
			</Form.Item>
			<Col span={24} className="permission__button ant-row-center ant-row mt-50">
				<Button type="primary"
					htmlType="submit"
					loading={saving}
					key="submit1">{t('SAVE')}</Button>
				<Button type="primary" key="resetCancel" danger onClick={listRoute}>{t('CANCEL')}</Button>
			</Col>
			</Layout>
		</FormBox>
	);
});

export default EditComponent;
