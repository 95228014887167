import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import AgGridWrapper from "../../../components/AgGridWrapper/AgGridWrapper";
import { Button } from "antd";
import { GrDocumentCsv, GrDocumentExcel } from "react-icons/gr";
import { observer } from "mobx-react";
import useStore from "../../../store";
import ContentBox from "../../../components/ContentBox/ContentBox";
import BreadcrumbComponent from "../../../components/BreadcrumbComponent/BreadcrumbComponent";
import { ReportBreadcrumb } from "../../../config/BreadcrumbConfig";

const AllReports: React.FC = observer(() => {
	const { t } = useTranslation();
	const {
		AUTH: { user },
		USER: { getUserReportList, exportReportData },
	} = useStore();
	const [reportList, setReportList] = useState<any>();

	const SwitchRenderer = (params: any) => {
		return (
			<>
				<div>
					<Button
						className="edit-button grey-btn"
						title="csv"
						type="text"
						onClick={() => exportReport(params.data, 'csv')}
					>
						<GrDocumentCsv />
					</Button>
					<Button
						className="edit-button grey-btn"
						title="xlsx"
						type="text"
						onClick={() => exportReport(params.data, 'xlsx')}
					>
						<GrDocumentExcel />
					</Button>
				</div>
			</>
		);
	};

	const exportReport = (data: any, type: string) => {
		const defaultPost = data.defaultPost;
		defaultPost.export_type = type;
		exportReportData(data.url, defaultPost);
	};

	const myReportColumns = [
		{ field: "title", headerName: t("TITLE"), flex: 1 },
		{
			field: "Action",
			headerName: t("ACTION"),
			type: "actionColumn",
			cellRenderer: "SwitchRenderer",
			wrapText: true,
			autoHeight: true,
			maxWidth: 150,
			minWidth: 150,
		},
	];

	useEffect(() => {
		user && getUserReportList().then((data: any) => {
			setReportList(data.reportList);
		});
	}, [user])


	return (
		<>
			<div className="Breadcrumb-bar">
				<BreadcrumbComponent items={ReportBreadcrumb(t).path} />
			</div>
			<ContentBox>
				<AgGridWrapper
					className="member-table"
					rowData={reportList}
					columnDefs={myReportColumns}
					frameworkComponents={{ SwitchRenderer }}
				/>
			</ContentBox>
		</>
	);
});

export default AllReports;
