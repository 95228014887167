import React, { useEffect, useState } from "react";
import { Button, Col, Layout } from "antd";
import useStore from "../../../../store";
import { observer } from "mobx-react-lite";
import { FormBox, InputBox } from "../../../../components/AntdAddons";
import BreadcrumbComponent from "../../../../components/BreadcrumbComponent/BreadcrumbComponent";
import { SettingsBreadcrumb } from "../../../../config/BreadcrumbConfig";
import { HomePageSettingRequest } from "../../../../requests/SettingsRequest";
import { Form } from "antd";
import { useHistory } from "react-router";
import ReactQuill from "react-quill";
import { useTranslation } from "react-i18next";

const SetHomePageMessageComponent: React.FC = observer(() => {
	const modules = {
		toolbar: [
			[{ header: [1, 2, false] }],
			["bold", "italic", "underline", "strike", "blockquote"],
			[{ list: "ordered" }, { list: "bullet" }],
			["link", "image"],
		],
	};
	const formats = [
		"header",
		"bold",
		"italic",
		"underline",
		"strike",
		"blockquote",
		"list",
		"bullet",
		"indent",
		"link",
		"image",
	];

	const { SETTINGS, ROOT, COMMON } = useStore();
	const { getSettingDetails, saveSettingDetails } = SETTINGS;
	const [settings, setSettings] = useState<any>();
	const { AssignErrorToInput } = ROOT;
	const { languageList, fetchLanguage } = COMMON;

	let key = "";
	if (window.location.href.search("welcome-message") != -1) {
		key = "welcome_message";
	}
	if (window.location.href.search("footer-message") != -1) {
		key = "footer_message";
	}
	if (window.location.href.search("passbook-message") != -1) {
		key = "passbook_message";
	}
	if (window.location.href.search("election-message") != -1) {
		key = "election_message";
	}
	if (window.location.href.search("meetings-message") != -1) {
		key = "meetings_message";
	}

	const listRoute = () => {
		history.push("/settings");
	};
	const [form] = Form.useForm();
	const [saving, setSaving] = useState<boolean>(false);
	const history = useHistory();
	const { t } = useTranslation();

	// Handle submit and call function to save new record
	const handleSubmit = () => {
		setSaving(true);
		const formData = new FormData();

		languageList &&
			languageList.map((languageData: any) => {
				formData.append(
					languageData.local_key + "[title]",
					form.getFieldValue([languageData.local_key + "[title]"])
				);
				formData.append(
					languageData.local_key + "[description]",
					form.getFieldValue([languageData.local_key + "[description]"])
				);
			});

		saveSettingDetails(key, formData)
			.then(() => {
				setSaving(false);
				form.resetFields();
				listRoute();
			})
			.catch((e: any) => {
				setSaving(false);
				const errors = e?.data?.errors || e?.errors;
				AssignErrorToInput(form, errors);
			});
	};

	useEffect(() => {
		if (!languageList) {
			fetchLanguage();
		}
		if (languageList && !settings) {
			getSettingDetails(key).then((data: any) => {
				if (data && data?.setting_value) {
					const settingData = JSON.parse(data?.setting_value);
					form.resetFields();
					languageList.map((languageData: any) => {
						form.setFieldsValue({
							[languageData.local_key + "[title]"]:
								settingData[languageData.local_key].title,
							[languageData.local_key + "[description]"]:
								settingData[languageData.local_key].description,
						});
					});
					setSettings(settingData);
				}
			});
		}
	}, [fetchLanguage, languageList, getSettingDetails, settings, setSettings]);

	return (
		<>
			<div className="Breadcrumb-bar Breadcrumb-bar-export">
				<BreadcrumbComponent items={SettingsBreadcrumb(t).path} />
			</div>
			<FormBox
				id="siteManagementForm"
				form={form}
				encType="multipart/formdata"
				onFinish={handleSubmit}
				key="formbasic"
			>
				<Layout style={{ padding: "0 24px" }}>
					<Form.Item>
						{languageList &&
							languageList.map((data: any, index: number) => {
								return (
									<div key={"language" + index}>
										<p>
											{`${data.name} ${t("TITLE")}`}
										</p>
										<InputBox.Text
											name={data.local_key + "[title]"}
											placeholder={`${data.name} ${t("TITLE")}`}
											maxLength={255}
											rules={HomePageSettingRequest(t).title}
										/>
										<ReactQuill
											placeholder={`${data.name} ${t("DESCRIPTION")}`}
											theme="snow"
											modules={modules}
											formats={formats}
											onChange={(e: any) => {
												form.setFieldsValue({
													[data.local_key + "[description]"]: e,
												});
											}}
											value={form.getFieldValue([
												data.local_key + "[description]",
											]) ?? ""}
										/>
									</div>
								);
							})}
					</Form.Item>

					<Col
						span={24}
						className="permission__button ant-row-center ant-row mt-50"
					>
						<Button
							type="primary"
							htmlType="submit"
							loading={saving}
							key="submit1"
						>
							{t("SAVE")}
						</Button>
						<Button type="primary" key="resetCancel" danger onClick={listRoute}>
							{t("CANCEL")}
						</Button>
					</Col>
				</Layout>
			</FormBox>
		</>
	);
});

export default SetHomePageMessageComponent;
