import { UploadOutlined, UserOutlined, UsergroupAddOutlined } from "@ant-design/icons";
import { Button, Row, Col, Layout, Upload  } from "antd";
import { observer } from "mobx-react";
import moment from "moment";
import React from "react";
import { FormBox, InputBox } from "../../../../../components/AntdAddons";
import BreadcrumbComponent from "../../../../../components/BreadcrumbComponent/BreadcrumbComponent";
import {  GroupBreadcrumb } from "../../../../../config/BreadcrumbConfig";
import { GroupRequest } from "../../../../../requests/GroupRequest";
import descriptionicon from "../../../../../assets/images/description.svg";
import { useTranslation } from "react-i18next";
import { displayFormats, uploadedFileOnPreview } from "../../../../../config/Global";

interface FormDataProps {
	formId:string;
	form:any;
	handleSubmit:(data: any) => void |any;
	saving:boolean;
	listRoute:() => void;
	isEdit:boolean;
	onFileChange:(data:any) => void;
	userList:any[];
	currencyList:any[];
	confederationList: any[];
	group:any;
	fileList?:any;
	setFileList?:any;
}


const FormComponent: React.FC<FormDataProps> = observer(({
		formId,
		form,
		handleSubmit,
		saving,
		listRoute,
		isEdit,
		onFileChange,
		userList,
		currencyList,
		confederationList,
		fileList,
		setFileList,
		group
	}) => {
		const { t } = useTranslation();

		const disableDate = (current: any) => {
			return current && current <= moment().add(6, "months");
		};

		return (
			<FormBox
			id={formId}
			form={form}
			encType="multipart/formdata"
			onFinish={handleSubmit} key="formbasic">
				<div className="Breadcrumb-bar Breadcrumb-bar-export">
					<BreadcrumbComponent items={GroupBreadcrumb(t).path} />
				</div>
				<Layout style={{ padding: '0 24px' }}>
					<Row gutter={6} wrap={false}>
						<Col span={8} className="form__input__textbox">
							<InputBox.Text
								label={t("GROUP_NAME")}
								size="large"
								placeholder={t("GROUP_NAME")}
								name="name"
								prefix={<UsergroupAddOutlined />}
								rules={GroupRequest(t).name}
							/>
						</Col>
						<Col span={8} className="form__input__textbox form__input__selectBox">
							<InputBox.TextArea
								name="group_description"
								placeholder={t("DESCRIPTION")}
								label={t("DESCRIPTION")}
								rules={GroupRequest(t).description}
								addonAfter={<img src={descriptionicon}/>}
							/>
						</Col>
					</Row>
					<Row gutter={6} wrap={false}>
						<Col span={8} className="form__input__textbox">
							<InputBox.Text
								size="large"
								placeholder={t("SHARE_PRICE")}
								label={t("SHARE_PRICE")}
								name="share_price"
								prefix={<UserOutlined />}
								rules={GroupRequest(t).share_price}
								disabled={isEdit}
							/>
						</Col>
						<Col span={8} className="form__input__textbox form__input__selectBox">
							<p>{t("OPERATING_CYCLE_DATE")} <i>({t("DEFAULT_6_MONTHS_AS_GROUP_CYCLE")})</i></p>
							<InputBox.DatePicker
								name="operating_cycle_date"
								placeholder={t("OPERATING_CYCLE_DATE")}
								initialValue={moment().add(6,'months').add(1,'day')}
								rules={!isEdit ? GroupRequest(t).operating_cycle_date : []}
								disabled={isEdit}
								disabledDate={disableDate}
								format={displayFormats.DATE_FORMAT}
							/>
						</Col>
					</Row>
					<Row gutter={6} wrap={false}>
						<Col span={8} className="form__input__textbox">
							<InputBox.Text
								size="large"
								placeholder={t("GROUP_WELFARE_RATE")}
								label={t("GROUP_WELFARE_RATE")}
								name="group_welfare_rate"
								rules={GroupRequest(t).group_welfare_rate}
								disabled={isEdit}
								prefix={<img src={descriptionicon}/>}
							/>
						</Col>
						<Col span={8} className="form__input__textbox">
							<InputBox.Text
								size="large"
								placeholder={t("MAX_SHARE_PURCHASE_LIMIT")}
								label={t("MAX_SHARE_PURCHASE_LIMIT")}
								name="max_share_purchase_limit"
								rules={GroupRequest(t).max_share_purchase_limit}
								disabled={isEdit}
							/>
						</Col>
					</Row>
					<Row gutter={6} wrap={false}>
						<Col span={8} className="form__input__textbox">
							<InputBox.Text
								size="large"
								placeholder={t("NUMBER_OF_MEMBERS")}
								label={t("NUMBER_OF_MEMBERS")}
								name="numbers_of_member"
								prefix={<UserOutlined />}
								rules={GroupRequest(t).numbers_of_member}
								disabled={isEdit}
							/>
						</Col>
						<Upload
							name="group_image"
							accept="image/png, image/jpeg"
							listType="picture"
							maxCount={1}
							beforeUpload={onFileChange}
							onPreview={uploadedFileOnPreview}
							fileList={fileList?fileList:[]}
							onChange={(e)=>{
								setFileList(e.fileList)
							}}
						>
							<Button icon={<UploadOutlined />}>{t("CLICK_TO_UPLOAD_IMAGE")}</Button>
						</Upload>
					</Row>
					<Row gutter={6} wrap={false}>
						<Col span={8} className="form__input__textbox">
							<InputBox.Number
								size="large"
								placeholder={t("INTEREST_RATE") + " (%)"}
								label={t("INTEREST_RATE") + " (%)"}
								name="interest_rate"
								rules={GroupRequest(t).interest_rate}
								disabled={isEdit}
								min={0}
								max={100}
							/>
						</Col>
						<Col span={8} className="form__input__textbox">
							<InputBox.Text
								size="large"
								placeholder={t("BORROWING_LIMIT")}
								label={t("BORROWING_LIMIT")}
								name="borrowing_limit"
								rules={GroupRequest(t).borrowing_limit}
								disabled={isEdit} />
						</Col>
					</Row>
					<Row gutter={6} wrap={false}>
						<Col span={8} className="form__input__textbox">
							<InputBox.Text
								size="large"
								placeholder={t("MAXIMUM_LOAN_DURATION_IN_MONTHS")}
								label={t("MAXIMUM_LOAN_DURATION_IN_MONTHS")}
								name="max_borrowing_time_line"
								rules={group.publish_status == 0 || group.publish_status == 3 ?GroupRequest(t).max_borrowing_time_line:[]}
								disabled={isEdit} />
						</Col>
						<Col span={8} className="form__input__textbox">
							<InputBox.Select
								options={{
									list:currencyList,
									valueKey:"id",
									textKey:"name"
								}}
								label={t("CURRENCY")}
								name="currency_id"
								placeholder={t("CURRENCY")}
								rules={GroupRequest(t).currency_id}
								disabled
							/>
						</Col>
					</Row>
					<Row gutter={6} wrap={false}>
						<Col span={8} className="form__input__textbox">
							{userList && <InputBox.Select
								options={{
									list: userList,
									valueKey: "id",
									textKey: ["user_name", " (", "email", ")"]
								}}
								label={t("PRESIDENT")}
								name="president_id"
								placeholder={t("PRESIDENT")}
								rules={GroupRequest(t).president_id}
								onChange={(e)=>{
									const cur=userList?.filter((item:any)=>item?.id==e)
									form.setFieldsValue({currency_id:cur?.[0].currency_id})
								}}
								disabled={isEdit}
								showSearch
								optionFilterProp="children"
								filterOption={(input, option: any) =>
									option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
								}
							/>}
						</Col>
						<Col span={8} className="form__input__textbox">
							{confederationList && <InputBox.Select
								options={{
									list:confederationList,
									valueKey:"id",
									textKey:"name"
								}}
								label={t("CONFEDERATION")}
								name="confederation_id"
								placeholder={t("CONFEDERATION")}
								rules={GroupRequest(t).confederation_id}
							/>}
						</Col>
					</Row>
					<Col span={24} className="permission__button ant-row-center ant-row mt-50">
						<Button type="primary"
							htmlType="submit"
							loading={saving}
							key="submit1">{t('SAVE')}</Button>
						<Button type="primary" key="resetCancel" danger onClick={listRoute}>{t('CANCEL')}</Button>
					</Col>
				</Layout>
			</FormBox>
  );
});
export default FormComponent;
