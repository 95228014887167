import { RequestProps } from "../config/InterfacesAndTypes";
import Message from "../config/Message";

export const MeetingRequest = (t: any): RequestProps => ({
	group_id: [{ required: true, message: Message(t).required(t("GROUP")) }],
	title: [{ required: true, message: Message(t).required(t("MEETING_TITLE")) }],
	description: [{ required: true, message: Message(t).required(t("DESCRIPTION")) }],
	meeting_start_date_time : [{ required: true, message: Message(t).required(t("START_DATE_TIME")) }],
	meeting_end_date_time : [{ required: true, message: Message(t).required(t("END_DATE_TIME")) }],
	type: [{ required: true, message: Message(t).required(t("MEETING_TYPE")) }],
	location: [{ required: true, message: Message(t).required(t("MEETING_LOCATION")) }]
});
