import axios from "axios";
import { makeAutoObservable } from "mobx";
import API_URL from "../../config/ApiUrl";
import { FineTypeListDataProps } from "./FineTypeInterface";

export default class FineTypeStore {
	
	public listData?: FineTypeListDataProps[];
	public FineType?: FineTypeListDataProps;
	public addFinestypePopup?: boolean;
	public editFinestypePopup?: boolean;
	public editFinestypeID?: number;
	public deleteFinestypePopup?: boolean;
	public deleteFinestypeID?: number;

	constructor() {
		makeAutoObservable(this);
	}

	// Setter Functions
	public openAddFinestypePopup = (): void => {
		this.addFinestypePopup = true;
	};
	public closeAddFinestypePopup = (): void => {
		this.addFinestypePopup = false;
	};
	public openEditFinestypePopup = (id: number): void => {
		this.editFinestypePopup = true;
		this.setEditFinestypeID(id);
	};
	public closeEditFinestypePopup = (): void => {
		this.editFinestypePopup = false;
		this.setEditFinestypeID();
	};
	public openDeleteFinestypePopup = (id: number): void => {
		this.deleteFinestypePopup = true;
		this.setDeleteFinestypeID(id);
	};

	public closeDeleteFinestypePopup = (): void => {
		this.deleteFinestypePopup = false;
		this.setDeleteFinestypeID();
	};

	private setEditFinestypeID = (id?: number): void => {
		this.editFinestypeID = id;
	};
	private setDeleteFinestypeID = (id?: number): void => {
		this.deleteFinestypeID = id;
	};

	private setListData = (value?: FineTypeListDataProps[]): void => {
		this.listData = value;
	};

	public setFineType = (value?: FineTypeListDataProps): void => {
		this.FineType = value;
	};

	// API Functions
	public fetchList = (): Promise<any> => {
		return axios.post(API_URL.FINE_TYPE.LIST).then(({ data }) => {
			this.setListData(data.data.rows);
			return data;
		});
	};

	public create = (payload: any): Promise<any> => {
		return axios
			.post(API_URL.FINE_TYPE.CREATE, payload)
			.then(({ data }) => {
				this.fetchList();
				return data;
			})
			.catch(({ data }) => {
				return Promise.reject(data);
			});
	};

	public update = (payload: any): Promise<any> => {
		return axios
			.post(API_URL.FINE_TYPE.UPDATE(payload.id), payload)
			.then(({ data }) => {
				this.fetchList();
				return data;
			});
	};

	public details = (id: number): Promise<any> => {
		return axios.get(API_URL.FINE_TYPE.DETAILS(id)).then(({ data }) => {
			return data;
		});
	};

	public remove = (id: number): Promise<any> => {
		return axios.delete(API_URL.FINE_TYPE.DELETE(id)).then(({ data }) => {
			this.fetchList();
			return data;
		});
	};

	public changeStatus = (payload:any): Promise<any> => {
		return axios.post(API_URL.FINE_TYPE.CHANGE_STATUS(payload.id),payload)
		.then(({ data }) => {
			this.fetchList();
			return data;
		});
	};

}
