import React, { useEffect } from "react";
import { AgGridColumn } from "ag-grid-react";
import AgGridWrapper from "../../../../components/AgGridWrapper/AgGridWrapper";
import moment from "moment";
import DateComparator from "../../../../utils/DateComparator";
import useStore from "../../../../store";
import { observer } from "mobx-react";
import { useHistory, useParams } from "react-router";
import BreadcrumbComponent from "../../../../components/BreadcrumbComponent/BreadcrumbComponent";
import { LoanDisbursedBreadcrumb } from "../../../../config/BreadcrumbConfig";
import { Button, Space } from "antd";
import { ArrowLeftOutlined } from "@ant-design/icons";
import ContentBox from "../../../../components/ContentBox/ContentBox";
import { useTranslation } from "react-i18next";
import { displayFormats } from "../../../../config/Global";

const LoanDisbursedDetailComponent: React.FC = observer(() => {
	const { LOAN_DISBURSEMENT} = useStore();
	const pathHistory = useHistory();
	const { setupLoanDisbursedGrid, changeLoanDisbursedId } = LOAN_DISBURSEMENT;
	const { id }: any = useParams();
	const { t } = useTranslation();

	const listRoute = () => {
		pathHistory.push('/loan-disbursed-management');
	}

	useEffect(() => {
		changeLoanDisbursedId(id)
	}, [changeLoanDisbursedId]);

	return (
		<>
			<div className="Breadcrumb-bar">
				<BreadcrumbComponent items={LoanDisbursedBreadcrumb(t).path} />
				<Space>
					<Button
						type="primary"
						icon={<ArrowLeftOutlined />}
						onClick={listRoute}>
						{t("BACK_TO_LOAN_DISBURSED")}
					</Button>
				</Space>
			</div>
			<ContentBox>
			<AgGridWrapper
				type="serverSide"
				rowSelection={"multiple"}
				onGridReady={setupLoanDisbursedGrid}
			>
				<AgGridColumn
					field="id"
					headerName={"# " + t('ID')}
					pinned="left"
					filter="agNumberColumnFilter"
					width={120}
				/>
				<AgGridColumn
					field="user_name"
					headerName={t("MEMBER_NAME")}
					width={140}
				/>
				<AgGridColumn
					field="repay_amount"
					headerName={t("REPAY_AMOUNT")}
					width={200}
				/>
				<AgGridColumn
					field="loan_amount"
					headerName={t("LOAN_AMOUNT_DISBURSED")}
					width={160}
				/>
				<AgGridColumn
					field="total_loan_pending"
					headerName={t("PENDING_LOAN")}
					width={200}
				/>
				<AgGridColumn
					field="total_loan_disbursed"
					headerName={t("LOAN_DISBURSED")}
					filter= {'agNumberColumnFilter'}
					width={160}
				/>
				<AgGridColumn
					field="due_date"
					headerName={t("DUE_DATE")}
					valueGetter={({ data }) =>
						data.due_date &&
						moment(data.due_date).format("YYYY-MM-DD")
					}
					filter={"agDateColumnFilter"}
					valueFormatter={(data:any) =>
						data?.data?.due_date && moment.utc(data?.data?.due_date).local().format(displayFormats.DATE_FORMAT)
					}
					filterParams={{
						comparator: DateComparator,
					}}
					width={220}
				/>
				<AgGridColumn
					field="status"
					headerName={t('STATUS')}
					width={160}
				/>
			</AgGridWrapper>
			</ContentBox>
		</>
	);
});

export default LoanDisbursedDetailComponent;
