import axios from "axios";
import { makeAutoObservable} from "mobx";
import API_URL from "../../config/ApiUrl";
import { FaqListDataProps } from "./FaqInterface";

export default class FaqStore {
	public listData?: FaqListDataProps[];
	public faqCategoryList: [] | any;
	public faq?: FaqListDataProps;
	public addFaqPopup?: boolean;
	public editFaqPopup?: boolean;
	public editFaqID?: number;
	public deleteFaqPopup?: boolean;
	public deleteFaqID?: number;
	constructor() {
		makeAutoObservable(this);
	
	}

	// Setter Functions
	public openAddFaqPopup = (): void => {
		this.addFaqPopup = true;
	};
	public closeAddFaqPopup = (): void => {
		this.addFaqPopup = false;
	};
	public openEditFaqPopup = (id: number): void => {
		this.editFaqPopup = true;
		this.setEditFaqID(id);
	};
	public closeEditFaqPopup = (): void => {
		this.editFaqPopup = false;
		this.setEditFaqID();
	};
	public openDeleteFaqPopup = (id: number): void => {
		this.deleteFaqPopup = true;
		this.setDeleteFaqID(id);
	};
	public closeDeleteFaqPopup = (): void => {
		this.deleteFaqPopup = false;
		this.setDeleteFaqID();
	};

	private setEditFaqID = (id?: number): void => {
		this.editFaqID = id;
	};
	private setDeleteFaqID = (id?: number): void => {
		this.deleteFaqID = id;
	};

	private setListData = (value?: FaqListDataProps[]): void => {
		this.listData = value;
	};

	public setFaq = (value?: FaqListDataProps): void => {
		this.faq = value;
	};

	public setFaqCategoryList = (values: any): void =>{
		this.faqCategoryList = values;
	};
	// API Functions
	public fetchList = (): Promise<any> => {
		return axios.post(API_URL.FAQ.LIST).then(({ data }) => {
			this.setListData(data.data.rows);
			return data;
		});
	};
	public create = (payload: FormData): Promise<any> => {
		return axios({
			method: "post",
			url: API_URL.FAQ.CREATE,
			data: payload,
			headers: { "Content-Type": "multipart/form-data" },
		}).then(({ data }) => {
				this.fetchList();
				return data;
			})
			.catch(({ data }) => {
				return Promise.reject(data);
			});
	};

	public update = (id:number,payload: FormData): Promise<any> => {
		return axios
			.post(API_URL.FAQ.UPDATE(id), payload,{headers: { "Content-Type": "multipart/form-data" }})
			.then(({ data }) => {
				this.fetchList();
				return data;
			});
	};

	public details = (id: number): Promise<any> => {
		return axios.get(API_URL.FAQ.DETAILS(id)).then(({ data }) => {
			return data;
		});
	};

	public remove = (id: number): Promise<any> => {
		return axios.delete(API_URL.FAQ.DELETE(id)).then(({ data }) => {
			this.fetchList();
			return data;
		});
	};

	public changeStatus = (payload:any): Promise<any> => {
		return axios.post(API_URL.FAQ.CHANGE_STATUS(payload.id),payload)
		.then(({ data }) => {
			this.fetchList();
			return data;
		});
	};

	public fetchFaqCategoryList = (): Promise<any> => {
		return axios.get(API_URL.FAQ_CATEGORY_LIST)
		.then(({ data }) => {
			this.setFaqCategoryList(data.data);
			return data;
		});
	};

}
