import axios from "axios";
import { makeAutoObservable } from "mobx";
import API_URL from "../../config/ApiUrl";
import { ConfederationListDataProps } from "./ConfederationInterface";

export default class ConfederationStore {
	public listData?: ConfederationListDataProps[];
	public confederation?: ConfederationListDataProps;
	public addConfederationPopup?: boolean;
	public editConfederationPopup?: boolean;
	public editConfederationID?: number;
	public deleteConfederationPopup?: boolean;
	public deleteConfederationID?: number;
	public readConfederationData?: any;

	constructor() {
		makeAutoObservable(this);
	}

	// Setter Functions
	public openAddConfederationPopup = (): void => {
		this.addConfederationPopup = true;
	};
	public closeAddConfederationPopup = (): void => {
		this.addConfederationPopup = false;
	};
	public openEditConfederationPopup = (id: number): void => {
		this.editConfederationPopup = true;
		this.setEditConfederationID(id);
	};
	public closeEditPofessionPopup = (): void => {
		this.editConfederationPopup = false;
		this.setEditConfederationID();
	};
	public openDeleteConfederationPopup = (id: number): void => {
		this.deleteConfederationPopup = true;
		this.setDeleteConfederationID(id);
	};

	public closeDeleteConfederationPopup = (): void => {
		this.deleteConfederationPopup = false;
		this.setDeleteConfederationID();
	};

	private setEditConfederationID = (id?: number): void => {
		this.editConfederationID = id;
	};
	private setDeleteConfederationID = (id?: number): void => {
		this.deleteConfederationID = id;
	};

	private setListData = (value?: ConfederationListDataProps[]): void => {
		this.listData = value;
	};

	public setConfederation = (value?: ConfederationListDataProps): void => {
		this.confederation = value;
	};
	public setReadConfederationData = (value?: ConfederationListDataProps): void => {
		this.readConfederationData = value;
	};

	// API Functions
	public fetchList = (): Promise<any> => {
		return axios.post(API_URL.CONFEDERATION.LIST).then(({ data }) => {
			this.setListData(data.data.rows);
			return data;
		});
	};

	public create = (payload: any): Promise<any> => {
		return axios
			.post(API_URL.CONFEDERATION.CREATE, payload)
			.then(({ data }) => {
				this.fetchList();
				return data;
			})
			.catch(({ data }) => {
				return Promise.reject(data);
			});
	};

	public update = (payload: any): Promise<any> => {
		return axios
			.post(API_URL.CONFEDERATION.UPDATE(payload.id), payload)
			.then(({ data }) => {
				this.fetchList();
				return data;
			});
	};

	public details = (id: number): Promise<any> => {
		return axios.get(API_URL.CONFEDERATION.DETAILS(id)).then(({ data }) => {
			this.setReadConfederationData(data)
			return data;
		});
	};

	public remove = (id: number): Promise<any> => {
		return axios.delete(API_URL.CONFEDERATION.DELETE(id)).then(({ data }) => {
			this.fetchList();
			return data;
		});
	};

	public changeStatus = (payload:any): Promise<any> => {
		return axios.post(API_URL.CONFEDERATION.CHANGE_STATUS(payload.id),payload)
		.then(({ data }) => {
			this.fetchList();
			return data;
		});
	};

}
