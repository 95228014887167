import React, { useEffect, useState }  from "react";
import { observer } from "mobx-react";
import {  Button, Col, Layout, Row } from 'antd';
import useStore from "../../../../../store";
import { useHistory, useParams } from "react-router";
import moment from "moment";
import BreadcrumbComponent from "../../../../../components/BreadcrumbComponent/BreadcrumbComponent";
import { MeetingBreadcrumb } from "../../../../../config/BreadcrumbConfig";
import { useTranslation } from "react-i18next";
import { displayFormats } from "../../../../../config/Global";

const MeetingDetailComponent: React.FC = observer(() => {
	const { t } = useTranslation();
	const { MEETING } = useStore();
	const {
		details,
	} = MEETING;

	const [meeting, setMeeting] = useState<any>([])
	const { id }: any = useParams();

	useEffect(()=>{
		details(id).then((data:any) => {
			setMeeting(data);

		});
	},[details])

	const history = useHistory();

	const listRoute = () =>{
		history.push('/meeting-management');
  }

	const getMeetingStatus = (status:number) => {
		switch (status) {
			case 1:
				return t("ACTIVE")
				break;
			case 2:
				return t("CANCELLED")
				break;
			case 3:
				return t("COMPLETED")
				break;

		}
	}
	return (
		<>
			<div className="Breadcrumb-bar Breadcrumb-bar-export">
				<BreadcrumbComponent items={MeetingBreadcrumb(t).path} />
			</div>
			<Layout className="details_table" style={{ padding: '0 24px' }}>
				<Row gutter={6} wrap={false}>
					<Col span={12} className="form__input__textbox form__input__selectBox">
						<label>{t("TITLE")} : </label>
						<span>{meeting?.title}</span>
					</Col>
					<Col span={12} className="form__input__textbox form__input__selectBox">
						<label>{t("GROUP")} : </label>
						<span>{meeting?.group?.name}</span>
					</Col>
				</Row>
				<Row gutter={6} wrap={false}>
					<Col span={24} className="form__input__textbox">
						<label>{t("DESCRIPTION")} : </label>
						<span>{meeting?.description}</span>
					</Col>
				</Row>
				<Row gutter={6} wrap={false}>
					<Col span={12} className="form__input__textbox">
						<label>{t("MEETING_TYPE")}: </label>
						<span>{(meeting?.type == 1) ? "Online" : "Live"}</span>
					</Col>
				</Row>
				<Row gutter={6} wrap={false}>
					<Col span={12} className="form__input__textbox">
						<label>{t("START_DATE_TIME")} : </label>
						<span>{moment.utc(meeting?.meeting_start_date_time).local().format(displayFormats.DATE_TIME_FORMAT)}</span>						
					</Col>
					<Col span={12} className="form__input__textbox">
						<label>{t("END_DATE_TIME")} : </label>
						<span>{moment.utc(meeting?.meeting_end_date_time).local().format(displayFormats.DATE_TIME_FORMAT)}</span>						
					</Col>
				</Row>
				<Row gutter={6} wrap={false}>
					<Col span={24} className="form__input__textbox">
						<label>{t("LOCATION")} : </label>
						<span>{meeting?.location}</span>
					</Col>
				</Row>
				<Row gutter={6} wrap={false} hidden={meeting.status != 1}>
					<Col span={12} className="form__input__textbox form__input__selectBox">
						<label>{t("TOTAL_MEMBERS")} : </label>
						<span>{meeting.user_count}</span>
					</Col>
					<Col span={12} className="form__input__textbox form__input__selectBox">
						<label>{t("PRESENT_USERS")}: </label>
						<span>{meeting.present_user_count}</span>
					</Col>
				</Row>
				<Row gutter={6} wrap={false}>
					<Col span={12} className="form__input__textbox form__input__selectBox">
						<label>{t("SUMMARY")} : </label>
						<span>{meeting.summery ?? "N/A"}</span>
					</Col>
					<Col span={12} className="form__input__textbox form__input__selectBox">
						<label>{t("STATUS")}: </label>
						<span>{getMeetingStatus(meeting.status)}</span>
					</Col>
				</Row>
				<Row gutter={6} wrap={false}>
					<Col span={12} className="form__input__textbox">
						<label>{t("CREATED_AT")}: </label>
						<span>{moment.utc(meeting?.created_at).local().format(displayFormats.DATE_TIME_FORMAT)}</span>
						
					</Col>
					<Col span={12} className="form__input__textbox">
						<label>{t("UPDATED_AT")}: </label>
						<span>{moment.utc(meeting?.updated_at).local().format(displayFormats.DATE_TIME_FORMAT)}</span>
						
					</Col>
				</Row>
				<Row gutter={6} wrap={false}>
					<Col span={12} className="form__input__textbox">
						<label>{t("CREATED_BY")}: </label>
						<span>{meeting?.createdBy ? meeting?.createdBy?.firstname + " "+meeting?.createdBy?.lastname+" ("+meeting?.createdBy?.email+")" : " - "}</span>
						
					</Col>
					<Col span={12} className="form__input__textbox">
						<label>{t("UPDATED_BY")}: </label>
						<span>{meeting?.updatedBy ? meeting?.updatedBy?.firstname + " "+meeting?.updatedBy?.lastname+" ("+meeting?.updatedBy?.email+")" : " - "}</span>
					</Col>
				</Row>
				<Row gutter={6} className="permission__button ant-row-center ant-row">
					<Button style={{ marginTop: 45 }} type="primary" key="resetCancel" danger onClick={listRoute}>{t("BACK")}</Button>
				</Row>
			</Layout>
		</>
	);
});
export default MeetingDetailComponent;
