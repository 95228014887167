import { observer } from "mobx-react";
import React, { useEffect, useState } from "react";
import { useHistory } from "react-router";
import BreadcrumbComponent from "../../../../components/BreadcrumbComponent/BreadcrumbComponent";
import ContentBox from "../../../../components/ContentBox/ContentBox";
import { UserBreadcrumb } from "../../../../config/BreadcrumbConfig";
import useStore from "../../../../store";
import HeaderButtons from "./components/HeaderButtons";
import ListComponent from "./components/ListComponent";
import RecordPerPage from "../../../../utils/RecordPerPage";
import { useTranslation } from "react-i18next";
import { Button } from "antd";
import { GrDocumentExcel } from "react-icons/gr";
import ExportPopupComponent from "../../../../utils/ExportPopupComponent";

const UserView: React.FC = observer(() => {
	const { t } = useTranslation();
	const { USER, AUTH, COMMON } = useStore();
	const { setupGrid, sendPasswordRequest, setPageSize, perPage, changeStatus, exportList } = USER;
	const { permissions } = AUTH;
	const pathHistory = useHistory();
	const {allPermissionList} = COMMON

	useEffect(()=>{
		//
	}, [allPermissionList]);

	const [exportSaving, setExportSaving] = useState<boolean>(false);
	const [exportPopup, showExportPopup] = useState<boolean>(false);

	const addUser = () => {
		pathHistory.push('/user-management/create');
	}

	const editUser = (id: number) => {
		pathHistory.push('/user-management/' + id + '/edit');
	}

	const userDetails = (id: number) => {
		pathHistory.push('/user-management/' + id + '/details');
	}

	const resetPasswordRequest = (data: any) => {
		sendPasswordRequest(data.id);
	}

	const exportListData = (type: string) => {
		setExportSaving(true);
		exportList(type).finally(() => {
			setExportSaving(false);
			showExportPopup(false);
		});
	}

	return (
		<>
			<div className="Breadcrumb-bar">

				<BreadcrumbComponent items={UserBreadcrumb(t).path} />
				<div className="header_all_btn">
					<Button type="primary" className="export-btn" icon={<GrDocumentExcel />} onClick={()=> showExportPopup(true)}>
						{t("GENERATE_REPORT")}
					</Button>
							
					<ExportPopupComponent
						callbackExportFunction={exportListData}
						setVisible={showExportPopup}
						visible={exportPopup}
						saving={exportSaving}
					/>

					<HeaderButtons
						key={1}
						addUserRedirect={addUser}
						addPermission={permissions && allPermissionList && permissions.indexOf(allPermissionList.ADD_USER) >= 0}
					/>
				</div>
			</div>
			{/* <PageHeader
				//title={UserBreadcrumb.title}
				extra={[<HeaderButtons key={1} />]}
			/> */}
			<ContentBox>
				<div className="RecordPerPage__right">
					<RecordPerPage
						style={{ width: "150px" }}
						defaultValue={perPage + " per page"}
						onChange={setPageSize}
					/>

				</div>
				<ListComponent
					setupGrid={setupGrid}
					editUserRedirect={editUser}
					editPermission={permissions && allPermissionList && permissions.indexOf(allPermissionList.EDIT_USER) >= 0}
					detailPermission={permissions && allPermissionList && permissions.indexOf(allPermissionList.USER_DETAILS) >= 0}
					userDetailsRedirect={userDetails}
					resetPasswordRequest={resetPasswordRequest}
					ChangeStatus={changeStatus}

				/>
			</ContentBox>
		</>
	);
});

export default UserView;
