import React, { useState, useEffect } from "react";
import { Button, Modal, Form, } from "antd";
import useStore from "../../../../../store";
import { FaqRequest } from "../../../../../requests/FaqRequest";
//import { UploadOutlined } from "@ant-design/icons";
import { FormBox, InputBox } from "../../../../../components/AntdAddons";
import { useTranslation } from "react-i18next";

interface EditProps {
	EditID: number;
	EditData: (id:number, data: any) => Promise<any>;
	closeEditFaqPopup: () => void;
	visible?: boolean;
	ReadData: (id: number) => Promise<any>;
	fetchFaqCategoryList:() => Promise<any>;
	faqCategoryList:[] | any;
}

const EditComponent: React.FC<EditProps> = ({
	EditID,
	EditData,
	visible,
	closeEditFaqPopup,
	ReadData,
	fetchFaqCategoryList,
	faqCategoryList,
}) => {
	const [form] = Form.useForm();
	const [saving, setSaving] = useState<boolean>(false);
	const { ROOT } = useStore();
	const { AssignErrorToInput } = ROOT;
	const { t } = useTranslation();
	//const[faqImage, setFaqImage] = useState(null)
	//const[faqImageUrl, setFaqImageUrl] = useState<string>("")
	useEffect(() => {
		if (EditID) {
			ReadData(EditID).then(({ data }) => {
				form.setFieldsValue({
					id: data?.id,
					question: data?.question,
					answer: data?.answer,
					faq_category_id: data?.faq_category_id,
				});
				//setFaqImageUrl(data?.faq_image);
			});
		}
		fetchFaqCategoryList()
	}, [EditID, fetchFaqCategoryList]);


// 	const onFileChange = (info:any) =>{
//		setFaqImage(info);
//		return false;
//   };

	// Handle submit and call function to save new record
	const handleSubmit = (data: any) => {
		setSaving(true);
		const formData = new FormData();
    //	faqImage && formData.append('faq_image', faqImage);
		formData.append('question', data.question);
		formData.append('answer', data.answer);
		formData.append('faq_category_id', data.faq_category_id);
		EditData(EditID,formData)
			.then(() => {
				close();
			})
			.catch((e) => {
				AssignErrorToInput(form, e?.data);
			})
			.finally(() => setSaving(false));
	};


	const close = () => {
		form.resetFields();
		closeEditFaqPopup();
	};
	return (
		<Modal
			centered
			title={t("EDIT_FAQ")}
			visible={visible}
			destroyOnClose={true}
			onCancel={close}
			footer={[
				<Button
					key="1"
					loading={saving}
					form="editFaqForm"
					type="primary"
					htmlType="submit"
				>
					{t('SAVE')}
				</Button>,
				<Button key="2" onClick={close}>
					{t('CANCEL')}
				</Button>,
			]}
		>

			<FormBox
				id="editFaqForm"
				form={form}
				encType="multipart/formdata"
				onFinish={handleSubmit}>
				<InputBox.Select
					options={{
						list:faqCategoryList,
						valueKey:"id",
						textKey:"name"
					}}
					label={t("FAQ_CATEGORY")}
					name="faq_category_id"
					placeholder={t("FAQ_CATEGORY")}
					rules={FaqRequest(t).faq_category_id}
				/>
				<InputBox.Text
					required
					label={t("QUESTION")}
					name="question"
					placeholder={t("QUESTION")}
					rules={FaqRequest(t).question}
					maxLength={255}
				/>
				<InputBox.TextArea
					required
					label={t("ANSWER")}
					name="answer"
					placeholder={t("ANSWER")}
					rules={FaqRequest(t).answer}
					maxLength={5000}
				/>

				{/* <Upload
					name="faq_image"
					accept="image/png, image/jpeg"
					listType="picture"
					defaultFileList={[
						{
							uid: '1',
							name: faqImageUrl,
							status: 'done',
							response: 'Server Error 500', // custom error message to show
							url: faqImageUrl,
						}]}
					maxCount={1}
					beforeUpload={onFileChange} >
					<Button icon={<UploadOutlined />}>{t("CLICK_TO_UPLOAD_IMAGE")}</Button>
				</Upload> */}
			</FormBox>
		</Modal>
	);
};

export default EditComponent;
