import { RequestProps } from "../config/InterfacesAndTypes";
import Message from "../config/Message";

export const LoanRequest = (t: any): RequestProps => ({
	group_id: [{ required: true, message: Message(t).required(t("GROUP")) }],
	user_id: [{ required: true, message: Message(t).required(t("USER")) }],
	loan_amount: [
		{ required: true, message: Message(t).required(t("AMOUNT")) },
		{ pattern: /^([0-9]+)$/, message:	Message(t).type.pattern(t("AMOUNT"))},
		({ getFieldValue }) => ({
			validator() {
				if (getFieldValue("loan_amount") < 1) {
					return Promise.reject(Message(t).minIntFloat(1, t('AMOUNT')));
				}
				return Promise.resolve();
			},
		}),
	],
	number_of_installments : [
		{ required: true, message: Message(t).required(t("DURATION")) },
		{ pattern: /^([0-9]+)$/, message:	Message(t).type.pattern(t("DURATION"))},

	],
});
