import moment from "moment";
import Config from "../config/Config";

const DateComparator = (
	filterLocalDateAtMidnight: Date,
	cellValue: string
): number => {
	const dateAsString = moment(cellValue, Config.dateTimeFormat).format(
		"YYYY-MM-DD"
	);

	const cellDate = new Date(
		moment(dateAsString).year(),
		moment(dateAsString).month(),
		moment(dateAsString).date()
	);
	if (filterLocalDateAtMidnight.getTime() === cellDate.getTime()) {
		return 0;
	}
	if (cellDate < filterLocalDateAtMidnight) {
		return -1;
	}
	if (cellDate > filterLocalDateAtMidnight) {
		return 1;
	}
	return 1;
};

export default DateComparator;
