import React, { useEffect } from "react";
import { AgGridColumn } from "ag-grid-react";
import AgGridWrapper from "../../../../components/AgGridWrapper/AgGridWrapper";
import { Button, Switch } from "antd";
import { EditOutlined } from "@ant-design/icons";
import { useTranslation } from "react-i18next";

interface ListProps {
	fetchList: () => void;
	rowDate: any;
	editCmsRedirect:(id:number) => void;
	ChangeStatus:(params: any) => Promise<any>;
}

const ListComponent: React.FC<ListProps> = ({ fetchList, rowDate, editCmsRedirect, ChangeStatus,}) => {
	const { t } = useTranslation();
	useEffect(()=> {
		fetchList();
	},
	[fetchList])
	const changeStatus = (params:any) => {
		ChangeStatus(params)
			.then(() => {
				fetchList();
				return;
			})
			.finally(() => {
				return;
			});
	}

	const ActionRenderer = (params: any) => {
		return (
			<div className="action-column">
				<Button
					type="text"
					title={t("EDIT")}
					icon={<EditOutlined />}
					onClick={() =>editCmsRedirect(params.data?.id)}
				></Button>
			</div>
		);
	};

	const SwitchRenderer = (params: any) => {
		return (
			<div>
				<Switch
					defaultChecked={params.data?.status}
					onChange= {() => changeStatus({id:params.data?.id,status:params.data?.status ==1 ? 0 : 1})}
				/>
			</div>
		);
	};

	return (
		<AgGridWrapper
			rowData={rowDate}
			rowSelection={"multiple"}
			frameworkComponents={{ ActionRenderer, SwitchRenderer}}
			overlayLoadingTemplate={t("PLEASE_WAIT_WHILE_DATA_IS_LOADING")} overlayNoRowsTemplate={t("NO_RECORD_FOUND")}
		>
			<AgGridColumn field="id" headerName={t('SR_NO')}  filter="agNumberColumnFilter"  width={200}  pinned={"left"}/>
			<AgGridColumn
				width={587}
				field="slug"
				headerName={t('TITLE')}
			/>
			<AgGridColumn
				field="status"
				headerName={t('STATUS')}
				cellRenderer="SwitchRenderer"
				width={150}
				sortable={false}
				filter={false}
			/>
			<AgGridColumn
				headerName={t('ACTION')}
				type="actionColumn"
				sortable={false}
				filter={false}
				width={120}
			/>
		</AgGridWrapper>
	);
};

export default ListComponent;
