import {
	GridOptions,
	GridReadyEvent,
	IServerSideGetRowsParams,
} from "ag-grid-community";
import axios from "axios";
import { makeAutoObservable } from "mobx";
import API_URL from "../../config/ApiUrl";
import Config from "../../config/Config";
import RootStore from "../RootStore/RootStore";


export default class GroupFinanceStore {
	public grid?: any;
	private rootStore: RootStore;
	public groupId?:any;
	public fineGrid:any;
	public donationGrid?:any;
	public loangrid?:any;
	public perPage?:any = 100;

	constructor() {
		this.rootStore = new RootStore();
		makeAutoObservable(this);
	}

	// Setter Functions
	public setGroupId = (id:any)=> {
		this.groupId=id;
	}

	private setFineGrid = (value: any) => {
		this.fineGrid = value;
	};

	private setDonationGrid = (value: any) => {
		this.donationGrid = value;
	};

	private setLoanGrid = (value: any) => {
		this.loangrid = value;
	};

  // Grid Setup Functions
	private setGrid = (value: any) => {
		this.grid = value;
	};

	public setupGrid = (params: GridReadyEvent) => {
		this.setGrid(params);
		this.setPageSize()
		const datasource = this.createDatasource(Config.grid.server.gridOptions);
		params.api.setServerSideDatasource(datasource);
	};

	public setPageSize = (page = this.perPage) => {
		this.perPage = page;
		if (this.grid) {
			this.grid.api.paginationSetPageSize(parseInt(page));
		}
	};

	private createDatasource = (gridOptions?: GridOptions) => {
		return {
			gridOptions,
			getRows: (params: IServerSideGetRowsParams) => {
				const payload = this.rootStore.getServerListPayload(params);
				this.fetchList(payload).then((data) => {
					params.successCallback(data?.rows, data?.count);
					if (data.count <= 0) {
						params.api.showNoRowsOverlay();
					} else {
						params.api.hideOverlay();
					}
				});
			},
		};
	};

	// Fine Grid Setup Functions
	public setUpFineGrid = (params: GridReadyEvent) => {
		this.setFineGrid(params);
		const datasource = this.createFineDatasource(Config.grid.server.gridOptions);
		params.api.setServerSideDatasource(datasource);
	};

	private createFineDatasource = (gridOptions?: GridOptions) => {
		return {
			gridOptions,
			getRows: (params: IServerSideGetRowsParams) => {
				const payload = this.rootStore.getServerListPayload(params);
				this.fetchFineList(payload).then((data) => {
					params.successCallback(data.rows, data.count);
					if (data.count <= 0) {
						params.api.showNoRowsOverlay();
					} else {
						params.api.hideOverlay();
					}
				});
			},
		};
	};

	//Donation grid setup
	public setupDonationGrid = (params: GridReadyEvent) => {
		this.setDonationGrid(params);
		const datasource = this.createDonationDatasource(Config.grid.server.gridOptions);
		params.api.setServerSideDatasource(datasource);
	};

	private createDonationDatasource = (gridOptions?: GridOptions) => {
			return {
					gridOptions,
					getRows: (params: IServerSideGetRowsParams) => {
							const payload = this.rootStore.getServerListPayload(params);
							this.fetchDonationList(payload).then((data) => {
									params.successCallback(data?.rows, data?.count);
									if (data.count <= 0) {
										params.api.showNoRowsOverlay();
									} else {
										params.api.hideOverlay();
									}
							});
					},
			};
	};

	// Loan Grid Setter Functions
	public setupLoanGrid = (params: GridReadyEvent) => {
		this.setLoanGrid(params);
		const datasource = this.createLoanDatasource(Config.grid.server.gridOptions);
		params.api.setServerSideDatasource(datasource);
	};

	private createLoanDatasource = (gridOptions?: GridOptions) => {
		return {
			gridOptions,
			getRows: (params: IServerSideGetRowsParams) => {
				const payload = this.rootStore.getServerListPayload(params);
				this.fetchLoanList(payload).then((data) => {
					params.successCallback(data?.rows, data?.count);
					if (data.count <= 0) {
						params.api.showNoRowsOverlay();
					} else {
						params.api.hideOverlay();
					}
				});
			},
		};
	};

	// API Functions
	public fetchList = (payLoad: any): Promise<any> => {
		return axios.post(API_URL.GROUP.GROUP_FINANCE_MANAGEMENT, payLoad).then(({ data }) => {
			return data.data;
		});
	};

	public fetchFineList = (payload: any): Promise<any> => {
		payload.group_id= this.groupId;
		return axios
			.post(API_URL.FINE.LIST, payload)
		.then(({ data }) => {
			return data.data;
		});
	};

	public fetchDonationList =  (payload:any): Promise<any> => {
		payload.group_id= this.groupId;
		return axios
			.post(API_URL.DONATION.LIST, payload)
		.then(({ data }) => {
			return data.data;
		});
	};

	public fetchLoanList = (payLoad: any): Promise<any> => {
		payLoad.group_id= this.groupId;
		return axios.post(API_URL.LOAN.LIST, payLoad).then(({ data }) => {
			return data.data;
		});
	};
}

