import React from "react";
import { AgGridColumn } from "ag-grid-react";
import AgGridWrapper from "../../../../components/AgGridWrapper/AgGridWrapper";
import { GridReadyEvent } from "ag-grid-community";
import { Button } from "antd";
import { EyeOutlined } from "@ant-design/icons";
import moment from "moment";
import Config from "../../../../config/Config";
import DateComparator from "../../../../utils/DateComparator";
import { useTranslation } from "react-i18next";
import { displayFormats } from "../../../../config/Global";

interface ListComponentProps {
	setupGrid: (params: GridReadyEvent) => void;
	ElectionProcessDetails: (id: string) => void | any;
}

const ListElectionProcessComponent: React.FC<ListComponentProps> = ({ setupGrid, ElectionProcessDetails }) => {
	const { t } = useTranslation();
	const ActionRenderer = (params: any) => {
		return (
			<div className="action-column">
				<Button
					title={t("VIEW_ELECTION")}
					type="text"
					onClick={() =>ElectionProcessDetails(params.data?.id)}
					icon={<EyeOutlined />}
				>
				</Button>
			</div>
		);
	};


	return (
		<AgGridWrapper
			type="serverSide"
			onGridReady={setupGrid}
			rowSelection={"multiple"}
			frameworkComponents={{ ActionRenderer }}
			overlayLoadingTemplate={t("PLEASE_WAIT_WHILE_DATA_IS_LOADING")} overlayNoRowsTemplate={t("NO_RECORD_FOUND")}
		>
			<AgGridColumn
				field="id"
				headerName={"# " + t('ID')}
				pinned="left"
				filter="agNumberColumnFilter"
				width={120}
			/>
			<AgGridColumn
				field="group_name"
				headerName={t('GROUP_NAME')}
				width={280}
			/>
			<AgGridColumn
				field="election_title"
				headerName={t("ELECTION_TITLE")}
				width={250}
			/>
			<AgGridColumn
				field="election_referee_name"
				headerName={t("ELECTION_REFEREE_NAME")}
				width={250}
			/>
			<AgGridColumn
				field="election_date"
				headerName={t("ELECTION_DATE")}
				valueGetter={({ data }) =>
					data.election_date &&
					moment(data.election_date).format(Config.dateTimeFormat)
				}
				filter={"agDateColumnFilter"}
				valueFormatter={(data:any) =>
					data?.data?.election_date && moment.utc(data?.data?.election_date).local().format(displayFormats.DATE_TIME_FORMAT)
				}
				filterParams={{
					comparator: DateComparator,
				}}
				width={200}
			/>
			<AgGridColumn
				headerName={t('ACTION')}
				type="actionColumn"
				sortable={false}
				filter={false}
				width={100}
				pinned={"right"}
			/>
		</AgGridWrapper>
	);
};

export default ListElectionProcessComponent;
