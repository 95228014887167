import React, { useEffect, useState } from "react";
import { observer } from "mobx-react";
import { Form } from "antd";
import useStore from "../../../../../store";
import { useHistory, useParams } from "react-router";
import FormComponent from "./FormComponent";
import moment from "moment";

const EditMeetingComponent: React.FC = observer(() => {
	const { ROOT, MEETING, COMMON } = useStore();
	const { update, details } = MEETING;

	const [meeting, setMeeting] = useState<any>([]);
	const { id }: any = useParams();

	const { groupList, fetchGroup } = COMMON;

	const { AssignErrorToInput } = ROOT;
	useEffect(() => {
		fetchGroup();

		details(id).then((data: any) => {
			setMeeting(data);
			form.resetFields();
			form.setFieldsValue({
				group_id: data.group_id,
				type: data.type,
				title: data.title,
				location: data.location,
				description: data.description,
				meeting_start_date_time: moment(data.meeting_start_date_time).local(),
				meeting_end_date_time: moment(data.meeting_end_date_time).local(),
			});
		});
	}, [fetchGroup, details]);

	const [form] = Form.useForm();
	const [saving, setSaving] = useState<boolean>(false);
	const history = useHistory();
	const listRoute = () => {
		history.push("/meeting-management");
	};

	// Handle submit and call function to save new record
	const handleSubmit = (data: any) => {
		const formData = new FormData();

		data.title && formData.append("title", data.title);
		data.location && formData.append("location", data.location);
		data.type && formData.append("type", data.type);
		data.description && data.description?.trim() && formData.append("description", data.description);
		data.meeting_start_date_time && formData.append("meeting_start_date_time", moment(data.meeting_start_date_time).utc().format("YYYY-MM-DD HH:mm"));
		data.meeting_end_date_time && formData.append("meeting_end_date_time", moment(data.meeting_end_date_time).utc().format("YYYY-MM-DD HH:mm"));

		setSaving(true);
		update(meeting.id, formData)
			.then(() => {
				setSaving(false);
				form.resetFields();
				listRoute();
			})
			.catch((e: any) => {
				setSaving(false);
				AssignErrorToInput(form, e?.data?.errors);
			});
	};

	return (
		<FormComponent
			formId="editLoanForm"
			form={form}
			handleSubmit={handleSubmit}
			saving={saving}
			listRoute={listRoute}
			isEdit={true}
			groupList={groupList}
			meetingDetails={meeting}
		/>
	);
});

export default EditMeetingComponent;
