import axios from "axios";
import { makeAutoObservable } from "mobx";
import API_URL from "../../config/ApiUrl";
import { SettingsListDataProps } from "./SettingsInterface";

export default class SliderStore {

	public listData?: SettingsListDataProps[];
	public settings?: SettingsListDataProps;

	constructor() {
		makeAutoObservable(this);
	}

	// Setter Functions


	public setSettings = (value?: SettingsListDataProps): void => {
		this.settings = value;
	};

	// API Functions
	public getFooterSection = (): Promise<any> => {
		return axios.get(API_URL.FOOTER_SECTION.DETAILS).then(({ data }) => {
			return data.data;
		});
	};

	// public create = (payload: any): Promise<any> => {
	// 	return axios.post(API_URL.FOOTER_SECTION.CREATE, payload).then(({ data }) => {
	// 		this.fetchList();
	// 		return data;
	// 	})
	// };

	public saveFooterSection = (payload:any): Promise<any> => {
		return axios.post(API_URL.FOOTER_SECTION.SAVE, payload).then(({ data }) => {
			return data.data;
		});
	};

	public getSettingDetails = (key:any): Promise<any> => {
		return axios.get(API_URL.SETTING.DETAILS(key)).then(({ data }) => {
			return data.data;
		});
	};

	public saveSettingDetails = (key:any, payload:any): Promise<any> => {
		return axios.post(API_URL.SETTING.SAVE(key), payload).then(({ data }) => {
			return data.data;
		});
	};

	public setDefaultLanguage = (languageId:any): Promise<any> => {
		return axios.post(API_URL.SETTING.CHANGE_DEFAULT_LANGUAGE, {language_id:languageId}).then(({ data }) => {
			return data.data;
		});
	}
}
