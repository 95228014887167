import React, { useEffect, useState }  from "react";
import { observer } from "mobx-react";
import {  Button, Col, Layout, Row } from 'antd';
import useStore from "../../../../../store";
import { useHistory, useParams } from "react-router";
import moment from "moment";
import BreadcrumbComponent from "../../../../../components/BreadcrumbComponent/BreadcrumbComponent";
import { GroupBreadcrumb } from "../../../../../config/BreadcrumbConfig";
import { useTranslation } from "react-i18next";
import { displayFormats } from "../../../../../config/Global";

const GroupDetailComponent: React.FC = observer(() => {
	const { t } = useTranslation();
	const { GROUP } = useStore();
	const {
		details,
	} = GROUP;

	const [group, setGroup] = useState<any>();
	const { id }: any = useParams();

	useEffect(()=>{
		details(id).then((data:any) => {
			setGroup(data);
		});
	},[details])

	const history = useHistory();

	const listRoute = () =>{
		history.push('/group-management');
  }


	return (
		<>
		<div className="Breadcrumb-bar Breadcrumb-bar-export">
			<BreadcrumbComponent items={GroupBreadcrumb(t).path} />
		</div>			
		{group && <Layout className="details_table" style={{ padding: '0 24px' }}>
			<Row gutter={6} wrap={false} hidden={!group.group_image}>
				<Col span={12} className="form__input__textbox form__input__selectBox">
					<span>{group.group_image ? <img src={group.group_image} width={200} height={150} alt="Group Icon"/> : "-"}</span>
				</Col>
			</Row>
			<Row  gutter={6} wrap={false} style={{ borderTop: '1px solid #cccacb' }}>
				<Col span={12} className="form__input__textbox">
					<label>{t("NAME")} : </label>
					<span>{group.name}</span>
				</Col>
				<Col span={12} className="form__input__textbox">
					<label>{t("MAX_NUMBER_OF_MEMBERS")} : </label>
					<span>{group.numbers_of_member}</span>
				</Col>
			</Row>
			<Row  gutter={6} wrap={false}>
				<Col span={24} className="form__input__textbox form__input__selectBox">
					<label>{t("DESCRIPTION")} : </label>
					<span>{group.group_description}</span>
				</Col>
			</Row>
			<Row  gutter={6} wrap={false}>
				<Col span={12} className="form__input__textbox">
					<label>{t("SHARE_PRICE")} : </label>
					<span>{group.share_price}</span>
				</Col>
				<Col span={12} className="form__input__textbox form__input__selectBox">
					<label>{t("OPERATING_CYCLE_DATE")} : </label>
					<span>{moment(group.operating_cycle_date).utc().format('YYYY-MM-DD')}</span>
				</Col>
			</Row>
			<Row  gutter={6} wrap={false}>
				<Col span={12} className="form__input__textbox form__input__selectBox">
					<label>{t("MAX_BORROWING_TIME_LINE")} : </label>
					<span>{group.max_borrowing_time_line}</span>
				</Col>
				<Col span={12} className="form__input__textbox">
					<label>{t("BORROWING_LIMIT")}: </label>
					<span>{group.borrowing_limit}</span>
				</Col>
			</Row>
			<Row  gutter={6} wrap={false}>
				<Col span={12} className="form__input__textbox form__input__selectBox">
					<label>{t("GROUP_WELFARE_RATE")} : </label>
					<span>{group.group_welfare_rate}</span>
				</Col>
				<Col span={12} className="form__input__textbox form__input__selectBox">
					<label>{t("MAX_SHARE_PURCHASE_LIMIT")} : </label>
					<span>{group.max_share_purchase_limit}</span>
				</Col>
			</Row>
			<Row  gutter={6} wrap={false}>
				<Col span={12} className="form__input__textbox form__input__selectBox">
					<label>{t("INTEREST_RATE")} (%) : </label>
					<span>{group.interest_rate}</span>
				</Col>
				<Col span={12} className="form__input__textbox form__input__selectBox">
					<label>{t("CONFEDERATION")} : </label>
					<span>{group.confederation?.name}</span>
				</Col>
			</Row>
			<Row  gutter={6} wrap={false}>
				<Col span={12} className="form__input__textbox form__input__selectBox">
					<label>{t("CURRENCY")} : </label>
					<span>{group.currency?.name} {group.currency?.symbol && "("+group.currency?.symbol+")"}</span>
				</Col>				
				<Col span={12} className="form__input__textbox form__input__selectBox">
					<label>{t("CREATED_BY_ADMIN")} : </label>
					<span>{group.is_created_by_admin==1 ? "Yes" :"No"}</span>
				</Col>
			</Row>
			<Row  gutter={6} wrap={false}>				
				<Col span={12} className="form__input__textbox">
					<label>{t("PRESIDENT")}: </label>
					<span>{group.president.firstname ? group.president?.firstname + " "+group.president?.lastname+" ("+group.president?.email+")" : "N/A"}</span>
				</Col>				
				<Col span={12} className="form__input__textbox">
					<label>{t("SECRETARY")}: </label>
					<span>{group.secretary.firstname ? group.secretary?.firstname + " "+group.secretary?.lastname +" ("+group.secretary?.email+")": "N/A"}</span>
				</Col>
			</Row>
			<Row  gutter={6} wrap={false}>
				<Col span={12} className="form__input__textbox">
					<label>{t("ACCOUNTANT")}: </label>
					<span>{group.accountant.firstname ? group.accountant?.firstname + " "+group.accountant?.lastname+" ("+group.accountant?.email+")" : "N/A"}</span>
				</Col>
				<Col span={12} className="form__input__textbox">
					<label>{t("VILLAGE_AGENT")}: </label>
					<span>{group.villageAgent.firstname ? group.villageAgent?.firstname + " "+group.villageAgent?.lastname+" ("+group.villageAgent?.email+")" : "N/A"}</span>
				</Col>
			</Row>
			<Row  gutter={6} wrap={false}>
				<Col span={12} className="form__input__textbox">
					<label>{t("CREATED_AT")}: </label>
					<span>{moment.utc(group?.created_at).local().format(displayFormats.DATE_TIME_FORMAT)}</span>
				</Col>
				<Col span={12} className="form__input__textbox">
					<label>{t("UPDATED_AT")}: </label>
					<span>{moment.utc(group?.updated_at).local().format(displayFormats.DATE_TIME_FORMAT)}</span>
				</Col>
			</Row>
			<Row  gutter={6} wrap={false}>
				<Col span={12} className="form__input__textbox">
					<label>{t("CREATED_BY")}: </label>
					<span>{group.createdBy ? group.createdBy?.firstname + " "+group.createdBy?.lastname+" ("+group.createdBy?.email+")" : " - "}</span>
				</Col>
				<Col span={12} className="form__input__textbox">
					<label>{t("UPDATED_BY")}: </label>
					<span>{group.updatedBy ? group.updatedBy?.firstname + " "+group.updatedBy?.lastname+" ("+group.updatedBy?.email+")" : " - "}</span>
				</Col>
			</Row>
			<Row gutter={6} className="permission__button ant-row-center ant-row">
				<Button style={{ marginTop: 45 }} type="primary" key="resetCancel" danger onClick={listRoute}>{t("BACK")}</Button>
			</Row>
		</Layout>}
		</>
	);
});
export default GroupDetailComponent;
