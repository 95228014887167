import { Button, Col, Layout, Modal, Row } from 'antd';
import React, { useState } from 'react';
import { FormBox, InputBox } from '../../../../components/AntdAddons';
import useStore from '../../../../store';
import { VillageAgentRequtest } from "../../../../requests/VillageAgentRequtest";
import { useTranslation } from 'react-i18next';
interface AssignVillageAgentInterface  {
	handleCancel: () => void;
	isAssignGroupModelVisiable: boolean;
	id: number;
	villageAgent: any;
}
const AssignVillageAgent : React.FC<AssignVillageAgentInterface> = ({
	handleCancel,
	isAssignGroupModelVisiable,
	id,
	villageAgent
}) => {
	const {
		VILLAGE_AGENT : {assignVillageAgentToGroup},
		COMMON : {fetchGroup},
	} = useStore();

	const [groupList, setGroupList] = useState<any>([]);
	const [saving, setSaving] = useState<boolean>(false);
	const { t } = useTranslation();

	const handleSubmit = (data:any) => {
		data.user_id = id;
		setSaving(true);
		assignVillageAgentToGroup(data).then(() => {
			handleModalClose()
		}).catch(() => {
			//AssignErrorToInput(data, e?.data);
		})
		.finally(() => { setSaving(false)});
	}

	const handleSearch = (value:any) => {
		fetchGroup(value).then((data:any) => {
			const newGroupList = data.filter((element: any)=>{
				if(villageAgent.assignedGroupIds.indexOf(element.id)==-1){
					return element;
				}
			});
			setGroupList(newGroupList);
		});
	}

	const handleModalClose = () => {
		setGroupList([]);
		handleCancel();
	}

	return (
		<Modal
				centered
				visible={isAssignGroupModelVisiable}
				destroyOnClose={true}
				onCancel={handleModalClose}
				width={600}
				footer={[
					<Button
						key="1"
						form="assignVillageAgentForm"
						type="primary"
						loading={saving}
						htmlType="submit"
					>
						{t('SAVE')}
					</Button>,
					<Button key="2" onClick={handleCancel}>
						{t('CANCEL')}
					</Button>,
				]}
			>
				<FormBox
					id="assignVillageAgentForm"
					encType="multipart/formdata"
					key="formbasic"
					onFinish={handleSubmit}>
					<Layout style={{ padding: '0 24px' }}>
						<Row wrap={false}>
							<Col span={24} className="form__input__textbox">
								<InputBox.Select
									placeholder={t("SEARCH_GROUP")}
									label={t("SEARCH_GROUP")}
									name="group_id"
									filterOption={false}
									options={{
										list:groupList,
										valueKey:"id",
										textKey:"name"
									}}
									required
									onSearch={handleSearch}
									mode="multiple"
									rules={VillageAgentRequtest(t).group_id}
								/>
							</Col>
						</Row>
					</Layout>
				</FormBox>
			</Modal>
	)
}

export default AssignVillageAgent;

