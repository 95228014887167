import React, { useEffect, useState } from "react";
import BreadcrumbComponent from "../../components/BreadcrumbComponent/BreadcrumbComponent";
import { DashboardBreadcrumb } from "../../config/BreadcrumbConfig";
import { observer } from "mobx-react-lite";
import { Layout, Row, Col, Button } from "antd";
import useStore from "../../store";
import {
	Chart as ChartJS,
	CategoryScale,
	LinearScale,
	BarElement,
	PointElement,
	LineElement,
	Title,
	Tooltip,
	Legend
} from 'chart.js';
import { Bar, Line } from 'react-chartjs-2';
import { useTranslation } from "react-i18next";
ChartJS.register(
	CategoryScale,
	LinearScale,
	BarElement,
	PointElement,
	LineElement,
	Title,
	Tooltip,
	Legend
);

export const barChartOptions = {
	responsive: true,
	plugins: {
		legend: {
			position: 'top' as const,
		},
		title: {
			display: false,
			text: 'Chart.js Bar Chart',
		},
	},
};

export const lineChartOptions = {
	responsive: true,
	plugins: {
		legend: {
			position: "top" as const,
		},
		title: {
			display: false,
			text: "Chart.js Line Chart",
		},
	},
};

const Dashboard: React.FC = observer(() => {
	const { t } = useTranslation();
	const { COMMON } = useStore();
	const {
		fetchDashboardDetails,
	} = COMMON;
	const [Dashboard, setDashboard] = useState<any>([])
	const [groupDetailChartData, setGroupDetailChartData] = useState<any>();
	const [groupDetailsType, setGroupDetailsType] = useState<string>("weekly");

	const [membersRegisteredChartData, setMembersRegisteredChartData] = useState<any>();
	const [membersRegisteredType, setMembersRegisteredType] = useState<string>("weekly");

	const [amountOfSavingChartData, setAmountOfSavingChartData] = useState<any>();
	const [amountOfSavingType, setAmountOfSavingType] = useState<string>("weekly");

	const [welfareAmountChartData, setWelfareAmountChartData] = useState<any>();
	const [welfareAmountType, setWelfareAmountType] = useState<string>("weekly");

	const [noOfMeetingTakenPlaceChartData, setNoOfMeetingTakenPlaceChartData] = useState<any>();
	const [noOfMeetingTakenPlaceType, setNoOfMeetingTakenPlaceType] = useState<string>("weekly");


	const setChartData = () => {
		const data = {
			group_details_type: groupDetailsType,
			members_registered_type: membersRegisteredType,
			amount_of_saving_type: amountOfSavingType,
			welfare_amount_type: welfareAmountType,
			no_of_meeting_taken_place_type: noOfMeetingTakenPlaceType
		}

		fetchDashboardDetails(data).then((data: any) => {
			setDashboard(data);

			setGroupDetailChartData({
				labels: data.group_details.map((element: any) => element.name),
				datasets: [
					{
						label: groupDetailsType.charAt(0).toUpperCase() + groupDetailsType.slice(1),
						data: data.group_details.map((element: any) => element.count),
						backgroundColor: "#116DAF",
					},
				],
			});

			setMembersRegisteredChartData({
				labels: data.members_registered.map((element: any) => element.name),
				datasets: [
					{
						label: membersRegisteredType.charAt(0).toUpperCase() + membersRegisteredType.slice(1),
						data: data.members_registered.map((element: any) => element.count),
						backgroundColor: "#FCA429",
					},
				],
			});

			setAmountOfSavingChartData({
				labels: data.total_amount_of_saving.map((element: any) => element.name),
				datasets: [
					{
						label: amountOfSavingType.charAt(0).toUpperCase() + amountOfSavingType.slice(1),
						data: data.total_amount_of_saving.map((element: any) => element.amount),
						fill: false,
						backgroundColor: "rgba(255, 255, 255, 1)",
						borderColor: "rgba(247, 0, 228, 0.8)",
						borderWidth: 1
					},
				],
			});

			setWelfareAmountChartData({
				labels: data.total_welfare_amount.map((element: any) => element.name),
				datasets: [
					{
						label: welfareAmountType.charAt(0).toUpperCase() + welfareAmountType.slice(1),
						data: data.total_welfare_amount.map((element: any) => element.amount),
						fill: false,
						backgroundColor: "rgba(255, 255, 255, 1)",
						borderColor: "rgba(17, 109, 175, 1)",
						borderWidth: 1
					},
				],
			});

			setNoOfMeetingTakenPlaceChartData({
				labels: data.no_of_meeting_taken_place_type.map((element: any) => element.name),
				datasets: [
					{
						label: noOfMeetingTakenPlaceType.charAt(0).toUpperCase() + noOfMeetingTakenPlaceType.slice(1),
						data: data.no_of_meeting_taken_place_type.map((element: any) => element.count),
						backgroundColor: "rgba(17, 147, 175, 1)",
					},
				],
			});
		});
	}

	useEffect(() => {
		setChartData();
	}, [fetchDashboardDetails, groupDetailsType, membersRegisteredType, amountOfSavingType, welfareAmountType, noOfMeetingTakenPlaceType]);

	return (
		<>
			<div className="Breadcrumb-bar Breadcrumb-bar-export">
				<BreadcrumbComponent items={DashboardBreadcrumb(t).path} />
			</div>
			<Layout style={{ padding: '0 24px' }}>
				<Row gutter={24} wrap={false} style={{ padding: '0', margin: '0px -15px 15px' }}>
					<div className="dashboard__top__box">
						<span className="dashboard__top__box__num">{Dashboard?.group_count}</span>
						<span className="dashboard__top__box__text">{t("TOTAL_GROUPS")}</span>
					</div>
					<div className="dashboard__top__box">
						<span className="dashboard__top__box__num">{Dashboard?.members_count}</span>
						<span className="dashboard__top__box__text">{t("TOTAL_MEMBERS")}</span>
					</div>
					<div className="dashboard__top__box">
						<span className="dashboard__top__box__num">{Dashboard?.new_group_count}</span>
						<span className="dashboard__top__box__text">{t("NEW_GROUPS")}</span>
					</div>
					<div className="dashboard__top__box">
						<span className="dashboard__top__box__num">{Dashboard?.new_members_count}</span>
						<span className="dashboard__top__box__text">{t("NEW_MEMBERS")}</span>
					</div>
					<div className="dashboard__top__box">
						<span className="dashboard__top__box__num">{Dashboard?.loan_paid_amount}</span>
						<span className="dashboard__top__box__text">{t("LOAN_PAID")}</span>
					</div>
					<div className="dashboard__top__box">
						<span className="dashboard__top__box__num">{Dashboard?.loan_due_amount}</span>
						<span className="dashboard__top__box__text">{t("DUE_LOAN")}</span>
					</div>
					<div className="dashboard__top__box">
						<span className="dashboard__top__box__num">{Dashboard?.loan_amount}</span>
						<span className="dashboard__top__box__text">{t("REPAID_LOAN")}</span>
					</div>
				</Row>
				<Row gutter={24} wrap={false} style={{ padding: '0', margin: '10px -19px' }}>
					<Col span={12} className="chart__box__design">
						<div className="chart__box__design__pr">
							<div className="chart__box__design__had">
								<span>{t("TOTAL_GROUPS")}</span>
								<Button type="primary" onClick={() => setGroupDetailsType("weekly")}>{t("WEEKLY")}</Button>
								<Button type="primary" onClick={() => setGroupDetailsType("monthly")}>{t("MONTHLY")}</Button>
								<Button type="primary" onClick={() => setGroupDetailsType("yearly")}>{t("YEARLY")}</Button>
							</div>
							{groupDetailChartData && <Bar options={barChartOptions} data={groupDetailChartData} />}
						</div>
					</Col>
					<Col span={12} className="chart__box__design">
						<div className="chart__box__design__pr">
							<div className="chart__box__design__had">
								<span>{t("TOTAL_MEMBERS_REGISTERED")}</span>
								<Button type="primary" onClick={() => setMembersRegisteredType("weekly")}>{t("WEEKLY")}</Button>
								<Button type="primary" onClick={() => setMembersRegisteredType("monthly")}>{t("MONTHLY")}</Button>
								<Button type="primary" onClick={() => setMembersRegisteredType("yearly")}>{t("YEARLY")}</Button>
							</div>
							{membersRegisteredChartData && <Bar options={barChartOptions} data={membersRegisteredChartData} />}
						</div>
					</Col>
				</Row>
				<Row gutter={24} wrap={false} style={{ padding: '0', margin: '10px -19px' }}>
					<Col span={12} className="chart__box__design">
						<div className="chart__box__design__pr">
							<div className="chart__box__design__had">
								<span>{t("TOTAL_AMOUNT_OF_SAVINGS")}</span>
								<Button type="primary" onClick={() => setAmountOfSavingType("weekly")}>{t("WEEKLY")}</Button>
								<Button type="primary" onClick={() => setAmountOfSavingType("monthly")}>{t("MONTHLY")}</Button>
								<Button type="primary" onClick={() => setAmountOfSavingType("yearly")}>{t("YEARLY")}</Button>
							</div>
							{amountOfSavingChartData && <Line options={lineChartOptions} data={amountOfSavingChartData} />}
						</div>
					</Col>
					<Col span={12} className="chart__box__design">
						<div className="chart__box__design__pr">
							<div className="chart__box__design__had">
								<span>{t("TOTAL_WELFARE_AMOUNT")}</span>
								<Button type="primary" onClick={() => setWelfareAmountType("weekly")}>{t("WEEKLY")}</Button>
								<Button type="primary" onClick={() => setWelfareAmountType("monthly")}>{t("MONTHLY")}</Button>
								<Button type="primary" onClick={() => setWelfareAmountType("yearly")}>{t("YEARLY")}</Button>
							</div>
							{welfareAmountChartData && <Line options={lineChartOptions} data={welfareAmountChartData} />}
						</div>
					</Col>
				</Row>
				<Row gutter={24} wrap={false} style={{ padding: '0', margin: '10px -19px' }}>
					<Col span={12} className="chart__box__design">
						<div className="chart__box__design__pr">
							<div className="chart__box__design__had">
								<span>{t("TOTAL_NO_OF_MEETINGS_TAKEN_PLACE")}</span>
								<Button type="primary" onClick={() => setNoOfMeetingTakenPlaceType("weekly")}>{t("WEEKLY")}</Button>
								<Button type="primary" onClick={() => setNoOfMeetingTakenPlaceType("monthly")}>{t("MONTHLY")}</Button>
								<Button type="primary" onClick={() => setNoOfMeetingTakenPlaceType("yearly")}>{t("YEARLY")}</Button>
							</div>
							{noOfMeetingTakenPlaceChartData && <Bar options={barChartOptions} data={noOfMeetingTakenPlaceChartData} />}
						</div>
					</Col>
				</Row>
			</Layout>
		</>
	);
})

export default Dashboard;
