import { observer } from "mobx-react";
import React from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router";
import BreadcrumbComponent from "../../../components/BreadcrumbComponent/BreadcrumbComponent";
import ContentBox from "../../../components/ContentBox/ContentBox";
import { CMSBreadcrumb } from "../../../config/BreadcrumbConfig";
import useStore from "../../../store";
import ListComponent from "./components/ListComponent";

const CmsView: React.FC = observer(() => {
	const { t } = useTranslation();
	const { CMS } = useStore();
	const { fetchList, listData, changeStatus } = CMS;
	const pathHistory = useHistory();

	const editCms = (id:number) => {
		pathHistory.push('/cms-management/'+id+'/edit');
	}

	return (
		<>
			<div className="Breadcrumb-bar">
				<BreadcrumbComponent items={CMSBreadcrumb(t).path} />
			</div>
			<ContentBox>
				<ListComponent fetchList={fetchList} rowDate={listData} ChangeStatus={changeStatus} editCmsRedirect={editCms}/>
			</ContentBox>
		</>
	);
});

export default CmsView;
