import React, { useState, useEffect } from "react";
import { Button, Modal, Form } from "antd";
import useStore from "../../../../store";
import { FineRequest } from "../../../../requests/FineRequest";
import { FormBox, InputBox } from "../../../../components/AntdAddons";
import moment from "moment";
import { useTranslation } from "react-i18next";
import { displayFormats } from "../../../../config/Global";
interface EditProps {
	EditID: number;
	EditData: (id:number, data: any) => Promise<any>;
	closeEditFinePopup: () => void;
	visible?: boolean;
	ReadData: (id: number) => Promise<any>;
	fetchGroupList:() => Promise<any>;
	groupList:[] | any;
	groupUser:[] | any;
	fetchGroupUser:(id:any) => Promise<any>;
	fetchFineTypeList:() => Promise<any>;
	fineTypeList:[] | any;
}

const EditComponent: React.FC<EditProps> = ({
	EditID,
	EditData,
	visible,
	closeEditFinePopup,
	ReadData,
	fetchGroupList,
	groupList,
	groupUser,
	fetchGroupUser,
	fetchFineTypeList,
	fineTypeList,
}) => {
	const [form] = Form.useForm();
	const [saving, setSaving] = useState<boolean>(false);
	const { ROOT } = useStore();
	const [fine, setFine] = useState<any>();
	const { AssignErrorToInput } = ROOT;
	const { t } = useTranslation();
	const getUserByGroupId = (id:any) => {
		fetchGroupUser(id);
	}

	useEffect(() => {
		!groupList && fetchGroupList();
		!fineTypeList && fetchFineTypeList();
		if (EditID && !fine?.id) {
			ReadData(EditID).then(({ data }) => {
				setFine(data);
				fetchGroupUser(data?.group_id);
				form.setFieldsValue({
					id: data?.id,
					group_id: data?.group_id,
					user_id: data?.user_id,
					fine_type_id: data?.fine_type_id,
					fine_amount: data?.fine_amount,
					description: data?.description,
					fine_due_date: moment(data?.fine_due_date),
					date: moment(data?.date),
				});
			});
		}

	}, [EditID, fetchGroupList, fetchFineTypeList, getUserByGroupId]);



	// Handle submit and call function to save new record
	const handleSubmit = (data: any) => {
		setSaving(true);
		const formData = new FormData();
		formData.append('group_id', data.group_id);
		formData.append('user_id', data.user_id);
		formData.append('fine_type_id', data.fine_type_id);
		formData.append('fine_amount', data.fine_amount);
		formData.append('description', data.description);
		formData.append('fine_due_date', moment(data.fine_due_date).utc().format('YYYY-MM-DD'));
		formData.append('date', moment(data.date).utc().format('YYYY-MM-DD'));
		EditData(EditID,formData)
			.then(() => {
				close();
			})
			.catch((e) => {
				AssignErrorToInput(form, e?.data);
			})
			.finally(() => setSaving(false));
	};


	const close = () => {
		form.resetFields();
		setFine(null);
		closeEditFinePopup();
	};

	const disableFutureDate = (current: any) => {
		return current && current >= moment(form.getFieldValue('date')).endOf("day");
	};

	const disableDate = (current:any) =>{
		return current && current < moment(form.getFieldValue('fine_due_date')).endOf('day');
	}
	return (
		<Modal
			centered
			title={t("EDIT_FINE")}
			visible={visible}
			destroyOnClose={true}
			onCancel={close}
			footer={[
				<Button
					key="1"
					loading={saving}
					form="editFineForm"
					type="primary"
					htmlType="submit"
				>
					{t('SAVE')}
				</Button>,
				<Button key="2" onClick={close}>
					{t('CANCEL')}
				</Button>,
			]}
		>
			<FormBox
				id="editFineForm"
				form={form}
				encType="multipart/formdata"
				onFinish={handleSubmit}>
				{groupList && <InputBox.Select
					options={{
						list:groupList,
						valueKey:"id",
						textKey:"name"
					}}
					name="group_id"
					placeholder={t("SELECT_GROUP")}
					label={t("SELECT_GROUP")}
					rules={FineRequest(t).group_id}
					onChange={getUserByGroupId}
				/>}
                {groupUser && <InputBox.Select
					options={{
						list:groupUser,
						valueKey:"id",
						textKey:"user_name"
					}}
					name="user_id"
					label={t("SELECT_MEMBER")}
					placeholder={t("SELECT_MEMBER")}
					rules={FineRequest(t).user_id}
				/>}
                {fineTypeList && <InputBox.Select
					options={{
						list:fineTypeList,
						valueKey:"id",
						textKey:"name"
					}}
					name="fine_type_id"
					label={t("FINE_TYPE")}
					placeholder={t("FINE_TYPE")}
					rules={FineRequest(t).fine_type_id}
				/>}
				<InputBox.Text
					name="fine_amount"
					placeholder={t("AMOUNT")}
					label={t("AMOUNT")}
					maxLength={255}
					rules={FineRequest(t).fine_amount}
				/>
				<InputBox.DatePicker
					defaultValue={moment()}
					format={displayFormats.DATE_FORMAT}
					name="date"
					placeholder={t("DATE")}
					label={t("DATE")}
					rules={FineRequest(t).fine_date}
					disabledDate={disableFutureDate}
					
				/>
				<InputBox.DatePicker
					defaultValue={moment()}
					format={displayFormats.DATE_FORMAT}
					label={t("DUE_DATE")}
					name="fine_due_date"
					placeholder={t("DUE_DATE")}
					rules={FineRequest(t).fine_due_date}
					disabledDate={disableDate}
				/>
				<InputBox.TextArea
					name="description"
					rules={FineRequest(t).description}
					placeholder={t("DESCRIPTION")}
					label={t("DESCRIPTION")}
				/>
			</FormBox>
		</Modal>
	);
};

export default EditComponent;
