import React from "react";
import { AgGridColumn } from "ag-grid-react";
import AgGridWrapper from "../../../../components/AgGridWrapper/AgGridWrapper";
import { Button } from "antd";
import { EyeOutlined } from "@ant-design/icons";
import { GridReadyEvent } from "ag-grid-community";
import { useTranslation } from "react-i18next";
interface ListProps {
	setupGrid: (params: GridReadyEvent) => void;
	LoanDisbursedDetailsRedirect:(id:number) => void;
}
const ListComponent: React.FC<ListProps> = ({
	setupGrid,
	LoanDisbursedDetailsRedirect,
}) => {
	const { t } = useTranslation();

	const ActionRenderer = (params: any) => {
		return (
			<div className="action-column">
				<Button
					type="text"
					title={t("DETAILS")}
					icon={<EyeOutlined />}
					onClick={() => LoanDisbursedDetailsRedirect(params.data?.id)}
				></Button>
			</div>
		);
	};
    return (
        <>
            <AgGridWrapper type="serverSide" onGridReady={setupGrid} frameworkComponents={{ ActionRenderer}} overlayLoadingTemplate={t("PLEASE_WAIT_WHILE_DATA_IS_LOADING")} overlayNoRowsTemplate={t("NO_RECORD_FOUND")}>
			<AgGridColumn
				field="id"
				headerName={"# " + t('ID')}
				width={100}
				pinned={"left"}
			/>
			<AgGridColumn field="group_name" headerName={t('GROUP_NAME')} />
			<AgGridColumn field="numbers_of_members" headerName={t("NUMBER_OF_REQUEST")} />
			<AgGridColumn field="balance" headerName={t("GROUP_CAPITAL")} />
			<AgGridColumn field="loan_amount_approved" headerName={t("LOAN_AMOUNT_DISBURSED")} />
			<AgGridColumn field="loan_amount_pending_approve" headerName={t("PENDING_LOAN")} />
			<AgGridColumn
				headerName={t('ACTION')}
				type="actionColumn"
				sortable={false}
				filter={false}
				width={100}
			/>
		</AgGridWrapper>
        </>
	);
};

export default ListComponent;
