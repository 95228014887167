import React from "react";
import { InputWrapper, SplitInputWrapperProps } from "../../functions";
import { CheckboxInputBoxProps } from "./interface";

const CheckboxInputBox: React.FC<CheckboxInputBoxProps> = (props) => {
	const { formProps } = SplitInputWrapperProps(props);
	return <InputWrapper {...formProps}>Checkbox</InputWrapper>;
};

export default CheckboxInputBox;
