import React from "react";
import { observer } from "mobx-react";
import BreadcrumbComponent from "../../../components/BreadcrumbComponent/BreadcrumbComponent";
import { NewsBreadcrumb } from "../../../config/BreadcrumbConfig";
import HeaderButtons from "./components/HeaderButtons";
import ContentBox from "../../../components/ContentBox/ContentBox";
import ListComponent from "./components/ListComponent";
import useStore from "../../../store";
import { useHistory } from "react-router";
import DeleteComponent from "./components/DeleteComponent";
import RecordPerPage from "../../../utils/RecordPerPage";
import { useTranslation } from "react-i18next";
const NewsView: React.FC = observer(() => {
	const { t } = useTranslation();
	const pathHistory = useHistory();
	const { NEWS } = useStore();
	const { setupGrid, remove, changeStatus, deleteNewsID, deleteNewsPopup,	openDeleteNewsPopup, setPageSize, perPage, closeDeleteNewsPopup, } = NEWS;

	const addNews = () => {
		pathHistory.push("/news-management/create");
	};

	const editNews = (id: number) => {
		pathHistory.push("/news-management/" + id + "/edit");
	};
	return (
		<>
			<div className="Breadcrumb-bar">
				<BreadcrumbComponent items={NewsBreadcrumb(t).path} />
				<HeaderButtons key={1} addNewsRedirect={addNews} />
			</div>			
			<ContentBox>
				<div className="RecordPerPage__right">
					<RecordPerPage
						style={{ width: "150px" }}
						defaultValue={perPage + " per page"}
						onChange={setPageSize}
					/>
				</div>
				<ListComponent
					setupGrid={setupGrid}
					editNewsRedirect={editNews}
					ChangeStatus={changeStatus}
					openDeleteNewsPopup={openDeleteNewsPopup}
				/>
				<DeleteComponent
					DeleteID={deleteNewsID}
					DeleteData={remove}
					visible={deleteNewsPopup}
					closeDeleteNewsPopup={closeDeleteNewsPopup}
				/>
			</ContentBox>
		</>
	);
});

export default NewsView;
