import React, { useEffect } from "react";
import { AgGridColumn } from "ag-grid-react";
import moment from "moment";
import AgGridWrapper from "../../../../../components/AgGridWrapper/AgGridWrapper";
import Config from "../../../../../config/Config";
import DateComparator from "../../../../../utils/DateComparator";
import { ConfederationListDataProps } from "../../../../../store/ConfederationStore/ConfederationInterface";
import { Button, Switch } from "antd";
import { EditOutlined, DeleteOutlined } from "@ant-design/icons";
import { useTranslation } from "react-i18next";

interface ListProps {
	rowData: ConfederationListDataProps[];
	getData: () => Promise<any>;
	openEditConfederationPopup: (id: number) => void | any;
	openDeleteConfederationPopup: (id: number) => void | any;
	ChangeStatus:(id: number) => Promise<any>;
	editPermission:boolean;
	deletePermission:boolean;
}

const ListComponent: React.FC<ListProps> = ({
	rowData,
	getData,
	openEditConfederationPopup,
	openDeleteConfederationPopup,
	ChangeStatus,
	editPermission,
	deletePermission,
}) => {
	const { t } = useTranslation();
	
	useEffect(() => {
		getData();
	}, [getData]);

	const changeStatus = (data:any) => {
		ChangeStatus(data)
			.then(() => {
				return;
			})
			.finally(() => {
				return;
			});
	}
	const ActionRenderer = (params: any) => {
		return (
			<div className="action-column">
				<Button
					type="text"
					title={t("EDIT") + " " + t("CONFEDERATION")}
					icon={<EditOutlined />}
					onClick={() => openEditConfederationPopup(params.data?.id)}
					hidden={!editPermission}
				></Button>
				<Button
					type="text"
					title={t("DELETE") + " " + t("CONFEDERATION")}
					icon={<DeleteOutlined />}
					onClick={() => openDeleteConfederationPopup(params.data?.id)}
					hidden={!deletePermission}
				></Button>
			</div>
		);
	};

	const SwitchRenderer = (params: any) => {
		return (
			<div>
				<Switch
					defaultChecked={params.data?.status}
					onChange= {() => changeStatus({id:params.data?.id,status:params.data?.status ==1 ? 0 : 1})}
					disabled={!editPermission}
				/>
			</div>
		);
	};

	return (
		<AgGridWrapper rowData={rowData} frameworkComponents={{ ActionRenderer, SwitchRenderer}} overlayLoadingTemplate={t("PLEASE_WAIT_WHILE_DATA_IS_LOADING")} overlayNoRowsTemplate={t("NO_RECORD_FOUND")}>
			<AgGridColumn field="id" headerName={"# " + t('ID')} width={100} />
			<AgGridColumn field="name" headerName={t("NAME")} />
			<AgGridColumn
				field="status"
				headerName={t('STATUS')}
				cellRenderer="SwitchRenderer"
				width={120}
				pinned="right"
				sortable={false}
				filter={false}
			/>
			<AgGridColumn
				field="created_by_name"
				headerName={t("CREATED_BY")}
				width={150}
			/>
			<AgGridColumn
				field="updated_by_name"
				headerName={t("UPDATED_BY")}
				width={150}
			/>
			<AgGridColumn
				field="created_at"
				headerName={t('CREATED_AT')}
				valueGetter={({ data }) =>
					data.created_at &&
					moment(data.created_at).format(Config.dateTimeFormat)
				}
				filter={"agDateColumnFilter"}
				filterParams={{
					comparator: DateComparator,
				}}
				width={220}
			/>
			<AgGridColumn
				field="updated_at"
				headerName={t("UPDATED_AT")}
				valueGetter={({ data }) =>
					data.updated_at &&
					moment(data.updated_at).format(Config.dateTimeFormat)
				}
				filter={"agDateColumnFilter"}
				filterParams={{
					comparator: DateComparator,
				}}
				width={220}
			/>
			<AgGridColumn
				headerName={t('ACTION')}
				type="actionColumn"
				sortable={false}
				filter={false}
				width={100}
				pinned={"right"}
			/>
		</AgGridWrapper>
	);
};

export default ListComponent;
